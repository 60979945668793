import React, { Fragment, useEffect, useRef, useState } from 'react'
import { CustomHobby } from 'Widgets/Onboarding/Slides/Hobbies/components/CustomHobbies'
import Tag from 'std-lib/UiKit/Tag'
import styled from 'styled-components'

type OverflowWrapperProps = {
  tags: Required<CustomHobby>[]
}

const Wrapper = styled.div`
  max-height: 136px;
  overflow: hidden;
  position: relative;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  @media screen and (max-width: 1000px) {
    max-height: 76px;
  }
`

const OverflowList = ({ tags }: OverflowWrapperProps) => {
  const [overflowIndex, setOverflowIndex] = useState(tags.length - 1)
  const overflowRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (overflowRef.current && wrapperRef.current) {
      const wrapperHeight = wrapperRef.current.clientHeight

      if (wrapperHeight && overflowRef.current.offsetTop >= wrapperHeight) {
        setOverflowIndex(prev => (prev - 1 >= 0 ? prev - 1 : 0))
      }
    }
  }, [overflowIndex])

  return (
    <Wrapper ref={wrapperRef}>
      {tags.map((tag, index) =>
        index != overflowIndex ? (
          <Tag tag={tag.name} key={tag.name + index} />
        ) : (
          <Fragment key={tag.name + index}>
            <Tag tag={tag.name} />
            <div ref={overflowRef}>
              {tags.length - overflowIndex - 1 > 0 && (
                <Tag tag={`+${tags.length - overflowIndex - 1}`} />
              )}
            </div>
          </Fragment>
        ),
      )}
    </Wrapper>
  )
}

export default OverflowList
