import { createContext } from 'react'
import { NotificationConfig } from './types'

type NotificationsContextType = {
  displayNotification: (notification: NotificationConfig) => void
  closeNotification: () => void
  removeNotification: () => void
}

const NotificationsContext = createContext<NotificationsContextType>({
  displayNotification: (notification: NotificationConfig) => {
    console.log(
      'notification context didnt initialize properly, trying to display',
      notification,
    )
  },
  closeNotification: () => {
    console.log('notification context didnt initialize properly')
  },
  removeNotification: () => {
    console.log('notification context didnt initialize properly')
  },
})
export default NotificationsContext
