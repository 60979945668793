import React, { useContext, useEffect, useState } from 'react'
import { trajectoriesRequest } from '../../API/trajectoriesRequest'
import {
  CoverageWrapper,
  DisciplineDescription,
  DisciplineInfoBlock,
  DisciplineKeywordsWrapper,
  DisciplineModalTitle,
  DisciplineModalWrapper,
  DisciplineTitle,
  DropdownButton,
  DropdownButtonWrapper,
  DropDownModalTitle,
  DropDownModalWrapper,
  DropdownWrapper,
  HintText,
  InfoTag,
  KeywordsCoverageTitle,
  KeywordsTitle,
  PromptWrapper,
  SecondaryTitle,
  Tag,
  TagsWrapper,
} from './styled'
import { css } from 'styled-components'
import NextOrPrevDisciplinesBlock from './components/NextOrPrevDisciplinesBlock'
import PromptImg from '../../../../Images/icons/questionСircle'
import { colors } from '../../../../Constants'
import IconDropdownArrow from '../../../../Images/icons/IconDropdownArrow'
import ReplacementOptionsDropdown from './components/ReplacementOptionsDropdown'
import { useWindowDimensions } from '../../../../std-lib/Extensions/window'
import ModalContext from '../../../../std-lib/Сontext/Modal'
import SkeletonDiv from '../../../../std-lib/UiKit/SkeletonDiv/SkeletonDiv'
import { createSkeletonRows } from '../../../../std-lib/Сomponents/SkeletonRow'
import SkeletonText from '../../../../std-lib/Сomponents/SkeletonText/SkeletonText'
import { TrajectoryDisciplineType } from '../../types'
import Tooltip from '../../../../std-lib/Сomponents/Tooltip'

type DisciplineModalProps = {
  id: number
}

const DisciplineModal = ({ id }: DisciplineModalProps) => {
  const [discipline, setDiscipline] = useState<TrajectoryDisciplineType>()
  const [changedDiscipline, setChangedDiscipline] = useState<TrajectoryDisciplineType>()
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false)
  const { width } = useWindowDimensions()
  const { displayModal, closeModal } = useContext(ModalContext)

  useEffect(() => {
    trajectoriesRequest.getDisciplineData(id).then(({ data }) => {
      setDiscipline(data)
      setChangedDiscipline(data)
    })
  }, [id])

  const onDropdownButtonClick = () => {
    if (width > 1000) {
      setIsDropdownVisible(!isDropdownVisible)
    } else if (discipline && changedDiscipline) {
      displayModal(
        <DropDownModalWrapper>
          <DropDownModalTitle>Дисциплины на выбор</DropDownModalTitle>
          <ReplacementOptionsDropdown
            bestDisciplineId={id}
            items={[discipline, ...discipline.replacement_options]}
            activeItem={changedDiscipline}
            onItemClick={({ id }) => {
              closeModal()
              setChangedDiscipline(undefined)
              trajectoriesRequest
                .getDisciplineData(id)
                .then(({ data }) => setChangedDiscipline(data))
            }}
          />
        </DropDownModalWrapper>,
      )
    }
  }

  const keywordPercent = changedDiscipline
    ? (100 * changedDiscipline.keywords_coverage).toFixed(0)
    : 0

  return (
    <DisciplineModalWrapper
      withStickyBlock={
        changedDiscipline?.replacement_options &&
        changedDiscipline.replacement_options.length > 0
      }
    >
      {changedDiscipline ? (
        <div>
          <DisciplineTitle>
            <DisciplineModalTitle>{changedDiscipline.name}</DisciplineModalTitle>

            {discipline?.replacement_options &&
              discipline.replacement_options.length > 0 && (
                <DropdownButtonWrapper>
                  <DropdownButton
                    isActive={isDropdownVisible}
                    onClick={onDropdownButtonClick}
                  >
                    <span>Еще {discipline.replacement_options.length}</span>
                    <IconDropdownArrow rotate={0} />
                  </DropdownButton>

                  {isDropdownVisible && width > 1000 && (
                    <DropdownWrapper>
                      <ReplacementOptionsDropdown
                        bestDisciplineId={id}
                        items={[discipline, ...discipline.replacement_options]}
                        activeItem={changedDiscipline}
                        onItemClick={({ id }) => {
                          setIsDropdownVisible(false)
                          trajectoriesRequest
                            .getDisciplineData(id)
                            .then(({ data }) => setChangedDiscipline(data))
                        }}
                      />
                    </DropdownWrapper>
                  )}
                </DropdownButtonWrapper>
              )}
          </DisciplineTitle>
        </div>
      ) : (
        createSkeletonRows(1, { fontSize: 32 })
      )}

      <DisciplineInfoBlock>
        {changedDiscipline?.description && (
          <>
            <SecondaryTitle fragment={css`order: 1`}>О дисциплине</SecondaryTitle>
            <DisciplineDescription>{changedDiscipline.description}</DisciplineDescription>
          </>
        )}

        <TagsWrapper>
          {changedDiscipline ? (
            <>
              <InfoTag color={'#F7C8FF'}>{changedDiscipline?.semester} семестр</InfoTag>
              <InfoTag color={changedDiscipline?.necessity ? '#F6DBFC' : '#DBFFE1'}>
                {changedDiscipline?.necessity
                  ? 'Обязятельная дисциплина'
                  : 'Дисциплина по выбору'}
              </InfoTag>
              <InfoTag color={'#FFF3DB'}>{changedDiscipline?.control}</InfoTag>
            </>
          ) : (
            <>
              {Array.from(new Array(3), (_, index) => (
                <SkeletonDiv key={index} height={28} width={78} />
              ))}
            </>
          )}
        </TagsWrapper>
      </DisciplineInfoBlock>

      <NextOrPrevDisciplinesBlock
        disciplines={changedDiscipline?.prev_disciplines}
        title={'prev'}
      />
      <NextOrPrevDisciplinesBlock
        disciplines={changedDiscipline?.next_disciplines}
        title={'next'}
      />

      <DisciplineInfoBlock>
        {changedDiscipline ? (
          <>
            {changedDiscipline.keywords.length > 0 && (
              <KeywordsTitle>
                <SecondaryTitle>Знания и навыки</SecondaryTitle>

                <CoverageWrapper>
                  <KeywordsCoverageTitle>
                    {keywordPercent > 0 ? `Подходит на ${keywordPercent}%` : ''}
                  </KeywordsCoverageTitle>
                  <Tooltip
                    triggerElement={
                      <PromptWrapper>
                        <PromptImg primaryColor={'#B7B6BC'} />
                      </PromptWrapper>
                    }
                    tooltipContent={
                      <HintText>
                        Определяется{' '}
                        {(100 * changedDiscipline.keywords_coverage).toFixed(0)}%
                        совпадение ключевых слов между выбранной профессией и предметом
                        образовательной программы
                      </HintText>
                    }
                  />
                </CoverageWrapper>
              </KeywordsTitle>
            )}
            <DisciplineKeywordsWrapper>
              {changedDiscipline.keywords_aligned_with_user.map(keyword => (
                <Tag key={keyword} bgColor={`${colors[changedDiscipline.class]}60`}>
                  {keyword}
                </Tag>
              ))}
              {changedDiscipline.keywords
                .filter(
                  item => !changedDiscipline.keywords_aligned_with_user.includes(item),
                )
                .map(keyword => (
                  <Tag key={keyword} bgColor={`${colors[changedDiscipline.class]}20`}>
                    {keyword}
                  </Tag>
                ))}
            </DisciplineKeywordsWrapper>
          </>
        ) : (
          <>
            <SkeletonDiv height={24} width={140} />
            <SkeletonText wordCount={16} height={32} />
          </>
        )}
      </DisciplineInfoBlock>
    </DisciplineModalWrapper>
  )
}

export default DisciplineModal
