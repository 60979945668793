import React from 'react'
import ErrorLayout from '../ErrorLayout/ErrorLayout'
import { styledBigButton, StyledButtonsGrid } from '../ErrorLayout/styled'
import StyledButton from '../../../std-lib/UiKit/Button'

type ErrorPageProps = {
  title: string
  code: string | number
}

const NotFound = ({ code, title }: ErrorPageProps) => {
  return (
    <ErrorLayout title={code} subtitle={title}>
      <StyledButtonsGrid>
        {/* <StyledButton*/}
        {/*  buttonStyle={'main'}*/}
        {/*  styledFragment={styledBigButton}*/}
        {/*  onClick={() => location.reload()}*/}
        {/* >*/}
        {/*  Перезагрузить*/}
        {/* </StyledButton>*/}
        <StyledButton
          styledFragment={styledBigButton}
          buttonStyle={'secondary'}
          onClick={() => window.location.replace('/')}
        >
          На главную
        </StyledButton>
      </StyledButtonsGrid>
    </ErrorLayout>
  )
}

export default NotFound
