import React from 'react'
import Tag from '../../UiKit/Tag'
import { FlattenSimpleInterpolation } from 'styled-components'
import { makeEmptyArray } from 'std-lib/Extensions/array'
import { randomNumberBetween } from 'std-lib/Extensions/number'
import { TagSkeleton, Wrapper } from 'std-lib/Сomponents/Tags/styled'

interface TagsProps {
  tags?: { id: number; name: string }[]
  selectedTags?: { id: number; name: string }[]
  onSelectedTagsChange?: (selectedTags: { id: number; name: string }[]) => void
  styledFragment?: FlattenSimpleInterpolation
  limit?: number
  withSkeleton?: boolean
  withWrapper?: boolean
}

const Tags = (props: TagsProps) => {
  const {
    tags,
    onSelectedTagsChange,
    selectedTags,
    styledFragment,
    limit,
    withSkeleton,
    withWrapper = true,
  } = props

  const isDisabled = Boolean(limit && selectedTags && selectedTags.length >= limit)

  const toggleTag = (index: number) => {
    if (onSelectedTagsChange && selectedTags && tags) {
      const tag = tags[index]
      const isSelected = selectedTags.some(selectedTag => selectedTag.id === tag.id)

      if (isSelected || isDisabled) {
        onSelectedTagsChange(
          selectedTags.filter(selectedTag => selectedTag.id !== tag.id),
        )
      } else {
        onSelectedTagsChange([...selectedTags, tag])
      }
    }
  }

  const renderTags = () => (
    <>
      {tags &&
        tags.map((tag, index) => (
          <Tag
            key={tag.id + tag.name + index}
            tag={tag.name}
            selected={
              !!selectedTags &&
              selectedTags.some(selectedTag => selectedTag.id === tag.id)
            }
            disabled={isDisabled}
            onClick={onSelectedTagsChange ? () => toggleTag(index) : undefined}
          />
        ))}
      {withSkeleton &&
        !tags &&
        makeEmptyArray(10).map((_, i) => (
          <TagSkeleton key={i} width={randomNumberBetween(100, 250)} />
        ))}
    </>
  )

  return withWrapper ? (
    <Wrapper styledFragment={styledFragment}>{renderTags()}</Wrapper>
  ) : (
    renderTags()
  )
}

export default Tags
