import React from 'react';

const Ecologist = () => {
	return (
		<svg width="119" height="148" viewBox="0 0 119 148" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M72.7916 50.9663L71.8877 125.086H77.0098L75.3526 50.9663V48.104H72.7916V50.9663Z" stroke="#1F1F22"
			      strokeWidth="0.75"></path>
			<path d="M29.7059 75.1194L29 130H33L31.7059 75.1194V73H29.7059V75.1194Z" stroke="#1F1F22"
			      strokeWidth="0.75"></path>
			<path
				d="M85.5 121.5C99.7291 122.931 105.5 136 105.5 136H38H16.5C20.5 128.815 34 119 58.5 126.973C67.6292 123.601 77.9902 120.745 85.5 121.5Z"
				fill="#73DD44"></path>
			<path
				d="M13.5 136H16.5M38 136H105.5C105.5 136 99.7291 122.931 85.5 121.5C77.9902 120.745 67.6292 123.601 58.5 126.973M38 136H16.5M38 136C38 136 47.3277 131.1 58.5 126.973M16.5 136C20.5 128.815 34 119 58.5 126.973"
				stroke="#1F1F22" strokeWidth="0.75" strokeLinecap="round"></path>
			<path
				d="M28 14.5053C28 14.5053 36.0561 10.8413 41.5 11.0053C46.3793 11.1524 48.6195 14.4038 53.5 14.5053C58.7565 14.6147 66.5 11.0053 66.5 11.0053"
				stroke="#1F1F22" strokeWidth="0.75" strokeLinecap="round"></path>
			<path
				d="M3 26.5053C3 26.5053 11.0561 22.8413 16.5 23.0053C21.3793 23.1524 23.6195 26.4038 28.5 26.5053C33.7565 26.6147 41.5 23.0053 41.5 23.0053"
				stroke="#1F1F22" strokeWidth="0.75" strokeLinecap="round"></path>
			<g className="svgAnime rotate(90deg) n0">
				<rect width="59.5" height="68" transform="translate(1 39)" fill="white" fillOpacity="0.01"></rect>
				<path
					d="M30.0421 74.5541C29.4804 74.2263 29.1753 73.6219 29.1979 73.0095L27.05 73.3223C27.05 73.3223 -0.888429 84.2004 2.06927 89.3779C5.02697 94.5554 28.7401 76.2808 28.7401 76.2808L30.0715 74.5708C30.0617 74.5653 30.0519 74.5598 30.0421 74.5541Z"
					fill="#C6F0B3"></path>
				<path
					d="M30.088 71.5694C30.5195 71.3359 31.0428 71.2965 31.5166 71.4893L32.6799 69.5298C32.6799 69.5298 36.4827 39.6375 30.5673 39.6375C24.6519 39.6375 28.8772 69.5298 28.8772 69.5298L30.088 71.5694Z"
					fill="#C6F0B3"></path>
				<path
					d="M32.5759 73.0002C32.5898 73.3144 32.518 73.6362 32.3509 73.9288C32.1837 74.2214 31.9438 74.4451 31.6676 74.5901L32.9695 76.2622C32.9695 76.2622 56.4714 94.9066 59.4291 89.7291C62.3868 84.5516 34.6597 73.3036 34.6597 73.3036L32.5759 73.0002Z"
					fill="#C6F0B3"></path>
				<path
					d="M29.1979 73.0095C29.1753 73.6219 29.4804 74.2263 30.0421 74.5541C30.0519 74.5598 30.0617 74.5653 30.0715 74.5708C30.584 74.8565 31.182 74.845 31.6676 74.5901C31.9438 74.4451 32.1837 74.2214 32.3509 73.9288C32.518 73.6362 32.5898 73.3144 32.5759 73.0002C32.551 72.4371 32.2511 71.8982 31.7322 71.5955C31.6619 71.5544 31.5898 71.5191 31.5166 71.4893C31.0428 71.2965 30.5195 71.3359 30.088 71.5694C29.8197 71.7145 29.5869 71.9347 29.4235 72.2207C29.2811 72.47 29.2079 72.7405 29.1979 73.0095Z"
					fill="#C6F0B3"></path>
				<path
					d="M32.4075 72.9757L32.5759 73.0002M31.5624 74.455L31.6676 74.5901M30.1472 74.4736L30.0715 74.5708M29.3022 72.9943L29.1979 73.0095M30.1447 71.6649L30.088 71.5694M31.4123 71.6649L31.5166 71.4893M29.1979 73.0095C29.1753 73.6219 29.4804 74.2263 30.0421 74.5541C30.0519 74.5598 30.0617 74.5653 30.0715 74.5708M29.1979 73.0095L27.05 73.3223C27.05 73.3223 -0.888429 84.2004 2.06927 89.3779C5.02697 94.5554 28.7401 76.2808 28.7401 76.2808L30.0715 74.5708M29.1979 73.0095C29.2079 72.7405 29.2811 72.47 29.4235 72.2207C29.5869 71.9347 29.8197 71.7145 30.088 71.5694M30.0715 74.5708C30.584 74.8565 31.182 74.845 31.6676 74.5901M30.088 71.5694C30.5195 71.3359 31.0428 71.2965 31.5166 71.4893M30.088 71.5694L28.8772 69.5298C28.8772 69.5298 24.6519 39.6375 30.5673 39.6375C36.4827 39.6375 32.6799 69.5298 32.6799 69.5298L31.5166 71.4893M31.5166 71.4893C31.5898 71.5191 31.6619 71.5544 31.7322 71.5955C32.2511 71.8982 32.551 72.4371 32.5759 73.0002M32.5759 73.0002C32.5898 73.3144 32.518 73.6362 32.3509 73.9288C32.1837 74.2214 31.9438 74.4451 31.6676 74.5901M32.5759 73.0002L34.6597 73.3036C34.6597 73.3036 62.3868 84.5516 59.4291 89.7291C56.4714 94.9066 32.9695 76.2622 32.9695 76.2622L31.6676 74.5901"
					stroke="#1F1F22" strokeWidth="0.633796"></path>
			</g>
			<g className="svgAnime rotate(90deg) n1" clipPath="url(#clip0_5017_95950)">
				<rect width="70" height="80" transform="translate(50.5453 0.304199) rotate(15)" fill="white"
				      fillOpacity="0.01"></rect>
				<path
					d="M72.7223 49.5503C72.1838 49.0068 72.0211 48.2271 72.2334 47.538L69.6973 47.2394C69.6973 47.2394 34.6362 51.0941 36.4207 57.8783C38.2053 64.6625 70.717 51.1161 70.717 51.1161L72.7507 49.5783C72.7412 49.5691 72.7317 49.5598 72.7223 49.5503Z"
					fill="#C6F0B3"></path>
				<path
					d="M73.6833 46.1725C74.2448 46.0387 74.8515 46.1532 75.3311 46.5165L77.2498 44.644C77.2498 44.644 90.6731 11.8328 83.951 10.0316C77.2288 8.23037 72.9284 43.4861 72.9284 43.4861L73.6833 46.1725Z"
					fill="#C6F0B3"></path>
				<path
					d="M76.0748 48.5561C75.9949 48.9174 75.8154 49.2612 75.5364 49.5428C75.2573 49.8244 74.9166 50.0056 74.5586 50.0862L75.5289 52.3828C75.5289 52.3828 96.559 80.7261 101.497 75.7431C106.434 70.7601 78.3504 49.5353 78.3504 49.5353L76.0748 48.5561Z"
					fill="#C6F0B3"></path>
				<path
					d="M72.2334 47.538C72.0211 48.2271 72.1838 49.0068 72.7223 49.5503C72.7317 49.5598 72.7412 49.5691 72.7507 49.5783C73.246 50.0589 73.9291 50.228 74.5586 50.0862C74.9166 50.0056 75.2573 49.8244 75.5364 49.5428C75.8154 49.2612 75.9949 48.9174 76.0748 48.5561C76.2181 47.9085 76.0413 47.2049 75.5438 46.7029C75.4764 46.6348 75.4053 46.5727 75.3311 46.5165C74.8515 46.1532 74.2448 46.0387 73.6833 46.1725C73.3342 46.2558 73.0026 46.4351 72.7298 46.7104C72.4921 46.9503 72.3266 47.2354 72.2334 47.538Z"
					fill="#C6F0B3"></path>
				<path
					d="M75.8909 48.4769L76.0748 48.5561M74.4802 49.9007L74.5586 50.0862M72.8663 49.4909L72.7507 49.5783M72.3564 47.5525L72.2334 47.538M73.7187 46.2984L73.6833 46.1725M75.1592 46.6844L75.3311 46.5165M72.2334 47.538C72.0211 48.2271 72.1838 49.0068 72.7223 49.5503C72.7317 49.5598 72.7412 49.5691 72.7507 49.5783M72.2334 47.538L69.6973 47.2394C69.6973 47.2394 34.6362 51.0941 36.4207 57.8783C38.2053 64.6625 70.717 51.1161 70.717 51.1161L72.7507 49.5783M72.2334 47.538C72.3266 47.2354 72.4921 46.9503 72.7298 46.7104C73.0026 46.4351 73.3342 46.2558 73.6833 46.1725M72.7507 49.5783C73.246 50.0589 73.9291 50.228 74.5586 50.0862M73.6833 46.1725C74.2448 46.0387 74.8515 46.1532 75.3311 46.5165M73.6833 46.1725L72.9284 43.4861C72.9284 43.4861 77.2288 8.23037 83.951 10.0316C90.6731 11.8328 77.2498 44.644 77.2498 44.644L75.3311 46.5165M75.3311 46.5165C75.4053 46.5727 75.4764 46.6348 75.5438 46.7029C76.0413 47.2049 76.2181 47.9085 76.0748 48.5561M76.0748 48.5561C75.9949 48.9174 75.8154 49.2612 75.5364 49.5428C75.2573 49.8244 74.9166 50.0056 74.5586 50.0862M76.0748 48.5561L78.3504 49.5353C78.3504 49.5353 106.434 70.7601 101.497 75.7431C96.559 80.7261 75.5289 52.3828 75.5289 52.3828L74.5586 50.0862"
					stroke="#1F1F22" strokeWidth="0.745642"></path>
			</g>
			<defs>
				<clipPath id="clip0_5017_95950">
					<rect width="70" height="80" fill="white" transform="translate(50.5453 0.304199) rotate(15)"></rect>
				</clipPath>
			</defs>
		</svg>
	);
};

export default Ecologist;