import React from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import { ReactComponent as DeleteIcon } from './icons/deleteIcon.svg'

const HobbiesTag = styled.div`
  display: flex;
  gap: 12px;
  background-color: ${({ theme }) => theme.color.background.trackCard.humanitarian};
  border-radius: 20px;
  transition: all .3s;

  &:hover {
    background-color: ${({ theme }) => theme.color.link.hover};
  }

  &:active {
    background-color: ${({ theme }) => theme.color.link.active};
  }
`

const HobbieTitle = styled.span`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.text.white};
  padding: 8px 0 8px 12px;
  font-size: 16px;
  line-height: 24px;

  @media screen and (max-width: 1000px) {
    font-size: 14px;
    line-height: 18px;
  }
`

const HobbiesButton = styled.button`
  padding: 8px 12px 8px 6px;
  border-left: 2px solid ${({ theme }) => theme.color.text.white};
  color: ${({ theme }) => theme.color.text.white};
  display: flex;
  align-items: center;
`

type CustomHobbieTagProps = {
  title: string
  onDelete: () => void
}

const CustomHobbieTag = ({ title, onDelete }: CustomHobbieTagProps) => {
  return (
    <HobbiesTag>
      <HobbieTitle>{title}</HobbieTitle>
      <HobbiesButton onClick={onDelete}>
        <DeleteIcon height={isMobile ? 20 : 24} width={isMobile ? 20 : 24} />
      </HobbiesButton>
    </HobbiesTag>
  )
}

export default CustomHobbieTag
