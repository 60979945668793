import React from 'react'
import { DisciplineInfoBlock, SecondaryTitle } from '../../styled'
import NextOrPrevDiscipline from '../NextOrPrevDiscipline'
import styled from 'styled-components'
import { NextOrPrevDisciplinesType } from '../../../../types'

const titles = {
  prev: 'Необходимо изучить',
  next: 'Где пригодится',
}

type NextOrPrevDisciplinesBlockProps = {
  title: keyof typeof titles
  disciplines?: NextOrPrevDisciplinesType[]
}

export const DisciplinesWrapper = styled.div`
  flex-direction: row;
  display: flex;
  gap: 12px;

  @media screen and (max-width: 1000px){
    flex-wrap: wrap;
  }
`

const PrevOrNextDisciplinesBlock = ({
  title,
  disciplines,
}: NextOrPrevDisciplinesBlockProps) => {
  return disciplines && disciplines.length > 0 ? (
    <DisciplineInfoBlock>
      <SecondaryTitle>{titles[title]}</SecondaryTitle>

      <DisciplinesWrapper>
        {disciplines.map(item => (
          <NextOrPrevDiscipline key={item.id} {...item} />
        ))}
      </DisciplinesWrapper>
    </DisciplineInfoBlock>
  ) : null
}

export default PrevOrNextDisciplinesBlock
