import './newYear.scss'

export const NewYearDeco = () => {
  return (
    <svg
      width="2041"
      height="26"
      viewBox="0 0 2041 26"
      className="newYear"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3491_17150)">
        <path d="M8 8C29.703 11.116 42.075 12 64 12" stroke="#B2B9C3" />
        <g opacity="0.8" filter="url(#filter0_f_3491_17150)">
          <path
            d="M9.5 17C8.187 17 7.398 16 6.61 14.25C5.865 12.595 5.554 10.466 7.15 9.5C7.863 9.068 8.194 9 9.5 9C10.806 9 11.137 9.068 11.85 9.5C13.446 10.466 13.135 12.595 12.39 14.25C11.602 16 10.813 17 9.5 17Z"
            fill="#76D7C4"
            className="color1"
          />
        </g>
        <path
          d="M9.5 16C8.187 16 7.398 15 6.61 13.25C5.865 11.595 5.554 9.466 7.15 8.5C7.863 8.068 8.194 8 9.5 8C10.806 8 11.137 8.068 11.85 8.5C13.446 9.466 13.135 11.595 12.39 13.25C11.602 15 10.813 16 9.5 16Z"
          fill="#76D7C4"
          className="color1"
        />
      </g>
      <g clipPath="url(#clip1_3491_17150)">
        <path d="M122 8C122 8 87.925 12 66 12" stroke="#B2B9C3" />
        <g opacity="0.8" filter="url(#filter1_f_3491_17150)">
          <path
            d="M63.373 18.688C62.259 17.992 62.121 16.727 62.38 14.825C62.625 13.027 63.489 11.057 65.354 11.083C66.189 11.094 66.505 11.213 67.612 11.904C68.72 12.596 68.964 12.829 69.341 13.574C70.182 15.239 68.79 16.879 67.281 17.888C65.685 18.954 64.487 19.385 63.373 18.688Z"
            fill="#FFD39B"
            className="color2"
          />
        </g>
        <path
          d="M63.903 17.84C62.789 17.145 62.651 15.879 62.91 13.977C63.155 12.179 64.019 10.209 65.884 10.235C66.719 10.246 67.035 10.365 68.142 11.056C69.25 11.748 69.494 11.981 69.871 12.726C70.711 14.391 69.32 16.031 67.811 17.04C66.215 18.106 65.017 18.537 63.903 17.84Z"
          fill="#FFD39B"
          className="color2"
        />
      </g>
      <g clipPath="url(#clip2_3491_17150)">
        <path d="M124 8C124 8 152.088 10.196 180 11.334" stroke="#B2B9C3" />
        <g opacity="0.8" filter="url(#filter2_f_3491_17150)">
          <path
            d="M127.466 14.144C126.46 14.989 125.214 14.729 123.485 13.895C121.851 13.106 120.244 11.675 120.845 9.91002C121.114 9.12002 121.324 8.85502 122.324 8.01602C123.324 7.17602 123.621 7.01602 124.446 6.88802C126.29 6.60302 127.419 8.43402 127.912 10.18C128.433 12.027 128.472 13.3 127.466 14.144Z"
            fill="#D9ABFF"
            className="color3"
          />
        </g>
        <path
          d="M126.823 13.378C125.817 14.222 124.571 13.963 122.843 13.128C121.207 12.34 119.6 10.91 120.201 9.14501C120.47 8.35501 120.68 8.09001 121.681 7.25101C122.681 6.41101 122.977 6.25101 123.802 6.12301C125.646 5.83801 126.776 7.66901 127.268 9.41501C127.79 11.262 127.828 12.535 126.822 13.379L126.823 13.378Z"
          fill="#D9ABFF"
          className="color3"
        />
      </g>
      <g clipPath="url(#clip3_3491_17150)">
        <path
          d="M182 11.334C191.757 11.732 201.493 12 210 12C220.49 12 229.512 11.91 238 11.687"
          stroke="#B2B9C3"
        />
        <g opacity="0.8" filter="url(#filter3_f_3491_17150)">
          <path
            d="M187.517 9.48499C188.194 10.611 187.742 11.801 186.648 13.378C185.614 14.869 183.949 16.233 182.298 15.363C181.561 14.973 181.332 14.725 180.66 13.605C179.987 12.486 179.875 12.168 179.878 11.333C179.884 9.46699 181.87 8.63799 183.672 8.42499C185.578 8.19899 186.841 8.35899 187.517 9.48499Z"
            fill="#76D7C4"
            className="color1"
          />
        </g>
        <path
          d="M186.66 10C187.336 11.126 186.885 12.316 185.791 13.893C184.757 15.384 183.092 16.748 181.441 15.878C180.704 15.488 180.475 15.24 179.803 14.12C179.13 13.001 179.018 12.683 179.021 11.848C179.027 9.98201 181.012 9.15301 182.814 8.94001C184.72 8.71401 185.984 8.87401 186.66 10Z"
          fill="#76D7C4"
          className="color1"
        />
      </g>
      <g clipPath="url(#clip4_3491_17150)">
        <path d="M297.5 8C275.249 10.124 259.588 11.21 241.5 11.687" stroke="#B2B9C3" />
        <g opacity="0.8" filter="url(#filter4_f_3491_17150)">
          <path
            d="M237.538 8.48403C238.545 7.63903 239.791 7.89903 241.52 8.73303C243.154 9.52103 244.761 10.952 244.16 12.718C243.89 13.508 243.68 13.773 242.68 14.612C241.68 15.452 241.383 15.612 240.559 15.74C238.715 16.025 237.585 14.194 237.092 12.448C236.572 10.6 236.532 9.32803 237.538 8.48403Z"
            fill="#FFD39B"
            className="color2"
          />
        </g>
        <path
          d="M238.181 9.25002C239.187 8.40502 240.434 8.66502 242.162 9.49902C243.797 10.287 245.404 11.718 244.802 13.484C244.534 14.274 244.324 14.539 243.323 15.378C242.323 16.218 242.026 16.378 241.201 16.506C239.358 16.791 238.228 14.96 237.735 13.214C237.215 11.367 237.175 10.094 238.181 9.25002Z"
          fill="#FFD39B"
          className="color2"
        />
      </g>
      <g clipPath="url(#clip5_3491_17150)">
        <path d="M297 8C318.703 11.116 331.075 12 353 12" stroke="#B2B9C3" />
        <g opacity="0.8" filter="url(#filter5_f_3491_17150)">
          <path
            d="M298.5 17C297.187 17 296.398 16 295.61 14.25C294.865 12.595 294.554 10.466 296.15 9.5C296.863 9.068 297.194 9 298.5 9C299.806 9 300.137 9.068 300.85 9.5C302.446 10.466 302.135 12.595 301.39 14.25C300.602 16 299.813 17 298.5 17Z"
            fill="#D9ABFF"
            className="color3"
          />
        </g>
        <path
          d="M298.5 16C297.187 16 296.398 15 295.61 13.25C294.865 11.595 294.554 9.466 296.15 8.5C296.863 8.068 297.194 8 298.5 8C299.806 8 300.137 8.068 300.85 8.5C302.446 9.466 302.135 11.595 301.39 13.25C300.602 15 299.813 16 298.5 16Z"
          fill="#D9ABFF"
          className="color3"
        />
      </g>
      <g clipPath="url(#clip6_3491_17150)">
        <path d="M411 8C411 8 376.925 12 355 12" stroke="#B2B9C3" />
        <g opacity="0.8" filter="url(#filter6_f_3491_17150)">
          <path
            d="M352.373 18.688C351.259 17.992 351.121 16.727 351.38 14.825C351.625 13.027 352.489 11.057 354.354 11.083C355.189 11.094 355.505 11.213 356.612 11.904C357.72 12.596 357.964 12.829 358.341 13.574C359.182 15.239 357.79 16.879 356.281 17.888C354.685 18.954 353.487 19.385 352.373 18.688Z"
            fill="#76D7C4"
            className="color1"
          />
        </g>
        <path
          d="M352.903 17.84C351.789 17.145 351.651 15.879 351.91 13.977C352.155 12.179 353.019 10.209 354.884 10.235C355.719 10.246 356.035 10.365 357.142 11.056C358.25 11.748 358.494 11.981 358.871 12.726C359.711 14.391 358.32 16.031 356.811 17.04C355.215 18.106 354.017 18.537 352.903 17.84Z"
          fill="#76D7C4"
          className="color1"
        />
      </g>
      <g clipPath="url(#clip7_3491_17150)">
        <path d="M413 8C413 8 441.088 10.196 469 11.334" stroke="#B2B9C3" />
        <g opacity="0.8" filter="url(#filter7_f_3491_17150)">
          <path
            d="M416.466 14.144C415.46 14.989 414.214 14.729 412.485 13.895C410.851 13.106 409.244 11.675 409.845 9.91002C410.114 9.12002 410.324 8.85502 411.324 8.01602C412.324 7.17602 412.621 7.01602 413.446 6.88802C415.29 6.60302 416.419 8.43402 416.912 10.18C417.433 12.027 417.472 13.3 416.466 14.144Z"
            fill="#FFD39B"
            className="color2"
          />
        </g>
        <path
          d="M415.823 13.378C414.817 14.222 413.571 13.963 411.843 13.128C410.207 12.34 408.6 10.91 409.201 9.14501C409.47 8.35501 409.68 8.09001 410.681 7.25101C411.681 6.41101 411.977 6.25101 412.802 6.12301C414.646 5.83801 415.776 7.66901 416.268 9.41501C416.79 11.262 416.828 12.535 415.822 13.379L415.823 13.378Z"
          fill="#FFD39B"
          className="color2"
        />
      </g>
      <g clipPath="url(#clip8_3491_17150)">
        <path
          d="M471 11.334C480.757 11.732 490.493 12 499 12C509.49 12 518.512 11.91 527 11.687"
          stroke="#B2B9C3"
        />
        <g opacity="0.8" filter="url(#filter8_f_3491_17150)">
          <path
            d="M476.517 9.48499C477.194 10.611 476.742 11.801 475.648 13.378C474.614 14.869 472.949 16.233 471.298 15.363C470.561 14.973 470.332 14.725 469.66 13.605C468.987 12.486 468.875 12.168 468.878 11.333C468.884 9.46699 470.87 8.63799 472.672 8.42499C474.578 8.19899 475.841 8.35899 476.517 9.48499Z"
            fill="#D9ABFF"
            className="color3"
          />
        </g>
        <path
          d="M475.66 10C476.336 11.126 475.885 12.316 474.791 13.893C473.757 15.384 472.092 16.748 470.441 15.878C469.704 15.488 469.475 15.24 468.803 14.12C468.13 13.001 468.018 12.683 468.021 11.848C468.027 9.98201 470.012 9.15301 471.814 8.94001C473.72 8.71401 474.984 8.87401 475.66 10Z"
          fill="#D9ABFF"
          className="color3"
        />
      </g>
      <g clipPath="url(#clip9_3491_17150)">
        <path d="M586.5 8C564.249 10.124 548.588 11.21 530.5 11.687" stroke="#B2B9C3" />
        <g opacity="0.8" filter="url(#filter9_f_3491_17150)">
          <path
            d="M526.538 8.48403C527.545 7.63903 528.791 7.89903 530.52 8.73303C532.154 9.52103 533.761 10.952 533.16 12.718C532.89 13.508 532.68 13.773 531.68 14.612C530.68 15.452 530.383 15.612 529.559 15.74C527.715 16.025 526.585 14.194 526.092 12.448C525.572 10.6 525.532 9.32803 526.538 8.48403Z"
            fill="#76D7C4"
            className="color1"
          />
        </g>
        <path
          d="M527.181 9.25002C528.187 8.40502 529.434 8.66502 531.162 9.49902C532.797 10.287 534.404 11.718 533.802 13.484C533.534 14.274 533.324 14.539 532.323 15.378C531.323 16.218 531.026 16.378 530.201 16.506C528.358 16.791 527.228 14.96 526.735 13.214C526.215 11.367 526.175 10.094 527.181 9.25002Z"
          fill="#76D7C4"
          className="color1"
        />
      </g>
      <g clipPath="url(#clip10_3491_17150)">
        <path d="M586 8C607.703 11.116 620.075 12 642 12" stroke="#B2B9C3" />
        <g opacity="0.8" filter="url(#filter10_f_3491_17150)">
          <path
            d="M587.5 17C586.187 17 585.398 16 584.61 14.25C583.865 12.595 583.554 10.466 585.15 9.5C585.863 9.068 586.194 9 587.5 9C588.806 9 589.137 9.068 589.85 9.5C591.446 10.466 591.135 12.595 590.39 14.25C589.602 16 588.813 17 587.5 17Z"
            fill="#FFD39B"
            className="color2"
          />
        </g>
        <path
          d="M587.5 16C586.187 16 585.398 15 584.61 13.25C583.865 11.595 583.554 9.466 585.15 8.5C585.863 8.068 586.194 8 587.5 8C588.806 8 589.137 8.068 589.85 8.5C591.446 9.466 591.135 11.595 590.39 13.25C589.602 15 588.813 16 587.5 16Z"
          fill="#FFD39B"
          className="color2"
        />
      </g>
      <g clipPath="url(#clip11_3491_17150)">
        <path d="M700 8C700 8 665.925 12 644 12" stroke="#B2B9C3" />
        <g opacity="0.8" filter="url(#filter11_f_3491_17150)">
          <path
            d="M641.373 18.688C640.259 17.992 640.121 16.727 640.38 14.825C640.625 13.027 641.489 11.057 643.354 11.083C644.189 11.094 644.505 11.213 645.612 11.904C646.72 12.596 646.964 12.829 647.341 13.574C648.182 15.239 646.79 16.879 645.281 17.888C643.685 18.954 642.487 19.385 641.373 18.688Z"
            fill="#D9ABFF"
            className="color3"
          />
        </g>
        <path
          d="M641.903 17.84C640.789 17.145 640.651 15.879 640.91 13.977C641.155 12.179 642.019 10.209 643.884 10.235C644.719 10.246 645.035 10.365 646.142 11.056C647.25 11.748 647.494 11.981 647.871 12.726C648.711 14.391 647.32 16.031 645.811 17.04C644.215 18.106 643.017 18.537 641.903 17.84Z"
          fill="#D9ABFF"
          className="color3"
        />
      </g>
      <g clipPath="url(#clip12_3491_17150)">
        <path d="M702 8C702 8 730.088 10.196 758 11.334" stroke="#B2B9C3" />
        <g opacity="0.8" filter="url(#filter12_f_3491_17150)">
          <path
            d="M705.466 14.144C704.46 14.989 703.214 14.729 701.485 13.895C699.851 13.106 698.244 11.675 698.845 9.91002C699.114 9.12002 699.324 8.85502 700.324 8.01602C701.324 7.17602 701.621 7.01602 702.446 6.88802C704.29 6.60302 705.419 8.43402 705.912 10.18C706.433 12.027 706.472 13.3 705.466 14.144Z"
            fill="#76D7C4"
            className="color1"
          />
        </g>
        <path
          d="M704.823 13.378C703.817 14.222 702.571 13.963 700.843 13.128C699.207 12.34 697.6 10.91 698.201 9.14501C698.47 8.35501 698.68 8.09001 699.681 7.25101C700.681 6.41101 700.977 6.25101 701.802 6.12301C703.646 5.83801 704.776 7.66901 705.268 9.41501C705.79 11.262 705.828 12.535 704.822 13.379L704.823 13.378Z"
          fill="#76D7C4"
          className="color1"
        />
      </g>
      <g clipPath="url(#clip13_3491_17150)">
        <path
          d="M760 11.334C769.757 11.732 779.493 12 788 12C798.49 12 807.512 11.91 816 11.687"
          stroke="#B2B9C3"
        />
        <g opacity="0.8" filter="url(#filter13_f_3491_17150)">
          <path
            d="M765.517 9.48499C766.194 10.611 765.742 11.801 764.648 13.378C763.614 14.869 761.949 16.233 760.298 15.363C759.561 14.973 759.332 14.725 758.66 13.605C757.987 12.486 757.875 12.168 757.878 11.333C757.884 9.46699 759.87 8.63799 761.672 8.42499C763.578 8.19899 764.841 8.35899 765.517 9.48499Z"
            fill="#FFD39B"
            className="color2"
          />
        </g>
        <path
          d="M764.66 10C765.336 11.126 764.885 12.316 763.791 13.893C762.757 15.384 761.092 16.748 759.441 15.878C758.704 15.488 758.475 15.24 757.803 14.12C757.13 13.001 757.018 12.683 757.021 11.848C757.027 9.98201 759.012 9.15301 760.814 8.94001C762.72 8.71401 763.984 8.87401 764.66 10Z"
          fill="#FFD39B"
          className="color2"
        />
      </g>
      <g clipPath="url(#clip14_3491_17150)">
        <path d="M875.5 8C853.249 10.124 837.588 11.21 819.5 11.687" stroke="#B2B9C3" />
        <g opacity="0.8" filter="url(#filter14_f_3491_17150)">
          <path
            d="M815.538 8.48403C816.545 7.63903 817.791 7.89903 819.52 8.73303C821.154 9.52103 822.761 10.952 822.16 12.718C821.89 13.508 821.68 13.773 820.68 14.612C819.68 15.452 819.383 15.612 818.559 15.74C816.715 16.025 815.585 14.194 815.092 12.448C814.572 10.6 814.532 9.32803 815.538 8.48403Z"
            fill="#D9ABFF"
            className="color3"
          />
        </g>
        <path
          d="M816.181 9.25002C817.187 8.40502 818.434 8.66502 820.162 9.49902C821.797 10.287 823.404 11.718 822.802 13.484C822.534 14.274 822.324 14.539 821.323 15.378C820.323 16.218 820.026 16.378 819.201 16.506C817.358 16.791 816.228 14.96 815.735 13.214C815.215 11.367 815.175 10.094 816.181 9.25002Z"
          fill="#D9ABFF"
          className="color3"
        />
      </g>
      <g clipPath="url(#clip15_3491_17150)">
        <path d="M875 8C896.703 11.116 909.075 12 931 12" stroke="#B2B9C3" />
        <g opacity="0.8" filter="url(#filter15_f_3491_17150)">
          <path
            d="M876.5 17C875.187 17 874.398 16 873.61 14.25C872.865 12.595 872.554 10.466 874.15 9.5C874.863 9.068 875.194 9 876.5 9C877.806 9 878.137 9.068 878.85 9.5C880.446 10.466 880.135 12.595 879.39 14.25C878.602 16 877.813 17 876.5 17Z"
            fill="#76D7C4"
            className="color1"
          />
        </g>
        <path
          d="M876.5 16C875.187 16 874.398 15 873.61 13.25C872.865 11.595 872.554 9.466 874.15 8.5C874.863 8.068 875.194 8 876.5 8C877.806 8 878.137 8.068 878.85 8.5C880.446 9.466 880.135 11.595 879.39 13.25C878.602 15 877.813 16 876.5 16Z"
          fill="#76D7C4"
          className="color1"
        />
      </g>
      <g clipPath="url(#clip16_3491_17150)">
        <path d="M989 8C989 8 954.925 12 933 12" stroke="#B2B9C3" />
        <g opacity="0.8" filter="url(#filter16_f_3491_17150)">
          <path
            d="M930.373 18.688C929.259 17.992 929.121 16.727 929.38 14.825C929.625 13.027 930.489 11.057 932.354 11.083C933.189 11.094 933.505 11.213 934.612 11.904C935.72 12.596 935.964 12.829 936.341 13.574C937.182 15.239 935.79 16.879 934.281 17.888C932.685 18.954 931.487 19.385 930.373 18.688Z"
            fill="#FFD39B"
            className="color2"
          />
        </g>
        <path
          d="M930.903 17.84C929.789 17.145 929.651 15.879 929.91 13.977C930.155 12.179 931.019 10.209 932.884 10.235C933.719 10.246 934.035 10.365 935.142 11.056C936.25 11.748 936.494 11.981 936.871 12.726C937.711 14.391 936.32 16.031 934.811 17.04C933.215 18.106 932.017 18.537 930.903 17.84Z"
          fill="#FFD39B"
          className="color2"
        />
      </g>
      <g clipPath="url(#clip17_3491_17150)">
        <path d="M991 8C991 8 1019.09 10.196 1047 11.334" stroke="#B2B9C3" />
        <g opacity="0.8" filter="url(#filter17_f_3491_17150)">
          <path
            d="M994.466 14.144C993.46 14.989 992.214 14.729 990.485 13.895C988.851 13.106 987.244 11.675 987.845 9.91002C988.114 9.12002 988.324 8.85502 989.324 8.01602C990.324 7.17602 990.621 7.01602 991.446 6.88802C993.29 6.60302 994.419 8.43402 994.912 10.18C995.433 12.027 995.472 13.3 994.466 14.144Z"
            fill="#D9ABFF"
            className="color3"
          />
        </g>
        <path
          d="M993.823 13.378C992.817 14.222 991.571 13.963 989.843 13.128C988.207 12.34 986.6 10.91 987.201 9.14501C987.47 8.35501 987.68 8.09001 988.681 7.25101C989.681 6.41101 989.977 6.25101 990.802 6.12301C992.646 5.83801 993.776 7.66901 994.268 9.41501C994.79 11.262 994.828 12.535 993.822 13.379L993.823 13.378Z"
          fill="#D9ABFF"
          className="color3"
        />
      </g>
      <g clipPath="url(#clip18_3491_17150)">
        <path
          d="M1049 11.334C1058.76 11.732 1068.49 12 1077 12C1087.49 12 1096.51 11.91 1105 11.687"
          stroke="#B2B9C3"
        />
        <g opacity="0.8" filter="url(#filter18_f_3491_17150)">
          <path
            d="M1054.52 9.48499C1055.19 10.611 1054.74 11.801 1053.65 13.378C1052.61 14.869 1050.95 16.233 1049.3 15.363C1048.56 14.973 1048.33 14.725 1047.66 13.605C1046.99 12.486 1046.87 12.168 1046.88 11.333C1046.88 9.46699 1048.87 8.63799 1050.67 8.42499C1052.58 8.19899 1053.84 8.35899 1054.52 9.48499Z"
            fill="#76D7C4"
            className="color1"
          />
        </g>
        <path
          d="M1053.66 10C1054.34 11.126 1053.89 12.316 1052.79 13.893C1051.76 15.384 1050.09 16.748 1048.44 15.878C1047.7 15.488 1047.48 15.24 1046.8 14.12C1046.13 13.001 1046.02 12.683 1046.02 11.848C1046.03 9.98201 1048.01 9.15301 1049.81 8.94001C1051.72 8.71401 1052.98 8.87401 1053.66 10Z"
          fill="#76D7C4"
          className="color1"
        />
      </g>
      <g clipPath="url(#clip19_3491_17150)">
        <path d="M1164.5 8C1142.25 10.124 1126.59 11.21 1108.5 11.687" stroke="#B2B9C3" />
        <g opacity="0.8" filter="url(#filter19_f_3491_17150)">
          <path
            d="M1104.54 8.48403C1105.54 7.63903 1106.79 7.89903 1108.52 8.73303C1110.15 9.52103 1111.76 10.952 1111.16 12.718C1110.89 13.508 1110.68 13.773 1109.68 14.612C1108.68 15.452 1108.38 15.612 1107.56 15.74C1105.71 16.025 1104.58 14.194 1104.09 12.448C1103.57 10.6 1103.53 9.32803 1104.54 8.48403Z"
            fill="#FFD39B"
            className="color2"
          />
        </g>
        <path
          d="M1105.18 9.25002C1106.19 8.40502 1107.43 8.66502 1109.16 9.49902C1110.8 10.287 1112.4 11.718 1111.8 13.484C1111.53 14.274 1111.32 14.539 1110.32 15.378C1109.32 16.218 1109.03 16.378 1108.2 16.506C1106.36 16.791 1105.23 14.96 1104.74 13.214C1104.22 11.367 1104.18 10.094 1105.18 9.25002Z"
          fill="#FFD39B"
          className="color2"
        />
      </g>
      <g clipPath="url(#clip20_3491_17150)">
        <path d="M1164 8C1185.7 11.116 1198.08 12 1220 12" stroke="#B2B9C3" />
        <g opacity="0.8" filter="url(#filter20_f_3491_17150)">
          <path
            d="M1165.5 17C1164.19 17 1163.4 16 1162.61 14.25C1161.86 12.595 1161.55 10.466 1163.15 9.5C1163.86 9.068 1164.19 9 1165.5 9C1166.81 9 1167.14 9.068 1167.85 9.5C1169.45 10.466 1169.13 12.595 1168.39 14.25C1167.6 16 1166.81 17 1165.5 17Z"
            fill="#D9ABFF"
            className="color3"
          />
        </g>
        <path
          d="M1165.5 16C1164.19 16 1163.4 15 1162.61 13.25C1161.86 11.595 1161.55 9.466 1163.15 8.5C1163.86 8.068 1164.19 8 1165.5 8C1166.81 8 1167.14 8.068 1167.85 8.5C1169.45 9.466 1169.13 11.595 1168.39 13.25C1167.6 15 1166.81 16 1165.5 16Z"
          fill="#D9ABFF"
          className="color3"
        />
      </g>
      <g clipPath="url(#clip21_3491_17150)">
        <path d="M1278 8C1278 8 1243.92 12 1222 12" stroke="#B2B9C3" />
        <g opacity="0.8" filter="url(#filter21_f_3491_17150)">
          <path
            d="M1219.37 18.688C1218.26 17.992 1218.12 16.727 1218.38 14.825C1218.62 13.027 1219.49 11.057 1221.35 11.083C1222.19 11.094 1222.5 11.213 1223.61 11.904C1224.72 12.596 1224.96 12.829 1225.34 13.574C1226.18 15.239 1224.79 16.879 1223.28 17.888C1221.68 18.954 1220.49 19.385 1219.37 18.688Z"
            fill="#76D7C4"
            className="color1"
          />
        </g>
        <path
          d="M1219.9 17.84C1218.79 17.145 1218.65 15.879 1218.91 13.977C1219.15 12.179 1220.02 10.209 1221.88 10.235C1222.72 10.246 1223.03 10.365 1224.14 11.056C1225.25 11.748 1225.49 11.981 1225.87 12.726C1226.71 14.391 1225.32 16.031 1223.81 17.04C1222.21 18.106 1221.02 18.537 1219.9 17.84Z"
          fill="#76D7C4"
          className="color1"
        />
      </g>
      <g clipPath="url(#clip22_3491_17150)">
        <path d="M1280 8C1280 8 1308.09 10.196 1336 11.334" stroke="#B2B9C3" />
        <g opacity="0.8" filter="url(#filter22_f_3491_17150)">
          <path
            d="M1283.47 14.144C1282.46 14.989 1281.21 14.729 1279.48 13.895C1277.85 13.106 1276.24 11.675 1276.84 9.91002C1277.11 9.12002 1277.32 8.85502 1278.32 8.01602C1279.32 7.17602 1279.62 7.01602 1280.45 6.88802C1282.29 6.60302 1283.42 8.43402 1283.91 10.18C1284.43 12.027 1284.47 13.3 1283.47 14.144Z"
            fill="#FFD39B"
            className="color2"
          />
        </g>
        <path
          d="M1282.82 13.378C1281.82 14.222 1280.57 13.963 1278.84 13.128C1277.21 12.34 1275.6 10.91 1276.2 9.14501C1276.47 8.35501 1276.68 8.09001 1277.68 7.25101C1278.68 6.41101 1278.98 6.25101 1279.8 6.12301C1281.65 5.83801 1282.78 7.66901 1283.27 9.41501C1283.79 11.262 1283.83 12.535 1282.82 13.379L1282.82 13.378Z"
          fill="#FFD39B"
          className="color2"
        />
      </g>
      <g clipPath="url(#clip23_3491_17150)">
        <path
          d="M1338 11.334C1347.76 11.732 1357.49 12 1366 12C1376.49 12 1385.51 11.91 1394 11.687"
          stroke="#B2B9C3"
        />
        <g opacity="0.8" filter="url(#filter23_f_3491_17150)">
          <path
            d="M1343.52 9.48499C1344.19 10.611 1343.74 11.801 1342.65 13.378C1341.61 14.869 1339.95 16.233 1338.3 15.363C1337.56 14.973 1337.33 14.725 1336.66 13.605C1335.99 12.486 1335.87 12.168 1335.88 11.333C1335.88 9.46699 1337.87 8.63799 1339.67 8.42499C1341.58 8.19899 1342.84 8.35899 1343.52 9.48499Z"
            fill="#D9ABFF"
            className="color3"
          />
        </g>
        <path
          d="M1342.66 10C1343.34 11.126 1342.89 12.316 1341.79 13.893C1340.76 15.384 1339.09 16.748 1337.44 15.878C1336.7 15.488 1336.48 15.24 1335.8 14.12C1335.13 13.001 1335.02 12.683 1335.02 11.848C1335.03 9.98201 1337.01 9.15301 1338.81 8.94001C1340.72 8.71401 1341.98 8.87401 1342.66 10Z"
          fill="#D9ABFF"
          className="color3"
        />
      </g>
      <g clipPath="url(#clip24_3491_17150)">
        <path d="M1453.5 8C1431.25 10.124 1415.59 11.21 1397.5 11.687" stroke="#B2B9C3" />
        <g opacity="0.8" filter="url(#filter24_f_3491_17150)">
          <path
            d="M1393.54 8.48403C1394.54 7.63903 1395.79 7.89903 1397.52 8.73303C1399.15 9.52103 1400.76 10.952 1400.16 12.718C1399.89 13.508 1399.68 13.773 1398.68 14.612C1397.68 15.452 1397.38 15.612 1396.56 15.74C1394.71 16.025 1393.58 14.194 1393.09 12.448C1392.57 10.6 1392.53 9.32803 1393.54 8.48403Z"
            fill="#76D7C4"
            className="color1"
          />
        </g>
        <path
          d="M1394.18 9.25002C1395.19 8.40502 1396.43 8.66502 1398.16 9.49902C1399.8 10.287 1401.4 11.718 1400.8 13.484C1400.53 14.274 1400.32 14.539 1399.32 15.378C1398.32 16.218 1398.03 16.378 1397.2 16.506C1395.36 16.791 1394.23 14.96 1393.74 13.214C1393.22 11.367 1393.18 10.094 1394.18 9.25002Z"
          fill="#76D7C4"
          className="color1"
        />
      </g>
      <g clipPath="url(#clip25_3491_17150)">
        <path d="M1453 8C1474.7 11.116 1487.08 12 1509 12" stroke="#B2B9C3" />
        <g opacity="0.8" filter="url(#filter25_f_3491_17150)">
          <path
            d="M1454.5 17C1453.19 17 1452.4 16 1451.61 14.25C1450.86 12.595 1450.55 10.466 1452.15 9.5C1452.86 9.068 1453.19 9 1454.5 9C1455.81 9 1456.14 9.068 1456.85 9.5C1458.45 10.466 1458.13 12.595 1457.39 14.25C1456.6 16 1455.81 17 1454.5 17Z"
            fill="#FFD39B"
            className="color2"
          />
        </g>
        <path
          d="M1454.5 16C1453.19 16 1452.4 15 1451.61 13.25C1450.86 11.595 1450.55 9.466 1452.15 8.5C1452.86 8.068 1453.19 8 1454.5 8C1455.81 8 1456.14 8.068 1456.85 8.5C1458.45 9.466 1458.13 11.595 1457.39 13.25C1456.6 15 1455.81 16 1454.5 16Z"
          fill="#FFD39B"
          className="color2"
        />
      </g>
      <g clipPath="url(#clip26_3491_17150)">
        <path d="M1567 8C1567 8 1532.92 12 1511 12" stroke="#B2B9C3" />
        <g opacity="0.8" filter="url(#filter26_f_3491_17150)">
          <path
            d="M1508.37 18.688C1507.26 17.992 1507.12 16.727 1507.38 14.825C1507.62 13.027 1508.49 11.057 1510.35 11.083C1511.19 11.094 1511.5 11.213 1512.61 11.904C1513.72 12.596 1513.96 12.829 1514.34 13.574C1515.18 15.239 1513.79 16.879 1512.28 17.888C1510.68 18.954 1509.49 19.385 1508.37 18.688Z"
            fill="#D9ABFF"
            className="color3"
          />
        </g>
        <path
          d="M1508.9 17.84C1507.79 17.145 1507.65 15.879 1507.91 13.977C1508.15 12.179 1509.02 10.209 1510.88 10.235C1511.72 10.246 1512.03 10.365 1513.14 11.056C1514.25 11.748 1514.49 11.981 1514.87 12.726C1515.71 14.391 1514.32 16.031 1512.81 17.04C1511.21 18.106 1510.02 18.537 1508.9 17.84Z"
          fill="#D9ABFF"
          className="color3"
        />
      </g>
      <g clipPath="url(#clip27_3491_17150)">
        <path d="M1569 8C1569 8 1597.09 10.196 1625 11.334" stroke="#B2B9C3" />
        <g opacity="0.8" filter="url(#filter27_f_3491_17150)">
          <path
            d="M1572.47 14.144C1571.46 14.989 1570.21 14.729 1568.48 13.895C1566.85 13.106 1565.24 11.675 1565.84 9.91002C1566.11 9.12002 1566.32 8.85502 1567.32 8.01602C1568.32 7.17602 1568.62 7.01602 1569.45 6.88802C1571.29 6.60302 1572.42 8.43402 1572.91 10.18C1573.43 12.027 1573.47 13.3 1572.47 14.144Z"
            fill="#FFD39B"
            className="color2"
          />
        </g>
        <path
          d="M1571.82 13.378C1570.82 14.222 1569.57 13.963 1567.84 13.128C1566.21 12.34 1564.6 10.91 1565.2 9.14501C1565.47 8.35501 1565.68 8.09001 1566.68 7.25101C1567.68 6.41101 1567.98 6.25101 1568.8 6.12301C1570.65 5.83801 1571.78 7.66901 1572.27 9.41501C1572.79 11.262 1572.83 12.535 1571.82 13.379L1571.82 13.378Z"
          fill="#FFD39B"
          className="color2"
        />
      </g>
      <g clipPath="url(#clip28_3491_17150)">
        <path
          d="M1627 11.334C1636.76 11.732 1646.49 12 1655 12C1665.49 12 1674.51 11.91 1683 11.687"
          stroke="#B2B9C3"
        />
        <g opacity="0.8" filter="url(#filter28_f_3491_17150)">
          <path
            d="M1632.52 9.48499C1633.19 10.611 1632.74 11.801 1631.65 13.378C1630.61 14.869 1628.95 16.233 1627.3 15.363C1626.56 14.973 1626.33 14.725 1625.66 13.605C1624.99 12.486 1624.87 12.168 1624.88 11.333C1624.88 9.46699 1626.87 8.63799 1628.67 8.42499C1630.58 8.19899 1631.84 8.35899 1632.52 9.48499Z"
            fill="#76D7C4"
            className="color1"
          />
        </g>
        <path
          d="M1631.66 10C1632.34 11.126 1631.89 12.316 1630.79 13.893C1629.76 15.384 1628.09 16.748 1626.44 15.878C1625.7 15.488 1625.48 15.24 1624.8 14.12C1624.13 13.001 1624.02 12.683 1624.02 11.848C1624.03 9.98201 1626.01 9.15301 1627.81 8.94001C1629.72 8.71401 1630.98 8.87401 1631.66 10Z"
          fill="#76D7C4"
          className="color1"
        />
      </g>
      <g clipPath="url(#clip29_3491_17150)">
        <path d="M1742.5 8C1720.25 10.124 1704.59 11.21 1686.5 11.687" stroke="#B2B9C3" />
        <g opacity="0.8" filter="url(#filter29_f_3491_17150)">
          <path
            d="M1682.54 8.48403C1683.54 7.63903 1684.79 7.89903 1686.52 8.73303C1688.15 9.52103 1689.76 10.952 1689.16 12.718C1688.89 13.508 1688.68 13.773 1687.68 14.612C1686.68 15.452 1686.38 15.612 1685.56 15.74C1683.71 16.025 1682.58 14.194 1682.09 12.448C1681.57 10.6 1681.53 9.32803 1682.54 8.48403Z"
            fill="#D9ABFF"
            className="color3"
          />
        </g>
        <path
          d="M1683.18 9.25002C1684.19 8.40502 1685.43 8.66502 1687.16 9.49902C1688.8 10.287 1690.4 11.718 1689.8 13.484C1689.53 14.274 1689.32 14.539 1688.32 15.378C1687.32 16.218 1687.03 16.378 1686.2 16.506C1684.36 16.791 1683.23 14.96 1682.74 13.214C1682.22 11.367 1682.18 10.094 1683.18 9.25002Z"
          fill="#D9ABFF"
          className="color3"
        />
      </g>
      <g clipPath="url(#clip30_3491_17150)">
        <path d="M1742 8C1763.7 11.116 1776.08 12 1798 12" stroke="#B2B9C3" />
        <g opacity="0.8" filter="url(#filter30_f_3491_17150)">
          <path
            d="M1743.5 17C1742.19 17 1741.4 16 1740.61 14.25C1739.86 12.595 1739.55 10.466 1741.15 9.5C1741.86 9.068 1742.19 9 1743.5 9C1744.81 9 1745.14 9.068 1745.85 9.5C1747.45 10.466 1747.13 12.595 1746.39 14.25C1745.6 16 1744.81 17 1743.5 17Z"
            fill="#FFD39B"
            className="color2"
          />
        </g>
        <path
          d="M1743.5 16C1742.19 16 1741.4 15 1740.61 13.25C1739.86 11.595 1739.55 9.466 1741.15 8.5C1741.86 8.068 1742.19 8 1743.5 8C1744.81 8 1745.14 8.068 1745.85 8.5C1747.45 9.466 1747.13 11.595 1746.39 13.25C1745.6 15 1744.81 16 1743.5 16Z"
          fill="#FFD39B"
          className="color2"
        />
      </g>
      <g clipPath="url(#clip31_3491_17150)">
        <path d="M1856 8C1856 8 1821.92 12 1800 12" stroke="#B2B9C3" />
        <g opacity="0.8" filter="url(#filter31_f_3491_17150)">
          <path
            d="M1797.37 18.688C1796.26 17.992 1796.12 16.727 1796.38 14.825C1796.62 13.027 1797.49 11.057 1799.35 11.083C1800.19 11.094 1800.5 11.213 1801.61 11.904C1802.72 12.596 1802.96 12.829 1803.34 13.574C1804.18 15.239 1802.79 16.879 1801.28 17.888C1799.68 18.954 1798.49 19.385 1797.37 18.688Z"
            fill="#76D7C4"
            className="color1"
          />
        </g>
        <path
          d="M1797.9 17.84C1796.79 17.145 1796.65 15.879 1796.91 13.977C1797.15 12.179 1798.02 10.209 1799.88 10.235C1800.72 10.246 1801.03 10.365 1802.14 11.056C1803.25 11.748 1803.49 11.981 1803.87 12.726C1804.71 14.391 1803.32 16.031 1801.81 17.04C1800.21 18.106 1799.02 18.537 1797.9 17.84Z"
          fill="#76D7C4"
          className="color1"
        />
      </g>
      <g clipPath="url(#clip32_3491_17150)">
        <path d="M1858 8C1858 8 1886.09 10.196 1914 11.334" stroke="#B2B9C3" />
        <g opacity="0.8" filter="url(#filter32_f_3491_17150)">
          <path
            d="M1861.47 14.144C1860.46 14.989 1859.21 14.729 1857.48 13.895C1855.85 13.106 1854.24 11.675 1854.84 9.91002C1855.11 9.12002 1855.32 8.85502 1856.32 8.01602C1857.32 7.17602 1857.62 7.01602 1858.45 6.88802C1860.29 6.60302 1861.42 8.43402 1861.91 10.18C1862.43 12.027 1862.47 13.3 1861.47 14.144Z"
            fill="#D9ABFF"
            className="color3"
          />
        </g>
        <path
          d="M1860.82 13.378C1859.82 14.222 1858.57 13.963 1856.84 13.128C1855.21 12.34 1853.6 10.91 1854.2 9.14501C1854.47 8.35501 1854.68 8.09001 1855.68 7.25101C1856.68 6.41101 1856.98 6.25101 1857.8 6.12301C1859.65 5.83801 1860.78 7.66901 1861.27 9.41501C1861.79 11.262 1861.83 12.535 1860.82 13.379L1860.82 13.378Z"
          fill="#D9ABFF"
          className="color3"
        />
      </g>
      <g clipPath="url(#clip33_3491_17150)">
        <path
          d="M1916 11.334C1925.76 11.732 1935.49 12 1944 12C1954.49 12 1963.51 11.91 1972 11.687"
          stroke="#B2B9C3"
        />
        <g opacity="0.8" filter="url(#filter33_f_3491_17150)">
          <path
            d="M1921.52 9.48499C1922.19 10.611 1921.74 11.801 1920.65 13.378C1919.61 14.869 1917.95 16.233 1916.3 15.363C1915.56 14.973 1915.33 14.725 1914.66 13.605C1913.99 12.486 1913.87 12.168 1913.88 11.333C1913.88 9.46699 1915.87 8.63799 1917.67 8.42499C1919.58 8.19899 1920.84 8.35899 1921.52 9.48499Z"
            fill="#FFD39B"
            className="color2"
          />
        </g>
        <path
          d="M1920.66 10C1921.34 11.126 1920.89 12.316 1919.79 13.893C1918.76 15.384 1917.09 16.748 1915.44 15.878C1914.7 15.488 1914.48 15.24 1913.8 14.12C1913.13 13.001 1913.02 12.683 1913.02 11.848C1913.03 9.98201 1915.01 9.15301 1916.81 8.94001C1918.72 8.71401 1919.98 8.87401 1920.66 10Z"
          fill="#FFD39B"
          className="color2"
        />
      </g>
      <path d="M2031.5 8C2009.25 10.124 1993.59 11.21 1975.5 11.687" stroke="#B2B9C3" />
      <g opacity="0.8" filter="url(#filter34_f_3491_17150)">
        <path
          d="M1971.54 8.48403C1972.54 7.63903 1973.79 7.89903 1975.52 8.73303C1977.15 9.52103 1978.76 10.952 1978.16 12.718C1977.89 13.508 1977.68 13.773 1976.68 14.612C1975.68 15.452 1975.38 15.612 1974.56 15.74C1972.71 16.025 1971.58 14.194 1971.09 12.448C1970.57 10.6 1970.53 9.32803 1971.54 8.48403Z"
          fill="#76D7C4"
          className="color1"
        />
      </g>
      <path
        d="M1972.18 9.25002C1973.19 8.40502 1974.43 8.66502 1976.16 9.49902C1977.8 10.287 1979.4 11.718 1978.8 13.484C1978.53 14.274 1978.32 14.539 1977.32 15.378C1976.32 16.218 1976.03 16.378 1975.2 16.506C1973.36 16.791 1972.23 14.96 1971.74 13.214C1971.22 11.367 1971.18 10.094 1972.18 9.25002Z"
        fill="#76D7C4"
        className="color1"
      />
      <g opacity="0.8" filter="url(#filter35_f_3491_17150)">
        <path
          d="M2031.5 15C2030.19 15 2029.4 14 2028.61 12.25C2027.86 10.595 2027.55 8.466 2029.15 7.5C2029.86 7.068 2030.19 7 2031.5 7C2032.81 7 2033.14 7.068 2033.85 7.5C2035.45 8.466 2035.13 10.595 2034.39 12.25C2033.6 14 2032.81 15 2031.5 15Z"
          fill="#D9ABFF"
          className="color3"
        />
      </g>
      <path
        d="M2031.5 14C2030.19 14 2029.4 13 2028.61 11.25C2027.86 9.595 2027.55 7.466 2029.15 6.5C2029.86 6.068 2030.19 6 2031.5 6C2032.81 6 2033.14 6.068 2033.85 6.5C2035.45 7.466 2035.13 9.595 2034.39 11.25C2033.6 13 2032.81 14 2031.5 14Z"
        fill="#D9ABFF"
        className="color3"
      />
      <defs>
        <filter
          id="filter0_f_3491_17150"
          x="0"
          y="3"
          width="19"
          height="20"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter1_f_3491_17150"
          x="56.2684"
          y="5.08276"
          width="19.3266"
          height="19.9445"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter2_f_3491_17150"
          x="114.716"
          y="0.858521"
          width="19.5558"
          height="19.8049"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter3_f_3491_17150"
          x="173.878"
          y="2.33691"
          width="19.9581"
          height="19.2984"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter4_f_3491_17150"
          x="230.732"
          y="1.96466"
          width="19.5564"
          height="19.8049"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter5_f_3491_17150"
          x="289"
          y="3"
          width="19"
          height="20"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter6_f_3491_17150"
          x="345.268"
          y="5.08276"
          width="19.3265"
          height="19.9445"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter7_f_3491_17150"
          x="403.716"
          y="0.858521"
          width="19.5558"
          height="19.8049"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter8_f_3491_17150"
          x="462.878"
          y="2.33691"
          width="19.9581"
          height="19.2984"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter9_f_3491_17150"
          x="519.732"
          y="1.96466"
          width="19.5564"
          height="19.8049"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter10_f_3491_17150"
          x="578"
          y="3"
          width="19"
          height="20"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter11_f_3491_17150"
          x="634.268"
          y="5.08276"
          width="19.3265"
          height="19.9445"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter12_f_3491_17150"
          x="692.716"
          y="0.858521"
          width="19.5558"
          height="19.8049"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter13_f_3491_17150"
          x="751.878"
          y="2.33691"
          width="19.9581"
          height="19.2984"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter14_f_3491_17150"
          x="808.732"
          y="1.96466"
          width="19.5564"
          height="19.8049"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter15_f_3491_17150"
          x="867"
          y="3"
          width="19"
          height="20"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter16_f_3491_17150"
          x="923.268"
          y="5.08276"
          width="19.3265"
          height="19.9445"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter17_f_3491_17150"
          x="981.716"
          y="0.858521"
          width="19.5558"
          height="19.8049"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter18_f_3491_17150"
          x="1040.88"
          y="2.33691"
          width="19.9581"
          height="19.2984"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter19_f_3491_17150"
          x="1097.73"
          y="1.96466"
          width="19.5564"
          height="19.8049"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter20_f_3491_17150"
          x="1156"
          y="3"
          width="19"
          height="20"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter21_f_3491_17150"
          x="1212.27"
          y="5.08276"
          width="19.3267"
          height="19.9445"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter22_f_3491_17150"
          x="1270.72"
          y="0.858521"
          width="19.5559"
          height="19.8049"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter23_f_3491_17150"
          x="1329.88"
          y="2.33691"
          width="19.958"
          height="19.2984"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter24_f_3491_17150"
          x="1386.73"
          y="1.96466"
          width="19.5564"
          height="19.8049"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter25_f_3491_17150"
          x="1445"
          y="3"
          width="19"
          height="20"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter26_f_3491_17150"
          x="1501.27"
          y="5.08276"
          width="19.3267"
          height="19.9445"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter27_f_3491_17150"
          x="1559.72"
          y="0.858521"
          width="19.5559"
          height="19.8049"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter28_f_3491_17150"
          x="1618.88"
          y="2.33691"
          width="19.958"
          height="19.2984"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter29_f_3491_17150"
          x="1675.73"
          y="1.96466"
          width="19.5564"
          height="19.8049"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter30_f_3491_17150"
          x="1734"
          y="3"
          width="19"
          height="20"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter31_f_3491_17150"
          x="1790.27"
          y="5.08276"
          width="19.3267"
          height="19.9445"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter32_f_3491_17150"
          x="1848.72"
          y="0.858521"
          width="19.5559"
          height="19.8049"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter33_f_3491_17150"
          x="1907.88"
          y="2.33691"
          width="19.958"
          height="19.2984"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter34_f_3491_17150"
          x="1964.73"
          y="1.96466"
          width="19.5564"
          height="19.8049"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <filter
          id="filter35_f_3491_17150"
          x="2022"
          y="1"
          width="19"
          height="20"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3491_17150" />
        </filter>
        <clipPath id="clip0_3491_17150">
          <rect width="64" height="20" fill="white" transform="translate(0 3)" />
        </clipPath>
        <clipPath id="clip1_3491_17150">
          <rect width="67" height="21" fill="white" transform="translate(56 5)" />
        </clipPath>
        <clipPath id="clip2_3491_17150">
          <rect width="67" height="21" fill="white" transform="translate(114)" />
        </clipPath>
        <clipPath id="clip3_3491_17150">
          <rect width="66" height="20" fill="white" transform="translate(173 2)" />
        </clipPath>
        <clipPath id="clip4_3491_17150">
          <rect width="68" height="21" fill="white" transform="translate(230 1)" />
        </clipPath>
        <clipPath id="clip5_3491_17150">
          <rect width="64" height="20" fill="white" transform="translate(289 3)" />
        </clipPath>
        <clipPath id="clip6_3491_17150">
          <rect width="67" height="21" fill="white" transform="translate(345 5)" />
        </clipPath>
        <clipPath id="clip7_3491_17150">
          <rect width="67" height="21" fill="white" transform="translate(403)" />
        </clipPath>
        <clipPath id="clip8_3491_17150">
          <rect width="66" height="20" fill="white" transform="translate(462 2)" />
        </clipPath>
        <clipPath id="clip9_3491_17150">
          <rect width="68" height="21" fill="white" transform="translate(519 1)" />
        </clipPath>
        <clipPath id="clip10_3491_17150">
          <rect width="64" height="20" fill="white" transform="translate(578 3)" />
        </clipPath>
        <clipPath id="clip11_3491_17150">
          <rect width="67" height="21" fill="white" transform="translate(634 5)" />
        </clipPath>
        <clipPath id="clip12_3491_17150">
          <rect width="67" height="21" fill="white" transform="translate(692)" />
        </clipPath>
        <clipPath id="clip13_3491_17150">
          <rect width="66" height="20" fill="white" transform="translate(751 2)" />
        </clipPath>
        <clipPath id="clip14_3491_17150">
          <rect width="68" height="21" fill="white" transform="translate(808 1)" />
        </clipPath>
        <clipPath id="clip15_3491_17150">
          <rect width="64" height="20" fill="white" transform="translate(867 3)" />
        </clipPath>
        <clipPath id="clip16_3491_17150">
          <rect width="67" height="21" fill="white" transform="translate(923 5)" />
        </clipPath>
        <clipPath id="clip17_3491_17150">
          <rect width="67" height="21" fill="white" transform="translate(981)" />
        </clipPath>
        <clipPath id="clip18_3491_17150">
          <rect width="66" height="20" fill="white" transform="translate(1040 2)" />
        </clipPath>
        <clipPath id="clip19_3491_17150">
          <rect width="68" height="21" fill="white" transform="translate(1097 1)" />
        </clipPath>
        <clipPath id="clip20_3491_17150">
          <rect width="64" height="20" fill="white" transform="translate(1156 3)" />
        </clipPath>
        <clipPath id="clip21_3491_17150">
          <rect width="67" height="21" fill="white" transform="translate(1212 5)" />
        </clipPath>
        <clipPath id="clip22_3491_17150">
          <rect width="67" height="21" fill="white" transform="translate(1270)" />
        </clipPath>
        <clipPath id="clip23_3491_17150">
          <rect width="66" height="20" fill="white" transform="translate(1329 2)" />
        </clipPath>
        <clipPath id="clip24_3491_17150">
          <rect width="68" height="21" fill="white" transform="translate(1386 1)" />
        </clipPath>
        <clipPath id="clip25_3491_17150">
          <rect width="64" height="20" fill="white" transform="translate(1445 3)" />
        </clipPath>
        <clipPath id="clip26_3491_17150">
          <rect width="67" height="21" fill="white" transform="translate(1501 5)" />
        </clipPath>
        <clipPath id="clip27_3491_17150">
          <rect width="67" height="21" fill="white" transform="translate(1559)" />
        </clipPath>
        <clipPath id="clip28_3491_17150">
          <rect width="66" height="20" fill="white" transform="translate(1618 2)" />
        </clipPath>
        <clipPath id="clip29_3491_17150">
          <rect width="68" height="21" fill="white" transform="translate(1675 1)" />
        </clipPath>
        <clipPath id="clip30_3491_17150">
          <rect width="64" height="20" fill="white" transform="translate(1734 3)" />
        </clipPath>
        <clipPath id="clip31_3491_17150">
          <rect width="67" height="21" fill="white" transform="translate(1790 5)" />
        </clipPath>
        <clipPath id="clip32_3491_17150">
          <rect width="67" height="21" fill="white" transform="translate(1848)" />
        </clipPath>
        <clipPath id="clip33_3491_17150">
          <rect width="66" height="20" fill="white" transform="translate(1907 2)" />
        </clipPath>
      </defs>
    </svg>
  )
}
