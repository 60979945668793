import React from 'react'
import Rectangle from 'std-lib/FastUI/Components/Rectangle'
import logo from '../logo_osnovnoy_russkiy_chernyy.png'

const Logo = () => {
  return <Rectangle as={'img'} src={logo} width={92} mobile={{ width: 61 }} />
  return (
    <svg
      width="126"
      height="50"
      viewBox="0 0 236 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_73_446"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="236"
        height="64"
      >
        <path d="M235.338 0H0.337891V64H235.338V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_73_446)">
        <path
          d="M61.834 28.1433H79.1641V63.5716H91.8559V28.1433H109.511V16.8091H61.834V28.1433Z"
          fill="#1D1D1B"
        />
        <path d="M57.732 16.7129H44.9258V63.5716H57.732V16.7129Z" fill="#1D1D1B" />
        <path d="M0.337891 16.7129V63.5716H13.1441V16.7129H0.337891Z" fill="#1D1D1B" />
        <path
          d="M32.455 16.7129L13.1445 63.5716H26.3468L44.9179 16.7129H39.8219H32.455Z"
          fill="#1D1D1B"
        />
        <path
          d="M169.978 16.7129H168.279H160.903L148.194 47.5585L135.485 16.7129H128.118H123.022H113.604V63.5716H126.41V25.266L141.593 63.5716H154.795L169.978 25.266V63.5716H182.784V16.7129H173.366H169.978Z"
          fill="#1D1D1B"
        />
        <path
          d="M51.3251 5.71977e-05C50.0874 0.024178 48.8844 0.411023 47.8671 1.11204C46.8498 1.81306 46.0636 2.79702 45.607 3.94044C45.1505 5.08386 45.044 6.33579 45.3009 7.53912C45.5578 8.74246 46.1667 9.84359 47.0512 10.7044C47.9357 11.5651 49.0563 12.1471 50.2724 12.3772C51.4885 12.6074 52.7459 12.4756 53.8869 11.9983C55.0279 11.5209 56.0016 10.7193 56.6858 9.6941C57.3699 8.6689 57.7341 7.46576 57.7326 6.23564C57.7141 4.56481 57.0288 2.96961 55.8274 1.80044C54.626 0.631269 53.0067 -0.0162675 51.3251 5.71977e-05Z"
          fill="#1D1D1B"
        />
        <path
          d="M222.919 40.7992C222.752 48.0055 217.894 52.8418 210.844 52.8418H210.58C206.786 52.7718 203.662 51.5562 201.541 49.3436C199.419 47.131 198.328 43.8601 198.398 39.9771C198.316 38.3587 198.58 36.7413 199.173 35.2318C199.767 33.7224 200.676 32.3554 201.841 31.2211C203.006 30.0869 204.399 29.2113 205.93 28.6522C207.461 28.0932 209.094 27.8634 210.721 27.9782C212.342 27.9214 213.959 28.1929 215.472 28.7762C216.985 29.3595 218.363 30.2425 219.522 31.3715C220.695 32.6416 221.6 34.1328 222.182 35.7568C222.764 37.3808 223.012 39.1044 222.911 40.8254L222.919 40.7992ZM223.914 19.9935C219.994 17.7427 215.538 16.577 211.011 16.6177C209.835 16.6205 208.659 16.6877 207.49 16.8188C200.616 17.571 195.089 20.422 191.058 25.2933C187.273 29.8672 185.636 35.4469 186.059 42.3296C186.217 47.0962 187.932 51.6822 190.944 55.3955C195.784 61.2725 202.386 64.0798 211.812 64.2285H211.918H212.023L213.247 64.0711C214.89 63.8889 216.519 63.5967 218.123 63.1965C222.931 62.0029 227.227 59.3048 230.374 55.5005C233.437 51.6639 235.166 46.9447 235.303 42.0498C235.717 32.0186 231.888 24.5587 223.923 19.9935"
          fill="#1D1D1B"
        />
      </g>
    </svg>
  )
}

export default Logo
