import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { Dot } from './styled'
import Chevron from '../../../../Images/icons/chevron'
import Flex from '../../../../std-lib/FastUI/Components/Flex'
import Rectangle from '../../../../std-lib/FastUI/Components/Rectangle'
import { css } from 'styled-components'

interface SliderProps {
  children: ReactNode
  dataLength: number
}

const Slider = ({ children, dataLength }: SliderProps) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const [activeDotIndex, setActiveDotIndex] = useState(0)
  const handleScrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -900, behavior: 'smooth' })
      setActiveDotIndex(prevIndex => Math.max(0, prevIndex - 1))
    }
  }
  const handleScrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 900, behavior: 'smooth' })
      setActiveDotIndex(prevIndex => Math.min(dataLength, prevIndex + 1))
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      if (scrollContainerRef.current) {
        const element = scrollContainerRef.current
        const maxScrollLeft = element.scrollWidth - element.clientWidth
        if (element.scrollLeft === 0) {
          setActiveDotIndex(0)
        } else if (element.scrollLeft === maxScrollLeft) {
          setActiveDotIndex(Math.ceil(dataLength / 3) - 1)
        } else {
          const index = Math.round(element.scrollLeft / 900)
          setActiveDotIndex(index)
        }
      }
    }

    if (scrollContainerRef.current) {
      scrollContainerRef.current.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.removeEventListener('scroll', handleScroll)
      }
    }
  }, [dataLength])

  return (
    <Rectangle width={'100%'} background={'white'} position={'relative'}>
      {activeDotIndex !== 0 && (
        <Rectangle
          position={'absolute'}
          left={-10}
          top={-10}
          width={50}
          height={'100%'}
          background={'#fff'}
          fragment={css`
            filter: blur(5px);
            z-index: 1;
            
            @media screen and (max-width: 1000px) {
              display: none;
            }
          `}
        />
      )}
      {activeDotIndex !== 0 && (
        <Rectangle
          position={'absolute'}
          top={'50%'}
          left={0}
          borderRadius={22}
          width={44}
          height={44}
          boxShadow={
            '0px 4px 10px 0px rgba(31, 31, 34, 0.06), 0px 0px 1px 0px rgba(31, 31, 34, 0.10)'
          }
          background={'white'}
          fragment={css`z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateY(-50%);

            @media screen and (max-width: 1000px) {
              display: none;
            }
          `}
        >
          <button onClick={handleScrollLeft}>
            <Chevron rotate={180} />
          </button>
        </Rectangle>
      )}
      {activeDotIndex !== Math.ceil(dataLength / 3) - 1 && (
        <Rectangle
          position={'absolute'}
          right={-10}
          top={-10}
          width={50}
          height={'100%'}
          background={'#fff'}
          fragment={css`
            filter: blur(5px);
            z-index: 1;

            @media screen and (max-width: 1000px) {
              display: none;
            }
          `}
        />
      )}
      {activeDotIndex !== Math.ceil(dataLength / 3) - 1 && (
        <Rectangle
          background={'white'}
          position={'absolute'}
          top={'50%'}
          right={0}
          borderRadius={22}
          width={44}
          height={44}
          boxShadow={
            '0px 4px 10px 0px rgba(31, 31, 34, 0.06), 0px 0px 1px 0px rgba(31, 31, 34, 0.10)'
          }
          fragment={css`
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateY(-50%);

            @media screen and (max-width: 1000px) {
              display: none;
            }
          `}
        >
          <button onClick={handleScrollRight}>
            <Chevron rotate={0} />
          </button>
        </Rectangle>
      )}
      <Flex
        gap={24}
        ref={scrollContainerRef}
        fragment={css`
          position: relative;
          overflow-x: auto;
          scroll-snap-type: x mandatory;
            padding: 0 0 12px 0;
            scrollbar-width: thin;
        `}
      >
        {children}
      </Flex>
      {dataLength > 4 && (
        <Flex
          alignItems={'center'}
          justifyContent={'center'}
          gap={12}
          fragment={css`
            width: 100%;
            margin-top: 15px;
            
            @media screen and (max-width: 1000px) {
              display: none;
            }
          `}
        >
          {Array.from({ length: Math.ceil(dataLength / 3) }, (_, index) => (
            <Dot key={index} isDotActive={index === activeDotIndex} />
          ))}
        </Flex>
      )}
    </Rectangle>
  )
}

export default Slider