import React from 'react'
import ErrorLayout from '../ErrorLayout/ErrorLayout'
import { styledBigButton, StyledButtonsGrid } from '../ErrorLayout/styled'
import StyledButton from '../../../std-lib/UiKit/Button'

const UnknownError = () => {
  return (
    <ErrorLayout subtitle={'Неизвестная ошибка'}>
      <StyledButtonsGrid>
        <StyledButton styledFragment={styledBigButton} buttonStyle={'main'} onClick={()=> window.location.replace('/')}>
          На главную
        </StyledButton>
      </StyledButtonsGrid>
    </ErrorLayout>
  )
}

export default UnknownError
