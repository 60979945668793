import React, { forwardRef, useContext, useState } from 'react'
import { useWindowDimensions } from '../../std-lib/Extensions/window'
import { GenericModalOwnProps } from '../../std-lib/types'
import ModalContext from '../../std-lib/Сontext/Modal'
import SideModal from '../SideModal'
import {
  CloseButton,
  CloseOverlay,
  ModalContainer,
  ModalWrapper,
  SwipeBlock,
} from 'LayoutComponents/GenericModal/styled'
import Close from '../../std-lib/Images/icons/close'

const GenericModal = forwardRef<HTMLDivElement, GenericModalOwnProps>((props, ref) => {
  const {
    onModalClose,
    unclosableWithoutAction,
    children,
    isModalVisible,
    isSideModal,
    colorCloseWhite,
  } = props
  const { lastModalHeight } = useContext(ModalContext)
  const { width } = useWindowDimensions()

  const [cardTranslate, setCardTranslate] = useState<number>(0)
  const [touchStartLocation, setTouchStartLocation] = useState(0)

  const onTouchMove = (e: React.TouchEvent) => {
    // e.preventDefault()
    if (touchStartLocation === 0) {
      setTouchStartLocation(e.touches[0].clientY)
    } else {
      const deltaY = touchStartLocation - e.touches[0].clientY
      setCardTranslate(Math.min(deltaY, 0))
    }
  }

  const onTouchEnd = (e: React.TouchEvent) => {
    setTouchStartLocation(0)
    if (cardTranslate <= -200) {
      setCardTranslate(touchStartLocation - e.changedTouches[0].clientY)
      onModalClose()
    } else {
      setCardTranslate(0)
    }
  }

  return isSideModal && width > 1000 ? (
    <SideModal isModalDisplay={!!isModalVisible} closeModal={onModalClose}>
      {children}
    </SideModal>
  ) : (
    <ModalContainer isVisible={!!isModalVisible}>
      <CloseOverlay onClick={unclosableWithoutAction ? undefined : onModalClose} />
      <ModalWrapper
        ref={ref}
        shiftHeight={ref ? null : lastModalHeight && lastModalHeight + 40}
        isVisible={!!isModalVisible}
        from={-cardTranslate}
        style={
          cardTranslate
            ? {
                bottom: `${cardTranslate}px`,
                transition: 'all .05s',
              }
            : undefined
        }
      >
        <SwipeBlock onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
          <CloseButton onClick={unclosableWithoutAction ? undefined : onModalClose}>
            <Close
              width={12}
              height={12}
              primaryColor={colorCloseWhite ? 'white' : undefined}
            />
          </CloseButton>
        </SwipeBlock>
        {children}
      </ModalWrapper>
    </ModalContainer>
  )
})

GenericModal.displayName = 'GenericModal'

export default GenericModal
