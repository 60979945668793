import React from 'react'

import { Sections, SectionsType } from 'Widgets/Onboarding/Layouts/Step/onboradingRoutes/types'
import NameQuestion from 'Widgets/Onboarding/Slides/NameQuestion'
import CityQuestion from 'Widgets/Onboarding/Slides/CityQuestion'
import Hobbies from 'Widgets/Onboarding/Slides/Hobbies'
import ProfessionCategories from 'Widgets/Onboarding/Slides/ProfessionCategories'
import ProfessionCategory from 'Widgets/Onboarding/Slides/ProfessionCategory'
import Professions from 'Widgets/Onboarding/Slides/Professions'
import SkillSets from 'Widgets/Onboarding/Slides/SkillSets'
import UserOccupation from 'Widgets/Onboarding/Slides/UserOccupation'
import AreasOfGrowth from 'Widgets/Onboarding/Slides/AreasOfGrowth'

export const masterSections: SectionsType = {
  [Sections.INTRO]: {
    title: 'Знакомство',
    next: Sections.TRACK,
    prev: null,
    steps: [
      { component: <NameQuestion />, nameForTrackers: 'name_question' },
      {
        component: <CityQuestion />,
        nameForTrackers: 'city_question',
        userDataFieldToSend: (data) => ({ city: data.city }),
      },
      {
        component: <UserOccupation />,
        nameForTrackers: 'social_status_question',
        userDataFieldToSend: (data) => ({ socialStatus: data.userOccupation.map(item => item.name) }),
      },
      {
        component: <Hobbies />,
        nameForTrackers: 'hobbies_question',
        userDataFieldToSend: (data) => ({ selectedHobbies: data.selectedHobbies.length }),
      },
      {
        component: <AreasOfGrowth />,
        nameForTrackers: 'purpose_master_degree',
        userDataFieldToSend: (data) => ({ purposeMasterDegree: data.areasOfGrowth.map(item => item.name) }),
      },
    ],
  },
  [Sections.TRACK]: {
    title: 'Траектория',
    next: null,
    prev: Sections.INTRO,
    steps: [
      {
        component: <ProfessionCategories />,
        nameForTrackers: 'category_question',
        userDataFieldToSend: (data) => ({ category: data.selectedSphere?.title }),
      },
      { component: <ProfessionCategory />, nameForTrackers: 'about_category' },
      {
        component: <Professions />,
        nameForTrackers: 'profession_question',
        userDataFieldToSend: (data) => ({ profession: data.selectedProfession?.name }),
      },
      {
        component: <SkillSets />,
        nameForTrackers: 'skillsets_question.',
        userDataFieldToSend: (data) => ({ skills: data.selectedSkillSets.length }),

      },
    ],
  },
}
