import React, { ReactNode, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import FeedbackGroupIdContext from '../../Context/IdGroup'
import Header from '../Header'
import request from '../../std-lib/API/request'
import { BASE_URL } from '../../Constants'
import { useOutlet } from 'react-router'
import NotificationsWrapper from '../../Widgets/Notifications/wrapper'
import { updateStickyBlocks } from '../../std-lib/Extensions/window'
import ModalContextProvider from '../ModalContextProvider'

import styled from 'styled-components'

export const DefaultLayoutContainer = styled.div`
    background-color: var(--bg-color);
    transition: var(--bg-transition);
`

export const Content = styled.div`
    z-index: 300;
    max-width: 1920px;
    margin: auto;
    padding: 24px 60px;
    background-color: var(--bg-color);
    transition: var(--bg-transition);

    @media screen and (max-width: 1000px) {
        padding: 0 16px;
    }

    @media screen and (max-width: 433px) {
        padding: 0 15px !important;
    }
`

type layoutPropType = {
  children?: ReactNode
}

const Layout = ({ children }: layoutPropType) => {
  const [cookie] = useCookies(['_ym_uid'])
  const outlet = useOutlet()

  const [groupId, setGroupId] = useState<number>(0)

  useEffect(() => {
    if (cookie._ym_uid) {
      request
        .get(`${BASE_URL}feedback/split_group?group_id=${cookie._ym_uid}`)
        .then(res => {
          setGroupId(res.data.group_id)
        })
    } else {
      setGroupId(-1)
    }

    updateStickyBlocks()
  }, [])

  return (
    <FeedbackGroupIdContext.Provider value={{ groupId }}>
      <DefaultLayoutContainer id="scroll-container">
        <Header />
        <Content id="ContentBlock">
          <ModalContextProvider>
            <NotificationsWrapper>{children ? children : outlet}</NotificationsWrapper>
          </ModalContextProvider>
        </Content>
      </DefaultLayoutContainer>
    </FeedbackGroupIdContext.Provider>
  )
}

export default Layout
