import styled from 'styled-components'

const CommonSlideLayout = styled.div<{ gap?: string; mediaWidth?: number }>`
  display: flex;
  flex-direction: row;
  gap: ${({ gap }) => gap ?? 'unset'};

  @media (max-width: ${({ mediaWidth }) => mediaWidth ?? 1000}px) {
    flex-direction: column;
    align-items: center;
    gap: 30px
  }
`

export default CommonSlideLayout
