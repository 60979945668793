import styled from 'styled-components'

interface InputProps {
  width?: number | string
  isValid?: boolean
}

const StyledInput = styled.input.attrs<InputProps>(props => ({
  isValid: props.isValid !== undefined ? props.isValid : true,
}))<InputProps>`
  padding: 10px 16px;
  box-sizing: border-box;
  font-family: 'Golos', sans-serif;
  border: 1px solid ${props => props.isValid ? '#DEDEDE' : '#F57B7B'};
  border-radius: 20px;
  height: 44px;
  width: ${props =>
          typeof props.width === 'number' ? `${props.width}px` : props.width || 'auto'};
  transition: border-radius .3s;

  &:hover {
    outline: none;
    border-color: ${({ theme }) => theme.color.elements.stroke.hover};
  }

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.color.elements.stroke.hover};
  }

`

export default StyledInput
