import React, { ReactNode, useEffect, useRef, useState } from 'react';
import {
  useFloating,
  autoUpdate,
  autoPlacement,
  useRole,
  useHover,
  useFocus,
  useDismiss,
  useInteractions,
  FloatingArrow,
  FloatingPortal,
  arrow, offset, detectOverflow, shift, useClick
} from '@floating-ui/react';
import styled from 'styled-components';

interface TooltipProps {
  triggerElement: ReactNode;
  tooltipContent: ReactNode;
}

const TriggerButton = styled.div`
  height: max-content;
  width: max-content;
`;

const TooltipWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 12px;
  background: #323243;
  padding: 16px;
  width: max-content;
  z-index: 100000;

  @media screen and (max-width: 1000px) {
    max-width: 215px;
  }
`;

const Tooltip = (props: TooltipProps) => {
  const { triggerElement, tooltipContent } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const arrowRef = useRef(null);

  const checkOverflowMiddleware: any = {
    name: 'checkOverflow',
    async fn(state: any) {
      const overflow = await detectOverflow(state);
      if (Object.values(overflow).some(value => value >= 0)){
        setIsOpen(false);
      }
      return {};
    }
  };

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      autoPlacement(),
      arrow({
        element: arrowRef
      }),
      offset(20),
      shift(),
      checkOverflowMiddleware
    ],
    whileElementsMounted: autoUpdate
  });

  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });
  const click = useClick(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
    click
  ]);

  return (
    <>
      <TriggerButton
        ref={refs.setReference}
        {...getReferenceProps({
          onClick: (e) => {
            e.stopPropagation();
          }
        })}>
        {triggerElement}
      </TriggerButton>
      {isOpen && (
        <FloatingPortal>
          <TooltipWrapper
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <FloatingArrow
              width={21}
              height={14}
              fill={'#323243'}
              ref={arrowRef}
              context={context}
              tipRadius={2}
            />
            {tooltipContent}
          </TooltipWrapper>
        </FloatingPortal>
      )}
    </>
  );
};

export default Tooltip;