import { UserData } from '../../../UserDataStorage/UseUserData'

type Step = {
  component: JSX.Element
  nameForTrackers: string
  userDataFieldToSend?: (data: UserData) => any
}

export enum Sections {
  INTRO = 'intro',
  TRACK = 'track',
}

export type Section = {
  title: string
  next: Sections | null
  prev: Sections | null
  steps: Step[]
}

export type SectionsType = Record<Sections, Section>
