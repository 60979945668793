import { AxiosInstance, AxiosResponse } from 'axios'
import request from '../../../std-lib/API/request'
import { TrajectoryDisciplineType } from '../types'

function trajectoriesModule(instance: AxiosInstance) {
  return {
    getDisciplineData(id: number): Promise<AxiosResponse<TrajectoryDisciplineType>> {
      return instance.get(`trajectory_disciplines/${id}`)
    },
  }
}

export const trajectoriesRequest = Object.assign(request, trajectoriesModule(request))
