import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { ChildrenScaler, CroppedImg, ImageWrapper } from './styled'
import { FlattenSimpleInterpolation } from 'styled-components'
import { useWindowDimensions } from '../../std-lib/Extensions/window'

export type SizeType = { width: number; height: number }
type ImgWithMaskAndCardsProps = {
  imageSrc: string
  maskSrc?: string
  children?: ReactNode | ReactNode[]
  styledFragment?: FlattenSimpleInterpolation
  initialSize: SizeType
}

const ImgWithMaskAndCards = ({
  imageSrc,
  maskSrc,
  initialSize,
  children,
  styledFragment,
}: ImgWithMaskAndCardsProps) => {
  const { width } = useWindowDimensions()
  const wrapper = useRef<HTMLDivElement>(null)
  const [scale, setScale] = useState<number>(1)
  // const ratio = initialSize.width/initialSize.height
  useEffect(() => {
    if (wrapper.current) {
      setScale(wrapper.current.offsetWidth / initialSize.width)
      console.log(wrapper.current.offsetWidth / initialSize.width)
    }
  }, [width, initialSize.width])

  return (
    <ImageWrapper
      style={{
        maxWidth: initialSize.width,
        maxHeight: initialSize.height,
        height: initialSize.height * scale,
      }}
      ref={wrapper}
      styledFragment={styledFragment}
    >
      <CroppedImg src={imageSrc} maskSrc={maskSrc} />
      <ChildrenScaler scale={scale} initialSize={initialSize}>
        {children}
      </ChildrenScaler>
    </ImageWrapper>
  )
}

export default ImgWithMaskAndCards
