import React from 'react'

import NameQuestion from 'Widgets/Onboarding/Slides/NameQuestion'
import CityQuestion from 'Widgets/Onboarding/Slides/CityQuestion'
import ClassQuestion from 'Widgets/Onboarding/Slides/ClassQuestion'
import Hobbies from 'Widgets/Onboarding/Slides/Hobbies'
import ProfessionCategories from 'Widgets/Onboarding/Slides/ProfessionCategories'
import ProfessionCategory from 'Widgets/Onboarding/Slides/ProfessionCategory'
import Professions from 'Widgets/Onboarding/Slides/Professions'
import SkillSets from 'Widgets/Onboarding/Slides/SkillSets'
import { Sections, SectionsType } from 'Widgets/Onboarding/Layouts/Step/onboradingRoutes/types'

export const bachelorSections: SectionsType = {
  [Sections.INTRO]: {
    title: 'Знакомство',
    next: Sections.TRACK,
    prev: null,
    steps: [
      { component: <NameQuestion />, nameForTrackers: 'name_question' },
      {
        component: <CityQuestion />,
        nameForTrackers: 'city_question',
        userDataFieldToSend: (data) => ({ city: data.city }),
      },
      {
        component: <ClassQuestion />,
        nameForTrackers: 'education_question',
        userDataFieldToSend: (data) => ({ class: data.schoolGrade }),
      },
      {
        component: <Hobbies />,
        nameForTrackers: 'hobbies_question',
        userDataFieldToSend: (data) => ({ selectedHobbies: data.selectedHobbies.length }),
      },
    ],
  },
  [Sections.TRACK]: {
    title: 'Траектория',
    next: null,
    prev: Sections.INTRO,
    steps: [
      {
        component: <ProfessionCategories />,
        nameForTrackers: 'category_question',
        userDataFieldToSend: (data) => ({ category: data.selectedSphere?.title }),
      },
      { component: <ProfessionCategory />, nameForTrackers: 'about_category' },
      {
        component: <Professions />,
        nameForTrackers: 'profession_question',
        userDataFieldToSend: (data) => ({ profession: data.selectedProfession?.name }),
      },
      {
        component: <SkillSets />,
        nameForTrackers: 'skillsets_question.',
        userDataFieldToSend: (data) => ({ skills: data.selectedSkillSets.length }),
      },
    ],
  },
}
