import React, { forwardRef } from 'react'
import { ClickableWrapper, DefaultWrapper } from './styled'

interface TagProps {
  tag: string
}

interface ViewTagProps extends TagProps {
  selected?: never
  disabled?: never
  onClick?: never
}

interface ClickableTagProps extends TagProps {
  selected?: boolean
  disabled?: boolean
  onClick?: () => void
}

const Tag = forwardRef<HTMLDivElement, ViewTagProps | ClickableTagProps>((props, ref) => {
  return props.onClick ? (
    <ClickableWrapper
      ref={ref}
      onClick={props.onClick}
      selected={props.selected}
      isDisabled={props.disabled}
    >
      {props.tag}
    </ClickableWrapper>
  ) : (
    <DefaultWrapper ref={ref}>{props.tag}</DefaultWrapper>
  )
})

Tag.displayName = 'Tag'

export default Tag
