import React, { ReactNode } from 'react'
import styled from 'styled-components'

const StepContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`

const ContentTitle = styled.span`
  color: ${({ theme }) => theme.color.text.primary};
  font-family: "ALS Gorizont", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;

  @media screen and (max-width: 1000px) {
    font-size: 16px;
    line-height: 24px;
  }
`
const ContentWrapper = styled.div`
  padding-top: 32px;
  padding-bottom: 40px;

  @media screen and (max-width: 1000px) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
`
type StepContentLayoutProps = {
  title: string | ReactNode
  children: ReactNode
}

const StepContentLayout = ({ title, children }: StepContentLayoutProps) => {
  return (
    <StepContentWrapper>
      <ContentTitle>{title}</ContentTitle>
      <ContentWrapper>{children}</ContentWrapper>
    </StepContentWrapper>
  )
}

export default StepContentLayout
