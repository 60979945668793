import React, { useContext } from 'react'
import Flex from '../../../../std-lib/FastUI/Components/Flex'
import Rectangle from '../../../../std-lib/FastUI/Components/Rectangle'
import Text from '../../../../std-lib/FastUI/Components/Text'
import Hand from '../../assets/handImage.svg'
import Img from '../../../../std-lib/FastUI/Components/Img'
import Button from '../../../../std-lib/UiKit/Button'
import { useUserData } from '../../../Onboarding/UserDataStorage/UseUserData'
import Br from '../../../../std-lib/FastUI/Components/Br'
import { css } from 'styled-components'
import Hide from '../../../../std-lib/FastUI/Components/Hider'
import { useParams } from 'react-router-dom'
import { Sections } from '../../../Onboarding/Layouts/Step/onboradingRoutes/types'
import ModalContext from '../../../../std-lib/Сontext/Modal'

const ReturnToStepModal = ({
  returnToCorrectStep,
}: {
  returnToCorrectStep: () => void
}) => {
  const params = useParams()
  const sectionNameParam = params.section as Sections
  const stepNumberParam = Number(params.stepNumber)
  const { closeModal } = useContext(ModalContext)
  const { clearUserData } = useUserData()

  const restart = () => {
    clearUserData()
    if (stepNumberParam === 0 && sectionNameParam === Sections.INTRO) {
      closeModal()
    } else {
      window.location.href = '/'
    }
  }

  return (
    <Rectangle width={618} padding={40} mobile={{ width: '100%', padding: 24 }}>
      <Flex direction="column">
        <Flex direction={'row'} alignItems={'flex-start'}>
          <Flex direction={'column'} fragment={css`flex-grow: 2`}>
            <Flex direction="row">
              <Text
                size={24}
                weight={700}
                font={'ALS Gorizont'}
                mobile={{ size: 16, weight: 600 }}
              >
                Продолжим с места, где остановились?
              </Text>
              <Img src={Hand} width={77} mobile={{ make: 'hidden' }} />
            </Flex>
            <Br height={24} mobile={{ height: 16 }} />
            <Text size={16} weight={400}>
              Ты остановился на выборе хобби и интересов, мы сохранили твои ответы.
            </Text>
          </Flex>
          <Img src={Hand} width={56} desktop={{ make: 'hidden' }} />
        </Flex>
        <Br height={40} mobile={{ height: 24 }} />
        <Flex direction="row" gap={16}>
          <Button
            buttonStyle={'secondary'}
            onClick={restart}
            styledFragment={css`
              height: 52px;
              flex-grow: 2;
              font-size: 18px;

              @media screen and (max-width: 1000px) {
                font-size: 14px;
                line-height: 22px;
                padding: 8px 20px;
                height: 38px;
              }
            `}
          >
            Начать заново
          </Button>
          <Button
            buttonStyle={'main'}
            onClick={returnToCorrectStep}
            styledFragment={css`
              height: 52px;
              flex-grow: 2;
              font-size: 18px;

              @media screen and (max-width: 1000px) {
                font-size: 14px;
                line-height: 22px;
                padding: 8px 20px;
                height: 38px;
              }
            `}
          >
            Продолжить <Br width={8} />
            <Hide mobile={{ when: true }}>заполнять</Hide>
          </Button>
        </Flex>
      </Flex>
    </Rectangle>
  )
}

export default ReturnToStepModal
