import { BASE_URL } from '../Constants'

const getCookie = (name: string) => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts && parts.length === 2) {
    // @ts-ignore
    return parts.pop().split(';').shift()
  }
}
export const makeAbitLink = (slug: string, trajectoyId: number) => {
  return `${BASE_URL}api/isapp/abit?trackId=${trajectoyId}&slug=${slug.replace(
    '/',
    '',
  )}&ymID=${getCookie('_ym_uid')}`
}
