import React, { ReactNode } from 'react'
import { Wrapper , TextsWrapper} from './styled'
import Text from '../../../../std-lib/FastUI/Components/Text'
import { css } from 'styled-components'

interface InfoBlockProps {
  title: string
  subtitle?: string
  children: ReactNode | ReactNode[]
}

const InfoBlock = (props: InfoBlockProps) => {
  const { title, children, subtitle} = props
  return (
    <Wrapper>
      <TextsWrapper>
        <Text size={24} weight={700} font={'ALS Gorizont'} mobile={{ size: 16 }}>
          {title}
        </Text>
        {subtitle && <Text size={20} weight={400}>{subtitle}</Text>}
      </TextsWrapper>
      {children}
    </Wrapper>
  )
}

export default InfoBlock
