import styled, { css } from 'styled-components'

export const ProfessionCategoryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    gap: 16px
  }
`

export const CategoryTitle = styled.span`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  display: block;
  margin-bottom: 16px;

  @media (max-width: 1000px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
  }
`

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  flex-shrink: 2;

  span:last-child {
    @media (max-width: 1000px) {
      font-size: 14px;
      line-height: 22px;
    }
  }
`

export const NumberItem = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  justify-content: flex-start;

  @media screen and (max-width: 1000px) {
    min-width: 164px;
    flex-grow: 2;
    flex-shrink: 1;
    height: auto;
  }

  @media screen and (max-width: 797px) {
    max-width: none;
  }
`

export const NumberBlock = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 20px 32px;
  border-radius: 20px;
  border: 1px solid #DEDEDE;
  gap: 36px;

  @media (max-width: 1000px) {
    padding: 16px 8px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 797px) {
    flex-direction: column;
  }
`

export const NumberTitle = styled.span`
  font-family: 'ALS Gorizont', sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 54px;

  @media (max-width: 1000px) {
    font-size: 36px;
    line-height: 32px;
  }
`

export const NumberSubtitle = styled.span`
  color: #79797A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-align: center;

  @media (max-width: 1000px) {
    font-size: 12px;
    line-height: 18px;
  }
`

export const cardStyle = {
  padding: '4px 8px 8px 8px',
  width: 156,
  height: 86,
}

export const ImageWrapper = css`
  flex-shrink: 1;
  min-width: 250px;
`
