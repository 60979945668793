import React from 'react'
import Flex from 'std-lib/FastUI/Components/Flex'
import Img from 'std-lib/FastUI/Components/Img'
import Text from 'std-lib/FastUI/Components/Text'
import arrowCircle from 'Images/Static/arrowCircle.png'
import Rectangle from 'std-lib/FastUI/Components/Rectangle'
import Logo from 'Images/Static/logo'
import { Link, useNavigate } from 'react-router-dom'
import { css } from 'styled-components'
import { useUserData } from '../../../Onboarding/UserDataStorage/UseUserData'

const FinalHeader = () => {
  const navigate = useNavigate()
  const { clearUserData } = useUserData()

  return (
    <Rectangle
      margin={'32px 64px'}
      width={'calc(100vw - 128px)'}
      fragment={css`max-width: 1440px`}
    >
      <Flex
        alignItems={'center'}
        justifyContent={'space-between'}
        mobile={{ make: 'centered' }}
      >
        <Link to={'/'}>
          <Logo />
        </Link>
        <Rectangle mobile={{ make: 'hidden' }}>
          <button onClick={() => {
            clearUserData()
            navigate('/')
          }}>
            <Flex make={'centered'} gap={12}>
              <Img src={arrowCircle} width={16} />
              <Text size={20}>Пройти еще раз</Text>
            </Flex>
          </button>
        </Rectangle>
      </Flex>
    </Rectangle>
  )
}

export default FinalHeader
