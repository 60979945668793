import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import StyledInput from '../Input'

export const AutocompleteRoot = styled.div<{
  styledFragment?: FlattenSimpleInterpolation
}>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ styledFragment }) => styledFragment ?? ''}
`
export const AutocompleteInput = styled(StyledInput)<{ withPopup: boolean }>`
  transition: all .3s, border-radius .2s;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  &:focus {
    border-radius: ${({ withPopup }) => (withPopup ? '20px 20px 0 0' : '20px')};
  }
`

export const NotFoundWrapper = styled.div`
  height: 132px;
  width: 100%;
  display: flex;
  padding: 10px 16px;
  box-sizing: border-box;
  align-items: center;
  gap: 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  img {
    height: 52px;
    width: 35px;
  }
`

const OptionHoverStyle = css`

  background-color: ${({ theme }) => theme.color.background.noStroke.hover};

  span:first-child {
    color: ${({ theme }) => theme.color.text.button};
  }

  span:last-child {
    color: ${({ theme }) => theme.color.text.secondary};
  }

`

export const Option = styled.div<{ active: boolean }>`
  display: flex;
  padding: 10px 16px;
  box-sizing: border-box;
  align-items: flex-start;
  gap: 12px;
  transition: all .2s;
  cursor: pointer;

  span:first-child {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  span:last-child {
    color: ${({ theme }) => theme.color.text.tertiary};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  ${({ active }) => active && OptionHoverStyle};
`

export const OptionsListWrapper = styled.div<{
  isOpen: boolean
}>`
  transition: all .2s, border-radius .3s;
  box-sizing: border-box;
  width: 100%;
  z-index: 3;
  overflow: auto;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme, isOpen }) =>
    isOpen ? theme.color.elements.stroke.active : 'transparent'};
  border-top-color: transparent;
  border-radius: ${({ isOpen }) => (isOpen ? '0 0 20px 20px' : '20px')};
  background-color: ${({ theme }) => theme.color.background.withStroke.primary};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};

  ${Option} {
    max-height: ${({ isOpen }) => (isOpen ? 80 : 0)}px;
    padding: ${({ isOpen }) => (isOpen ? '10px 16px' : 0)};
    transition: all .25s;
  }

  ${Option} span {
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  }

  @media screen and (max-width: 1000px) {
    ${Option} {
      padding: ${({ isOpen }) => (isOpen ? '10px 10px' : 0)} !important;
    }
  }
`

export const OptionsList = styled.ul`
  padding: 0;
  margin: 0;
  width: 100%;
  top: 43px;
  list-style: none;
  height: 100%;
`
