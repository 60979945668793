import React from 'react';

const FrontendDeveloper = () => {
	return (
		<svg width="113" height="148" viewBox="0 0 113 148" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g className="svgAnime translateX(-4px)+rotate(-34.29deg) n0">
				<path
					d="M12.2513 33.2416C10.8529 34.0647 7.17563 36.6215 7.17563 36.6215C15.6335 39.9701 19.5176 33.4649 19.4895 30.0119C22.2183 28.637 33.9467 25.8359 38.1419 23.3666C41.4755 18.6287 37.8134 17.546 35.1433 17.4985L17.4033 24.3561C16.7628 23.3453 14.3456 21.5761 9.80123 22.5855C5.2569 23.5949 3.73131 27.7775 3.53656 29.7426L9.4211 27.6667L12.8649 29.5961L12.2513 33.2416Z"
					fill="#C1D5F6"></path>
				<path fillRule="evenodd" clipRule="evenodd"
				      d="M3.97107 29.2253L9.45545 27.2906L13.2539 29.4187L12.5752 33.4513L12.4361 33.5332C11.7486 33.9379 10.4894 34.7761 9.39312 35.5196C8.84696 35.89 8.34434 36.2349 7.97823 36.4871C7.95669 36.5019 7.93562 36.5164 7.91505 36.5306C11.7205 37.8573 14.4688 37.0775 16.2868 35.6056C18.2285 34.0335 19.1482 31.6418 19.135 30.0145L19.1333 29.8058L19.3252 29.709C20.0375 29.3501 21.3108 28.909 22.8537 28.4164C23.8015 28.1137 24.8655 27.7873 25.9821 27.4447C26.6912 27.2271 27.4216 27.0031 28.1569 26.7744C31.9278 25.6019 35.8202 24.3114 37.8915 23.1132C38.6778 21.9822 39.0287 21.1001 39.1026 20.4218C39.1762 19.7465 38.9774 19.2643 38.6335 18.9054C38.2782 18.5346 37.7431 18.2708 37.1104 18.0978C36.5041 17.9322 35.8345 17.8571 35.208 17.8416L17.257 24.7808L17.1007 24.5342C16.5534 23.6705 14.3038 21.9359 9.88085 22.9184C7.71377 23.3998 6.26729 24.6349 5.33341 25.9942C4.56288 27.1158 4.14656 28.3156 3.97107 29.2253ZM6.96825 36.3443L7.12949 36.2325C7.23298 36.1608 7.38232 36.0576 7.56588 35.9311C7.93296 35.6782 8.43706 35.3324 8.98504 34.9607C10.009 34.2663 11.1971 33.4741 11.9272 33.0331L12.4759 29.7733L9.38673 28.0426L3.13013 30.2497L3.18362 29.71C3.28594 28.6775 3.73308 27.0858 4.74218 25.6169C5.75587 24.1414 7.34434 22.7805 9.7216 22.2525C14.0748 21.2855 16.6164 22.7911 17.5334 23.9376L35.078 17.1555L35.1499 17.1568C35.8381 17.169 36.5996 17.2479 37.3038 17.4404C38.0045 17.6319 38.6772 17.9437 39.1544 18.4417C39.6431 18.9517 39.9017 19.6315 39.8078 20.4932C39.7156 21.3397 39.2855 22.3499 38.4353 23.5583L38.3922 23.6196L38.3266 23.6581C36.1854 24.9185 32.1588 26.2482 28.3746 27.4248C27.6329 27.6554 26.9001 27.8803 26.1903 28.098C25.0777 28.4394 24.0216 28.7634 23.0767 29.0651C21.6603 29.5173 20.525 29.9099 19.8411 30.2246C19.7791 32.034 18.769 34.4884 16.7429 36.1289C14.6063 37.8588 11.3781 38.6547 7.04091 36.9375L6.45098 36.704L6.96825 36.3443Z"
				      fill="#1F1F22"></path>
			</g>
			<g className="svgAnime translateX(-14px) n1">
				<path
					d="M95.5448 133.068L98.9538 129.653L104.635 136.483L107.476 134.206C105.772 132.119 102.363 127.376 102.363 127.376C102.363 127.376 105.393 126.048 106.908 125.668L91.5677 114.853L95.5448 133.068Z"
					fill="#C1D5F6"></path>
				<path fillRule="evenodd" clipRule="evenodd"
				      d="M91 114L107.78 125.83L106.999 126.025C106.27 126.208 105.156 126.626 104.207 127.006C103.737 127.194 103.313 127.371 103.007 127.501C102.982 127.512 102.957 127.522 102.934 127.532C103.015 127.645 103.113 127.78 103.225 127.935C103.571 128.411 104.05 129.069 104.582 129.791C105.647 131.239 106.921 132.939 107.766 133.975L108 134.261L104.582 137L98.9285 130.203L95.3258 133.812L91 114ZM102.363 127.376L102.212 127.039L102.221 127.035L102.247 127.023L102.347 126.98C102.433 126.943 102.558 126.889 102.713 126.823C103.022 126.692 103.451 126.513 103.927 126.322C104.604 126.051 105.389 125.751 106.069 125.53L92.1354 115.706L95.7637 132.324L98.9789 129.102L104.688 135.966L106.954 134.15C106.106 133.091 104.951 131.546 103.979 130.225C103.445 129.501 102.966 128.842 102.619 128.364C102.446 128.125 102.306 127.932 102.209 127.798L102.059 127.589L102.363 127.376ZM102.363 127.376L102.059 127.589L101.794 127.221L102.212 127.039L102.363 127.376Z"
				      fill="#1F1F22"></path>
			</g>
			<g className="svgAnime translateX(7.48px) n2">
				<rect x="2" y="40" width="6.90905" height="6.90905" fill="#1F1F22"></rect>
				<rect x="2" y="106.787" width="6.90905" height="6.90905" fill="#1F1F22"></rect>
			</g>
			<g className="svgAnime translateX(-7.48px) n3">
				<rect x="104.484" y="106.787" width="6.90905" height="6.90905" fill="#1F1F22"></rect>
				<rect x="104.484" y="40" width="6.90905" height="6.90905" fill="#1F1F22"></rect>
			</g>
			<path className="svgAnime scaleX(0.839) n4" fillRule="evenodd" clipRule="evenodd"
			      d="M5.02271 43.0227H7.78371V43.8863H5.88634V45.8398H5.02271V43.0227ZM17.1005 43.8863H12.4421V43.0227H17.1005V43.8863ZM26.4172 43.8863H21.7588V43.0227H26.4172V43.8863ZM35.7339 43.8863H31.0756V43.0227H35.7339V43.8863ZM45.0507 43.8863H40.3923V43.0227H45.0507V43.8863ZM54.3674 43.8863H49.7091V43.0227H54.3674V43.8863ZM63.6842 43.8863H59.0258V43.0227H63.6842V43.8863ZM73.0009 43.8863H68.3426V43.0227H73.0009V43.8863ZM82.3177 43.8863H77.6593V43.0227H82.3177V43.8863ZM91.6344 43.8863H86.9761V43.0227H91.6344V43.8863ZM100.951 43.8863H96.2928V43.0227H100.951V43.8863ZM107.507 43.8863H105.61V43.0227H108.371V45.8398H107.507V43.8863ZM107.507 55.3809V50.6103H108.371V55.3809H107.507ZM5.88634 50.6103V55.3809H5.02271V50.6103H5.88634ZM107.507 64.9219V60.1514H108.371V64.9219H107.507ZM5.88634 60.1514V64.9219H5.02271V60.1514H5.88634ZM107.507 74.463V69.6924H108.371V74.463H107.507ZM5.88634 69.6925V74.463H5.02271V69.6925H5.88634ZM107.507 84.004V79.2335H108.371V84.004H107.507ZM5.88634 79.2335V84.004H5.02271V79.2335H5.88634ZM107.507 93.5451V88.7746H108.371V93.5451H107.507ZM5.88634 88.7746V93.5451H5.02271V88.7746H5.88634ZM107.507 103.086V98.3156H108.371V103.086H107.507ZM5.88634 98.3156V103.086H5.02271V98.3156H5.88634ZM107.507 109.81V107.857H108.371V110.674H105.61V109.81H107.507ZM5.88634 109.81V107.857H5.02271V110.674H7.78369V109.81H5.88634ZM12.4421 109.81H17.1004V110.674H12.4421V109.81ZM21.7588 109.81H26.4172V110.674H21.7588V109.81ZM31.0756 109.81H35.7339V110.674H31.0756V109.81ZM40.3923 109.81H45.0507V110.674H40.3923V109.81ZM49.7091 109.81H54.3674V110.674H49.7091V109.81ZM59.0258 109.81H63.6842V110.674H59.0258V109.81ZM68.3426 109.81H73.0009V110.674H68.3426V109.81ZM77.6593 109.81H82.3177V110.674H77.6593V109.81ZM86.976 109.81H91.6344V110.674H86.976V109.81ZM96.2928 109.81H100.951V110.674H96.2928V109.81Z"
			      fill="#1F1F22"></path>
			<g className="svgAnime translateX(9px) n5">
				<path d="M19.2726 57.2727H53.8178V98.727H19.2726V57.2727Z" fill="#677CE8"></path>
				<path fillRule="evenodd" clipRule="evenodd"
				      d="M53.2421 57.8485H19.8483V98.1512H53.2421V57.8485ZM19.2726 57.2727V98.727H53.8178V57.2727H19.2726Z"
				      fill="#1F1F22"></path>
				<path fillRule="evenodd" clipRule="evenodd"
				      d="M38.787 72.2952C39.0278 72.4191 39.1254 72.7203 39.005 72.968L34.4557 82.3285C34.3354 82.5762 34.0426 82.6766 33.8018 82.5527C33.561 82.4289 33.4634 82.1276 33.5838 81.8799L38.1331 72.5195C38.2535 72.2718 38.5462 72.1714 38.787 72.2952ZM31.7649 72.3892C31.9552 72.585 31.9552 72.9025 31.7649 73.0983L27.5603 77.424L31.7649 81.7496C31.9552 81.9454 31.9552 82.2629 31.7649 82.4588C31.5745 82.6546 31.2659 82.6546 31.0755 82.4588L26.1816 77.424L31.0755 72.3892C31.2659 72.1933 31.5745 72.1933 31.7649 72.3892ZM40.1741 72.3892C40.3644 72.1933 40.673 72.1933 40.8634 72.3892L45.7573 77.424L40.8634 82.4588C40.673 82.6546 40.3644 82.6546 40.1741 82.4588C39.9837 82.2629 39.9837 81.9454 40.1741 81.7496L44.3786 77.424L40.1741 73.0983C39.9837 72.9025 39.9837 72.585 40.1741 72.3892Z"
				      fill="white"></path>
			</g>
			<g className="svgAnime scaleX(0.4998) n6">
				<path d="M59.5754 57.2725H94.1207V67.636H59.5754V57.2725Z" fill="#677CE8"></path>
				<path fillRule="evenodd" clipRule="evenodd"
				      d="M93.5449 57.8482H60.1512V67.0603H93.5449V57.8482ZM59.5754 57.2725V67.636H94.1207V57.2725H59.5754Z"
				      fill="#1F1F22"></path>
				<path d="M59.5754 72.2422H94.1207V82.6058H59.5754V72.2422Z" fill="#C1D5F6"></path>
				<path fillRule="evenodd" clipRule="evenodd"
				      d="M93.5449 72.8179H60.1512V82.03H93.5449V72.8179ZM59.5754 72.2422V82.6058H94.1207V72.2422H59.5754Z"
				      fill="#1F1F22"></path>
				<path d="M59.5754 88.3633H94.1207V98.7269H59.5754V88.3633Z" fill="#677CE8"></path>
				<path fillRule="evenodd" clipRule="evenodd"
				      d="M93.5449 88.939H60.1512V98.1511H93.5449V88.939ZM59.5754 88.3633V98.7269H94.1207V88.3633H59.5754Z"
				      fill="#1F1F22"></path>
			</g>
		</svg>
	);
};

export default FrontendDeveloper;