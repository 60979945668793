import styled from 'styled-components'

export const PaddingWrapper = styled.div<{ isFullWidth: boolean }>`
  margin: ${p => (p.isFullWidth ? 0 : '0 64px')};
  width: calc(100vw - 128px);
  max-width: ${p => (p.isFullWidth ? 'calc(100vw)' : '1440px')};

  @media screen and (max-width: 1000px) {
    margin: ${p => (p.isFullWidth ? 0 : '0 16px')};
    width: calc(100vw - 32px);
  }
`
export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 32px;
  //width: 100vw; 
  //overflow: hidden;
  
  @media screen and (max-width: 1000px) {
    gap: 16px;
  }
`

export const TextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`
