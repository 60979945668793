import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  ThemeProps,
} from 'styled-components'
import { isMobile } from 'react-device-detect'
import React, { DetailedHTMLProps, ReactNode } from 'react'

// type ButtonPropType = {
//   buttonStyle: 'secondary' | 'main'
//   onClick?: MouseEventHandler<HTMLButtonElement>
//   children: React.ReactNode
//   isDisabled?: boolean
//   styledFragment?: FlattenSimpleInterpolation
//   classNames?: string[]
// }

const primaryStyledButton = (isDisabled?: boolean) => css`
  color: #FFFFFF;
  ${() =>
    isDisabled
      ? css`background-color: #D8BEFF;`
      : css`
                    background-color: ${({ theme }) =>
                      theme.color.states.primary.default};

                    &:active {
                      background-color: #5722A5;
                    }

                    @media (hover: hover) {
                      &:hover {
                        background-color: ${({ theme }) =>
                          theme.color.states.primary.active};
                      }
                    }

                  `}
`

const secondaryStyledButton = (isDisabled?: boolean) =>
  isDisabled
    ? css`
            border: 1px solid ${({ theme }) => theme.color.states.primary.disabled};
            background: none;
            color: ${({ theme }) => theme.color.states.primary.disabled};
    `
    : css`
            background: none;
            color: ${({ theme }) => theme.color.text.button};
            border: 1px solid ${({ theme }) => theme.color.text.button};

            ${({ theme }) =>
              isMobile
                ? ''
                : `&:hover {
                background-color: ${theme.color.background.noStroke.default};
                color: #712FD3;
              }`}
            &:active {
              background-color: ${({ theme }) => theme.color.background.noStroke.active};
              color: #5722A5;
              border: 1px solid #5722A5;
            }
    `

export const StyledButton = styled.button<{
  buttonStyle?: 'secondary' | 'main'
  isDisabled?: boolean
  isValid?: boolean
  styledFragment?:
    | FlattenSimpleInterpolation
    | FlattenInterpolation<ThemeProps<DefaultTheme>>
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 40px;
  width: max-content;
  height: 40px;
  border-radius: 26px;
  cursor: pointer;
  outline: none !important;
  border: none;
  font-family: 'Golos', sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  transition: all .3s;

  ${props =>
    props.isDisabled &&
    css`
            pointer-events: none;
          `};


  ${({ buttonStyle, isDisabled }) => {
    switch (buttonStyle) {
      case 'main':
        return primaryStyledButton(isDisabled)
      case 'secondary':
        return secondaryStyledButton(isDisabled)
      default:
        return primaryStyledButton(isDisabled)
    }
  }}

  ${({ isValid }) =>
    isValid !== undefined &&
    css`
            background-color: ${isValid ? '#37CE86' : '#F57B7B'};
            pointer-events: none;
            color: white;
          `}

  ${({ styledFragment }) => styledFragment};
`

// const ImageWrapper = styled.div<{ position: 'left' | 'right' }>`
//   display: flex;
//   align-items: center;
// `

const StyledImg = styled.img`
  width: auto;
  height: 100%;
  max-height: 100%;
`

type StyledButtonProps = {
  ref?:
    | ((instance: HTMLButtonElement | null) => void)
    | React.RefObject<HTMLButtonElement>
    | null
    | undefined
  leftIconSrc?: string | ReactNode
  rightIconSrc?: string | ReactNode
  buttonStyle?: 'secondary' | 'main'
  isDisabled?: boolean
  isValid?: boolean
  styledFragment?:
    | FlattenSimpleInterpolation
    | FlattenInterpolation<ThemeProps<DefaultTheme>>
}

type ButtonOwnProps = DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

type ButtonProps = StyledButtonProps & Omit<ButtonOwnProps, keyof StyledButtonProps>

export const Button = ({
  leftIconSrc,
  rightIconSrc,
  children,
  ...buttonProps
}: ButtonProps) => {
  return (
    <StyledButton {...buttonProps}>
      {leftIconSrc && typeof leftIconSrc === 'string' ? (
        <StyledImg src={leftIconSrc} alt="icon" />
      ) : (
        leftIconSrc
      )}
      {children}
      {rightIconSrc && typeof rightIconSrc === 'string' ? (
        <StyledImg src={rightIconSrc} alt="icon" />
      ) : (
        rightIconSrc
      )}
    </StyledButton>
  )
}

export default Button
