import bio1 from './Sphere_icn_bio_1.svg'
import bio2 from './Sphere_icn_bio_2.svg'
import buisness1 from './Sphere_icn_business_1.svg'
import buisness2 from './Sphere_icn_business_2.svg'
import coding1 from './Sphere_icn_coding_1.svg'
import coding2 from './Sphere_icn_coding_2.svg'
import design1 from './Sphere_icn_design_1.svg'
import design2 from './Sphere_icn_design_2.svg'
import physics1 from './Sphere_icn_physics_1.svg'
import physics2 from './Sphere_icn_physics_2.svg'
import notSelected1 from './Sphere_icn_not_selected_1.svg'
import notSelected2 from './Sphere_icn_not_selected_2.svg'

export default {
  Bio: {
    1: bio1,
    2: bio2,
  },
  Business: {
    1: buisness1,
    2: buisness2,
  },
  Coding: {
    1: coding1,
    2: coding2,
  },
  Design: {
    1: design1,
    2: design2,
  },
  Physics: {
    1: physics1,
    2: physics2,
  },
  NotSelected: {
    1: notSelected1,
    2: notSelected2,
  },
}
