import React, { ReactNode } from 'react'
import useCheckStep from './hooks/useCheckStep'

type PropType = {
  children?: ReactNode
}

const ProgressChecker = (props: PropType) => {
  useCheckStep()
  return <>{props.children}</>
}

export default ProgressChecker
