import React, { useEffect, useState } from 'react'
import {
  CheckMarkWrapper,
  LoaderWrapper,
  ProgressBar,
  ProgressBarLine,
  ProgressBarLineGradient,
  ProgressBarLineWrapper,
  ProgressBarPercent,
  ProgressBarPhrase,
  ProgressBarsWrapper,
  ProgressBarText,
  Title,
  TitleWrapper,
} from './styled'

import Stars from '../../Images/Static/stars'
import Checkmark from '../../Images/icons/checkmark'
import { randomNumberBetween } from '../../std-lib/Extensions/number'
import { isMobile } from 'react-device-detect'

interface TrajectoriesLoaderProps {
  onLoadingFinish: (isLoadingFinish: boolean) => void
  canFinishAnimation: boolean
  minAnimationTime: number
}

const loaderPhrases = [
  'Анализируем твои интересы',
  'Подбираем образовательные программы',
  'Подбираем дисциплины по выбору',
  'Прокачиваем твою удачу',
]

const TrajectoriesLoader = (props: TrajectoriesLoaderProps) => {
  const { canFinishAnimation, onLoadingFinish, minAnimationTime } = props

  const [loadingProgress, setLoadingProgress] = useState<number[]>([0, 0, 0, 0])
  const [shouldSlowDown, setShouldSlowDown] = useState<boolean>(false)

  const isLoadingProgressFinished =
    loadingProgress.every(complete => complete >= 100) && !isMobile

  useEffect(() => {
    const timer = setInterval(
      () => {
        setLoadingProgress(prevProgress => {
          return prevProgress.map((progress, index) => {
            const isAllComplete = loadingProgress.every(complete => complete >= 100)

            if (isAllComplete) {
              clearInterval(timer)
              setTimeout(() => {
                onLoadingFinish(true)
              }, 500)
            }

            if (canFinishAnimation) {
              setShouldSlowDown(false)
              const diff = Math.random() * 50
              return Math.min(progress + diff, 100)
            } else {
              if (index >= prevProgress.length - 2) {
                if (progress >= 50) {
                  setShouldSlowDown(true)
                }
                const diff = shouldSlowDown
                  ? randomNumberBetween(1, 3)
                  : Math.random() * 10
                return Math.min(progress + diff, 100)
              } else {
                const diff = Math.random() * 15
                return Math.min(progress + diff, 100)
              }
            }
          })
        })
      },
      shouldSlowDown ? 1000 : 300,
    )

    return () => {
      clearInterval(timer)
    }
  }, [shouldSlowDown, canFinishAnimation, loadingProgress])

  return (
    <LoaderWrapper isAnimationFinish={isLoadingProgressFinished}>
      <TitleWrapper isAnimationFinish={isLoadingProgressFinished}>
        <Title>Наш искусственный интеллект строит твои траектории</Title>
        <Stars />
      </TitleWrapper>
      <ProgressBarsWrapper isAnimationFinish={isLoadingProgressFinished}>
        {loaderPhrases.map((phrase, index) => (
          <ProgressBar key={phrase}>
            <ProgressBarText>
              <ProgressBarPhrase>{phrase}</ProgressBarPhrase>
              {loadingProgress[index] === 100 && (
                <CheckMarkWrapper>
                  <Checkmark />
                </CheckMarkWrapper>
              )}
              {loadingProgress[index] !== 100 && (
                <ProgressBarPercent>
                  {loadingProgress[index].toFixed(0)}%
                </ProgressBarPercent>
              )}
            </ProgressBarText>
            <ProgressBarLineWrapper>
              <ProgressBarLine />
              {loadingProgress[index] === 100 && (
                <ProgressBarLineGradient width={100} isDisabled={true} />
              )}
              {loadingProgress[index] !== 100 && (
                <ProgressBarLineGradient
                  width={loadingProgress[index]}
                  isDisabled={false}
                />
              )}
            </ProgressBarLineWrapper>
          </ProgressBar>
        ))}
      </ProgressBarsWrapper>
    </LoaderWrapper>
  )
}

export default TrajectoriesLoader
