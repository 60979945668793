import { ComponentType } from 'react'
import InformationSecurity from './InformationSecurity'
import SystemArchitect from './SystemArchitect'
import GameDesigner from './GameDesigner'
import Modeller from './3DModeller'
import DataScientist from './DataScientist'
import MicrocontrollerProgrammer from './MicrocontrollerProgrammer'
import BackendDeveloper from './BackendDeveloper'
import MobileDeveloper from './MobileDeveloper'
import FrontendDeveloper from './FrontendDeveloper'
import DevOps from './DevOps'
import Designer from './Designer'
import DesignConstructor from './DesignConstructor'
import PlanningEngineer from './PlanningEngineer'
import LaserEngineer from './LaserEngineer'
import Marketer from './Marketer'
import Economist from './Economist'
import FoodIndustryTechnologist from './FoodIndustryTechnologist'
import Microbiologist from './Microbiologist'
import Biotechnologist from './Biotechnologist'
import Ecologist from './Ecologist'
import OpticEngineer from './OpticEngineer'
import BusinessAnalyst from './BusinessAnalyst'
import ProductManager from './ProductManager'
import EngineerResearcher from './EngineerResearcher'
import Bioinformaticks from './Bioinformatics'
import infochemestry from './Infochemestry'
import DataAnalyst from './DataAnalyst'
import DataEngeneer from './DataEngeneer'
import DeepLearningEngeneer from './DeepLearningEngineer'
import Energeticks from './Energeticks'
import Roboticks from './Roboticks'
import Urbanism from './Urbanism'
import Project from './Project'
import Inovation from './Inovation'

const ProfessionsIconsImages: Record<string, ComponentType> = {
  'Специалист по инф. безопасности': InformationSecurity,
  'Системный архитектор': SystemArchitect,
  'Game Designer': GameDesigner,
  '3D Modeller': Modeller,
  'Data Scientist': DataScientist,
  'Программист микроконтроллеров': MicrocontrollerProgrammer,
  'Backend-разработчик': BackendDeveloper,
  'Мобильный разработчик': MobileDeveloper,
  'DevOps инженер': DevOps,
  'Frontend-разработчик': FrontendDeveloper,
  'UX/UI Designer': Designer,
  'Инженер-конструктор': DesignConstructor,
  'Инженер-проектировщик': PlanningEngineer,
  'Инженер по лазерной технике и лазерным технологиям': LaserEngineer,
  'Маркетолог': Marketer,
  'Экономист': Economist,
  'Технолог пищевой промышленности': FoodIndustryTechnologist,
  'Биолог': Microbiologist,
  'Биотехнолог': Biotechnologist,
  'Биоинформатик': Bioinformaticks,
  'Специалист по инфохимии': infochemestry,
  'Data Analyst': DataAnalyst,
  'Data Engineer': DataEngeneer,
  'Deep learning engineer': DeepLearningEngeneer,
  'Инженер-энергетик': Energeticks,
  'Специалист по робототехнике': Roboticks,
  'Специалист по урбанистике': Urbanism,
  'Специалист по инновациям': Inovation,
  'Project manager': Project,
  'Финансовый аналитик': Economist,
  'Эколог': Ecologist,
  'Инженер-оптик': OpticEngineer,
  'Бизнес-аналитик': BusinessAnalyst,
  'Product-менеджер': ProductManager,
  'Инженер-исследователь': EngineerResearcher,
}

export default ProfessionsIconsImages
