import styled, { css } from 'styled-components'
import {
  hoverOnboardingCard,
  selectedOnboardingCard,
} from '../../../../Fragments/CardStates'

export const CardImg = styled.img`
  height: 100%;
  flex-shrink: 0;
  border-radius: 19px;
  object-fit: cover;
`

export const Title = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  transition: all .3s;
  text-align: left;
`

export const Subtitle = styled.span`
  color: #79797A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
`

const bigCardPseudo = css`
  &:hover {
    border-color: ${({ theme }) => theme.color.elements.stroke.hover};
    background-color: ${({ theme }) => theme.color.background.noStroke.default};;

    ${Title} {
      color: ${({ theme }) => theme.color.states.primary.default};
    }
  }

  &:active {
    ${Title} {
      color: #712FD3;
      border-color: ${({ theme }) => theme.color.elements.stroke.hover};
    }
  }
`

export const ButtonCard = styled.button<{ selected?: boolean; skeleton?: boolean }>`
  border-radius: 20px;
  transition: all .3s;
  border: 1px solid ${({ selected, theme }) =>
    selected ? theme.color.brand.primary : '#DEDEDE'};
  background: #FFF;
  flex-grow: 1;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  height: 168px;

  ${CardImg} {
    width: 187px;

    @media screen and (max-width: 1000px) {
      width: 117px;
    }
  }

  &:hover {
    ${hoverOnboardingCard}
  }

  ${({ selected }) => selected && selectedOnboardingCard}

  ${bigCardPseudo};

  @media screen and (max-width: 1000px) {
    height: initial;
  }
`

export const TextWrapper = styled.div`
  padding: 16px 0 16px 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: space-between;
`
