import React from 'react'
import styled from 'styled-components'
import Flame from '../../../../../../Images/icons/flame/preview.svg'
import { ReplacementDiscipline } from '../../../../types'

const Dropdown = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  padding: 12px 8px;
  flex-direction: column;
  gap: 8px;
  background-color: #FFFFFF;
  overflow: auto;

  @media screen and (max-width: 1000px) {
    gap: 20px;
    padding: 0
  }
`

const DropDownItem = styled.button<{ isActive: boolean }>`
  align-items: center;
  width: 100%;
  padding: 8px;
  display: flex;
  gap: 12px;
  border-radius: 8px;
  transition: all .3s;
  text-align: left;
  background-color: ${({ isActive }) => (isActive ? '#F4F4FB' : 'transparent')};
  color: ${({ isActive }) => (isActive ? '#8533FF' : '#1F1F22')};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  &:hover {
    background-color: #F4F4FB;
    color: #8533FF
  }
`

const CoverageTag = styled.div`
  width: 42px;
  padding: 4px 8px;
  height: min-content;
  color: #1F1F22;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  display: flex;
  background: #F4F4FB;
  border-radius: 6px;
  flex-shrink: 0;
  justify-content: flex-end;
`

type ReplacementOptionsDropdown = {
  items: ReplacementDiscipline[]
  onItemClick: (discipline: ReplacementDiscipline) => void
  activeItem: ReplacementDiscipline
  bestDisciplineId: number
}

const ReplacementOptionsDropdown = ({
  items,
  onItemClick,
  bestDisciplineId,
  activeItem,
}: ReplacementOptionsDropdown) => {
  return (
    <Dropdown>
      {items.map(item => (
        <DropDownItem
          key={item.id}
          isActive={item.id === activeItem.id}
          onClick={() => onItemClick(item)}
        >
          {item.id === bestDisciplineId ? (
            <img src={Flame} alt="" style={{ height: 16, width: 42, flexShrink: 0 }} />
          ) : (
            <CoverageTag>{(item.keywords_coverage * 100).toFixed(0)}%</CoverageTag>
          )}
          {item.name}
        </DropDownItem>
      ))}
    </Dropdown>
  )
}

export default ReplacementOptionsDropdown
