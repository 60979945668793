import { AxiosInstance } from 'axios'
import request from 'std-lib/API/request'

type TrajectoryCount = {
  count: number
}
const startRequestConfig = (instance: AxiosInstance) => ({
  getTrajectoryCount: () => {
    return instance.get<TrajectoryCount>('trajectories/count/')
  },
})

export const startRequest = startRequestConfig(request)
