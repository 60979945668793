import React from 'react'
import { Wrapper, TextWrapper, Tag } from './styled'
import Text from '../../../../std-lib/FastUI/Components/Text'
import { clubColors } from 'Constants'
import { Club as ClubType } from '../../API/finalRequest'

const Club = ({ category, title, description }: ClubType) => {
  return (
    <Wrapper>
      <Tag background={clubColors[category]?.baseColor}>
        <Text size={14} weight={400}>
          {category}
        </Text>
      </Tag>
      <TextWrapper>
        <Text
          size={20}
          weight={600}
          lineHeight={28}
          mobile={{
            size: 16,
          }}
        >
          {title}
        </Text>
        <Text size={14} weight={400} lineHeight={22}>
          {description}
        </Text>
      </TextWrapper>
    </Wrapper>
  )
}

export default Club
