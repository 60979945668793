import React from 'react';

const DesignConstructor = () => {
	return (
		<svg width="113" height="148" viewBox="0 0 113 148" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="3.45" y="11.45" width="55.1" height="97.1" stroke="#1F1F22" strokeWidth="0.9"></rect>
			<path
				d="M23 54.45H23.45V54V32.5V15.45H55.05V103.55H7.45V32.95H9.5552L9.79474 53.55H7.5V54.45H10.25H13H16H19.5H23ZM9.55 32.05H7.45V15.45H9.55V32.05ZM10.45 32.05V15.45H12.55V32.05H10.45ZM13.45 32.05V15.45H15.55V32.05H13.45ZM16.45 32.05V15.45H19.05V32.05H16.45ZM19.95 32.05V15.45H22.55V32.05H19.95ZM19.95 32.95H22.55V53.55H19.95V32.95ZM19.05 32.95V53.55H16.45V32.95H19.05ZM15.55 32.95V53.55H13.45V32.95H15.55ZM12.55 32.95V53.55H10.6948L10.4553 32.95H12.55Z"
				stroke="#1F1F22" strokeWidth="0.9"></path>

			<g className="svgAnime translateY(-9px) n0">
				<path d="M23.3 75.4151V89H39.0667V75.4151M39.0667 75.4151H20L31 69L42 75.4151H39.0667Z" stroke="#1F1F22"
				      strokeWidth="0.9"></path>
			</g>

			<g className="svgAnime translateY(11px) n1">
				<path d="M78.5 78H70H67.5H65V82.5H72.75H80.5V78H78.5Z" fill="#F8CFB9"></path>
				<path d="M76.5 103.5H67.5H61V108.5H83V103.5H76.5Z" fill="#F5A171"></path>
				<path
					d="M65 78V82.5H72.75M65 78H67.5H70M65 78H70M72.75 82.5H80.5V78H78.5H70M72.75 82.5V85.5C73.6667 85.8333 75.4 86.8 75 88C74.7937 88.6189 73.8212 89.1527 72.75 89.3555M69.5 87.5C69.5 88.7828 70.4143 89.3343 71.5 89.4279M71.5 89.4279L67.5 103.5M71.5 89.4279C71.9056 89.4629 72.3352 89.4341 72.75 89.3555M67.5 103.5H76.5M67.5 103.5H61M72.75 89.3555L76.5 103.5M76.5 103.5H61M76.5 103.5H83V108.5H61V103.5"
					stroke="#1F1F22" strokeWidth="0.9"></path>
			</g>

			<g className="svgAnime matrix(1,0,0,1.2391,0,5.5) n2">
				<path d="M69 78V32M75.5 78V32" stroke="#1F1F22" strokeWidth="0.9"></path>
			</g>

			<path d="M61.5 11.5V32H67.5H78.5H84.5V11.5H78.5V27H67.5V11.5H61.5Z" fill="#F8CFB9"></path>
			<path d="M61.5 11.5V32H67.5H78.5H84.5V11.5H78.5V27H67.5V11.5H61.5Z" stroke="#1F1F22" strokeWidth="0.9"></path>
			<path d="M102.5 131H93.5H87V136H109V131H102.5Z" fill="#F5A171"></path>
			<path d="M102.5 131H87M102.5 131H109V136H87V131M102.5 131H93.5H87" stroke="#1F1F22" strokeWidth="0.9"></path>
			<path d="M77.5 131H68.5H62V136H84V131H77.5Z" fill="#F5A171"></path>
			<path d="M77.5 131H62M77.5 131H84V136H62V131M77.5 131H68.5H62" stroke="#1F1F22" strokeWidth="0.9"></path>
			<path d="M52.5 131H43.5H37V136H59V131H52.5Z" fill="#F5A171"></path>
			<path d="M52.5 131H37M52.5 131H59V136H37V131M52.5 131H43.5H37" stroke="#1F1F22" strokeWidth="0.9"></path>
			<path d="M90.5 124H81.5H75V129H97V124H90.5Z" fill="#F5A171"></path>
			<path d="M90.5 124H75M90.5 124H97V129H75V124M90.5 124H81.5H75" stroke="#1F1F22" strokeWidth="0.9"></path>
			<path d="M65.5 124H56.5H50V129H72V124H65.5Z" fill="#F5A171"></path>
			<path d="M65.5 124H50M65.5 124H72V129H50V124M65.5 124H56.5H50" stroke="#1F1F22" strokeWidth="0.9"></path>

			<g className="svgAnime rotate(26.42deg) n3">
				<circle cx="18.5" cy="111.5" r="15.05" fill="#F8CFB9" stroke="#1F1F22" strokeWidth="0.9"></circle>
				<path d="M11.9592 110.383L10 112.936L16.8571 119L26 105.596L23.0612 104L16.2041 113.894L11.9592 110.383Z"
				      fill="white" stroke="#1F1F22" strokeWidth="0.9" strokeLinecap="round"></path>
			</g>
		</svg>
	);
};

export default DesignConstructor;