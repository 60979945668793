import React, { useContext, useEffect, useState } from 'react'
import { StyleContext } from 'std-lib/Сontext/StyleContext'
import { secondaryThemeFragment } from 'std-lib/Stylesheets/StyledVars/newVars'
import * as Scroll from 'react-scroll'
import {
  NavBar,
  NavBarWrapper,
  NavButtonStyle,
  NavTitleWrapper,
  ProgressBar,
  StepContentCard,
  StepLayout,
} from './styled'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Button from '../../../../std-lib/UiKit/Button'
import { ButtonContextState, NavContext } from 'Context/NavContext'
import { Sections } from 'Widgets/Onboarding/Layouts/Step/onboradingRoutes/types'
import ProgressModule from './components/ProgressModule'
import Text from 'std-lib/FastUI/Components/Text'
import RightArrow from '../../../../Images/Static/rightArrow.svg'
import LeftArrow from '../../../../Images/Static/leftArrow.svg'
import { MobilePortal } from 'Utils/PortalHelper'
import { useUserData } from '../../UserDataStorage/UseUserData'
import { sendTypedEvent } from '../../../../Utils/events/sendEvent'
import { onboardingRoutes } from 'Widgets/Onboarding/Layouts/Step/onboradingRoutes/onboardingRoutes'
import ProgressChecker from 'Widgets/PropgressChecker/wrapper'

const Step = () => {
  const params = useParams()
  const sectionName = params.section as Sections
  const stepNumber = Number(params.stepNumber)
  const navigate = useNavigate()
  const { setThemeFragment } = useContext(StyleContext)
  const { updateUserDataWith, getUserData } = useUserData()
  const educationStage = getUserData().userType ?? 'bachelor'
  const location = useLocation()
  const [forwardState, setForwardState] = useState<ButtonContextState>()
  const [backwardState, setBackwardState] = useState<ButtonContextState>()

  const route = onboardingRoutes[educationStage]
  const section = route[sectionName]
  const step = section.steps[stepNumber]

  const onBackwardClick = () => {
    if (backwardState?.link) {
      navigate(backwardState.link)
      setForwardState(undefined)
      setBackwardState(undefined)
      return
    }

    if (stepNumber > 0) {
      updateUserDataWith({ section: sectionName, step: stepNumber - 1 })
      navigate(`/step/${sectionName}/${stepNumber - 1}`)
    } else if (section.prev) {
      const prevSection = route[section.prev]
      updateUserDataWith({
        section: section.prev,
        step: prevSection.steps.length - 1,
      })
      console.log(`/step/${section.prev}/${prevSection.steps.length - 1}`)
      navigate(`/step/${section.prev}/${prevSection.steps.length - 1}`)
    } else {
      navigate('/stage')
    }
    setForwardState(undefined)
    setBackwardState(undefined)
  }
  const onForwardClick = () => {
    const userData = getUserData()

    sendTypedEvent(
      'click',
      step.nameForTrackers,
      'ob',
      step.userDataFieldToSend ? step.userDataFieldToSend(userData) : undefined,
    )

    if (forwardState?.link) {
      navigate(forwardState.link)

      setForwardState(undefined)
      setBackwardState(undefined)
      return
    }

    if (section.steps.length - 1 > stepNumber) {
      updateUserDataWith({ section: sectionName, step: stepNumber + 1 })
      navigate(`/step/${sectionName}/${stepNumber + 1}`)
    } else if (section.next) {
      updateUserDataWith({ section: section.next, step: 0 })
      navigate(`/step/${section.next}/0`)
    } else {
      navigate('/step/profile')
    }
    setForwardState(undefined)
    setBackwardState(undefined)
  }

  useEffect(() => {
    setThemeFragment(secondaryThemeFragment)
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && !forwardState?.disabled) {
        onForwardClick()
      }
    }

    window.addEventListener('keydown', handleKeyPress)

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [location.pathname, setThemeFragment, onForwardClick])

  useEffect(() => {
    Scroll.animateScroll.scrollToTop()
  }, []);

  return (
    section &&
    step && (
      <ProgressChecker>
        <NavContext.Provider
          value={{
            setForwardState,
            setBackwardState,
          }}
        >
          <StepLayout>
            <StepContentCard>{step.component}</StepContentCard>
            <MobilePortal>
              <NavBarWrapper>
                <NavBar>
                  <Button
                    buttonStyle="secondary"
                    styledFragment={NavButtonStyle}
                    onClick={onBackwardClick}
                    leftIconSrc={LeftArrow}
                    isDisabled={backwardState?.disabled}
                  >
                    Назад
                  </Button>
                  <ProgressBar>
                    <NavTitleWrapper>
                      {section.title}{' '}
                      <Text size={6} lineHeight={24} style={{ verticalAlign: 'bottom' }}>
                        ★
                      </Text>{' '}
                      Вопрос {stepNumber + 1} из {section.steps.length}
                    </NavTitleWrapper>
                    <ProgressModule
                      config={onboardingRoutes[educationStage]}
                      sectionName={sectionName}
                      section={section}
                      step={stepNumber}
                    />
                  </ProgressBar>
                  <Button
                    buttonStyle="main"
                    styledFragment={NavButtonStyle}
                    rightIconSrc={RightArrow}
                    onClick={onForwardClick}
                    isDisabled={forwardState?.disabled}
                  >
                    Далее
                  </Button>
                </NavBar>
              </NavBarWrapper>
            </MobilePortal>
          </StepLayout>
        </NavContext.Provider>
      </ProgressChecker>
    )
  )
}

export default Step
