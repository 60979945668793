import styled, { css } from 'styled-components'
import CommonSlideLayout from '../../Components/CommonSlideLayout'
import Text from 'std-lib/FastUI/Components/Text'

export const ClassQuestionLayout = styled(CommonSlideLayout)`
  gap: 60px;
`

export const ClassQuestionWrapper = styled(Text)`
  margin-bottom: 40px;
  display: block;

  @media screen and (max-width: 1000px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 16px;

    b {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }
`

export const ClassImageWrapper = css`
  min-width: 250px;
  flex-shrink: 1;

  @media screen and (max-width: 1135px) {
    margin: 0 auto;
  }
`
