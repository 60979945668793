import React, { useContext, useEffect } from 'react'
import GeoIcon from 'Images/icons/geo'
import BookIcon from 'Images/icons/book'
import {
  Avatar,
  AvatarAndPersonalPointsWrapper,
  AvatarContentItemWrapper,
  BlockTitle,
  NameWrapper,
  OtherInfo,
  PersonalDataWrapper,
  PersonalPoint,
  PersonalPointsWrapper,
  PrivateInfoWrapper,
  ProfessionCard,
  ProfileContentCard,
  ProfileLayout,
  ProfileNavBar,
  ProfileTagsWrapper,
  StarsWrapper,
  testListWrapper,
  TitledBlock,
} from './styled'
import Tags from 'std-lib/Сomponents/Tags'
import Text from 'std-lib/FastUI/Components/Text'
import { NavButtonStyle, StepLayout } from '../../Layouts/Step/styled'
import StepContentLayout from '../../Layouts/Step/components/StepContentLayout'
import Button from 'std-lib/UiKit/Button'
import AvatarImage from 'Images/Static/AvatarImage.png'
import { useNavigate } from 'react-router-dom'
import { EducationType, useUserData } from '../../UserDataStorage/UseUserData'
import { StyleContext } from 'std-lib/Сontext/StyleContext'
import { primaryThemeFragment } from 'std-lib/Stylesheets/StyledVars/newVars'
import OverflowList from 'Widgets/Onboarding/Slides/Profile/components/OverflowList'
import { sendTypedEvent } from 'Utils/events/sendEvent'

const getEducationTypeTitle = (educationType?: EducationType, grade?: string) => {
  switch (educationType) {
    case 'school':
      return `${grade} класс`
    case 'spo':
      return 'СПО'
  }
}

const Profile = () => {
  const { getUserData } = useUserData()

  const userData = getUserData()

  const navigate = useNavigate()
  const { setThemeFragment } = useContext(StyleContext)

  const goToFinalPage = () => {
    sendTypedEvent('click', 'summary', 'ob')
    navigate('/trajectory')
  }

  useEffect(() => {
    setThemeFragment(primaryThemeFragment)
  })

  useEffect(() => {
    sendTypedEvent('visit', 'summary', 'ob')

    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        goToFinalPage()
      }
    }

    window.addEventListener('keydown', handleKeyPress)

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [])

  const isBachelor = getUserData().userType === 'bachelor'

  return (
    <StepLayout>
      <ProfileContentCard>
        <StepContentLayout
          title={'Финал! Проверяй данные и строй свою уникальную траекторию'}
        >
          <ProfileLayout>
            <PrivateInfoWrapper>
              <PersonalDataWrapper
                padding={isBachelor ? 32 : 24}
                gap={isBachelor ? 32 : 24}
                width={isBachelor ? 365 : 395}
              >
                <AvatarAndPersonalPointsWrapper gap={isBachelor ? 32 : 24}>
                  <Avatar
                    imageSrc={AvatarImage}
                    width={isBachelor ? 100 : 70}
                    height={isBachelor ? 100 : 70}
                  />
                  <PersonalPointsWrapper>
                    <NameWrapper>{userData.name}</NameWrapper>
                    <PersonalPoint>
                      <GeoIcon />
                      <Text size={14} weight={400}>
                        {userData.city?.city}
                      </Text>
                    </PersonalPoint>
                    {userData.educationType !== 'notStudy' &&
                      userData.userType === 'bachelor' && (
                        <PersonalPoint>
                          <BookIcon />
                          <Text size={14} weight={400}>
                            {getEducationTypeTitle(
                              userData.educationType,
                              userData.schoolGrade,
                            )}
                          </Text>
                        </PersonalPoint>
                      )}
                  </PersonalPointsWrapper>
                </AvatarAndPersonalPointsWrapper>
                {!isBachelor && (
                  <AvatarContentItemWrapper>
                    <Text size={20} weight={600}>
                      Сейчас:
                    </Text>
                    <Text
                      size={16}
                      weight={400}
                      mobile={{
                        size: 14,
                      }}
                      fragment={testListWrapper}
                    >
                      {getUserData()
                        .userOccupation.map(occupancy => occupancy.name)
                        .join(', ')}
                    </Text>
                  </AvatarContentItemWrapper>
                )}
                <AvatarContentItemWrapper>
                  {!isBachelor && (
                    <Text size={20} weight={600}>
                      Интересы:
                    </Text>
                  )}
                  <OverflowList
                    tags={[...userData.selectedHobbies, ...userData.customHobbies]}
                  />
                </AvatarContentItemWrapper>
              </PersonalDataWrapper>
            </PrivateInfoWrapper>
            <OtherInfo>
              {!isBachelor && (
                <TitledBlock gap={isBachelor ? 24 : 12}>
                  <BlockTitle size={20} weight={600}>
                    В магистратуру поступаю, чтобы:
                  </BlockTitle>
                  <Text
                    size={16}
                    weight={400}
                    mobile={{
                      size: 14,
                    }}
                    fragment={testListWrapper}
                  >
                    {getUserData()
                      .areasOfGrowth.map(area => area.name)
                      .join(', ')}
                  </Text>
                </TitledBlock>
              )}
              <TitledBlock gap={isBachelor ? 24 : 12}>
                <BlockTitle size={20} weight={600}>
                  После обучения хочу работать по профессии:
                </BlockTitle>
                {isBachelor ? (
                  <ProfessionCard>
                    <span>{userData.selectedProfession?.name}</span>
                    <span>{userData.selectedProfession?.description}</span>
                  </ProfessionCard>
                ) : (
                  <Text
                    size={16}
                    weight={400}
                    mobile={{
                      size: 14,
                    }}
                  >
                    {userData.selectedProfession?.name}
                  </Text>
                )}
              </TitledBlock>
              <TitledBlock gap={isBachelor ? 24 : 12}>
                <BlockTitle size={20} weight={600}>
                  Дополнительно хочу изучить:
                </BlockTitle>
                <Tags
                  styledFragment={ProfileTagsWrapper}
                  tags={userData.selectedSkillSets}
                />
              </TitledBlock>
            </OtherInfo>
          </ProfileLayout>
        </StepContentLayout>
      </ProfileContentCard>
      <ProfileNavBar>
        <Button
          styledFragment={NavButtonStyle}
          rightIconSrc={<StarsWrapper />}
          onClick={goToFinalPage}
        >
          Узнать свой результат
        </Button>
      </ProfileNavBar>
    </StepLayout>
  )
}

export default Profile
