import React, { ReactNode } from 'react'
import { CloseModalButton, SideModalWrapper } from './styled'
import IconDoubleArrow from '../../Images/icons/IconDoubleArrow'

type SideModalProps = {
  children: ReactNode
  isModalDisplay: boolean
  closeModal: () => void
}

const SideModal = ({ children, isModalDisplay, closeModal }: SideModalProps) => {
  return (
    <SideModalWrapper isModalOpen={isModalDisplay}>
      <CloseModalButton onClick={closeModal}>
        <IconDoubleArrow />
      </CloseModalButton>
      {children}
    </SideModalWrapper>
  )
}

export default SideModal
