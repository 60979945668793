import styled, { FlattenSimpleInterpolation } from 'styled-components'
import { StyledMainSkeleton } from 'std-lib/Stylesheets/StyledVars/Skeleton'

export const Wrapper = styled.div<{ styledFragment?: FlattenSimpleInterpolation }>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 8px;
  ${({ styledFragment }) => styledFragment ?? ''}
`
export const TagSkeleton = styled.div<{ width: number }>`
  ${StyledMainSkeleton};
  height: 40px;
  width: ${({ width }) => width}px;
  border-radius: 20px;

  @media screen and (max-width: 1000px) {
    height: 34px;
    border-radius: 17px;
  }
`
