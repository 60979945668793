import React from 'react'

const Roboticks = () => {
  return (
    <svg
      width="113"
      height="148"
      viewBox="0 0 113 148"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="svgAnime translateX(56px) n0">
        <path
          d="M38 14.5C38 14.2239 37.7761 14 37.5 14H21.5C21.2239 14 21 14.2239 21 14.5V16V17.5C21 17.7761 21.2239 18 21.5 18H24H35H37.5C37.7761 18 38 17.7761 38 17.5V16V14.5Z"
          fill="#8CC9EC"
        />
        <path
          d="M24 22.5C24 22.7761 24.2239 23 24.5 23H34.5C34.7761 23 35 22.7761 35 22.5V21V18H24V21V22.5Z"
          fill="#D6EDF5"
        />
        <path
          d="M15 19C15.5523 19 16 18.5523 16 18V16V14C16 13.4477 15.5523 13 15 13C14.4477 13 14 13.4477 14 14V18C14 18.5523 14.4477 19 15 19Z"
          fill="#8CC9EC"
        />
        <path
          d="M44 13C43.4477 13 43 13.4477 43 14V16V18C43 18.5523 43.4477 19 44 19C44.5523 19 45 18.5523 45 18V14C45 13.4477 44.5523 13 44 13Z"
          fill="#8CC9EC"
        />
        <path
          d="M24 18H21.5C21.2239 18 21 17.7761 21 17.5V16M24 18H35M24 18V21M35 18H37.5C37.7761 18 38 17.7761 38 17.5V16M35 18V21M24 21V22.5C24 22.7761 24.2239 23 24.5 23H34.5C34.7761 23 35 22.7761 35 22.5V21M24 21H22.4336C22.1848 21 21.9739 21.183 21.9387 21.4293L21.027 27.8109C21.0096 27.9327 21.0377 28.0566 21.106 28.1589L23 31M35 21H36.5664C36.8152 21 37.0261 21.183 37.0613 21.4293L37.973 27.8109C37.9904 27.9327 37.9623 28.0566 37.894 28.1589L36 31M21 16V14.5C21 14.2239 21.2239 14 21.5 14H37.5C37.7761 14 38 14.2239 38 14.5V16M21 16H16M16 16V18C16 18.5523 15.5523 19 15 19V19C14.4477 19 14 18.5523 14 18V14C14 13.4477 14.4477 13 15 13V13M16 16V14C16 13.4477 15.5523 13 15 13V13M38 16H43M43 16V14C43 13.4477 43.4477 13 44 13V13M43 16V18C43 18.5523 43.4477 19 44 19V19C44.5523 19 45 18.5523 45 18V14C45 13.4477 44.5523 13 44 13V13M15 13V8M44 13V8M36 10H52M23 10H7"
          stroke="black"
          strokeWidth="0.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M75.8114 120.705H61.8953C61.222 120.705 59.0406 121.206 59.2425 123.213V131.74H79.3169V123.213C79.5189 121.006 77.0641 120.621 75.8114 120.705Z"
        fill="#8CC9EC"
      />
      <path
        d="M31.7767 67.8631C30.5142 68.6736 27.9901 70.8949 29.0001 72.5C30.2625 74.5064 34.3015 71.3851 34.554 71.3415L31.7767 67.8631Z"
        fill="#D6EDF5"
      />
      <path
        d="M36.3214 73.555L39.3513 69.2915L38.7453 68.5391L35.7155 64.7772L35.3115 64.2756L30.5142 66.282L31.7767 67.8631L34.554 71.3415L36.3214 73.555Z"
        fill="#8CC9EC"
      />
      <path
        d="M57.2779 52.4882C57.0254 51.9866 53.2381 45.9675 48.1883 50.9834C47.3291 52.5197 47.0046 53.8367 47.0305 54.9506C47.0332 55.0642 47.0395 55.1757 47.0492 55.285C47.2806 57.8855 49.4613 59.3015 50.9657 59.7613C52.3045 59.7613 53.3826 59.5865 54.248 59.2909C57.4829 58.1862 57.7482 55.395 57.5597 53.7422C57.4848 53.0851 57.3382 52.608 57.2779 52.4882Z"
        fill="#8CC9EC"
      />
      <path
        d="M102.546 73.555C95.4761 72.3512 94.382 78.0693 94.7186 81.0788C94.793 81.522 94.9007 81.94 95.0377 82.3328C96.3426 86.0738 100.311 87.5353 103.556 86.8059C105.754 86.3117 107.621 84.8115 108.1 82.3328C109.11 77.1162 104.818 74.3074 102.546 73.555Z"
        fill="#8CC9EC"
      />
      <path
        d="M47.0305 54.9506L35.7155 64.7772L38.7453 68.5391L50.9657 59.7613C49.4613 59.3015 47.2806 57.8855 47.0492 55.285C47.0395 55.1757 47.0332 55.0642 47.0305 54.9506Z"
        fill="#D6EDF5"
      />
      <path
        d="M54.248 59.2909L94.7186 81.0788C94.382 78.0693 95.4761 72.3512 102.546 73.555L57.5597 53.7422C57.7482 55.395 57.4829 58.1862 54.248 59.2909Z"
        fill="#D6EDF5"
      />
      <path
        d="M75.8114 120.705L103.556 86.8059C100.311 87.5353 96.3426 86.0738 95.0377 82.3328L61.8953 120.705H75.8114Z"
        fill="#D6EDF5"
      />
      <path
        d="M31.7767 67.8631L30.5142 66.282L35.3115 64.2756L35.7155 64.7772M31.7767 67.8631C30.5142 68.6736 27.9901 70.8949 29.0001 72.5M31.7767 67.8631L34.554 71.3415M29.0001 72.5C30.2625 74.5064 34.3015 71.3851 34.554 71.3415M29.0001 72.5V77M34.554 71.3415L36.3214 73.555L39.3513 69.2915L38.7453 68.5391M35.7155 64.7772L47.0305 54.9506M35.7155 64.7772L38.7453 68.5391M47.0305 54.9506C47.0046 53.8367 47.3291 52.5197 48.1883 50.9834M47.0305 54.9506C47.0332 55.0642 47.0395 55.1757 47.0492 55.285M38.7453 68.5391L50.9657 59.7613M50.9657 59.7613C52.3045 59.7613 53.3826 59.5865 54.248 59.2909M50.9657 59.7613C49.4613 59.3015 47.2806 57.8855 47.0492 55.285M48.1883 50.9834C53.2381 45.9675 57.0254 51.9866 57.2779 52.4882C57.3382 52.608 57.4848 53.0851 57.5597 53.7422M48.1883 50.9834C47.2415 52.6763 46.944 54.103 47.0492 55.285M54.248 59.2909L94.7186 81.0788M54.248 59.2909C57.4829 58.1862 57.7482 55.395 57.5597 53.7422M94.7186 81.0788C94.382 78.0693 95.4761 72.3512 102.546 73.555M94.7186 81.0788C94.793 81.522 94.9006 81.94 95.0377 82.3328M57.5597 53.7422L102.546 73.555M102.546 73.555C104.818 74.3074 109.11 77.1162 108.1 82.3328C107.621 84.8115 105.754 86.3117 103.556 86.8059M103.556 86.8059L75.8114 120.705M103.556 86.8059C100.311 87.5353 96.3426 86.0738 95.0377 82.3328M75.8114 120.705H61.8953M75.8114 120.705C77.0641 120.621 79.5189 121.006 79.3169 123.213M95.0377 82.3328L61.8953 120.705M61.8953 120.705C61.222 120.705 59.0406 121.206 59.2425 123.213V131.74H79.3169M79.3169 123.213C79.3169 125.42 79.3169 129.817 79.3169 131.74M79.3169 123.213V131.74"
        stroke="#1F1F22"
        strokeWidth="0.9"
      />
      <g className="svgAnime translateY(-26px) n1">
        <path
          d="M26.7895 114L25.3158 118.412H32.6842L31.2105 114H26.7895Z"
          fill="#8CC9EC"
        />
        <path
          d="M32.6842 118.412H25.3158V124.294H26.7895V122.824H28.2632V124.294H29.7368V122.824H31.2105V124.294H32.6842V118.412Z"
          fill="#8CC9EC"
        />
        <path
          d="M15 118.412V139H43V118.412H32.6842V124.294H31.2105V122.824H29.7368V124.294H28.2632V122.824H26.7895V124.294H25.3158V118.412H15Z"
          fill="white"
        />
        <path d="M43 118.412L38.5789 114H31.2105L32.6842 118.412H43Z" fill="white" />
        <path d="M19.4211 114L15 118.412H25.3158L26.7895 114H19.4211Z" fill="white" />
        <path
          d="M15 118.412V139H43V118.412M15 118.412L19.4211 114H26.7895M15 118.412H25.3158M43 118.412L38.5789 114H31.2105M43 118.412H32.6842M26.7895 114L25.3158 118.412M26.7895 114H31.2105M25.3158 118.412H32.6842M25.3158 118.412V124.294H26.7895V122.824H28.2632V124.294H29.7368V122.824H31.2105V124.294H32.6842V118.412M31.2105 114L32.6842 118.412M19.4211 130.176H28.2632M19.4211 132.382H28.2632M19.4211 134.588H28.2632M36.3684 136.059H38.5789V128.706H31.2105V136.059H34.8947M33.4211 133.853H36.3684M34.8947 133.118V129.441M34.8947 129.441L33.4211 130.912M34.8947 129.441L36.3684 130.912"
          stroke="black"
          strokeWidth="0.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M28.5 110H29.5L31 114H27L28.5 110Z" fill="black" />
      </g>
      <g className="svgAnime scaleY(0.366) n2">
        <path
          d="M29 73V114"
          stroke="black"
          strokeWidth="0.75"
          strokeDasharray="1.5 1.5"
        />
      </g>
    </svg>
  )
}

export default Roboticks
