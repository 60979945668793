import styled from 'styled-components'
import { CssFrom, UnitValue } from '../../std-lib/FastUI/TypedCss'
export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  gap: 100px;
  padding: 0 64px;
  
  @media screen and (max-width: 1000px) {
    gap: 40px;
    padding: 0 16px;
  }
`
export const MobileCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
`
export const ActionButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: ${props => props.theme.color.background.withStroke.primary};

  @media screen and (max-width: 1000px) {
    border-radius: 20px 20px 0 0;
    box-shadow: 4px 4px 20px 0 rgba(97, 120, 201, 0.20);
    padding: 16px;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 2;
  }
`
export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 104px;
  
  @media screen and (max-width: 1000px) {
    margin-bottom: 70px;
  }
`
export const ScrollWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;
  overflow-x: auto;
  
  @media screen and (max-width: 1000px) {
    gap: 16px;
  }
`
export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`
