import styled from 'styled-components'

export const StyledProgress = styled.progress`
  border: none;
  -webkit-appearance: none;
  flex-basis: 0;
  block-size: 8px;
  inline-size: auto;

  &::-webkit-progress-bar {
    background-color: ${({ theme }) => theme.color.background.noStroke.hover};
    border-radius: 4px;
  }

  progress {
    background-color: ${({ theme }) => theme.color.background.noStroke.hover};
    border-radius: 4px;
  }

  &::-webkit-progress-value {
    background-color: ${({ theme }) => theme.color.states.primary.default};
    border-radius: inherit;
    transition: width .3s ease-in-out;
  }

  &::-moz-progress-bar {
    background-color: ${({ theme }) => theme.color.states.primary.default};
    border-radius: inherit;
    transition: width .3s ease-in-out;
  }

  progress {
    color: ${({ theme }) => theme.color.states.primary.default};
    border-radius: inherit;
    transition: width .3s ease-in-out;
  }
`

export const ProgressesWrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  ${StyledProgress}{
    flex-grow: 3;
  }
  ${StyledProgress}:first-child, ${StyledProgress}:last-child{
    flex-grow: 1;
  }
`
