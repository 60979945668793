import React from 'react';

const LaserEngineer = () => {
	return (
		<svg width="113" height="148" viewBox="0 0 113 148" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M68.9679 117.629H50.9371C50.0647 117.629 47.2383 118.282 47.5 120.895V132H73.5098V120.895C73.7715 118.021 70.5909 117.52 68.9679 117.629Z"
				fill="#8CC9EC"></path>
			<path
				d="M35.5614 44.5659C33.9257 45.6214 30.9814 48.2552 32.29 50.3456C33.9257 52.9585 38.8328 49.1527 39.16 49.0959L35.5614 44.5659Z"
				fill="#D6EDF5"></path>
			<path
				d="M41.4499 51.9787L45.3756 46.4262L44.5905 45.4463L40.6648 40.547L40.1414 39.8938L33.9257 42.5067L35.5614 44.5659L39.16 49.0959L41.4499 51.9787Z"
				fill="#8CC9EC"></path>
			<path
				d="M68.6027 24.5428C68.2756 23.8895 63.3684 16.0507 56.8256 22.5831C55.7123 24.5837 55.2919 26.299 55.3254 27.7496C55.3289 27.8975 55.337 28.0427 55.3496 28.1852C55.6495 31.5718 58.4749 33.4159 60.4242 34.0147C62.1588 34.0147 63.5556 33.787 64.677 33.4021C68.8683 31.9634 69.2121 28.3284 68.9679 26.1759C68.8708 25.3201 68.6808 24.6987 68.6027 24.5428Z"
				fill="#8CC9EC"></path>
			<path
				d="M103.607 56.2247C94.4469 54.6569 93.0293 62.1038 93.4654 66.0232C93.5618 66.6004 93.7013 67.1447 93.8788 67.6563C95.5696 72.5283 100.711 74.4317 104.915 73.4818C107.764 72.8382 110.182 70.8844 110.804 67.6563C112.112 60.8627 106.551 57.2045 103.607 56.2247Z"
				fill="#8CC9EC"></path>
			<path
				d="M55.3254 27.7496L40.6648 40.547L44.5905 45.4463L60.4242 34.0147C58.4749 33.4159 55.6495 31.5718 55.3496 28.1852C55.337 28.0427 55.3289 27.8975 55.3254 27.7496Z"
				fill="#D6EDF5"></path>
			<path
				d="M64.677 33.4021L93.4654 66.0232C93.0293 62.1038 94.4469 54.6569 103.607 56.2247L68.9679 26.1759C69.2121 28.3284 68.8683 31.9634 64.677 33.4021Z"
				fill="#D6EDF5"></path>
			<path
				d="M68.9679 117.629L104.915 73.4818C100.711 74.4317 95.5696 72.5283 93.8788 67.6563L50.9371 117.629H68.9679Z"
				fill="#D6EDF5"></path>
			<path
				d="M35.5614 44.5659L33.9257 42.5067L40.1414 39.8938L40.6648 40.547M35.5614 44.5659C33.9257 45.6214 30.9814 48.2552 32.29 50.3456M35.5614 44.5659L39.16 49.0959M32.29 50.3456C33.9257 52.9585 38.8328 49.1527 39.16 49.0959M32.29 50.3456L30 52.5M39.16 49.0959L41.4499 51.9787L45.3756 46.4262L44.5905 45.4463M40.6648 40.547L55.3254 27.7496M40.6648 40.547L44.5905 45.4463M55.3254 27.7496C55.2919 26.299 55.7123 24.5837 56.8256 22.5831M55.3254 27.7496C55.3289 27.8975 55.337 28.0427 55.3496 28.1852M44.5905 45.4463L60.4242 34.0147M60.4242 34.0147C62.1588 34.0147 63.5556 33.787 64.677 33.4021M60.4242 34.0147C58.4749 33.4159 55.6495 31.5718 55.3496 28.1852M56.8256 22.5831C63.3684 16.0507 68.2756 23.8895 68.6027 24.5428C68.6808 24.6987 68.8708 25.3201 68.9679 26.1759M56.8256 22.5831C55.5988 24.7877 55.2133 26.6458 55.3496 28.1852M64.677 33.4021L93.4654 66.0232M64.677 33.4021C68.8683 31.9634 69.2121 28.3284 68.9679 26.1759M93.4654 66.0232C93.0293 62.1038 94.4469 54.6569 103.607 56.2247M93.4654 66.0232C93.5618 66.6004 93.7013 67.1447 93.8788 67.6563M68.9679 26.1759L103.607 56.2247M103.607 56.2247C106.551 57.2045 112.112 60.8627 110.804 67.6563C110.182 70.8844 107.764 72.8382 104.915 73.4818M104.915 73.4818L68.9679 117.629M104.915 73.4818C100.711 74.4317 95.5696 72.5283 93.8788 67.6563M68.9679 117.629H50.9371M68.9679 117.629C70.5909 117.52 73.7715 118.021 73.5098 120.895M93.8788 67.6563L50.9371 117.629M50.9371 117.629C50.0647 117.629 47.2383 118.282 47.5 120.895V132H73.5098M73.5098 120.895C73.5098 123.769 73.5098 129.496 73.5098 132M73.5098 120.895V132"
				stroke="#1F1F22" strokeWidth="0.9"></path>
			<path
				d="M20 81.5L23 80L25.5 82.5L23 86L29 89L31 85L33 85L35 87.5L41 84L38 80.5L40 78L44 78L44 72L39 72L37.5 70L39 67.5L32 64L31.5 68L28.5 68.5L26.5 66L21.5 70L23.5 73L23 75.5L19.5 75.5M28 80C27.1667 77.5 26.6 72.4 31 72C35.4 71.6 36.5 75.5 36.5 77.5C36 79.3333 33.6 82.4 28 80Z"
				stroke="#1F1F22" strokeWidth="0.9"></path>
			<path d="M29 54L3 73.5L31.5 106.5L60.5 81L41.5 60.5L40 62.5L55 79.5L56.5 81L32 103L17 85.5L15.5 84L20 81.5"
			      stroke="#1F1F22" strokeWidth="0.9"></path>

			<g className="svgAnime rotate(-55.05deg) n0">
				<rect x="68" y="92.7612" width="23.134" height="33.313" rx="2.26943" transform="rotate(-19.602 68 92.7612)"
				      fill="#D6EDF5"></rect>
				<rect x="68.5749" y="93.0342" width="22.234" height="32.413" rx="0.684717"
				      transform="rotate(-19.602 68.5749 93.0342)" stroke="#1F1F22" strokeWidth="0.9"></rect>
				<circle cx="77.1772" cy="97.8427" r="3.71413" transform="rotate(-19.602 77.1772 97.8427)" fill="#8CC9EC"
				        stroke="#1F1F22" strokeWidth="0.9"></circle>
				<circle cx="78.5675" cy="108.644" r="1.40072" transform="rotate(-19.602 78.5675 108.644)" fill="white"
				        stroke="#1F1F22" strokeWidth="0.9"></circle>
				<circle cx="84.67" cy="106.47" r="1.40072" transform="rotate(-19.602 84.67 106.47)" fill="white"
				        stroke="#1F1F22" strokeWidth="0.9"></circle>
				<circle cx="87.464" cy="114.316" r="1.40072" transform="rotate(-19.602 87.464 114.316)" fill="#8CC9EC"
				        stroke="#1F1F22" strokeWidth="0.9"></circle>
				<circle cx="90.7716" cy="104.297" r="1.40072" transform="rotate(-19.602 90.7716 104.297)" fill="white"
				        stroke="#1F1F22" strokeWidth="0.9"></circle>
			</g>
			<g className="svgAnime matrix(1,0,0,1.76,0,-4.56) n1">
				<line x1="35.5545" y1="38.9364" x2="37.2516" y2="27.057" stroke="#1F1F22" strokeWidth="0.9"></line>
			</g>


			<g className="svgAnime rotate(-45deg)+scaleY(1.885) ray n2" style={{transformOrigin: 'bottom right', transform: 'rotate(-45deg)'}}>
				<line x1="30.6818" y1="40.3182" x2="30.6818" y2="28.3182" stroke="#1F1F22" strokeWidth="0.9"></line>
			</g>

			<g className="svgAnime matrix(1.75,0,0,1,-4.5,0) n3">
				<line x1="29" y1="44.45" x2="17" y2="44.45" stroke="#1F1F22" strokeWidth="0.9"></line>
			</g>

		</svg>
	);
};

export default LaserEngineer;