import styled, { css } from 'styled-components'
import { CssFrom, WidthHeightValue } from '../../TypedCss'
import prepare, { Prepared } from '../../Models'

interface ImgProps {
  alt?: string
  width?: WidthHeightValue
  height?: WidthHeightValue
  borderRadius?: WidthHeightValue
  padding?: WidthHeightValue
  margin?: WidthHeightValue
  objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down'
}

const imgProcessor = (props: ImgProps) => {
  return css`
    width: ${CssFrom(props.width)};
    height: ${CssFrom(props.height)};
    border-radius: ${CssFrom(props.borderRadius)};
    padding: ${CssFrom(props.padding)};
    margin: ${CssFrom(props.margin)};
    object-fit: ${props.objectFit || 'initial'};
  `
}

const Img = styled.img<Prepared<ImgProps>>`
  ${props => prepare(props, imgProcessor)}}
`

export default Img
