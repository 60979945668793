import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ContentCard } from '../Onboarding/Layouts/Step/styled'
import StepContentLayout from '../Onboarding/Layouts/Step/components/StepContentLayout'
import styled, { css } from 'styled-components'
import AdultManImg from '../../Images/Static/adult.png'
import YoungManImg from '../../Images/Static/young.png'
import Text from 'std-lib/FastUI/Components/Text'
import { SocialMediaList } from 'Components/SocialLink'
import Input from 'std-lib/UiKit/Input'
import Button from 'std-lib/UiKit/Button'
import { useValidationState, validationFuncs } from 'std-lib/Hooks/useValidationState'
import Blot from 'Images/Static/Blot.svg'
import { sendTypedEvent } from '../../Utils/events/sendEvent'
import { personalDataRequest } from './API/API'
import parse from 'html-react-parser'

const DeadEndLayout = styled.div`
  flex-grow: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }

`

const ContactBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
`

const MediaBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;

  @media screen and (max-width: 1000px) {
    align-items: flex-start;
  }
`

const ManImgWrapper = styled.div`
  width: 420px;
  height: 450px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  background-image: url(${Blot});
  background-position: 0 0;

  @media screen and (max-width: 1000px) {
    display: none;
  }
`

const ManImg = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 400px;
  height: 371px;
`

const ContactTitle = styled(Text)`
  margin-bottom: 24px;

  @media screen and (max-width: 1000px) {
    margin-bottom: 12px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
`

const ContactWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-end;
`

const InputLabel = styled.label`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin-bottom: 0;

  input {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  @media screen and (max-width: 1000px) {
    font-size: 16px;
    line-height: 24px
  }
`

const DeadEndContentCard = styled(ContentCard)`
  height: 718px;
  margin-bottom: 60px;
  @media screen and (max-width: 1030px) {
    height: auto !important;
  }
`

const ButtonFragment = css`
  margin-top: 8px;

  height: unset;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`

const StyledText = styled(Text)`
  margin-bottom: 24px;
  @media screen and (max-width: 1000px) {
    margin-bottom: 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
`

const youngContent = {
  text: `Пока что наш алгоритм не&nbsp;научился работать с&nbsp;траекториями для&nbsp;младших классов.`,
  icon: YoungManImg,
}

const adultContent = {
  text: `Пока что наш алгоритм не&nbsp;научился работать с&nbsp;траекториями для&nbsp;тех, кто давно закончил школу.`,
  icon: AdultManImg,
}

const DeadEnd = () => {
  const { type } = useParams()
  const content = Number(type) > 5 ? adultContent : youngContent

  // const [vkname, setvkname] = useState<string>('')
  const [[email, setEmail], isEmailValid] = useValidationState<string>(
    '',
    validationFuncs.isEmail,
    true,
  )
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const [status, setStatus] = useState<string>('')
  const [isContactSaved, setIsContactSaved] = useState<boolean | undefined>(undefined)
  // const onVkNameChange: ChangeEventHandler<HTMLInputElement> = e => {
  //   let vkname = e.target.value
  //   if (vkname[0] === '@') {
  //     vkname = vkname.replaceAll('@', '')
  //   }
  //   setvkname('@' + vkname)
  // }
  const sendPersonalData = async () => {
    try {
      await personalDataRequest.sendPersonalContacts(email)
      setStatus('Записали! Совсем скоро свяжемся с тобой')
      setIsContactSaved(true)
    } catch (error) {
      setStatus('Не получилось записать! Попробуй позже')
      setIsContactSaved(false)
    } finally {
      setTimeout(() => {
        setStatus('')
        setIsContactSaved(undefined)
      }, 4000)
    }
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  const handleFocus = () => {
    setIsFocused(true)
  }

  useEffect(() => {
    sendTypedEvent('visit', 'deadend', 'ob')
  }, [])

  return (
    <DeadEndContentCard>
      <StepContentLayout title={'Круто, у тебя еще столько времени до поступления!'}>
        <DeadEndLayout>
          <ContactBlock>
            <ContactTitle size={20} weight={600}>
              Оставайся на связи!
            </ContactTitle>

            <StyledText size={16} lineHeight={24}>
              {parse(content.text)}
              <br />
              <br />
              Оставь, пожалуйста свой контакт, мы обязательно сообщим, когда функционал
              будет добавлен.
            </StyledText>

            <ContactWrapper>
              <InputLabel>
                Email
                <Input
                  isValid={isEmailValid}
                  placeholder={'example@mail.ru'}
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  type={'email'}
                />
                {!isEmailValid && !isFocused && (
                  <Text weight={600} size={16} color={'#F57B7B'}>
                    Проверьте правильность формата (example@mail.ru)
                  </Text>
                )}
              </InputLabel>

              {/* <InputLabel>*/}
              {/*  Твой ник в ВК*/}
              {/*  <Input placeholder={'nani335'} value={vkname} onChange={onVkNameChange} />*/}
              {/* </InputLabel>*/}

              <Button
                isValid={isContactSaved}
                isDisabled={!email}
                onClick={() => {
                  sendTypedEvent('click', 'deadend.contact_button', 'ob')
                  sendPersonalData()
                }}
                styledFragment={ButtonFragment}
              >
                <Text size={20} lineHeight={28} weight={600} color={'#fff'}>
                  {status ? status : 'Оставить контакт'}
                </Text>
              </Button>
            </ContactWrapper>
          </ContactBlock>
          <MediaBlock>
            <ContactTitle size={20} weight={600}>
              Университет ИТМО в социальных сетях
            </ContactTitle>
            <StyledText size={16}>
              Подписывайся на наши соцсети, чтобы быть в курсе событий в жизни ИТМО!
            </StyledText>
            <SocialMediaList />
          </MediaBlock>
        </DeadEndLayout>
      </StepContentLayout>
      <ManImgWrapper>
        <ManImg src={content.icon} />
      </ManImgWrapper>
    </DeadEndContentCard>
  )
}

export default DeadEnd
