import styled, { css } from 'styled-components'
import { CssFrom, WidthHeightValue } from '../../TypedCss'
import prepare, { Prepared } from '../../Models'

interface BrProps {
  width?: WidthHeightValue
  height?: WidthHeightValue
}

const brProcessor = (props: BrProps) => {
  return css`
    width: ${CssFrom(props.width)};
    height: ${CssFrom(props.height)};
  `
}

const Br = styled.div<Prepared<BrProps>>`
  ${props => prepare(props, brProcessor)}
`

export default Br
