import styled, { keyframes } from 'styled-components'

export const cover = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0);
    opacity: 0;
  }
`

export const uncover = keyframes`
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`

export const ModalContainer = styled.div<{ isVisible: boolean }>`
  background: rgba(45, 43, 68, 0.6);
  z-index: 11000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  transition: all .3s;
  animation: appear .3s;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  @keyframes appear {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`

export const CloseOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`

export const SwipeBlock = styled.div`
  position: absolute;
  min-height: 20px;
  right: 0;
  top: 0;
  left: 0;
  z-index: 1000;
`

export const CloseButton = styled.button`
  position: absolute;
  padding: 20px 16px 0;
  top: 0;
  right: 0;
`

export const ModalWrapper = styled.div<{
  shiftHeight: number | null
  isVisible: boolean
  from?: number
}>`
  transition: all .3s;
  background: white;
  border-radius: 16px;
  position: absolute;
  overflow: hidden;

  @media screen and (min-width: 1000px) {
    max-height: 70vh;
    overflow: hidden;
    animation-name: ${({ isVisible }) => (isVisible ? uncover : cover)};
    animation-fill-mode: forwards;
    animation-duration: .3s;
    animation-timing-function: ease;
  }

  @media screen and (max-width: 1000px) {
    border-radius: 16px 16px 0 0;
    width: 100vw;
    position: absolute;
    bottom: 0;

    max-height: calc(100% - 80px);
    min-height: 10px;
    transform: scale(1) translateY(0);

    animation-name: ${({ isVisible }) => (isVisible ? 'slideIn' : 'slideOut')};
    animation-iteration-count: 1;
    animation-duration: .32s;
    animation-timing-function: ease;

    ${({ shiftHeight, isVisible }) =>
      shiftHeight &&
      isVisible &&
      `
    min-height: ${shiftHeight}px;
    max-height: ${shiftHeight}px;
    transform: ${shiftHeight ? 'scaleX(0.96)' : ''};
  `}
  }

  @keyframes slideOut {
    from {
      transform: translateY(${({ from }) => from ?? 0}px);

    }
    to {
      transform: translateY(100%);
    }
  }

  @keyframes slideIn {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
`
