import styled, { css, keyframes } from 'styled-components'

export const StartPageContainer = styled.div`
  padding-top: 28px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  max-width: 1320px;
  gap: 51px;

  @media screen and (max-width: 1000px) {
    flex-direction: column-reverse;
    align-items: center;
    padding-top: 12px;
    gap: 32px;
    margin-bottom: 94px;
  }
`

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 670px;
  flex-grow: 0;

  @media screen and (max-width: 1000px) {
    gap: 16px;
  }
`

export const StartTitle = styled.h1`
  color: ${({ theme }) => theme.color.text.primary};
  font-family: "ALS Gorizont", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 68px;
  margin: 0;

  @media screen and (max-width: 1220px) {
    font-size: 24px;
    line-height: 32px;
  }
`

export const StartSubtitle = styled.span`
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin: 40px 0 64px;

  @media screen and (max-width: 1000px) {
    font-size: 14px;
    line-height: 22px;
    margin: 0;
  }

`

export const ImageWrapper = css`
  flex-shrink: 3;
  min-height: 290px;
  min-width: 350px;

  @media screen and (max-width: 1220px) {
    min-width: 290px;
    width: 100%;
  }
`

export const FactCard = css`
  width: 240px;
  height: 127px;
  transform-origin: 0 0;
`

export const SmallCardTitle = styled.div`
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: inherit;
  display: flex;
  align-items: center;
  padding: 8px 0;
`

export const BottomCardTitle = styled.span`
  text-align: center;
  font-family: "ALS Gorizont", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  color: inherit;
`

export const ButtonWrapper = styled.div`
  @media screen and (max-width: 1000px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 20px 20px 0 0;
    padding: 16px;
    background: #F8F8FE;
    box-shadow: 4px 4px 20px 0 rgba(97, 120, 201, 0.20);
  }
`

const shine = keyframes`
  from {
    background-position: right;
    transform: scale(1);
  }

  75% {
    background-position: right;
  }

  80% {
    transform: scale(1);
  }

  85% {
    transform: scale(1.03);
  }

  to {
    background-position: left;
    transform: scale(1);
  }
`

export const ButtonFragment = css`
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  height: unset;
  padding: 20px 40px;
  gap: 12px;
  border-radius: 36px;
  flex-shrink: 0;
  white-space: nowrap;


  @media screen and (max-width: 1000px) {
    padding: 8px 20px;
    width: 100%;
    font-size: 14px;
    line-height: 22px;
    height: unset;

    img {
      height: 22px;
    }
  }

  --main-color: ${({ theme }) => theme.color.states.primary.default};

  animation: ${shine} 6s infinite;
  background-image: linear-gradient(45deg, var(--main-color) 0, var(--main-color) 48%, rgba(255, 255, 255, 0.8), var(--main-color) 52%, var(--main-color) 100%);
  background-size: 400% 400% !important;
`
