import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import CategoryCard from './components/CategoryCard'
import { NavContext } from 'Context/NavContext'
import StepContentLayout from '../../Layouts/Step/components/StepContentLayout'
import { useUserData } from '../../UserDataStorage/UseUserData'
import { Category, onboardingRequest } from '../../API/API'
import Image from './assets/mockImage.png'
import { StyledMainSkeleton } from 'std-lib/Stylesheets/StyledVars/Skeleton'
import { makeEmptyArray } from 'std-lib/Extensions/array'
import { PhotoBuCategoryName } from 'Widgets/Onboarding/Slides/ProfessionCategories/constants'

const CategoriesList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  @media screen and (max-width: 1400px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 1020px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const SkeletonCategoryCard = styled.div`
  ${StyledMainSkeleton};
  height: 168px;
  border-radius: 20px;

  @media screen and (max-width: 1000px) {
    height: 136px;
  }
`

const SamuraiCategory: Category = {
  id: -1,
  title: 'Я пока не решил',
  slug: 'Не беда! У самурая нет цели, лишь путь',
}
const ProfessionCategories = () => {
  const { updateUserDataWith, getUserData } = useUserData()
  const [selectedCategory, setSelectedCategory] = useState<Category | undefined>(
    getUserData().selectedSphere,
  )
  const [categories, setCategories] = useState<Category[]>([])

  const { setForwardState } = useContext(NavContext)

  useEffect(() => {
    updateUserDataWith({ selectedSphere: selectedCategory })
  }, [selectedCategory, updateUserDataWith])

  useEffect(() => {
    setForwardState({
      disabled: !selectedCategory,
      // link: selectedCategory ? '/step/track/0' : undefined,
    })
  }, [selectedCategory, setForwardState])

  useEffect(() => {
    onboardingRequest.getCategories().then(({ data }) => {
      setCategories([...data, SamuraiCategory])
    })
  }, [])

  return (
    <StepContentLayout title="Выбери область, в которой хотел бы развиваться">
      <CategoriesList>
        {categories.length > 0
          ? categories.map(category => (
              <CategoryCard
                key={category.id}
                selected={selectedCategory?.id == category.id}
                src={PhotoBuCategoryName[category.title] ?? Image}
                title={category.title}
                subtitle={category.slug}
                onClick={() => setSelectedCategory(category)}
              />
            ))
          : makeEmptyArray(6).map((_, index) => <SkeletonCategoryCard key={index} />)}
      </CategoriesList>
    </StepContentLayout>
  )
}

export default ProfessionCategories
