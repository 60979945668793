import React from 'react'
import { CountType } from 'types'
import Flex from '../../../../../std-lib/FastUI/Components/Flex'
import Text from '../../../../../std-lib/FastUI/Components/Text'
import Rectangle from '../../../../../std-lib/FastUI/Components/Rectangle'
import { css } from 'styled-components'

type ClassesCountProps = {
  classes: CountType[]
}

const ClassesCount = ({ classes }: ClassesCountProps) => {
  const totalCount = classes.reduce((acc, { count }) => acc + count, 0)
  return (
    <Flex
      direction={'column'}
      gap={12}
      fragment={css`padding-top: 12px;
        padding-bottom: 12px`}
    >
      {classes
        .sort((item1, item2) => item2.count - item1.count)
        .map(item => {
          return (
            <Flex key={item.name} alignItems={'stretch'} gap={24} mobile={{ gap: 12 }}>
              <Text
                size={20}
                fragment={css`width: 265px;
                  flex-shrink: 0`}
                mobile={{
                  size: 14,
                  fragment: css`width: 160px;
                    flex-shrink: 0`,
                }}
              >
                {item.name
                  .replace(
                    'программирование и информационные технологии',
                    'программирование',
                  )
                  .replace('электроника и робототехника', 'инженерия')
                  .replace('экономика и менеджмент', 'бизнес')}
              </Text>
              <Rectangle
                borderRadius={24}
                width={'100%'}
                height={19}
                background={'#EBEBFD'}
                mobile={{ width: '100%' }}
              >
                <Rectangle
                  borderRadius={24}
                  width={`${(item.count / totalCount) * 100}%`}
                  height={19}
                  background={'#9480EF'}
                  fragment={css`min-width: 24px`}
                />
              </Rectangle>
            </Flex>
          )
        })}
    </Flex>
  )
}

export default ClassesCount
