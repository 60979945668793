import styled, { css } from 'styled-components'

export const StepLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1000px) {
    height: unset;
  }
`
export const ContentCard = styled.section`
  flex-grow: 3;
  width: 100%;
  position: relative;
  border-radius: 40px;
  background: #FFF;
  box-shadow: 4px 4px 76px 0 rgba(97, 120, 201, 0.20);
  padding: 40px 60px;
  z-index: 1;
  box-sizing: border-box;

  @media screen and (max-width: 1000px) {
    padding: 16px;
    height: unset;
    border-radius: 20px;
  }
`

export const StepContentCard = styled(ContentCard)`
  min-height: 560px;
  max-width: 1440px;
  margin-bottom: 134px;

  @media screen and (max-width: 1000px) {
    height: unset;
    margin-bottom: 145px;
  }
`

export const NavBarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  right: 0;
  height: 134px;
  width: 100%;
  padding: 36px 64px;
  border-radius: 40px 40px 0 0;
  background: #FFF;
  box-shadow: 4px 4px 76px 0 rgba(97, 120, 201, 0.20);

  @media screen and (max-width: 1000px) {
    height: 112px;
    border-radius: 20px 20px 0 0;
  }
`

export const NavBar = styled.nav`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: end;
  gap: 90px;
  width: 100%;
  max-width: 1440px;

  @media screen and (max-width: 1000px) {
    gap: 30px;
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    padding: 0 16px 28px 16px;
    gap: 16px;
    height: auto;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 20px 20px 0 0;
    box-shadow: 4px 4px 20px 0 rgba(97, 120, 201, 0.20);
    background: #F8F8FE;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;
    z-index: 2;
  }
`

export const ProgressBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 13px;
  width: 100%;
  max-width: 900px;

  @media screen and (max-width: 1000px) {
    order: -1;
    grid-column-start: 1;
    grid-column-end: 3;
    gap: 0;
  }
`

export const NavTitleWrapper = styled.span`
  background-color: ${({ theme }) => theme.color.background.noStroke.hover};
  padding: 8px 12px;
  border-radius: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  @media screen and (max-width: 1000px) {
    font-size: 14px;
    line-height: 22px;
    background-color: unset;
  }
`

export const NavButtonStyle = css`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  gap: 12px;
  height: auto;

  @media screen and (max-width: 1000px) {
    font-size: 14px;
    line-height: 22px
  }

  @media screen and (max-width: 1000px) {
    padding: 8px 20px;
    width: 100%;
    height: 38px;
  }
`
