import { AxiosInstance, AxiosResponse } from 'axios'
import request from '../../../std-lib/API/request'
import { TrajectoryType, SimpleTrajectoryType } from '../types'
import { RawHobbyTag } from 'Widgets/Onboarding/API/API'

export type Club = {
  id: number
  category: string
  tags: RawHobbyTag[]
  title: string
  description: string
}

function finalModule(instance: AxiosInstance) {
  return {
    getTrajectories(
      keywords: number[],
      professionId: number,
      studentId?: number
    ): Promise<AxiosResponse<SimpleTrajectoryType[]>> {
      const params: {presets: number[], profession: number, student_type_id?: number} = {
        presets: keywords,
        profession: professionId
      }

      if (studentId) {
        params.student_type_id = studentId
      }

      return instance.post(`trajectories/?top_n=3`, params)
    },
    getFinalData(id: number | null): Promise<AxiosResponse<TrajectoryType>> {
      return instance.get(`trajectories/${id}/`)
    },
    getClubs(interestsId: number[]): Promise<AxiosResponse<Club[]>> {
      return instance.get(`clubs/?tags_in=${interestsId.join(',')}`)
    },
    sendPersonalContacts(email: string): Promise<AxiosResponse<unknown>> {
      return instance.post(`contacts/`, {
        email,
      })
    },
  }
}

export const finalRequest = Object.assign(request, finalModule(request))
