import React from 'react'
import { IconType } from '../../../std-lib/Images/icons/types'

const Book = (props: IconType) => {
  const {
    width = 8,
    height = 12,
    viewBox = '0 0 12 12',
    primaryColor = '#8533FF',
  } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4554 6.48445C9.47359 4.39151 6.39865 4.1961 4.21364 5.87897C3.92488 6.10109 3.75 6.45977 3.75 6.84378V18.1568C3.75 18.246 3.78999 18.3059 3.85361 18.3434C3.92094 18.3831 4.0136 18.3948 4.11101 18.3468C6.59671 17.1134 9.73203 17.3301 12 19.029C14.2681 17.33 17.4035 17.1133 19.8892 18.3458C19.9864 18.3941 20.0789 18.3826 20.1462 18.3429C20.2098 18.3053 20.25 18.2452 20.25 18.1558V6.84378C20.25 6.46098 20.0756 6.10249 19.7867 5.88025C17.6017 4.19653 14.5264 4.39152 12.5446 6.48445C12.4029 6.63404 12.206 6.71878 12 6.71878C11.794 6.71878 11.5971 6.63404 11.4554 6.48445ZM12 19.9998L11.4967 20.5558C9.69184 18.922 6.93398 18.6203 4.77741 19.6906C3.653 20.2469 2.25 19.4979 2.25 18.1568V6.84378C2.25 6.01394 2.62534 5.20848 3.29836 4.69059M12 19.9998L12.5033 20.5558C14.3082 18.922 17.0661 18.6203 19.2228 19.6897C20.3488 20.2483 21.75 19.4955 21.75 18.1558V6.84378C21.75 6.01468 21.3745 5.20926 20.7015 4.6915C18.103 2.68926 14.5107 2.77552 12 4.93379C9.48935 2.77556 5.89688 2.6895 3.29836 4.69059"
        fill="#1F1F22"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5.21978C12.4142 5.21978 12.75 5.55557 12.75 5.96978V19.9998C12.75 20.414 12.4142 20.7498 12 20.7498C11.5858 20.7498 11.25 20.414 11.25 19.9998V5.96978C11.25 5.55557 11.5858 5.21978 12 5.21978Z"
        fill="#1F1F22"
      />
    </svg>
  )
}

export default Book
