import styled from 'styled-components'
import { StyledMainSkeleton } from '../../Stylesheets/StyledVars/Skeleton'

const SkeletonDiv = styled.div<{ width: number; height: number }>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  ${StyledMainSkeleton};
`

export default SkeletonDiv
