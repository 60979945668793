import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  border-radius: 20px;
  border: 1px solid #DEDEDE;
  background: #fff;
  box-shadow: 4px 4px 20px 0 rgba(97, 120, 201, 0.12);
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  gap: 24px;
  cursor: pointer;
`;

export const Title = styled.div`
  font-weight: bold;
`;


export const Content = styled.div<{isOpen: boolean}>`
  max-height: ${(props) => (props.isOpen ? '1000px' : '0')};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
`;