import styled, { css } from 'styled-components'
import prepare, { Fragments, Prepared } from '../../Models'

interface HideProps {
  when?: boolean
}

const hideProcessor = (props: HideProps) => {
  return css`
    ${props.when && Fragments.hidden};
    color: inherit;
  `
}

const Hide = styled.span<Prepared<HideProps>>`
  ${props => prepare(props, hideProcessor)}
`

export default Hide
