import React, { useState } from 'react'
import Rectangle from '../../../../std-lib/FastUI/Components/Rectangle'
import Flex from '../../../../std-lib/FastUI/Components/Flex'
import { TrajectoryType } from '../../types'
import ClassesCount from './ClassesCount'
import styled, { css } from 'styled-components'
import Br from '../../../../std-lib/FastUI/Components/Br'
import Text from '../../../../std-lib/FastUI/Components/Text'
import Card from '../MobileContentCard'
import { sendTypedEvent } from 'Utils/events/sendEvent'
import { useUserData } from 'Widgets/Onboarding/UserDataStorage/UseUserData'
import { ContentWrapper, TabsWrapper } from './styled'

type CoursesViewProps = {
  trajectory: TrajectoryType
}

const CourseSelectButton = styled.button<{ isSelected: boolean }>`
    width: 100%;
    height: 40px;
    background: transparent;
    border-radius: 20px 20px 0 0;
    position: relative;

    ${({ isSelected, theme }) =>
      isSelected &&
      css`
                z-index: 1;
                border: 1px solid #DEDEDE;
                border-bottom: none;
                background-color: ${theme.color.background.withStroke.primary};

                &[data-last="true"]:after {
                    left: 0;
                    width: calc(100% + 1px);
                    border-right: 1px solid #DEDEDE;
                }

                &[data-first="true"]:after {
                    left: -1px;
                    width: calc(100% + 1px);
                    border-left: 1px solid #DEDEDE;
                }

                &:after {
                    content: '';
                    width: 100%;
                    height: 30px;
                    background-color: ${theme.color.background.withStroke.primary};
                    position: absolute;
                    bottom: -25px;
                    left: 0;
                }

            `};

    &:hover {
        color: ${({ theme }) => theme.color.states.primary.default};
    }

    &:active {
        color: ${({ theme }) => theme.color.states.primary.default};
    }
`

const courseTexts: { [key: number]: { time: string; description: string } } = {
  1: {
    time: ' ~ 10 пар в неделю',
    description: `Первый курс посвящен изучению общих предметов для всех направлений и
                  знакомству со спецификой выбранного направления. Это дает студентам
                  возможность оценить свои ожидания и при необходимости сменить
                  специальность без значительных академических долгов.`,
  },
  2: {
    time: ' ~ 6 пар в неделю',
    description: `Второй курс акцентирует внимание на более углубленном изучении специфических дисциплин выбранного направления. На этом этапе студенты начинают погружаться в профессиональные аспекты своей специальности, расширяя знания и навыки в ключевых областях.`,
  },
  3: {
    time: ' ~ 5 пар в неделю',
    description: `Третий курс предлагает студентам возможность заниматься научно-исследовательской деятельностью, применять полученные знания на практике и начать проходить стажировки или практики на предприятиях и в организациях. Это время для активной профессиональной реализации и выбора направления дипломной работы.`,
  },
  4: {
    time: ' ~ 3 пар в неделю',
    description: `Четвертый курс фокусируется на завершении академической программы. Студенты подготавливают и защищают свою дипломную работу, основанную на исследованиях и практическом опыте, полученных за годы обучения. Это последний этап перед получением диплома и началом профессиональной карьеры.`,
  },
}

const cards = [
  'Семестр делится на модули, в течение одного модуля изучается одна дисциплина',
  'В модуле бывает перерыв между практическим и теоретическим блоком',
  'Дисциплины и расписание ты выбираешь в начале семестра',
  'Сессии нет, аттестация по дисциплине проходит в конце модуля',
  'Изучение некоторых дисциплин продолжается в традиционном формате',
]

const ModuleSystemCard = ({ text }: { text: string }) => {
  return (
    <Rectangle
      padding={'16px 24px'}
      background={'white'}
      borderRadius={12}
      width={300}
      fragment={css`
          flex-grow: 5;
          max-width: 375px;
      `}
      mobile={{
        width: '100%',
      }}
    >
      <Text size={14} align={'center'}>
        {text}
      </Text>
    </Rectangle>
  )
}

const ModuleSystem = () => {
  return (
    <Rectangle
      style={{ borderTop: 'none' }}
      background={'#EBEBFD'}
      padding={'24px 32px'}
      border={'1px solid #DEDEDE'}
      borderRadius={'0 0 24px 24px'}
      mobile={{
        padding: '16px',
        border: 'none',
      }}
    >
      <Flex direction={'column'} gap={24}>
        <Text size={20} weight={600} mobile={{ make: 'hidden', size: 20 }}>
          На магистратуре обучение происходит по модульной системе, вот что нужно о ней
          знать:
        </Text>
        <Flex
          direction={'row'}
          wrap={'wrap'}
          gap={16}
          mobile={{ gap: 12 }}
          justifyContent={'center'}
        >
          {cards.map((card, index) => (
            <ModuleSystemCard key={index} text={card} />
          ))}
        </Flex>
      </Flex>
    </Rectangle>
  )
}

const WhiteCard = styled(Card)`
    background: #EBEBFD;
`

const CoursesView = ({ trajectory }: CoursesViewProps) => {
  const [selectedCourse, setSelectedCourse] = useState(1)
  const { getUserData } = useUserData()
  return (
    <>
      <Flex direction={'column'} gap={16} desktop={{ make: 'hidden' }}>
        <>
          {trajectory.courses.map(course => {
            return (
              <Card
                key={course.course}
                title={
                  <Flex make={'centered'} gap={24}>
                    <Text size={14}>{course.course} курс</Text>
                    <Text size={12} color={'#79797A'}>
                      {courseTexts[course.course].time}
                    </Text>
                  </Flex>
                }
              >
                <Flex gap={24} direction={'column'} fragment={css`padding: 12px`}>
                  <ClassesCount
                    classes={trajectory.courses[course.course - 1].classes_count}
                  />
                  <Text size={16}>{courseTexts[course.course].description}</Text>
                </Flex>
              </Card>
            )
          })}

          {getUserData().userType === 'master' && (
            <WhiteCard
              key={'ModuleSystem'}
              title={<Text size={16}>Модульная система обучения в магистратуре</Text>}
            >
              <ModuleSystem />
            </WhiteCard>
          )}
        </>
      </Flex>
      <Rectangle
        borderRadius={24}
        width={'100%'}
        background={'none'}
        mobile={{ make: 'hidden' }}
      >
        <TabsWrapper>
          {trajectory.courses.map((course, index, arr) => (
            <CourseSelectButton
              key={course.course}
              onClick={() => {
                sendTypedEvent(
                  'click',
                  'course_tab',
                  'trajectory',
                  {
                    course: course.course,
                  },
                  'all',
                  false,
                )
                setSelectedCourse(course.course)
              }}
              isSelected={course.course === selectedCourse}
              data-first={index == 0}
              data-last={index == arr.length - 1}
            >
              <Text color={'inherit'} size={14}>
                {course.course} курс
              </Text>
            </CourseSelectButton>
          ))}
        </TabsWrapper>
        <ContentWrapper
          fragment={css`padding: 24px 40px;`}
          gap={40}
          withMaster={getUserData().userType === 'master'}
        >
          <Flex
            direction={'column'}
            fragment={css`width: 100%;
                flex-grow: 5`}
          >
            <ClassesCount
              classes={trajectory.courses[selectedCourse - 1].classes_count}
            />
          </Flex>
          <Flex
            direction={'column'}
            fragment={css`
                flex-shrink: 2.5`}
          >
            <Text size={16}>
              Средняя нагрузка на курсе:
              <Br height={24} />
              <b>{courseTexts[selectedCourse].time}</b>
            </Text>
            <Br height={24} />
            <Text size={16}>{courseTexts[selectedCourse].description}</Text>
          </Flex>
        </ContentWrapper>
        {getUserData().userType === 'master' && <ModuleSystem />}
      </Rectangle>
    </>
  )
}

export default CoursesView
