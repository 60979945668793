import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { SizeType } from './ImgWithMaskAndCards'

export const CroppedImg = styled.img<{ maskSrc?: string }>`
  width: 100%;
  max-height: 100%;
  ${({ maskSrc }) => (maskSrc ? `mask: url(${maskSrc})` : '')};
  mask-size: 100% 100%;
`

export const ChildrenScaler = styled.div<{ scale: number; initialSize: SizeType }>`
  ${({ initialSize }) => css({ ...initialSize })};
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(${({ scale }) => scale});
  transform-origin: 0 0;
`

export const ImageWrapper = styled.div<{ styledFragment?: FlattenSimpleInterpolation }>`
  position: relative;
  flex-shrink: 0;
  width: 100%;
  height: auto;

  ${({ styledFragment }) => styledFragment ?? ''};
`
