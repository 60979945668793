import React from 'react';

const GameDesigner = () => {
	return (
		<svg width="113" height="148" viewBox="0 0 113 148" fill="none" xmlns="http://www.w3.org/2000/svg">

			<g className="svgAnime rotate(91.28deg) n0">
				<path
					d="M68.3763 125.334C72.9578 124.574 80.2492 129.583 83.3222 132.183L85.796 130.087L78.7394 121.757C77.701 122.204 75.2392 122.642 73.6996 120.824C71.7751 118.552 72.6545 116.507 74.1754 115.218C74.3086 115.105 74.4526 114.993 74.6056 114.885L57.4962 95.53C59.4207 97.8017 67.5691 118.213 68.3763 125.334Z"
					fill="#F2D5F6"></path>
				<path
					d="M86.6275 109.872C82.3026 110.935 55.5716 93.2583 57.4962 95.53L74.6056 114.885C76.2006 113.75 78.7878 112.953 80.5438 115.026C82.0834 116.843 81.375 119.091 80.8284 119.988L87.885 128.317L90.9268 125.74C86.3173 121.841 86.6742 117.637 86.6275 109.872Z"
					fill="#F2D5F6"></path>
				<path
					d="M83.3222 132.183L81.8013 133.471L83.7258 135.743L93.6119 127.368L91.6873 125.096L90.9268 125.74L87.885 128.317L85.796 130.087L83.3222 132.183Z"
					fill="#C083C9"></path>
				<path
					d="M83.3222 132.183C80.2492 129.583 72.9578 124.574 68.3763 125.334C67.5691 118.213 59.4207 97.8017 57.4962 95.53M83.3222 132.183L81.8013 133.471L83.7258 135.743L93.6119 127.368L91.6873 125.096L90.9268 125.74M83.3222 132.183C83.85 131.736 84.7296 130.99 85.796 130.087M83.3222 132.183L85.796 130.087M57.4962 95.53C55.5716 93.2583 82.3026 110.935 86.6275 109.872C86.6742 117.637 86.3173 121.841 90.9268 125.74M57.4962 95.53L74.6056 114.885M90.9268 125.74C89.9129 126.599 88.8708 127.482 87.885 128.317M90.9268 125.74L87.885 128.317M85.796 130.087L78.7394 121.757C77.701 122.204 75.2392 122.642 73.6996 120.824C71.7751 118.552 72.6545 116.507 74.1754 115.218C74.3086 115.105 74.4526 114.993 74.6056 114.885M85.796 130.087C86.4368 129.544 87.145 128.944 87.885 128.317M85.796 130.087L87.885 128.317M87.885 128.317L80.8284 119.988C81.375 119.091 82.0834 116.843 80.5438 115.026C78.7878 112.953 76.2006 113.75 74.6056 114.885"
					stroke="#1F1F22" strokeWidth="0.9"></path>
			</g>

			<g className="svgAnime rotate(-17.74deg) n1">
				<path
					d="M47.5345 56.6896C46.3038 59.3823 46.55 64.6446 57.3796 64.1523C68.2092 63.6601 68.0451 59.2045 66.6094 57.0382C59.6516 60.103 53.0121 58.9244 47.5345 56.6896Z"
					fill="#1F1F22"></path>
				<path
					d="M47.5345 56.6896C42.526 54.6462 38.4888 51.7198 36.068 50.347C30.6156 47.2549 15.1308 65.1446 10.5507 91.4269C6.88672 112.453 22.4007 104.605 30.6156 98.0527C50.3315 87.6281 65.994 89.9957 80.3416 95.1816C86.2996 97.9949 101.366 110.907 104.332 101.366C107.298 91.8245 94.0817 66.7642 87.1026 55.4267C85.879 53.439 81.377 48.154 69.6549 55.4267C68.6347 56.0597 67.6187 56.5936 66.6094 57.0382M47.5345 56.6896C46.3038 59.3823 46.55 64.6446 57.3796 64.1523C68.2092 63.6601 68.0451 59.2045 66.6094 57.0382M47.5345 56.6896C53.0121 58.9244 59.6516 60.103 66.6094 57.0382"
					stroke="#1F1F22" strokeWidth="0.9"></path>
				<circle cx="29.6903" cy="67.2289" r="5.70319" fill="#C083C9" stroke="#1F1F22" strokeWidth="0.9"></circle>
				<circle cx="82.6073" cy="67.2289" r="5.70319" fill="#C083C9" stroke="#1F1F22" strokeWidth="0.9"></circle>
				<circle cx="42.6118" cy="73.9975" r="2.6266" fill="#F2D5F6" stroke="#1F1F22" strokeWidth="0.9"></circle>
				<circle cx="42.6118" cy="83.8427" r="2.6266" fill="#F2D5F6" stroke="#1F1F22" strokeWidth="0.9"></circle>
				<circle cx="36.4584" cy="78.9201" r="2.6266" fill="#F2D5F6" stroke="#1F1F22" strokeWidth="0.9"></circle>
				<circle cx="48.7651" cy="78.9201" r="2.6266" fill="#F2D5F6" stroke="#1F1F22" strokeWidth="0.9"></circle>
				<circle cx="68.4555" cy="73.9975" r="2.6266" fill="#F2D5F6" stroke="#1F1F22" strokeWidth="0.9"></circle>
				<circle cx="68.4555" cy="83.8425" r="2.6266" fill="#F2D5F6" stroke="#1F1F22" strokeWidth="0.9"></circle>
				<circle cx="62.3022" cy="78.9201" r="2.6266" fill="#F2D5F6" stroke="#1F1F22" strokeWidth="0.9"></circle>
				<circle cx="74.6088" cy="78.9201" r="2.6266" fill="#F2D5F6" stroke="#1F1F22" strokeWidth="0.9"></circle>
			</g>

			<g className="svgAnime rotate(27.69deg) n2">
				<path fillRule="evenodd" clipRule="evenodd"
				      d="M41.1836 31.6612C38.6671 31.574 34.85 29.7637 36.9612 24.2819C39.6002 17.4298 50.684 0.0358234 68.1014 6.36089C82.0354 11.421 78.1297 25.3361 74.435 31.6612C71.0777 36.8889 64.6179 47.1576 46.4616 45.8926C28.3052 44.6276 37.8408 37.2835 44.8782 33.7695C46.4616 32.7129 45.8282 29.9745 41.1836 31.6612ZM46.4616 36.9321C45.0541 38.8647 43.5059 42.6246 48.5728 42.203C54.9064 41.6759 52.2674 33.2424 46.4616 36.9321Z"
				      fill="#F2D5F6" stroke="#1F1F22" strokeWidth="0.9"></path>
				<circle cx="47.8446" cy="21.4" r="4.10555" fill="#C083C9" stroke="#1F1F22" strokeWidth="0.9"></circle>
				<circle cx="59.6893" cy="13.2001" r="4.10555" fill="#C083C9" stroke="#1F1F22" strokeWidth="0.9"></circle>
				<circle cx="66.0663" cy="25.9555" r="4.10555" fill="#C083C9" stroke="#1F1F22" strokeWidth="0.9"></circle>
			</g>
		</svg>
	);
};

export default GameDesigner;