import React from 'react'
import { IconType } from '../../../std-lib/Images/icons/types'

const Geo = (props: IconType) => {
  const {
    width = 8,
    height = 12,
    viewBox = '0 0 12 12',
    primaryColor = '#8533FF',
  } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7.75C10.7572 7.75 9.75 8.75721 9.75 10C9.75 11.2428 10.7572 12.25 12 12.25C13.2428 12.25 14.25 11.2428 14.25 10C14.25 8.75721 13.2428 7.75 12 7.75ZM12 6.25C9.92879 6.25 8.25 7.92879 8.25 10C8.25 12.0712 9.92879 13.75 12 13.75C14.0712 13.75 15.75 12.0712 15.75 10C15.75 7.92879 14.0712 6.25 12 6.25Z"
        fill="#1F1F22"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.75C8.54821 3.75 5.75 6.54821 5.75 10C5.75 12.3384 7.33713 14.908 9.07546 16.9878C9.92687 18.0064 10.7804 18.8678 11.4218 19.4748C11.6434 19.6846 11.8392 19.8634 12 20.0073C12.1608 19.8634 12.3566 19.6846 12.5782 19.4748C13.2196 18.8678 14.0731 18.0064 14.9245 16.9878C16.6629 14.908 18.25 12.3384 18.25 10C18.25 6.54821 15.4518 3.75 12 3.75ZM12 21C11.5239 21.5795 11.5236 21.5793 11.5236 21.5793L11.5217 21.5777L11.5168 21.5737L11.4997 21.5595C11.4851 21.5472 11.464 21.5296 11.4368 21.5066C11.3826 21.4607 11.3043 21.3937 11.2053 21.3071C11.0075 21.134 10.7267 20.8823 10.3907 20.5643C9.71961 19.9291 8.82313 19.0248 7.92454 17.9497C6.16287 15.842 4.25 12.9116 4.25 10C4.25 5.71979 7.71979 2.25 12 2.25C16.2802 2.25 19.75 5.71979 19.75 10C19.75 12.9116 17.8371 15.842 16.0755 17.9497C15.1769 19.0248 14.2804 19.9291 13.6093 20.5643C13.2733 20.8823 12.9925 21.134 12.7947 21.3071C12.6957 21.3937 12.6174 21.4607 12.5632 21.5066C12.536 21.5296 12.5149 21.5472 12.5003 21.5595L12.4832 21.5737L12.4783 21.5777L12.4769 21.5789C12.4769 21.5789 12.4761 21.5795 12 21ZM12 21L12.4761 21.5795C12.1994 21.8068 11.8003 21.8065 11.5236 21.5793L12 21Z"
        fill="#1F1F22"
      />
    </svg>
  )
}

export default Geo
