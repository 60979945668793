import React from 'react';

const Modeller = () => {
	return (
		<svg width="113" height="148" viewBox="0 0 113 148" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g className="svgAnime rotate(-26.14deg) n0">
				<path
					d="M31 53.0606L33.1045 42.3788L46.4328 26L63.2687 29.5606L78 45.2273L74.4925 56.6212L63.2687 73L46.4328 69.4394L45.8758 68.8482L31 53.0606Z"
					fill="#C083C9"></path>
				<path
					d="M31 53.0606L33.1045 42.3788L46.4328 26M31 53.0606L45.8758 68.8482M31 53.0606L43.6269 37.3939M46.4328 26L63.2687 29.5606L78 45.2273L74.4925 56.6212M46.4328 26L43.6269 37.3939M74.4925 56.6212L58.3582 53.0606M74.4925 56.6212L63.2687 73L46.4328 69.4394L45.8758 68.8482M58.3582 53.0606L43.6269 37.3939M58.3582 53.0606L45.8758 68.8482"
					stroke="#1F1F22" strokeWidth="0.9"></path>
			</g>
			<rect x="6.19238" y="13" width="6" height="6" fill="#1F1F22"></rect>
			<rect x="6.19238" y="79.7876" width="6" height="6" fill="#1F1F22"></rect>
			<rect x="97.6943" y="79.7876" width="6" height="6" fill="#1F1F22"></rect>
			<rect x="97.6943" y="13" width="6" height="6" fill="#1F1F22"></rect>
			<path fillRule="evenodd" clipRule="evenodd"
			      d="M9.38379 16H12.3214V16.8636H10.3027V18.8171H9.38379V16ZM22.2343 16.8636H17.2779V16H22.2343V16.8636ZM32.1471 16.8636H27.1907V16H32.1471V16.8636ZM42.0599 16.8636H37.1035V16H42.0599V16.8636ZM51.9728 16.8636H47.0164V16H51.9728V16.8636ZM61.8856 16.8636H56.9292V16H61.8856V16.8636ZM71.7984 16.8636H66.842V16H71.7984V16.8636ZM81.7113 16.8636H76.7549V16H81.7113V16.8636ZM91.6241 16.8636H86.6677V16H91.6241V16.8636ZM100.777 16.8636H98.7581V16H101.696V18.8171H100.777V16.8636ZM100.777 28.3581V23.5876H101.696V28.3581H100.777ZM10.3027 23.5876V28.3582H9.38379V23.5876H10.3027ZM100.777 37.8992V33.1287H101.696V37.8992H100.777ZM10.3027 33.1287V37.8992H9.38379V33.1287H10.3027ZM100.777 47.4403V42.6697H101.696V47.4403H100.777ZM10.3027 42.6697V47.4403H9.38379V42.6697H10.3027ZM100.777 56.9813V52.2108H101.696V56.9813H100.777ZM10.3027 52.2108V56.9813H9.38379V52.2108H10.3027ZM100.777 66.5224V61.7519H101.696V66.5224H100.777ZM10.3027 61.7519V66.5224H9.38379V61.7519H10.3027ZM100.777 76.0635V71.2929H101.696V76.0635H100.777ZM10.3027 71.2929V76.0635H9.38379V71.2929H10.3027ZM100.777 82.7875V80.834H101.696V83.6511H98.7581V82.7875H100.777ZM10.3027 82.7875V80.834H9.38379V83.6511H12.3214V82.7875H10.3027ZM17.2778 82.7875H22.2343V83.6511H17.2778V82.7875ZM27.1907 82.7875H32.1471V83.6511H27.1907V82.7875ZM37.1035 82.7875H42.0599V83.6511H37.1035V82.7875ZM47.0164 82.7875H51.9728V83.6511H47.0164V82.7875ZM56.9292 82.7875H61.8856V83.6511H56.9292V82.7875ZM66.842 82.7875H71.7984V83.6511H66.842V82.7875ZM76.7549 82.7875H81.7113V83.6511H76.7549V82.7875ZM86.6677 82.7875H91.6241V83.6511H86.6677V82.7875Z"
			      fill="#1F1F22"></path>

			<g className="svgAnime rotate(-126.88deg) n1">
				<path
					d="M93.5784 98.6972C86.649 100.986 79.6798 98.2284 77.8776 92.7735C76.0755 87.3186 80.0299 80.9516 86.9593 78.6622C93.8888 76.3729 100.858 79.131 102.66 84.5859C104.462 90.0408 100.508 96.4078 93.5784 98.6972ZM103.935 88.4456L105.327 92.6568C107.129 98.1117 103.174 104.479 96.2449 106.768C89.3154 109.057 82.3462 106.299 80.5441 100.844L79.1528 96.6332C82.4241 100.134 88.2535 101.404 93.8607 99.5517C99.468 97.6992 103.393 93.2064 103.935 88.4456Z"
					fill="#F2D5F6" stroke="#1F1F22" strokeWidth="0.9"></path>
			</g>
			<path d="M3 116.669L11.9158 105.842L34.2053 92.4685L56.4947 105.205L66.0474 116.032L34.2053 134.5L3 116.669Z"
			      fill="#F2D5F6"></path>
			<path
				d="M11.9158 105.842L3 116.669L34.2053 134.5L66.0474 116.032L56.4947 105.205M11.9158 105.842L34.2053 117.942L56.4947 105.205M11.9158 105.842L34.2053 92.4685L56.4947 105.205"
				stroke="#1F1F22" strokeWidth="0.9"></path>

			<g className="svgAnime translateY(-7px) n2">
				<path d="M34.2054 112.847L12.5527 99.4737L34.2054 74L56.4948 99.4737L34.2054 112.847Z" fill="#C083C9"></path>
				<path
					d="M34.2054 112.847L12.5527 99.4737L34.2054 74M34.2054 112.847L56.4948 99.4737L34.2054 74M34.2054 112.847V74"
					stroke="#1F1F22" strokeWidth="0.9"></path>
			</g>

		</svg>
	);
};

export default Modeller;