export const BASE_URL = process.env.REACT_APP_API_URL
export const ENVIRONMENT = process.env.NODE_ENV

export const colors: { [key: string]: string } = {
  'программирование и информационные технологии': '#677CE8',
  'soft-skills': '#F49987',
  'естественные науки': '#75C2DC',
  'электроника и робототехника': '#6290D0',
  'математика': '#7C81F1',
  'физика': '#B386E7',
  'экономика и менеджмент': '#F18AA7',
  'гуманитарные науки': '#9480EF',
  'физкультура': '#E185CD',
  'введение в профессиональную деятельность': '#CF7DDC',
  'практика': '#989CF8',
  'другое': '#996FDB',
}

export const clubColors: { [key: string]: { baseColor: string } } = {
  'Студ. отряд': { baseColor: '#A7EDD8' },
  'Спорт': { baseColor: '#C1D5F6' },
  'Инновации': { baseColor: '#D8D7FE' },
  'Разработка': { baseColor: '#C5E9F0' },
  'Предпринимательство': { baseColor: '#FCE8B9' },
  'Волонтерство': { baseColor: '#C6F0B3' },
  'Развлечения': { baseColor: '#F8CFB9' },
  'Творчество': { baseColor: '#F2D5F6' },
  'Выступления': { baseColor: '#FFC3C3' },
}
