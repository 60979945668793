import React from 'react'
import styled, { css } from 'styled-components'
import { CssFrom, UnitValue } from '../../TypedCss'
import prepare, { Prepared } from '../../Models'

type FontWeight = number | 'bold' | 'bolder' | 'lighter' | 'normal'
type ElementType = 'span' | 'h1' | 'h2' | 'div' | 'p'

export interface TextProps {
  size: UnitValue
  visibility?: string
  color?: string
  align?: 'left' | 'center' | 'right'
  weight?: FontWeight
  lineHeight?: number
  font?: 'Golos' | 'ALS Gorizont'
  as?: ElementType
}

export function textCssProcessor(props: TextProps) {
  return css`
    color: ${props.color};
    font-size: ${CssFrom(props.size)};
    font-family: ${props.font || 'Golos'}, sans-serif;
    font-weight: ${props.weight || 'normal'};
    line-height: ${CssFrom(props.lineHeight)};
    visibility: ${props.visibility};
    text-align: ${props.align || 'left'};
  `
}

const Text = styled.span<Prepared<TextProps>>`
  ${props => prepare(props, textCssProcessor)}
`

export default Text
