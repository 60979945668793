import styled, { css } from 'styled-components'
import CommonSlideLayout from '../../Components/CommonSlideLayout'
import { ContentCard, NavBarWrapper } from '../../Layouts/Step/styled'
import Text from 'std-lib/FastUI/Components/Text'
import StarsImage from 'Images/Static/ButtonStars.svg'

export const ProfileLayout = styled(CommonSlideLayout)`
  gap: 40px;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: stretch;
    gap: 24px
  }
`

export const PrivateInfoWrapper = styled.div`
  display: flex;
  gap: 40px;
`

export const OtherInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media screen and (max-width: 1000px) {
    gap: 24px
  }
`

export const PersonalDataWrapper = styled.div<{
  padding: number
  gap: number
  width: number
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${p => p.width}px;
  gap: ${p => p.gap}px;
  border-radius: 20px;
  border: 1px solid #DEDEDE;
  padding: ${p => p.padding}px;
  box-sizing: border-box;
  height: min-content;


  @media screen and (max-width: 1000px) {
    width: 100%;
    padding: 16px
  }
`

export const PersonalPointsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`
export const PersonalPoint = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  white-space: pre-wrap;
  align-items: flex-start;

  span {
    word-wrap: anywhere;
  }

  svg {
    flex-shrink: 0;
  }
`

export const NameWrapper = styled.span`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;

  @media screen and (max-width: 1000px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }
`

export const AvatarAndPersonalPointsWrapper = styled.div<{ gap: number }>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${p => p.gap}px;

  @media screen and (max-width: 1000px) {
    gap: 12px;
  }
`

export const Avatar = styled.div<{ imageSrc: string; width: number; height: number }>`
  width: ${p => p.width}px;
  height: ${p => p.height}px;
  background-color: #FCE8B9;
  border-radius: 50%;
  background-image: url(${({ imageSrc }) => imageSrc});
  background-position: center;
  background-repeat: no-repeat;
  background-size: ${p => p.width * 0.6}px ${p => p.height * 0.6}px;
  border: 1px solid #DEDEDE;
  flex-shrink: 0;

  @media screen and (max-width: 1000px) {
    background-size: 32px 32px;
    width: 52px;
    height: 52px;
  }
`

export const TitledBlock = styled.div<{ gap: number }>`
  display: flex;
  flex-direction: column;
  gap: ${p => p.gap}px;

  @media screen and (max-width: 1000px) {
    gap: 12px;
  }
`

export const ProfessionCard = styled.div`
  border-radius: 20px;
  transition: all .3s;
  border: 1px solid #DEDEDE;
  background: #FFF;
  flex-grow: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-style: normal;

  span:first-child {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  span:last-child {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: ${({ theme }) => theme.color.text.secondary}
  }

  @media screen and (max-width: 1000px) {
    span:first-child {
      font-size: 14px;
      line-height: 22px;
    }

    span:last-child {
      font-size: 12px;
      line-height: 18px;
    }
`

export const ProfileTagsWrapper = css`
  gap: 8px;
`
export const ProfileContentCard = styled(ContentCard)`
  min-height: 560px;
  max-width: 1440px;
  margin-bottom: 134px;

  @media screen and (max-width: 1000px) {
    height: unset;
    margin-bottom: 92px;
  }
`

export const ProfileNavBar = styled(NavBarWrapper)`
  @media screen and (max-width: 1000px) {
    padding: 16px;
    height: 70px;
  }
`

export const BlockTitle = styled(Text)`
  @media screen and (max-width: 1000px) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const AvatarContentItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`

export const StarsWrapper = styled.div`

  @keyframes shine {
    from {
      background-position: right;
      transform: scale(1);
    }

    70% {
      background-position: right;
      transform: scale(1);
    }

    85% {
      transform: scale(1.1);
    }

    to {
      background-position: left;
      transform: scale(1);
    }
  }

  width: 28px;
  height: 32px;
  mask-image: url("${StarsImage}");
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
  animation: shine 3s infinite;
  background-image: linear-gradient(45deg, #F9CF6B 0, #F9CF6B 45%, white, #F9CF6B 55%, #F9CF6B 100%);
  background-size: 400% 400% !important;

  @media screen and (max-width: 1000px) {
    width: 26.716px;
    height: 22px;
  }
`

export const testListWrapper = css`
  text-transform: lowercase;

  &:first-letter {
    text-transform: uppercase;
  }
`
