import styled, { css } from 'styled-components'

const defaultClassTag = css`
  background: #EBEBFD;
`

const activeClassCard = css`
  background: ${({ theme }) => theme.color.background.trackCard.humanitarian};
  color: #fff;
`

export const ClassTag = styled.button<{ isActive: boolean }>`
  padding: 8px 20px;
  border-radius: 20px;
  transition: all .3s;
  ${({ isActive }) => (isActive ? activeClassCard : defaultClassTag)};

  &:hover {
    ${({ isActive }) =>
      !isActive
        ? css`
                      background: ${({ theme }) => theme.color.background.tags.preset};
                    `
        : ''};
  }

  &:disabled {
    background-color: #dedbec;
    color: #9f9e9e;
  }

  @media screen and (max-width: 1000px) {
    padding: 8px 0;
    width: 43px;
    text-align: center;
  }
`

export const ClassesSelectorActiveWrapper = styled.div<{ isActive?: boolean }>`
  transition: all .3s;
  max-height: ${({ isActive }) => (isActive ? 200 : 0)}px;
  overflow: hidden;
  padding-top: 16px;
  padding-bottom: ${({ isActive }) => (isActive ? 16 : 0)}px;
`

export const ClassesSelectorWrapper = styled.div`
  display: flex;
  padding: 10px 16px;
  align-items: center;
  gap: 24px;
  border-radius: 30px;
  border: 1px solid #DEDEDE;
  white-space: nowrap;
  width: min-content;
  transition: all .3s;
  margin-left: 40px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  @media screen and (max-width: 1285px) {
    padding: 12px;
    gap: 12px;
    border-radius: 20px;
    font-size: 14px;
    line-height: 22px;
  }

  @media screen and (max-width: 400px) {
    margin-left: auto;
  }

  @media screen and (max-width: 375px) {
    flex-direction: column;
  }
`

export const ClassOptionsWrapper = styled.div`
  display: flex;
  gap: 8px;

  @media screen and (max-width: 1000px) {
    gap: 4px;
  }
`
