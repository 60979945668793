import React, { FC, ReactNode } from 'react'
import { useWindowDimensions } from '../std-lib/Extensions/window'
import { createPortal } from 'react-dom'

type MobilePortalProps = {
  children: ReactNode
}

export const MobilePortal: FC<MobilePortalProps> = ({ children }) => {
  const { width } = useWindowDimensions()
  return width > 1000 ? <>{children}</> : <>{createPortal(children, document.body)}</>
}
