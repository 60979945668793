export type TransformConfig = {
  rotate?: string;
  scale?: string;
  translate?: string;
  skew?: string;
  matrix?: string;
};

export const generateTransformFragment = (config: TransformConfig): string => {
  let transformString = '';

  if (config.rotate) {
    transformString += `rotate(${config.rotate}) `;
  }

  if (config.scale) {
    transformString += `scale(${config.scale}) `;
  }

  if (config.translate) {
    transformString += `translate(${config.translate}) `;
  }

  if (config.skew) {
    transformString += `skew(${config.skew}) `;
  }

  if (config.matrix) {
    transformString += `matrix(${config.matrix}) `;
  }

  return transformString.trim();
};