import React from 'react'
import { IconType } from 'std-lib/Images/icons/types'

const YouTubeIcon = (props: IconType) => {
  const { width = 32, height = 32, viewBox = '0 0 32 32' } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
    >
      <circle cx="16" cy="16" r="16" fill="transparent" />
      <path
        d="M25.5612 11.224C26 12.7552 26 16.0365 26 16.0365C26 16.0365 26 19.2813 25.5612 20.849C25.3419 21.724 24.6472 22.3802 23.8062 22.599C22.234 23 16.0183 23 16.0183 23C16.0183 23 9.766 23 8.19378 22.599C7.35283 22.3802 6.65814 21.724 6.43876 20.849C6 19.2813 6 16.0365 6 16.0365C6 16.0365 6 12.7552 6.43876 11.224C6.65814 10.349 7.35283 9.65625 8.19378 9.4375C9.766 9 16.0183 9 16.0183 9C16.0183 9 22.234 9 23.8062 9.4375C24.6472 9.65625 25.3419 10.349 25.5612 11.224ZM13.9707 18.9896L19.1627 16.0365L13.9707 13.0833V18.9896Z"
        fill="white"
      />
    </svg>
  )
}

export default YouTubeIcon
