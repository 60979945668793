import React, { useEffect, useState } from 'react'
import { StepLayout } from 'Widgets/Onboarding/Layouts/Step/styled'
import StepContentLayout from 'Widgets/Onboarding/Layouts/Step/components/StepContentLayout'
import {
  EducationStageButtonStyle,
  EducationStageCard,
  EducationStageContentLayout,
  EducationStageNavBar,
  StageButton,
  StagesWrapper,
} from 'Widgets/Onboarding/Slides/EducationStage/styled'
import Text from 'std-lib/FastUI/Components/Text'
import Button from 'std-lib/UiKit/Button'
import { useNavigate } from 'react-router-dom'
import RightArrow from 'Images/Static/rightArrow.svg'
import {
  defaultState,
  EduStages,
  useUserData,
} from 'Widgets/Onboarding/UserDataStorage/UseUserData'
import { onboardingRoutes } from 'Widgets/Onboarding/Layouts/Step/onboradingRoutes/onboardingRoutes'
import { sendTypedEvent } from '../../../../Utils/events/sendEvent'

const stages: Record<EduStages, string> = {
  bachelor: 'Бакалавриат',
  master: 'Магистратура',
  // 'parent': 'Родитель',
}

const EducationStage = () => {
  const navigate = useNavigate()
  const { updateUserDataWith, getUserData, clearUserData } = useUserData()
  const EducationStage = getUserData().userType ?? 'bachelor'
  const [activeStage, setActiveStage] = useState<EduStages | undefined>(undefined)

  const to = Object.keys(onboardingRoutes[EducationStage])

  const startOnboarding = () => {
    if (!activeStage) {
      return
    }
    const userData = getUserData()
    console.log(userData)
    console.log(activeStage)

    if (userData.userType !== activeStage) {
      updateUserDataWith({ ...defaultState, userType: activeStage })
    } else {
      updateUserDataWith({ userType: activeStage })
    }
    sendTypedEvent('click', 'target_audience', 'ob', { targetAudience: activeStage })
    navigate(`/step/${to[0]}/0`)
  }

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && activeStage) {
        startOnboarding()
      }
    }

    window.addEventListener('keydown', handleKeyPress)

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [activeStage])

  return (
    <StepLayout>
      <EducationStageCard>
        <StepContentLayout title={'Выбери, куда поступаешь'}>
          <EducationStageContentLayout>
            <Text size={20} mobile={{ size: 14 }} mobileBreakpoint={1000}>
              Стать частью ITMO.FAMILY&nbsp;— это шаг на&nbsp;пути к&nbsp;успешной карьере
              в&nbsp;науке, технологиях или бизнесе. Университет ИТМО не&nbsp;просто
              обучает&nbsp;— он&nbsp;вдохновляет на&nbsp;решение глобальных задач
              и&nbsp;предоставляет все инструменты для&nbsp;вашего гармоничного развития.
            </Text>
            <StagesWrapper>
              {Object.entries(stages).map(([type, title]) => (
                <StageButton
                  active={type === activeStage}
                  key={type}
                  onClick={() => setActiveStage(type as EduStages)}
                >
                  {title}
                </StageButton>
              ))}
            </StagesWrapper>
          </EducationStageContentLayout>
        </StepContentLayout>
      </EducationStageCard>
      <EducationStageNavBar>
        <Button
          isDisabled={!activeStage}
          styledFragment={EducationStageButtonStyle}
          rightIconSrc={RightArrow}
          onClick={startOnboarding}
        >
          Начать свой путь с ITMO TRACK
        </Button>
      </EducationStageNavBar>
    </StepLayout>
  )
}

export default EducationStage
