import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  ThemeProps,
} from 'styled-components'

export type PositionConfig = {
  top?: number
  left?: number
  right?: number
  bottom?: number
}

export type GenericCardProps = {
  width?: number | string
  height?: number | string
  color?: string
  bg?: string
  absoluteConfig?: PositionConfig
  styledFragment?:
    | FlattenSimpleInterpolation
    | FlattenInterpolation<ThemeProps<DefaultTheme>>
}

export const GenericCard = styled.div<GenericCardProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ bg }) => bg || 'transparent'};
  color: ${({ color }) => color ?? 'inherit'};
  border-radius: 20px;
  padding: 12px 8px;
  width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width || 'auto')};
  height: ${({ height }) =>
    typeof height === 'number' ? `${height}px` : height || 'auto'};
  box-sizing: border-box;
  position: ${({ absoluteConfig }) => (absoluteConfig ? 'absolute' : 'unset')};
  ${({ absoluteConfig }) => (absoluteConfig ? css(absoluteConfig) : '')};

  ${({ styledFragment }) => styledFragment ?? ''};
`
