import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import CategorySelect from './components/CategorySelect'
import { NavContext } from 'Context/NavContext'
import StepContentLayout from '../../Layouts/Step/components/StepContentLayout'
import { Category, onboardingRequest, Profession } from '../../API/API'
import { useUserData } from '../../UserDataStorage/UseUserData'
import ProfessionCard from './components/ProfessionCard'
import { makeEmptyArray } from 'std-lib/Extensions/array'
import { StyledMainSkeleton } from 'std-lib/Stylesheets/StyledVars/Skeleton'

const ProfessionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    overflow-y: auto;
    overflow-x: hidden;
`

const ProfessionsList = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
    gap: 24px;
    flex-wrap: wrap;

    @media (max-width: 500px) {
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }
`

const ProfessionCardSkeleton = styled.div`
    ${StyledMainSkeleton};
    height: 100px;
    border-radius: 20px;
`
const Professions = () => {
  const { updateUserDataWith, getUserData } = useUserData()

  const userType = getUserData().userType

  const [selectedProfession, setSelectedProfession] = useState<Profession | undefined>(
    getUserData().selectedProfession,
  )
  const [professions, setProfessions] = useState<Profession[]>([])
  const { setForwardState } = useContext(NavContext)
  const [categories, setCategories] = useState<Category[] | undefined>(undefined)
  const [category, setCategory] = useState<Category | null>(null)
  const [isCategorySelectorAvailable, setIsCategorySelectorAvailable] =
    useState<boolean>(false)

  useEffect(() => {
    console.log(userType)
    if (!userType) return
    onboardingRequest.getProfessions(userType).then(({ data }) => setProfessions(data))
    onboardingRequest.getCategories().then(({ data }) => {
      setCategories(data)
      const category = getUserData().selectedSphere
      if (category && category.id == -1) {
        // setIsCategorySelectorAvailable(true)
        // setCategory(data[0])
      } else if (category) {
        setCategory(category)
      }
    })
  }, [userType])

  useEffect(() => {
    setForwardState({ disabled: !selectedProfession })
    updateUserDataWith({ selectedProfession: selectedProfession })
  }, [selectedProfession?.id])

  return (
    <StepContentLayout title="Выбери профессию, в которой хотел бы развиваться">
      <ProfessionsWrapper>
        {/* {isCategorySelectorAvailable && (*/}
        {/*  <CategorySelect*/}
        {/*    value={category}*/}
        {/*    onChange={setCategory}*/}
        {/*    categories={categories}*/}
        {/*  />*/}
        {/* )}*/}
        <ProfessionsList>
          {professions && professions.length > 0 && categories
            ? professions
                .filter(item => (category ? item.domain.id === category?.id : true))
                .map(item => (
                  <ProfessionCard
                    key={item.id}
                    id={item.id}
                    onClick={() => setSelectedProfession(item)}
                    selected={selectedProfession?.id == item.id}
                    title={item.name}
                    subtitle={item.description}
                  />
                ))
            : makeEmptyArray(5).map((_, i) => <ProfessionCardSkeleton key={i} />)}
        </ProfessionsList>
      </ProfessionsWrapper>
    </StepContentLayout>
  )
}

export default Professions
