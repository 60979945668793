import React from 'react'

const Project = () => {
  return (
    <svg
      width="113"
      height="148"
      viewBox="0 0 113 148"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="svgAnime translateY(17px)+opacity(0) n0">
        <path
          d="M34.1546 51.3071C34.7284 53.0311 36.0448 52.4429 36.9899 51.875C38.1241 48.4676 35.8558 49.0355 35.2887 49.0355C34.7216 49.0355 33.5875 49.6034 34.1546 51.3071Z"
          fill="black"
        />
        <path
          d="M71.7744 51.3071C71.2006 53.0311 69.8842 52.4429 68.939 51.875C67.8049 48.4676 70.0732 49.0355 70.6403 49.0355C71.2073 49.0355 72.3415 49.6034 71.7744 51.3071Z"
          fill="black"
        />
        <path
          d="M50.9999 37.5C50.4261 39.224 49.945 39.5679 48.9999 39C45.4999 37.5 48.4328 35.5 48.9999 35.5C49.567 35.5 51.567 35.7963 50.9999 37.5Z"
          fill="black"
        />
        <path
          d="M58.6894 54C58.1155 55.724 57.6345 56.0679 56.6893 55.5C53.1893 54 56.1223 52 56.6893 52C57.2564 52 59.2564 52.2963 58.6894 54Z"
          fill="black"
        />
        <path
          d="M43.6894 28C43.1155 29.724 42.6345 30.0679 41.6893 29.5C38.1893 28 41.1223 26 41.6893 26C42.2564 26 44.2564 26.2963 43.6894 28Z"
          fill="black"
        />
        <path
          d="M61.9226 39.023C60.2977 39.8363 59.7144 39.7393 59.4477 38.6695C58.0335 35.1339 61.5216 35.7936 61.9226 36.1946C62.3235 36.5956 63.5282 38.2193 61.9226 39.023Z"
          fill="black"
        />
      </g>

      <g className="svgAnime translateY(23px) n1">
        <path
          className="svgAnime opacity(1) n2"
          d="M37.9952 70.0657C40.8226 70.0697 64.1749 70.094 67.7651 70.0914C67.898 69.4785 68.0795 68.1654 68.0427 67.158C68.0111 66.3322 68.0151 65.4885 68.0545 64.6271C68.1111 63.3594 68.5003 62.3165 69.2223 61.4985C70.2895 60.2912 71.7 59.2595 72.8106 58.0148C75.3698 55.1518 77.2267 51.9988 78.3814 48.5559C79.9516 43.8715 80.2113 39.1351 79.1606 34.3468C77.9474 28.8234 74.9766 23.6629 70.6801 19.8754C66.5559 16.2405 61.7769 14.0331 56.3428 13.2532C49.403 12.259 42.3173 14.2396 36.8037 18.3644C31.4104 22.3965 27.5381 28.4447 26.4078 35.1872C25.3439 41.5194 26.3618 47.5058 29.4615 53.1463C29.8954 53.9353 30.4143 54.6613 30.9054 55.4069C31.275 55.9698 31.6787 56.4998 32.1167 56.9969C32.7223 57.6853 33.2766 58.3994 33.9512 59.0248C34.5759 59.6021 35.2124 60.186 35.8608 60.7765C37.1035 61.9108 37.7111 63.1693 37.7368 64.8007C37.7512 65.6792 37.7466 66.5958 37.7229 67.5506C37.6966 68.5882 37.7874 69.4266 37.9952 70.0657Z"
          fill="#F7E1A0"
          opacity="0.08"
        />
        <path
          d="M37.9952 70.0657C40.8226 70.0697 64.1749 70.094 67.7651 70.0914C67.898 69.4785 68.0795 68.1654 68.0427 67.158C68.0111 66.3322 68.0151 65.4885 68.0545 64.6271C68.1111 63.3594 68.5003 62.3165 69.2223 61.4985C70.2895 60.2912 71.7 59.2595 72.8106 58.0148C75.3698 55.1518 77.2267 51.9988 78.3814 48.5559C79.9516 43.8715 80.2113 39.1351 79.1606 34.3468C77.9474 28.8234 74.9766 23.6629 70.6801 19.8754C66.5559 16.2405 61.7769 14.0331 56.3428 13.2532C49.403 12.259 42.3173 14.2396 36.8037 18.3644C31.4104 22.3965 27.5381 28.4447 26.4078 35.1872C25.3439 41.5194 26.3618 47.5058 29.4615 53.1463C29.8954 53.9353 30.4143 54.6613 30.9054 55.4069C31.275 55.9698 31.6787 56.4998 32.1167 56.9969C32.7223 57.6853 33.2766 58.3994 33.9512 59.0248C34.5759 59.6021 35.2124 60.186 35.8608 60.7765C37.1035 61.9108 37.7111 63.1693 37.7368 64.8007C37.7512 65.6792 37.7466 66.5958 37.7229 67.5506C37.6966 68.5882 37.7874 69.4266 37.9952 70.0657Z"
          stroke="black"
          strokeWidth="0.75"
        />
        <path
          d="M34.1546 51.3071C34.7284 53.0311 36.0448 52.4429 36.9899 51.875C38.1241 48.4676 35.8558 49.0355 35.2887 49.0355C34.7216 49.0355 33.5875 49.6034 34.1546 51.3071Z"
          fill="#F0C64C"
          opacity="0.01"
        />
        <path
          d="M71.7744 51.3071C71.2006 53.0311 69.8842 52.4429 68.939 51.875C67.8049 48.4676 70.0732 49.0355 70.6403 49.0355C71.2073 49.0355 72.3415 49.6034 71.7744 51.3071Z"
          fill="#F0C64C"
          opacity="0.01"
        />
        <path
          d="M50.9999 37.5C50.4261 39.224 49.945 39.5679 48.9999 39C45.4999 37.5 48.4328 35.5 48.9999 35.5C49.567 35.5 51.567 35.7963 50.9999 37.5Z"
          fill="#F0C64C"
          opacity="0.01"
        />
        <path
          d="M58.6894 54C58.1155 55.724 57.6345 56.0679 56.6893 55.5C53.1893 54 56.1223 52 56.6893 52C57.2564 52 59.2564 52.2963 58.6894 54Z"
          fill="#F0C64C"
          opacity="0.01"
        />
        <path
          d="M43.6894 28C43.1155 29.724 42.6345 30.0679 41.6893 29.5C38.1893 28 41.1223 26 41.6893 26C42.2564 26 44.2564 26.2963 43.6894 28Z"
          fill="#F0C64C"
          opacity="0.01"
        />
        <path
          d="M61.9226 39.023C60.2977 39.8363 59.7144 39.7393 59.4477 38.6695C58.0335 35.1339 61.5216 35.7936 61.9226 36.1946C62.3235 36.5956 63.5282 38.2193 61.9226 39.023Z"
          fill="#F0C64C"
          opacity="0.01"
        />
      </g>

      <path d="M61 93V76.4128" stroke="#1F1F22" strokeWidth="0.9" strokeLinecap="round" />
      <path
        d="M44.929 93V76.4128"
        stroke="#1F1F22"
        strokeWidth="0.9"
        strokeLinecap="round"
      />
      <path d="M49 93L49 77.5" stroke="#1F1F22" strokeWidth="0.9" strokeLinecap="round" />
      <path
        d="M56.6895 93V74.25"
        stroke="#1F1F22"
        strokeWidth="0.9"
        strokeLinecap="round"
      />

      <g className="svgAnime translateY(17px) n3">
        <path
          d="M56.6893 55.5C57.6345 56.0679 58.1155 55.724 58.6894 54C59.2564 52.2963 57.2564 52 56.6893 52C56.1223 52 53.1893 54 56.6893 55.5ZM56.6893 55.5V74.25"
          stroke="#1F1F22"
          strokeWidth="0.9"
          strokeLinecap="round"
        />
        <path
          d="M41.6893 56L41.6893 29.5M41.6893 29.5C42.6345 30.0679 43.1155 29.724 43.6894 28C44.2564 26.2963 42.2564 26 41.6893 26C41.1223 26 38.1893 28 41.6893 29.5Z"
          stroke="#1F1F22"
          strokeWidth="0.9"
          strokeLinecap="round"
        />
        <path
          d="M49.1946 48.9225L59.4477 38.6695M59.4477 38.6695C59.7144 39.7393 60.2977 39.8363 61.9226 39.023C63.5282 38.2193 62.3235 36.5956 61.9226 36.1946C61.5216 35.7936 58.0335 35.1339 59.4477 38.6695Z"
          stroke="#1F1F22"
          strokeWidth="0.9"
          strokeLinecap="round"
        />

        <path
          d="M36.9899 51.875L44.9289 59.8256V76.4128M36.9899 51.875C36.0448 52.4429 34.7284 53.0311 34.1546 51.3071C33.5875 49.6034 34.7216 49.0355 35.2887 49.0355C35.8558 49.0355 38.1241 48.4676 36.9899 51.875Z"
          stroke="#1F1F22"
          strokeWidth="0.9"
          strokeLinecap="round"
        />
        <path
          d="M48.9999 77.5V39M48.9999 39C49.945 39.5679 50.4261 39.224 50.9999 37.5C51.567 35.7963 49.567 35.5 48.9999 35.5C48.4328 35.5 45.4999 37.5 48.9999 39Z"
          stroke="#1F1F22"
          strokeWidth="0.9"
          strokeLinecap="round"
        />
        <path
          d="M68.939 51.875L61 59.8256V76.4128M68.939 51.875C69.8842 52.4429 71.2006 53.0311 71.7744 51.3071C72.3415 49.6034 71.2073 49.0355 70.6403 49.0355C70.0732 49.0355 67.8049 48.4676 68.939 51.875Z"
          stroke="#1F1F22"
          strokeWidth="0.9"
          strokeLinecap="round"
        />
      </g>

      <g className="svgAnime rotate(60deg) n4">
        <path
          d="M90.4201 108.065C90.5681 108.263 89.7239 110.396 89.5601 111.035L93.7508 112.516L94.857 109.36L96.951 108.554L98.4925 110.099L102.063 106.632L100.09 105.025L100.335 103.293L102.675 102.301L101.316 97.9105L98.6055 98.4077L97.6803 97.1714L97.9865 95.006L93.4883 94.144L93.1822 96.3093L91.0882 97.1153L89.1766 95.0756L85.8526 98.3571L87.5792 100.149L87.7656 101.943L84.994 102.874L86.1069 107.45L88.8785 106.52C89.3307 106.952 90.272 107.867 90.4201 108.065Z"
          fill="#F7E1A0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M89.1851 94.6335L91.1708 96.7523L92.9026 96.0857L93.2287 93.7794L98.3327 94.7576L98.0032 97.0881L98.7374 98.0692L101.529 97.557L103.052 102.477L100.615 103.51L100.419 104.896L102.527 106.612L98.489 110.533L96.8746 108.915L95.1002 109.598L93.939 112.91L89.1914 111.233L89.2617 110.958C89.3202 110.73 89.461 110.32 89.6102 109.887C89.679 109.687 89.7497 109.481 89.8149 109.286C89.9211 108.968 90.0148 108.672 90.0728 108.446C90.1009 108.336 90.1181 108.252 90.1255 108.194C90.0882 108.152 90.0326 108.092 89.959 108.016C89.8452 107.898 89.7014 107.754 89.5469 107.601C89.2943 107.35 89.0161 107.08 88.7999 106.872L85.887 107.85L84.6274 102.671L87.4335 101.729L87.2838 100.288L85.4187 98.3517L89.1851 94.6335ZM86.2863 98.3626L87.8744 100.011L88.0975 102.158L85.3604 103.077L86.3267 107.05L88.9567 106.168L89.0909 106.296C89.3179 106.513 89.6676 106.851 89.9796 107.16C90.1355 107.315 90.283 107.463 90.4015 107.586C90.5143 107.703 90.6154 107.811 90.6663 107.879C90.7299 107.964 90.7406 108.058 90.7438 108.102C90.7476 108.156 90.7439 108.211 90.7382 108.259C90.7265 108.358 90.7011 108.476 90.6694 108.6C90.6056 108.849 90.5057 109.163 90.399 109.483C90.3259 109.702 90.2502 109.922 90.1788 110.129C90.0864 110.397 90.0013 110.645 89.9386 110.841L93.5624 112.121L94.6136 109.123L97.0271 108.194L98.4959 109.666L101.599 106.652L99.7603 105.155L100.054 103.076L102.298 102.125L101.103 98.2641L98.4736 98.7464L97.3574 97.2548L97.6402 95.2546L93.7478 94.5087L93.4616 96.533L91.0054 97.4784L89.1679 95.5179L86.2863 98.3626Z"
          fill="#1F1F22"
        />
        <path
          d="M96.6979 101.008C97.9242 102.646 97.5953 104.973 95.9631 106.204C94.331 107.436 92.0137 107.105 90.7874 105.467C89.561 103.828 89.89 101.501 91.5221 100.27C93.1543 99.0384 95.4715 99.3687 96.6979 101.008Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M95.593 105.71C96.9532 104.684 97.2273 102.745 96.2053 101.379C95.1834 100.013 93.2523 99.7382 91.8922 100.764C90.5321 101.79 90.258 103.729 91.2799 105.095C92.3019 106.461 94.2329 106.736 95.593 105.71ZM95.9631 106.204C97.5953 104.973 97.9242 102.646 96.6979 101.008C95.4715 99.3687 93.1543 99.0384 91.5221 100.27C89.89 101.501 89.561 103.828 90.7874 105.467C92.0137 107.105 94.331 107.436 95.9631 106.204Z"
          fill="#1F1F22"
        />
      </g>

      <path
        d="M67.77 93.0256C64.1797 93.0283 40.8275 93.0039 38 93V100.934C38 104.8 41.134 107.934 45 107.934H46.5048H60.0048H60.7699C64.6359 107.934 67.77 104.8 67.77 100.934V93.0256Z"
        fill="#F0C64C"
      />
      <path
        d="M46.5048 107.934V109.434C46.5048 111.643 48.2957 113.434 50.5048 113.434H56.0048C58.214 113.434 60.0048 111.643 60.0048 109.434V107.934H46.5048Z"
        fill="#F0C64C"
      />
      <path
        d="M46.5048 107.934H45C41.134 107.934 38 104.8 38 100.934V93C40.8275 93.0039 64.1797 93.0283 67.77 93.0256V100.934C67.77 104.8 64.6359 107.934 60.7699 107.934H60.0048M46.5048 107.934V109.434C46.5048 111.643 48.2957 113.434 50.5048 113.434H56.0048C58.214 113.434 60.0048 111.643 60.0048 109.434V107.934M46.5048 107.934H60.0048"
        stroke="black"
        strokeWidth="0.75"
      />
    </svg>
  )
}

export default Project
