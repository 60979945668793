import React from 'react'
import { IconType } from 'std-lib/Images/icons/types'

const VKIcon = (props: IconType) => {
  const { width = 32, height = 32, viewBox = '0 0 32 32' } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
    >
      <g id="VK" clipPath="url(#clip0_488_3754)">
        <circle id="Ellipse 110" cx="16" cy="16" r="16" fill="transparent" />
        <path
          id="Vector"
          d="M5 9C5.17331 17.32 9.33328 22.32 16.6266 22.32H17.04V17.56C19.72 17.8266 21.7466 19.7867 22.5599 22.32H26.3467C25.3067 18.5333 22.5732 16.44 20.8665 15.64C22.5732 14.6533 24.9732 12.2533 25.5465 9H22.1064C21.3598 11.64 19.1467 14.04 17.04 14.2666V9H13.5999V18.2266C11.4666 17.6933 8.77331 15.1067 8.65331 9H5Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_488_3754">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default VKIcon
