import React, { Component, ErrorInfo, ReactNode } from 'react'
import ErrorPage from './Widgets/ErrorPage'
import ErrorLayout from './LayoutComponents/ErrorLayout'
import { sendEvent } from './Utils/events/sendEvent'

interface State {
  error: any // Could be an exception thrown in synchronous code or could be a rejection reason from a Promise, we don't care
}

interface Props {
  children: ReactNode // Could be an exception thrown in synchronous code or could be a rejection reason from a Promise, we don't care
}

class CoolErrorBoundary extends Component<Props, State> {
  public state: State = {
    error: null,
  }

  private promiseRejectionHandler = (event: PromiseRejectionEvent) => {
    this.setState({
      error: event.reason,
    })
  }

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { error: error }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    sendEvent('all', 'caughtError')
    console.error('Uncaught error:', error, errorInfo)
  }

  componentDidMount() {
    // Add an event listener to the window to catch unhandled promise rejections & stash the error in the state
    window.addEventListener('unhandledrejection', this.promiseRejectionHandler)
  }

  componentWillUnmount() {
    window.removeEventListener('unhandledrejection', this.promiseRejectionHandler)
  }

  public render() {
    if (this.state.error) {
      const error = this.state.error

      return (
        <ErrorLayout>
          <ErrorPage error={error} />
        </ErrorLayout>
      )
    } else {
      return this.props.children
    }
  }
}

export default CoolErrorBoundary
