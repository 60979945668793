import { useEffect } from 'react'
import * as Sentry from '@sentry/browser'
import {
  LocalStorageInteraction,
  withLocalStorage,
} from 'std-lib/Extensions/localStorage'
import { Sections } from 'Widgets/Onboarding/Layouts/Step/onboradingRoutes/types'
import { City } from '../Slides/CityQuestion/assets/types'
import { Hobby } from '../Slides/Hobbies'
import { Category, Profession } from '../API/API'
import { CustomHobby } from 'Widgets/Onboarding/Slides/Hobbies/components/CustomHobbies'

export type EduStages = 'bachelor' | 'master' // | 'parent'
export type EducationType = 'school' | 'spo' | 'notStudy'

export type UserData = {
  name: string | undefined
  city: City | undefined
  schoolGrade: '1-7' | '8' | '9' | '10' | '11' | undefined
  step: number
  section: Sections
  selectedSphere: Category | undefined
  selectedProfession: Profession | undefined
  selectedSkillSets: { id: number; name: string }[]
  selectedHobbies: Hobby[]
  customHobbies: Required<CustomHobby>[]
  userType: EduStages | undefined
  userOccupation: { id: number; name: string }[]
  areasOfGrowth: { id: number; name: string }[]
  educationType: EducationType | undefined
  _v: number | string
}

export const storeVersion = process.env.REACT_APP_STORE_V ?? 1

export const defaultState: UserData = {
  name: undefined,
  city: undefined,
  schoolGrade: undefined,
  step: 0,
  section: Sections.INTRO,
  selectedSphere: undefined,
  selectedProfession: undefined,
  selectedSkillSets: [],
  selectedHobbies: [],
  customHobbies: [],
  userType: undefined,
  userOccupation: [],
  areasOfGrowth: [],
  educationType: undefined,
  // на всякий случай вместо 1 руками ставим актуальную версию стора
  _v: storeVersion,
}

export function useUserData(): {
  getUserData: () => UserData
  updateUserDataWith: (newPartialUserData: Partial<UserData>) => void
  clearUserData: () => void
} {
  const localStorage = withLocalStorage({ userData: {} }, LocalStorageInteraction.load)

  function setState(state: UserData) {
    withLocalStorage({ userData: state }, LocalStorageInteraction.save)
  }

  useEffect(() => {
    if (Object.keys(localStorage.userData).length === 0) {
      setState(defaultState)
    }

    if (localStorage.userData._v != storeVersion) {
      setState(defaultState)

      if (!(window.location.pathname == '/' || window.location.pathname == '/stage')) {
        window.location.replace('/')
      }
    }
  }, [])

  function getUserData() {
    if (Object.keys(localStorage.userData).length > 0) return localStorage.userData
    return defaultState
  }

  function updateUserDataWith(newPartialUserData: Partial<UserData>) {
    const newState = { ...localStorage.userData, ...newPartialUserData }
    Sentry.setContext('userData', newState)
    setState(newState)
  }

  function clearUserData() {
    setState(defaultState)
  }

  return { updateUserDataWith, getUserData, clearUserData }
}
