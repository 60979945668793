import React from 'react'

const DataAnalyst = () => {
  return (
    <svg
      width="113"
      height="148"
      viewBox="0 0 113 148"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_32_67" fill="white">
        <path d="M67.3636 85.4022C70.2004 86.6117 72.6672 88.5493 74.5142 91.0189C76.3612 93.4885 77.5228 96.4024 77.8813 99.4653C78.2399 102.528 77.7826 105.632 76.556 108.461C75.3293 111.29 73.3767 113.745 70.896 115.577L64.9173 107.482C65.9714 106.703 66.8011 105.66 67.3223 104.458C67.8435 103.256 68.0378 101.937 67.8854 100.635C67.7331 99.334 67.2395 98.0959 66.4547 97.0465C65.6699 95.9972 64.6218 95.1738 63.4164 94.6599L67.3636 85.4022Z" />
      </mask>
      <g className="svgAnime rotate(30.62deg) n0">
        <path
          d="M67.3636 85.4022C70.2004 86.6117 72.6672 88.5493 74.5142 91.0189C76.3612 93.4885 77.5228 96.4024 77.8813 99.4653C78.2399 102.528 77.7826 105.632 76.556 108.461C75.3293 111.29 73.3767 113.745 70.896 115.577L64.9173 107.482C65.9714 106.703 66.8011 105.66 67.3223 104.458C67.8435 103.256 68.0378 101.937 67.8854 100.635C67.7331 99.334 67.2395 98.0959 66.4547 97.0465C65.6699 95.9972 64.6218 95.1738 63.4164 94.6599L67.3636 85.4022Z"
          fill="#677CE8"
          stroke="#1F1F22"
          mask="url(#path-1-inside-1_32_67)"
        />
        <mask id="path-2-inside-2_32_67" fill="white">
          <path d="M68.3011 117.165C64.4388 119.088 60.0022 119.517 55.8432 118.369C51.6841 117.221 48.0958 114.577 45.7673 110.944L54.24 105.513C55.2294 107.056 56.7541 108.18 58.5213 108.668C60.2885 109.156 62.1736 108.973 63.8147 108.156L68.3011 117.165Z" />
        </mask>
        <path
          d="M68.3011 117.165C64.4388 119.088 60.0022 119.517 55.8432 118.369C51.6841 117.221 48.0958 114.577 45.7673 110.944L54.24 105.513C55.2294 107.056 56.7541 108.18 58.5213 108.668C60.2885 109.156 62.1736 108.973 63.8147 108.156L68.3011 117.165Z"
          fill="#99B5E3"
          stroke="#1F1F22"
          mask="url(#path-2-inside-2_32_67)"
        />
        <mask id="path-3-inside-3_32_67" fill="white">
          <path d="M44.6502 108.918C42.8212 105.011 42.5002 100.565 43.749 96.435C44.9978 92.3051 47.7282 88.782 51.416 86.5424L56.6402 95.1444C55.0732 96.096 53.913 97.593 53.3824 99.3479C52.8518 101.103 52.9881 102.992 53.7653 104.652L44.6502 108.918Z" />
        </mask>
        <path
          d="M44.6502 108.918C42.8212 105.011 42.5002 100.565 43.749 96.435C44.9978 92.3051 47.7282 88.782 51.416 86.5424L56.6402 95.1444C55.0732 96.096 53.913 97.593 53.3824 99.3479C52.8518 101.103 52.9881 102.992 53.7653 104.652L44.6502 108.918Z"
          fill="#C1D5F6"
          stroke="#1F1F22"
          mask="url(#path-3-inside-3_32_67)"
        />
        <mask id="path-4-inside-4_32_67" fill="white">
          <path d="M53.7429 85.3571C57.1229 83.9424 60.8617 83.6242 64.4321 84.4475L62.1708 94.2543C60.6537 93.9044 59.065 94.0396 57.6289 94.6408L53.7429 85.3571Z" />
        </mask>
        <path
          d="M53.7429 85.3571C57.1229 83.9424 60.8617 83.6242 64.4321 84.4475L62.1708 94.2543C60.6537 93.9044 59.065 94.0396 57.6289 94.6408L53.7429 85.3571Z"
          fill="#99B5E3"
          stroke="#1F1F22"
          mask="url(#path-4-inside-4_32_67)"
        />
      </g>
      <g className="svgAnime rotate(-10deg) n1">
        <path
          d="M58.4741 116.056C49.6311 125.636 34.4397 126.026 24.5413 116.888C14.6429 107.751 13.8202 92.5764 22.6633 82.9973C31.5063 73.4182 46.6977 73.0277 56.5961 82.1655C66.4945 91.3033 67.3172 106.477 58.4741 116.056ZM27.5603 113.618C35.5668 121.009 47.89 120.718 55.083 112.926C62.276 105.134 61.5836 92.827 53.5771 85.4358C45.5706 78.0445 33.2474 78.3362 26.0544 86.1279C18.8615 93.9196 19.5538 106.227 27.5603 113.618Z"
          fill="white"
          stroke="#1F1F22"
          strokeWidth="0.9"
        />
        <path
          d="M24.0111 116.705L19.9389 122.537L10.8 136.734C10.7512 140.706 13.7652 140.413 15.2783 139.769L24.93 124.746C26.367 122.614 28.2358 119.702 27.8569 119.475C27.3507 119.171 25.1658 117.703 24.0111 116.705Z"
          stroke="#1F1F22"
          strokeWidth="0.9"
        />
      </g>
      <path
        d="M12 35.5H48.5L72.5 30L94.5 21L102 12"
        stroke="black"
        strokeWidth="0.75"
        strokeLinecap="round"
      />
      <circle
        cx="26"
        cy="36"
        r="2.625"
        fill="#677CE8"
        stroke="black"
        strokeWidth="0.75"
      />
      <circle
        cx="48"
        cy="36"
        r="2.625"
        fill="#677CE8"
        stroke="black"
        strokeWidth="0.75"
      />
      <circle
        cx="72"
        cy="31"
        r="2.625"
        fill="#677CE8"
        stroke="black"
        strokeWidth="0.75"
      />
      <circle
        cx="94"
        cy="21"
        r="2.625"
        fill="#677CE8"
        stroke="black"
        strokeWidth="0.75"
      />
      <g className="svgAnime rotate(-19.03deg) n2">
        <path
          d="M17 42.5L26 45L47 50.5L71 56.5L93 58.5"
          stroke="black"
          strokeWidth="0.75"
          strokeLinecap="round"
        />
        <circle
          cx="25"
          cy="45"
          r="2.625"
          fill="white"
          stroke="black"
          strokeWidth="0.75"
        />
        <circle
          cx="46"
          cy="51"
          r="2.625"
          fill="white"
          stroke="black"
          strokeWidth="0.75"
        />
        <circle
          cx="71"
          cy="56"
          r="2.625"
          fill="white"
          stroke="black"
          strokeWidth="0.75"
        />
        <circle
          cx="93"
          cy="59"
          r="2.625"
          fill="white"
          stroke="black"
          strokeWidth="0.75"
        />
      </g>
      <rect
        x="7.375"
        y="7.375"
        width="98.25"
        height="63.25"
        stroke="black"
        strokeWidth="0.75"
      />
      <g className="svgAnime rotate(90deg) n3">
        <path
          d="M67 140.5L76.5 128L86.5 137L102 116"
          stroke="#1F1F22"
          strokeWidth="0.75"
        />
        <path
          d="M101.641 116.391L98.25 115.087L104.25 113L103.728 119L101.641 116.391Z"
          fill="#677CE8"
          stroke="#1F1F22"
          strokeWidth="0.5"
          strokeLinecap="round"
        />
      </g>
    </svg>
  )
}

export default DataAnalyst
