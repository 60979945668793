import React, { useContext, useEffect, useState } from 'react'
import {
  ClassesSelectorActiveWrapper,
  ClassesSelectorWrapper,
  ClassOptionsWrapper,
  ClassTag,
} from './styled'
import CustomCheckbox from '../CustomCheckbox'
import { useWindowDimensions } from 'std-lib/Extensions/window'
import { EducationType, useUserData } from '../../../../UserDataStorage/UseUserData'
import { NavContext } from 'Context/NavContext'
import Br from '../../../../../../std-lib/FastUI/Components/Br'

type SchoolGrades = '1-7' | '8' | '9' | '10' | '11' | undefined

const ClassSelect = () => {
  const { updateUserDataWith, getUserData } = useUserData()
  const { setForwardState } = useContext(NavContext)
  const { width } = useWindowDimensions()

  const classNumbers: SchoolGrades[] = ['1-7', '8', '9', '10', '11']
  const [schoolGrade, setSchoolGrade] = useState(getUserData().schoolGrade)
  const [educationType, setEducationType] = useState<EducationType>()

  useEffect(() => {
    updateUserDataWith({ schoolGrade, educationType })
    setForwardState({
      disabled:
        educationType === undefined ||
        (educationType === 'school' && schoolGrade === undefined),
    })
  }, [educationType, schoolGrade])

  return (
    <div>
      <CustomCheckbox
        value={1}
        onChange={() => {
          setEducationType('school')
          if (schoolGrade == null) {
            setSchoolGrade(undefined)
          }
        }}
        checked={educationType === 'school'}
      >
        Да
      </CustomCheckbox>

      <ClassesSelectorActiveWrapper isActive={educationType === 'school'}>
        <ClassesSelectorWrapper>
          {width > 1285 && <span>Я учусь в</span>}
          <ClassOptionsWrapper>
            {classNumbers.map(
              schoolGradeValue =>
                schoolGradeValue && (
                  <ClassTag
                    disabled={educationType !== 'school'}
                    isActive={schoolGradeValue === schoolGrade}
                    key={schoolGradeValue}
                    onClick={() => setSchoolGrade(schoolGradeValue)}
                  >
                    {schoolGradeValue}
                  </ClassTag>
                ),
            )}
          </ClassOptionsWrapper>
          <span> {width > 1285 ? 'классе' : 'класс'}</span>
        </ClassesSelectorWrapper>
      </ClassesSelectorActiveWrapper>

      <CustomCheckbox
        value={1}
        onChange={() => {
          setEducationType('spo')
          if (schoolGrade) {
            setSchoolGrade(undefined)
          }
        }}
        checked={educationType === 'spo'}
      >
        Нет, получаю СПО
      </CustomCheckbox>
      <Br height={16} />
      <CustomCheckbox
        value={0}
        onChange={() => {
          setEducationType('notStudy')
          setSchoolGrade(undefined)
        }}
        checked={educationType === 'notStudy'}
      >
        Нет, уже не учусь
      </CustomCheckbox>
    </div>
  )
}

export default ClassSelect
