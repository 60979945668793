import React, { useContext, useEffect, useState } from 'react'
import StepContentLayout from '../../Layouts/Step/components/StepContentLayout'
import Picture from './assets/Picture.png'
import Text from '../../../../std-lib/FastUI/Components/Text'
import { useUserData } from '../../UserDataStorage/UseUserData'
import styled from 'styled-components'
import Tags from '../../../../std-lib/Сomponents/Tags'
import CommonSlideLayout from '../../Components/CommonSlideLayout'
import { NavContext } from 'Context/NavContext'

const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`

const StyledPicture = styled.img`
  width: 393px;
  height: 262px;

  @media screen and (max-width: 1000px) {
    height: auto;
    width: 100%;
  }
`

const TagsWrapper = styled.div`
  max-width: 740px;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;

  @media screen and (max-width: 1000px) {
    gap: 12px;
  }
`

const tags: { id: number; name: string }[] = [
  { id: 1, name: 'Увеличить зарплату' },
  { id: 9, name: 'Запустить свой стартап' },
  { id: 2, name: 'Пойти в науку' },
  { id: 3, name: 'Сменить профессию' },
  { id: 4, name: 'Пойти на стажировку' },
  { id: 5, name: 'Устроиться в топовую компанию' },
  { id: 6, name: 'Нетворкинг' },
  { id: 7, name: 'Получить диплом' },
  { id: 8, name: 'Продолжить учебу на военной кафедре' },
]

const AreasOfGrowth = () => {
  const { updateUserDataWith, getUserData } = useUserData()
  const { setForwardState } = useContext(NavContext)

  const [selectedTags, setSelectedTags] = useState<{ id: number; name: string }[]>(
    getUserData().areasOfGrowth || [],
  )
  const onSelectedTagsChange = (tags: { id: number; name: string }[]) => {
    setSelectedTags(tags)
  }

  useEffect(() => {
    updateUserDataWith({ areasOfGrowth: selectedTags })
  }, [selectedTags])

  useEffect(() => {
    setForwardState({ disabled: !selectedTags.length })
  }, [selectedTags.length, setForwardState])

  return (
    <StepContentLayout title="Для чего тебе магистратура?">
      <CommonSlideLayout gap={'60px'}>
        <TextWrapper>
          <Text
            size={20}
            weight={400}
            mobile={{
              size: 14,
            }}
          >
            <b>{getUserData().name}</b>, понимаем, что у каждого студента свой уникальный
            путь. Чтобы помочь тебе достичь поставленных целей, расскажи, что ты хочешь
            получить от обучения.
          </Text>
          <QuestionWrapper>
            <TagsWrapper>
              <Tags
                tags={tags}
                selectedTags={selectedTags}
                onSelectedTagsChange={onSelectedTagsChange}
              />
            </TagsWrapper>
          </QuestionWrapper>
        </TextWrapper>
        <StyledPicture src={Picture} />
      </CommonSlideLayout>
    </StepContentLayout>
  )
}

export default AreasOfGrowth
