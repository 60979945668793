import React from 'react'
import { ClassImageWrapper, ClassQuestionWrapper } from './styled'
import ImgWithMaskAndCards from '../../../../Components/ImgWithMaskAndCards/ImgWithMaskAndCards'
import { GenericCard } from '../../../../std-lib/Сomponents/GenericCard'
import Image from './assets/Pictures.jpg'
import Mask from './assets/mask.svg'

import LearningImg from '../../../../Images/Static/Learning.svg'
import ClassSelect from './components/ClassSelector'
import StepContentLayout from '../../Layouts/Step/components/StepContentLayout'
import CommonSlideLayout from '../../Components/CommonSlideLayout'
import { useUserData } from '../../UserDataStorage/UseUserData'

const ClassQuestion = () => {
  const { getUserData } = useUserData()

  return (
    <StepContentLayout title="Твое образование">
      <CommonSlideLayout gap={'40px'} mediaWidth={900}>
        <div>
          <ClassQuestionWrapper size={20}>
            Чтобы подобрать для тебя наиболее подходящие образовательные и&nbsp;карьерные
            пути, нам важно знать, на&nbsp;каком этапе обучения ты сейчас находишься.
            <br />
            <br />
            <b>{getUserData().name}, ты сейчас учишься в&nbsp;школе?</b>
          </ClassQuestionWrapper>
          <ClassSelect />
        </div>
        <ImgWithMaskAndCards
          initialSize={{ width: 452, height: 396 }}
          imageSrc={Image}
          maskSrc={Mask}
          styledFragment={ClassImageWrapper}
        >
          <GenericCard
            bg={'#323243'}
            height={147}
            width={154}
            absoluteConfig={{ bottom: 0, right: 109 }}
          >
            <img src={LearningImg} alt="Книжки и яблоко" />
          </GenericCard>
        </ImgWithMaskAndCards>
      </CommonSlideLayout>
    </StepContentLayout>
  )
}

export default ClassQuestion
