import Bio from 'Widgets/Onboarding/Slides/ProfessionCategories/assets/ITMO Photos/Spheres/Bio.jpg'
import Engineer from 'Widgets/Onboarding/Slides/ProfessionCategories/assets/ITMO Photos/Spheres/Engineer.jpg'
import Business from 'Widgets/Onboarding/Slides/ProfessionCategories/assets/ITMO Photos/Spheres/Business.jpg'
import Design from './assets/ITMO Photos/Spheres/Design.jpg'
import EmptyIcon from 'Widgets/Onboarding/Slides/ProfessionCategories/assets/ITMO Photos/Spheres/Donnot_know.jpg'
import Development from 'Widgets/Onboarding/Slides/ProfessionCategories/assets/ITMO Photos/Spheres/Development.jpg'

export const PhotoBuCategoryName: Record<string, string> = {
  'Я пока не решил': EmptyIcon,
  'IT и программирование': Development,
  'Дизайн': Design,
  'Науки о жизни': Bio,
  'Бизнес и управление': Business,
  'Физика и инженерия': Engineer,
}
