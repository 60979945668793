import React from 'react'
import { IconType } from '../../../std-lib/Images/icons/types'

const Book = (props: IconType) => {
  const {
    width = 8,
    height = 12,
    viewBox = '0 0 12 12',
    primaryColor = '#8533FF',
  } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="28"
      viewBox="0 0 11 28"
      fill="none"
    >
      <path
        d="M6.85961 14.9684H0.349609V13.2324H6.85961L3.66761 9.90039H6.11761L10.3176 14.1004L6.11761 18.3004H3.66761L6.85961 14.9684Z"
        fill="#8533FF"
      />
    </svg>
  )
}

export default Book
