import React from 'react'
import Rectangle from '../../../../std-lib/FastUI/Components/Rectangle'
import Img from '../../../../std-lib/FastUI/Components/Img'
import { css } from 'styled-components'

type TrajectoryInfoBlockProps = {
  imgSrc: string
}

const ImageStack = ({ imgSrc }: TrajectoryInfoBlockProps) => {
  return (
    <Rectangle
      height={490}
      width={434}
      make={'centered'}
      mobile={{ width: 179, height: 200 }}
      fragment={css`flex-shrink: 0`}
    >
      <Rectangle
        borderRadius={'20px'}
        left={65}
        top={0}
        position={'absolute'}
        width={357}
        height={445}
        transformConfig={{ rotate: '-1.5deg' }}
        background={'#323243'}
        mobile={{ width: 147, height: 184, left: 26 }}
      />

      <Rectangle
        borderRadius={'20px'}
        position={'absolute'}
        top={0}
        left={0}
        transformConfig={{ rotate: '3.7deg' }}
      >
        <Img src={imgSrc} width={368} height={459} mobile={{ width: 152, height: 190 }} />
      </Rectangle>
    </Rectangle>
  )
}

export default ImageStack
