import React from 'react'
import styled from 'styled-components'
import { NextOrPrevDisciplinesType } from '../../../../types'

const DisciplineWrapper = styled.div`
  padding: 16px;
  background-color: #F4F4FB;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 50%;

  @media screen and (max-width: 1000px){
    max-width: unset;
    width: 100%;
  }`

const DisciplineName = styled.span`
  color: #1F1F22;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

const DisciplineSemester = styled.span`
  color: #79797A;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`

const NextOrPrevDiscipline = ({ name, semester }: NextOrPrevDisciplinesType) => {
  return (
    <DisciplineWrapper>
      <DisciplineName>{name}</DisciplineName>

      <DisciplineSemester>{semester} семестр</DisciplineSemester>
    </DisciplineWrapper>
  )
}

export default NextOrPrevDiscipline
