import styled from 'styled-components';
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 88px;
  width: 100%;
  background: ${props => props.theme.color.background.noStroke.default};
  margin-top: 100px;

  @media screen and (max-width: 1000px) {
    padding: 20px 16px;
    margin-top: 40px;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1440px;
  width: 100%;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 573px;
`
export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  margin-bottom: 32px;
  
  @media screen and (max-width: 1000px) {
    margin-bottom: 24px;
  }
`
export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
`
export const FormButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  
  @media screen and (max-width: 1000px) {
    margin-bottom: 24px;
  }
`
export const SocialMediaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const Links = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  max-width: 470px;
  
  @media screen and (max-width: 1000px) {
    gap: 12px;
  }
`
export const BubbleMessageIconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  
  @media screen and (max-width: 1000px) {
    display: none;
  }
`

export const BubbleMessageIcon = styled.img`
  width: 320px;
  height: 260px;
`