import React, { ComponentType, MouseEventHandler, useEffect } from 'react'
import styled from 'styled-components'
import ProfessionsIconsImages from '../../../../../../Images/ProfessionsIcons'
import { render } from 'react-dom'
import {
  hoverOnboardingCard,
  selectedOnboardingCard,
} from '../../../../Fragments/CardStates'

const ProfessionCardWrapper = styled.button<{ isSelected: boolean }>`
  height: max-content;
  display: flex;
  gap: 16px;
  padding: 16px;
  position: relative;
  border-radius: 20px;
  transition: all .3s;
  border: 1px solid ${({ isSelected, theme }) =>
    isSelected ? theme.color.brand.primary : '#DEDEDE'};

  @media (max-height: 1000px) {
    height: max-content;
    min-width: 300px;
  }

  span:first-child {
    opacity: 1;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    transition: all .2s;
    text-align: start;
  }

  span:last-child {
    opacity: 1;
    position: relative;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    transition: all .2s;
    text-align: start;
    color: ${({ theme }) => theme.color.text.secondary};
  }

  &:hover {
    ${hoverOnboardingCard}
  }

  ${({ isSelected }) => isSelected && selectedOnboardingCard};
`

const IconWrapper = styled.div`
  width: auto;
  height: 63px;

  svg {
    height: 100%;
    width: auto;

    .svgAnime {
      transition: all 0.3s;
      transform-box: fill-box;
      transform-origin: center;
    }
  }
`

const InfoWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  gap: 12px;
`

type ProfessionCardProps = {
  id: number
  icon?: ComponentType
  title: string
  subtitle: string
  selected: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
}
const ProfessionCard = ({
  id,
  title,
  subtitle,
  selected,
  onClick,
}: ProfessionCardProps) => {
  useEffect(() => {
    if (id) {
      const div = document.getElementsByClassName(`icon-wrapper-${id}`)[0]
      const IconToRender = ProfessionsIconsImages[title]
      render(<IconToRender />, div)

      const animateSvg = (svg: Element) => {
        let style: HTMLElement | null = document.getElementById('svgAnimeStyles')
        if (!style) {
          style = document.createElement('style')
          style.setAttribute('type', 'text/css')
          style.setAttribute('id', 'svgAnimeStyles')
        }

        svg.querySelectorAll('.svgAnime').forEach((animatedChild, index) => {
          const classList = animatedChild.classList
          animatedChild.classList.add(`n${index}`)
          const transforms = classList[1].split('+')
          let cssTransform = 'transform: '
          let opacity = undefined
          transforms.forEach(transform => {
            if (transform.includes('opacity')) {
              opacity = transform.split('(')[1].split(')')[0]
            } else {
              cssTransform += ' ' + transform
            }
          })
          cssTransform += ';'

          const css = `${ProfessionCardWrapper}:hover .icon-wrapper-${id} .n${index}{${cssTransform}; opacity: ${
            opacity || 1
          };}`

          if (style) {
            style.appendChild(document.createTextNode(css))
          }
        })
        document.head.appendChild(style)
        return style
      }

      const observer = new MutationObserver(() => {
        animateSvg(div)
      })

      observer.observe(div, { childList: true })

      animateSvg(div)
    }
  }, [id])

  return (
    <ProfessionCardWrapper isSelected={selected} onClick={onClick}>
      <>
        <InfoWrapper>
          <span>{title}</span>
          <span>{subtitle}</span>
        </InfoWrapper>
        <IconWrapper className={`icon-wrapper-${id}`} />
      </>
    </ProfessionCardWrapper>
  )
}

export default ProfessionCard
