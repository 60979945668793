import styled, { css } from 'styled-components'
import Flex from '../../../../std-lib/FastUI/Components/Flex'

export const TabsWrapper = styled(Flex)`

`

export const ContentWrapper = styled(Flex)<{ withMaster: boolean }>`
    border: 1px solid #DEDEDE;
    border-radius: inherit;
    box-shadow: 4px 4px 50px 0 rgba(97, 120, 201, 0.12);
    overflow: hidden;

    ${({ withMaster }) =>
      withMaster &&
      css`
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            `}
`
