import axios from 'axios'
import { captureException } from '@sentry/react'

const axiosConfig = {
  baseUrl: process.env.REACT_APP_API_URL,
}

const request = axios.create({
  baseURL: axiosConfig.baseUrl,
})

request.interceptors.response.use(
  r => r,
  e => {
    captureException(e)
    console.log('Error with request')
    throw e
  },
)

export default request
