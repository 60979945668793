import styled from 'styled-components'
import Text from '../../../../std-lib/FastUI/Components/Text';

export const NameText= styled(Text)`
  @media screen and (max-width: 1000px) {
    font-size: 16px;
  }
`

export const CodeText = styled(Text)`
    @media screen and (max-width: 1000px) {
        font-size: 12px;
    }
`

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    scroll-snap-align: start;
    padding: 24px;
    width: 330px;
    max-width: 330px;
    height: 300px;
    border-radius: 20px;
    border: 1px solid #DEDEDE;
    background: #FFF;
    flex-shrink: 0;
    transition: all 0.3s linear;
    cursor: pointer;

    @media screen and (max-width: 1000px) {
        width: 255px;
        min-width: 255px;
    }

    &:hover {
        border-color: #712FD3;

        ${NameText} {
            color: #712FD3;
        }

        ${CodeText} {
            color: #712FD3;
        }
    }
`
export const CoverageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`
export const NameAndCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  transition: color .3s linear;
  
  &:active {
    color: #712FD3;
  }
`
export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`
export const Link = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  color: ${props => props.theme.color.text.button};
  font-size: 14px;
  font-weight: 600;
  padding: 0;
`
