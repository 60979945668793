import React, { useContext } from 'react'
import { css } from 'styled-components'
import Rectangle from '../../../../../std-lib/FastUI/Components/Rectangle'
import { DisciplineType } from '../../../../../types'
import Text from '../../../../../std-lib/FastUI/Components/Text'
import Flex from '../../../../../std-lib/FastUI/Components/Flex'
import Flame from '../../../../../Images/icons/flame'
import DisciplineModal from '../../../modals/DisciplineModal'
import ModalContext from '../../../../../std-lib/Сontext/Modal'
import FastButton from '../../../../../std-lib/FastUI/Components/Button'
import Tooltip from '../../../../../std-lib/Сomponents/Tooltip'

type DisciplineCardProps = {
  discipline: DisciplineType
  isSelected: boolean
  onClick?: () => void
}

const colorsByClass: { [key: string]: string } = {
  'математика': '--color-background-trackCard-math',
  'электроника и робототехника': '--color-background-trackCard-electronics',
  'гуманитарные науки': '--color-background-trackCard-humanitarian',
  'программирование и информационные технологии':
    '--color-background-trackCard-programming',
  'естественные науки': '--color-background-trackCard-naturalScience',
  'практика': '--color-background-trackCard-practice',
  'экономика и менеджмент': '--color-background-trackCard-economics',
  'физкультура': '--color-background-trackCard-sport',
  'soft-skills': '--color-background-trackCard-softSkills',
  'физика': '--color-background-trackCard-physics',
  'другое': '--color-background-trackCard-other',
}

const DisciplineCard = ({ discipline, isSelected, onClick }: DisciplineCardProps) => {
  const { swapModal } = useContext(ModalContext)
  return (
    <FastButton
      size={14}
      width={'100%'}
      padding={12}
      fragment={css`
        border-radius: 0 12px 12px 0;
        background: white;
        border: 1px solid #DEDEDE;

        ${
          isSelected
            ? css`
                  border: 1px solid #8533FF;
                  color: #712FD3;
                `
            : ''
        };

        &:hover {
          border: 1px solid #8533FF;
          color: #8533FF
        }

        &::before {
          content: '';
          position: absolute;
          top: -1px;
          left: 0;
          width: 4px;
          height: calc(100% + 2px);
          background: var(${colorsByClass[discipline.class]});
        }
      `}
      onClick={() => {
        if (onClick) {
          onClick()
        }
        swapModal(<DisciplineModal id={discipline.id} />, { isSideModal: true })
      }}
    >
      <Flex justifyContent={'space-between'} gap={12} fragment={css`color: inherit`}>
        <Text size={14} fragment={css`color: inherit`}>
          {discipline.name}
        </Text>
        {discipline.necessity !== 'necessary' && (
          <Tooltip
            triggerElement={
              <Rectangle
                width={27.5}
                height={20}
                borderRadius={4}
                fragment={css`display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-shrink: 0;
                  background: #F4F4FB`}
              >
                <Flame height={16} width={16} viewBox={'0 0 8 12'} />
              </Rectangle>
            }
            tooltipContent={
              <Text
                size={14}
                weight={400}
                color={'#fff'}
                fragment={css`max-width: 275px`}
              >
                Дисциплина является выборной. Рекомендуем выбрать ее для изучения.
              </Text>
            }
          />
        )}
      </Flex>
    </FastButton>
  )
}

export default DisciplineCard
