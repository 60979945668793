import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Scroll from 'react-scroll'
import { StyleContext } from 'std-lib/Сontext/StyleContext'
import Button from '../../std-lib/UiKit/Button'
import { primaryThemeFragment } from 'std-lib/Stylesheets/StyledVars/newVars'
import { NewYearDeco } from './assets/NewYear'
import {
  BottomCardTitle,
  ButtonFragment,
  ButtonWrapper,
  FactCard,
  ImageWrapper,
  LeftWrapper,
  SmallCardTitle,
  StartPageContainer,
  StartSubtitle,
  StartTitle,
} from '../Start/styled'
import ImgWithMaskAndCards from '../../Components/ImgWithMaskAndCards/ImgWithMaskAndCards'
import Image from './assets/StartImage.jpg'
import HeartImg from '../../Images/Static/heart.svg'
import Mask from './assets/layermask.svg'
import { GenericCard } from 'std-lib/Сomponents/GenericCard'
import Arrow from './../../Images/Static/rightArrow.svg'
import { MobilePortal } from 'Utils/PortalHelper'
import Flex from 'std-lib/FastUI/Components/Flex'
import { sendTypedEvent } from 'Utils/events/sendEvent'
import { startRequest } from 'Widgets/Start/API/API'
import { RoutesName } from 'router'
import NotificationsContext from '../Notifications/context'
import Rectangle from '../../std-lib/FastUI/Components/Rectangle'
import { css } from 'styled-components'

const Start = () => {
  const { setThemeFragment } = useContext(StyleContext)
  const { removeNotification } = useContext(NotificationsContext)
  const header = document.querySelector('#Header')

  useEffect(() => {
    if (header) header.setAttribute('style', 'max-width: 1440px; margin: 0 auto;')
    const scroll = Scroll.animateScroll
    scroll.scrollToTop()
    setThemeFragment(primaryThemeFragment)

    return () => {
      if (header) header.removeAttribute('style')
    }
  }, [setThemeFragment])

  const [trajectoryCount, setTrajectoryCount] = useState<number | null>(null)

  useEffect(() => {
    sendTypedEvent('visit', 'start_page')
    startRequest
      .getTrajectoryCount()
      .then(({ data: { count } }) => setTrajectoryCount(count))
  }, [])

  const navigate = useNavigate()

  return (
    <Flex justifyContent={'center'}>
      <Rectangle
        width={'100vw'}
        position={'absolute'}
        top={-80}
        mobile={{
          top: -50,
        }}
        fragment={css`overflow: hidden`}
      >
        <NewYearDeco />
      </Rectangle>
      <StartPageContainer>
        <LeftWrapper>
          <StartTitle>Создай себе персональную траекторию обучения</StartTitle>
          <StartSubtitle>
            Не знаешь, куда поступить? Не беда!
            <br />
            <br />
            ITMO TRACK с помощью уникального алгоритма поможет тебе не&nbsp;потеряться во
            всем разнообразии выбора.
          </StartSubtitle>
          <MobilePortal>
            <ButtonWrapper>
              <Button
                onClick={() => {
                  sendTypedEvent('click', 'start_page')
                  removeNotification()
                  navigate(RoutesName.EDUCATION_STAGE)
                }}
                styledFragment={ButtonFragment}
                rightIconSrc={Arrow}
              >
                Построить свою траекторию
              </Button>
            </ButtonWrapper>
          </MobilePortal>
        </LeftWrapper>
        <ImgWithMaskAndCards
          imageSrc={Image}
          maskSrc={Mask}
          initialSize={{ width: 600, height: 600 }}
          styledFragment={ImageWrapper}
        >
          <GenericCard
            bg={'#FCE8B9'}
            absoluteConfig={{ top: 0, left: 0 }}
            styledFragment={FactCard}
          >
            <SmallCardTitle>Построено траекторий</SmallCardTitle>
            {trajectoryCount ? (
              <BottomCardTitle>{trajectoryCount.toLocaleString('ru')}</BottomCardTitle>
            ) : (
              <></>
            )}
          </GenericCard>
          <GenericCard
            bg={'rgba(50, 50, 67, 1)'}
            absoluteConfig={{ bottom: 0, right: 0 }}
            styledFragment={FactCard}
          >
            <img src={HeartImg} alt={'сердечко'} />
            <SmallCardTitle style={{ color: 'rgba(252, 232, 185, 1)' }}>
              Создано студентами для студентов
            </SmallCardTitle>
          </GenericCard>
        </ImgWithMaskAndCards>
      </StartPageContainer>
    </Flex>
  )
}

export default Start
