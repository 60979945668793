import { AxiosInstance } from 'axios'
import { KeywordType } from 'std-lib/types'
import request from '../../../std-lib/API/request'
import { HobbyCategory } from 'Widgets/Onboarding/Slides/Hobbies'

export type Category = {
  id: number
  title: string
  slug: string
}

export type Profession = {
  id: number
  description: string
  visible_keywords: KeywordType[]
  domain: Category
  name: string
  icon: 'https://storage.yandexcloud.net/trajectories-media/media/icons/InformationSecurity.svg?AWSAccessKeyId=YCAJEGYLa2G2pNXrRvBEoin_j&Signature=Y4%2BCsx4%2Fe3KlNT%2FhT5dumOGhGLs%3D&Expires=1693299278'
  presets_blacklist: []
}

export interface CategoryDetails extends Category {
  description: string
}

export type Preset = {
  id: number
  category: string
  title: string
  tag: string
  group: string
  keywords: KeywordType
}

export type RawHobbyTag = {
  id: number
  text: string
  category: HobbyCategory
}

export const onboardingRequestConfig = (instance: AxiosInstance) => {
  return {
    getCategory: (id: number) => {
      return instance.get<CategoryDetails>(`domains/${id}`)
    },
    getInterestsTags: () => {
      return instance.get<RawHobbyTag[]>(`tags`).then(({ data }) =>
        data.map(({ id, category, text }) => {
          return {
            id,
            category,
            name: text,
          }
        }),
      )
    },
    getCategories: () => {
      return instance.get<Category[]>('domains')
    },
    getProfessions: (userType: 'bachelor' | 'master') => {
      return instance.get<Profession[]>(`professions/${userType}/`)
    },
    getPresets: (professionId: number) => {
      return instance.get<Preset[]>(`presets/?with_profession=${professionId}`)
    },
  }
}

export const onboardingRequest = onboardingRequestConfig(request)
