import styled, { css } from 'styled-components'

export const DefaultWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  width: max-content;
  background: #EBEBFD;
  color: #1F1F22;
  border-radius: 20px;
  transition: background 0.3s, color 0.3s;
  height: min-content;

  @media screen and (max-width: 1000px) {
    max-width: 100%;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 18px;
  }

  @media screen and (max-width: 375px) {
    max-width: 100%;
  }
`
export const ClickableWrapper = styled(DefaultWrapper)<{
  selected?: boolean
  isDisabled?: boolean
}>`
  cursor: pointer;

  ${({ selected, isDisabled }) =>
    !selected &&
    !isDisabled &&
    css`
        @media (hover: hover) {
          &:hover {
            background: ${({ theme }) => theme.color.background.tags.preset};
          }
        }


        &:active {
          background: #9480EF;
        }
      `}
}

${({ isDisabled, selected }) =>
  isDisabled &&
  !selected &&
  css`
      background: ${({ theme }) => theme.color.background.noStroke.default};
      color: ${({ theme }) => theme.color.text.tertiary};
    `}
${({ selected }) =>
  selected &&
  css`
      color: #FFFFFF;
      background: #9480EF;

      &:active {
        background: #712FD3;
      }

      &:hover {
        background: #7D6AD6;
      }
    `}

;

@media screen and(max-width: 1000px) {
  &:hover {
    background: #EBEBFD !important;
  }
}
`
