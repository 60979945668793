import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'
import { StyledMainSkeleton } from '../../Stylesheets/StyledVars/Skeleton'
import { makeEmptyArray } from '../../Extensions/array'

type SkeletonRowProps = {
  lineHeight?: number
  fontSize?: number
}

const SkeletonRow = styled.div<SkeletonRowProps>`
  width: 100%;
  height: ${({ fontSize = 14 }) => fontSize}px;
  margin: ${({ lineHeight = 21, fontSize = 14 }) => (lineHeight - fontSize) / 2}px 0;
  flex-shrink: 0;
  ${StyledMainSkeleton}
`

export const createSkeletonRows = (
  rowsCount: number,
  rowProps?: SkeletonRowProps & HTMLAttributes<HTMLDivElement>,
) =>
  makeEmptyArray(rowsCount).map((_, index) => <SkeletonRow key={index} {...rowProps} />)

export default SkeletonRow
