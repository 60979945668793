import { CategoryDetails } from 'Widgets/Onboarding/API/API'
import Image from 'Widgets/Onboarding/Slides/ProfessionCategory/assets/dontKnow.jpg'
import Business from './assets/Busines.jpg'
import Bio from './assets/Bio.jpg'
import Design from './assets/Design.jpg'
import Development from './assets/Development.jpg'
import Physics from './assets/Physics.jpg'

export const SamuraiCategory: CategoryDetails = {
  id: -1,
  title: '',
  slug: 'Не беда! У&nbsp;самурая нет&nbsp;цели, лишь&nbsp;путь',
  description:
    'Мы&nbsp;осознаем, насколько&nbsp;важным и&nbsp;непростым может быть&nbsp;выбор профессии. Следующим&nbsp;шагом вам предстоит сделать этот&nbsp;выбор, и мы&nbsp;хотим подчеркнуть — не&nbsp;стоит переживать. Открывайте для себя то, что&nbsp;кажется знакомым и&nbsp;близким к&nbsp;вашему сердцу. Важно не&nbsp;бояться первого&nbsp;шага, ведь даже если он&nbsp;оказался не&nbsp;совсем точным, он&nbsp;в&nbsp;любом случае приблизит вас к&nbsp;пониманию того, кем вы&nbsp;хотите стать. Успокойтесь и&nbsp;доверьтесь&nbsp;процессу.',
}

type CardContent = {
  smallText: string
  bigText: string
}

export type CategoryInfo = {
  slideTitle: string
  img?: string
  numberContent?: CardContent[]
}

export const categoryInfoByCategoryId: Record<string, CategoryInfo> = {
  'Я пока не решил': {
    slideTitle: `Не беда! Мы обязательно найдем решение`,
    img: Image,
    numberContent: [
      {
        bigText: '15000+',
        smallText: 'студентов',
      },
      {
        bigText: '45+',
        smallText: 'университетов-партнеров в&nbsp;мире',
      },
      {
        bigText: '90+',
        smallText: 'студенческих клубов по&nbsp;интересам',
      },
    ],
  },
  'Физика и инженерия': {
    slideTitle: 'Отличный выбор!',
    img: Physics,
    numberContent: [
      {
        bigText: '1',
        smallText: 'Топ 1% самых цитируемых публикаций по&nbsp;физике в&nbsp;мире',
      },
      {
        bigText: '3',
        smallText: 'Место по&nbsp;IT и&nbsp;робототехнике в&nbsp;рейтинге RAEX',
      },
      {
        bigText: '13',
        smallText: 'Международных исследовательских центров',
      },
    ],
  },
  'IT и программирование': {
    slideTitle: 'Отличный выбор!',
    img: Development,
    numberContent: [
      {
        bigText: '7',
        smallText: 'Побед на&nbsp;Чемпионате Мира по&nbsp;программированию',
      },
      {
        bigText: '№1',
        smallText:
          'Среди вузов СПб по&nbsp;зарплатам выпускников с&nbsp;235 тыс.&nbsp;рублей, 3-е по&nbsp;РФ',
      },
      {
        bigText: '49',
        smallText: 'Образовательных программ, связанных с&nbsp;IT',
      },
    ],
  },
  'Дизайн': {
    slideTitle: 'Отличный выбор!',
    img: Design,
    numberContent: [
      {
        bigText: '18',
        smallText: 'Компаний партнеров',
      },
      {
        bigText: '30+',
        smallText: 'Разработанных игр',
      },
      {
        bigText: '150+',
        smallText: 'Выпускников в школе разработке видеоигр',
      },
    ],
  },
  'Бизнес и управление': {
    slideTitle: 'Отличный выбор!',
    img: Business,
    numberContent: [
      {
        bigText: '7',
        smallText: 'Место в&nbsp;общем рейтинге российских вузов Forbes',
      },
      {
        bigText: '2000+',
        smallText: 'Заявок в&nbsp;Акселератор ИТМО',
      },
      {
        bigText: '320+',
        smallText: 'млн рублей размер фонда стартап-студии',
      },
    ],
  },
  'Науки о жизни': {
    slideTitle: 'Отличный выбор!',
    img: Bio,
    numberContent: [
      {
        bigText: '43.3%',
        smallText: 'среди всех публикаций scopus Q1',
      },
      {
        bigText: '50+',
        smallText: 'проектов в&nbsp;области foodtech',
      },
      {
        bigText: '3',
        smallText: 'Место в&nbsp;рейтингах центра «Эксперт» по&nbsp;наукам о&nbsp;жизни ',
      },
    ],
  },
}
