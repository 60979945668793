import { useUserData } from '../../Onboarding/UserDataStorage/UseUserData'
import React, { useContext, useEffect } from 'react'
import { RoutesName } from '../../../router'
import { Sections } from '../../Onboarding/Layouts/Step/onboradingRoutes/types'
import { useNavigate, useParams } from 'react-router-dom'
import ReturnToStepModal from '../Modals/returnToCorrectStep'
import NotificationsContext from '../../Notifications/context'
import ModalContext from '../../../std-lib/Сontext/Modal'
import { onboardingRoutes } from '../../Onboarding/Layouts/Step/onboradingRoutes/onboardingRoutes'

const useCheckStep = () => {
  const { closeModal } = useContext(ModalContext)
  const navigate = useNavigate()
  const { getUserData, updateUserDataWith } = useUserData()
  const { displayModal } = useContext(ModalContext)
  const { displayNotification, removeNotification } = useContext(NotificationsContext)
  const params = useParams()
  const sectionNameParam = params.section as Sections
  const stepNumberParam = Number(params.stepNumber)
  const educationStage = getUserData().userType ?? 'bachelor'

  const windowLocation = window.location.pathname

  const returnToCorrectStep = () => {
    const userData = getUserData()
    const stepFromState = userData.step
    const sectionFromState = userData.section
    removeNotification()
    closeModal()
    if (stepFromState === 0 && sectionFromState === Sections.INTRO) {
      navigate(RoutesName.EDUCATION_STAGE)
      return
    }
    navigate(`/step/${sectionFromState}/${stepFromState}`)
  }

  const checkIsStepCorrect = () => {
    const { step, section } = getUserData()
    if (windowLocation.includes('step') && !windowLocation.includes('step/profile')) {
      if (step !== stepNumberParam || section !== sectionNameParam) {
        const route = onboardingRoutes[educationStage]
        const sectionFromUserData = route[section]

        if (
          stepNumberParam == step - 1 ||
          (sectionNameParam === sectionFromUserData.prev &&
            step === sectionFromUserData.steps.length - 1) ||
          stepNumberParam == step + 1 ||
          (sectionNameParam === sectionFromUserData.next &&
            step === sectionFromUserData.steps.length + 1)
        ) {
          updateUserDataWith({ step: stepNumberParam, section: sectionNameParam })
          return true
        }
        return false
      }
    }
    return true
  }

  useEffect(() => {
    const isStepCorrect = checkIsStepCorrect()
    if (!isStepCorrect && windowLocation !== RoutesName.START) {
      displayModal(<ReturnToStepModal returnToCorrectStep={returnToCorrectStep} />, {
        unclosableWithoutAction: true,
      })
    }
  }, [windowLocation])

  return { checkIsStepCorrect }
}

export default useCheckStep
