import React from 'react';

const SystemArchitect = () => {
	return (
		<svg width="113" height="148" viewBox="0 0 113 148" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="6.45" y="25.45" width="71.1" height="65.1" fill="#C1D5F6" stroke="#1F1F22" strokeWidth="0.9"></rect>
			<path d="M11 42V31H73V42H11Z" fill="white" stroke="#1F1F22" strokeWidth="0.9" strokeLinecap="round"></path>
			<path d="M11 56V45H73V56H11Z" fill="white" stroke="#1F1F22" strokeWidth="0.9" strokeLinecap="round"></path>
			<path d="M11 70V59H73V70H11Z" fill="white" stroke="#1F1F22" strokeWidth="0.9" strokeLinecap="round"></path>
			<path d="M11 84V73H73V84H11Z" fill="white" stroke="#1F1F22" strokeWidth="0.9" strokeLinecap="round"></path>
			<path d="M52 48V37H107V48H52Z" fill="white" stroke="#1F1F22" strokeWidth="0.9" strokeLinecap="round"></path>

			<path d="M55.5 39V46.5H72V39H55.5Z" fill="#C1D5F6"></path>
			<g className="svgAnime matrix(0.5,0,0,1,-4.125,0) n0">
				<path d="M55.5 39V46.5H72V39H55.5Z" fill="#677CE8"></path>
			</g>

			<path
				d="M74.5 39V45M77 47V43M77 40.5V39M79.5 39V43M79.5 45V46.5M82 39V44M84.5 47V44M84.5 42V39M87.5 39V43M87.5 45V47M55.5 39V46.5H72V39H55.5Z"
				stroke="#1F1F22" strokeWidth="0.9" strokeLinecap="round"></path>
			<path d="M52 62V51H107V62H52Z" fill="white" stroke="#1F1F22" strokeWidth="0.9" strokeLinecap="round"></path>

			<path d="M55.5 53V60.5H72V53H55.5Z" fill="#677CE8"></path>

			<g className="svgAnime matrix(0.001,0,0,1,8.25,0) n1">
				<path d="M55.5 53V60.5H72V53H55.5Z" fill="#C1D5F6"></path>
			</g>

			<path
				d="M74.5 53V59M77 61V57M77 54.5V53M79.5 53V57M79.5 59V60.5M82 53V58M84.5 61V58M84.5 56V53M87.5 53V57M87.5 59V61M55.5 53V60.5H72V53H55.5Z"
				stroke="#1F1F22" strokeWidth="0.9" strokeLinecap="round"></path>
			<path d="M52 76V65H107V76H52Z" fill="white" stroke="#1F1F22" strokeWidth="0.9" strokeLinecap="round"></path>

			<path d="M55.5 67V74.5H72V67H55.5Z" fill="#C1D5F6"></path>

			<g className="svgAnime matrix(2.538,0,0,1,5,0) n2">
				<path d="M 55.5 67 V 74.5 H 62 V 67 H 55.5 Z" fill="#677CE8"></path>
			</g>

			<path
				d="M74.5 67V73M77 75V71M77 68.5V67M79.5 67V71M79.5 73V74.5M82 67V72M84.5 75V72M84.5 70V67M87.5 67V71M87.5 73V75M55.5 67V74.5H72V67H55.5Z"
				stroke="#1F1F22" strokeWidth="0.9" strokeLinecap="round"></path>
			<path d="M104.5 37V15H26V25" stroke="#1F1F22" strokeWidth="0.9" strokeLinecap="round"></path>


			{/* first rect with twinkles*/}
			{/* background twinkles*/}
			<circle cx="17" cy="37" r="1.55" fill="#677CE8" stroke="#1F1F22" strokeWidth="0.9"></circle>
			<circle cx="31" cy="37" r="1.55" fill="#C1D5F6" stroke="#1F1F22" strokeWidth="0.9"></circle>

			{/* other twinkles*/}
			<circle cx="24" cy="37" r="1.55" fill="#677CE8" stroke="#1F1F22" strokeWidth="0.9"></circle>

			{/* animated twinkles*/}
			<g className="svgAnime scale(0) n3">
				<circle cx="17" cy="37" r="1.55" fill="#C1D5F6" stroke="#1F1F22" strokeWidth="0.9"></circle>
			</g>
			<g className="svgAnime scale(0) n4">
				<circle cx="31" cy="37" r="1.55" fill="#677CE8" stroke="#1F1F22" strokeWidth="0.9"></circle>
			</g>


			<circle cx="17" cy="51" r="1.55" fill="#C1D5F6" stroke="#1F1F22" strokeWidth="0.9"></circle>
			<circle cx="24" cy="51" r="1.55" fill="#677CE8" stroke="#1F1F22" strokeWidth="0.9"></circle>

			<g className="svgAnime scale(0) n5">
				<circle cx="17" cy="51" r="1.55" fill="#677CE8" stroke="#1F1F22" strokeWidth="0.9"></circle>
			</g>
			<g className="svgAnime scale(0) n6">
				<circle cx="24" cy="51" r="1.55" fill="#C1D5F6" stroke="#1F1F22" strokeWidth="0.9"></circle>
			</g>

			<circle cx="31" cy="51" r="1.55" fill="#C1D5F6" stroke="#1F1F22" strokeWidth="0.9"></circle>


			<circle cx="17" cy="65" r="1.55" fill="#C1D5F6" stroke="#1F1F22" strokeWidth="0.9"></circle>

			<circle cx="24" cy="65" r="1.55" fill="#C1D5F6" stroke="#1F1F22" strokeWidth="0.9"></circle>
			<circle cx="31" cy="65" r="1.55" fill="#677CE8" stroke="#1F1F22" strokeWidth="0.9"></circle>

			<g className="svgAnime scale(0) n7">
				<circle cx="24" cy="65" r="1.55" fill="#677CE8" stroke="#1F1F22" strokeWidth="0.9"></circle>
			</g>
			<g className="svgAnime scale(0) n8">
				<circle cx="31" cy="65" r="1.55" fill="#C1D5F6" stroke="#1F1F22" strokeWidth="0.9"></circle>
			</g>

			<circle cx="17" cy="79" r="1.55" fill="#677CE8" stroke="#1F1F22" strokeWidth="0.9"></circle>

			<circle cx="24" cy="79" r="1.55" fill="#C1D5F6" stroke="#1F1F22" strokeWidth="0.9"></circle>
			<g className="svgAnime scale(0) n9">
				<circle cx="24" cy="79" r="1.55" fill="#677CE8" stroke="#1F1F22" strokeWidth="0.9"></circle>
			</g>
			<circle cx="31" cy="79" r="1.55" fill="#677CE8" stroke="#1F1F22" strokeWidth="0.9"></circle>

			<g className="svgAnime rotate(150deg) n10">
				<path
					d="M76.5 103C73.3333 100 64.5 95.2173 54.5 104.017C50.1 108.017 50.6667 113.684 51.5 116.017H53.5L51.5 122.017L46 117.017L47 116.517C46.1667 111.517 47.5 101.017 57 96.5173C69.1669 90.7539 76 96.0173 82 101.017M57.5 126.517C61.6667 129.017 71.6 132.117 78 124.517C83.2 118.917 82.5 112.517 81.5 110.017L80.5 110.517L81.5 104.017L87 108.517L85.5 109.017C87 111.684 88 121 80 129.017C71.1732 137.863 58 132.684 52 128.517"
					stroke="#1F1F22" strokeWidth="0.9" strokeLinecap="round"></path>
			</g>
		</svg>
	);
};

export default SystemArchitect;