import React from 'react'
import Flex from 'std-lib/FastUI/Components/Flex'
import PercentProgress from 'std-lib/UiKit/PercentProgress'
import Text from 'std-lib/FastUI/Components/Text'
import Button from '../../../../std-lib/UiKit/Button'
import Rectangle from '../../../../std-lib/FastUI/Components/Rectangle'
import { css } from 'styled-components'
import { makeAbitLink } from '../../../../Utils/makeAbitLink'
import { useUserData } from '../../../Onboarding/UserDataStorage/UseUserData'
import Tooltip from '../../../../std-lib/Сomponents/Tooltip'
import { sendTypedEvent } from '../../../../Utils/events/sendEvent'
import * as amplitude from '@amplitude/analytics-browser'
import Info from '../../assets/info.svg'
import Planet from '../../assets/planet.svg'

import Img from '../../../../std-lib/FastUI/Components/Img'

type TrajectoryInfoBlockProps = {
  coverage: number
  OPName: string
  code: string
  id: number
  abitLink: string
  isTaughtInEnglish: boolean
  isTaughtInRussian: boolean
}

const TrajectoryInfoBlock = ({
  coverage,
  OPName,
  code,
  id,
  abitLink,
  isTaughtInEnglish,
  isTaughtInRussian,
}: TrajectoryInfoBlockProps) => {
  const { getUserData } = useUserData()

  const isMaster = getUserData().userType === 'master'

  const languages = [isTaughtInEnglish && 'английский', isTaughtInRussian && 'русский']
    .filter(Boolean)
    .join(', ')

  return (
    <Flex direction={'column'} gap={44} mobile={{ fragment: css`width: 100%`, gap: 18 }}>
      <Flex
        direction={'row'}
        gap={30}
        alignItems={'center'}
        mobile={{ fragment: css`justify-content: center` }}
      >
        <Rectangle
          borderRadius={'14px'}
          padding={'12px'}
          background={'#F4F4FB'}
          mobile={{ make: 'hidden' }}
        >
          <PercentProgress percent={coverage} />
        </Rectangle>
        <Text
          size={20}
          mobile={{
            size: 14,
            align: 'center',
          }}
          fragment={css`
              @media screen and (max-width: 1000px) {
                  max-width: 160px;
              }
          `}
        >
          Тебе на<b>{` ${Math.floor(coverage * 100)}% `}</b>
          подходит направление
        </Text>
      </Flex>
      <Flex
        direction={'column'}
        gap={28}
        style={{ paddingBottom: 24 }}
        mobile={{ gap: 12 }}
      >
        <Text
          size={46}
          weight={'bolder'}
          font={'ALS Gorizont'}
          style={{ maxWidth: 672 }}
          mobile={{ size: 24 }}
        >
          {OPName}
        </Text>
        <Flex direction={'column'} gap={8}>
          <Flex
            direction={'row'}
            gap={16}
            alignItems={'center'}
            mobile={{
              gap: 12,
            }}
          >
            <Tooltip
              triggerElement={<Img src={Info} />}
              tooltipContent={
                <Rectangle width={300}>
                  <Text size={16} weight={400} color={'#fff'}>
                    Это код направления подготовки
                  </Text>
                </Rectangle>
              }
            />
            <Text size={20} mobile={{ size: 14 }}>
              {code}
            </Text>
          </Flex>
          {isMaster && (
            <Flex
              direction={'row'}
              gap={16}
              alignItems={'center'}
              mobile={{
                gap: 12,
              }}
            >
              <Img src={Planet} />

              <Text size={20} style={{ maxWidth: 428 }} mobile={{ size: 14 }}>
                Язык преподавания - <b>{languages}</b>
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
      <Rectangle mobile={{ make: 'hidden' }}>
        <Button
          onClick={() => {
            sendTypedEvent('click', 'go_to_abit', 'trajectory')
            const identify = new amplitude.Identify().add('viewd_trajectory_count', 1)
            amplitude.identify(identify)
            window.open(makeAbitLink(abitLink, id), '_blank')
          }}
          styledFragment={css`
              font-size: 20px;
              font-weight: 600;
              padding: 16px 40px;
              height: 60px;
              border-radius: 40px;
          `}
        >
          Присоединиться к ИТМО
        </Button>
      </Rectangle>
    </Flex>
  )
}

export default TrajectoryInfoBlock
