import React from 'react';

const FoodIndustryTechnologist = () => {
	return (
		<svg width="113" height="148" viewBox="0 0 113 148" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g className="svgAnime rotate(24.26deg) n0">
				<path
					d="M25.4811 127.374C26.1243 129.837 28.9318 134.277 35.0159 132.334C41.1 130.391 40.8146 125.146 39.9113 122.766L35.0838 107.648C28.3086 106.35 23.3094 112.273 21.6567 115.397L25.4811 127.374Z"
					fill="#73DD44"></path>
				<path
					d="M18.4069 55.4218L3.97664 60.0297L21.6567 115.397C23.3094 112.273 28.3086 106.35 35.0838 107.648L18.4069 55.4218Z"
					fill="white"></path>
				<path
					d="M2.72274 56.103C0.0540969 58.5129 2.44674 59.7249 3.97664 60.0297L18.4069 55.4218C20.7134 52.9545 18.532 51.7759 17.153 51.4951L2.72274 56.103Z"
					fill="white"></path>
				<path
					d="M3.97664 60.0297C2.44674 59.7249 0.0540969 58.5129 2.72274 56.103M3.97664 60.0297C7.4217 58.9296 15.1308 56.4679 18.4069 55.4218M3.97664 60.0297L21.6567 115.397M3.97664 60.0297L18.4069 55.4218M2.72274 56.103C6.1548 55.007 13.7729 52.5744 17.153 51.4951M2.72274 56.103L17.153 51.4951M17.153 51.4951C18.532 51.7759 20.7134 52.9545 18.4069 55.4218M18.4069 55.4218L35.0838 107.648M21.6567 115.397L25.4811 127.374C26.1243 129.837 28.9318 134.277 35.0159 132.334C41.1 130.391 40.8146 125.146 39.9113 122.766L35.0838 107.648M21.6567 115.397C23.3094 112.273 28.3086 106.35 35.0838 107.648"
					stroke="#1F1F22" strokeWidth="0.9" strokeLinecap="round"></path>
			</g>

			<rect x="37.45" y="11.45" width="74.1" height="99.1" stroke="#1F1F22" strokeWidth="0.9"></rect>

			<g className="svgAnime rotate(-26.47deg) n1">
				<path
					d="M18.0158 81.1302C17.4494 83.7526 17.9462 89.1798 24.4643 89.9097C30.9824 90.6396 33.1173 86.0317 33.37 83.6366L37.0535 61.4751C35.3449 69.2154 25.2879 67.7686 20.4729 66.0777L18.0158 81.1302Z"
					fill="#73DD44"></path>
				<path
					d="M30.069 7.29135C26.624 8.06553 28.1909 10.3261 29.4049 11.3596L44.9612 13.899C47.5981 12.9929 46.5026 10.8093 45.6253 9.83071L30.069 7.29135Z"
					fill="white"></path>
				<path
					d="M44.9612 13.899L29.4049 11.3596L20.4729 66.0777C25.2879 67.7686 35.3449 69.2154 37.0535 61.4751L44.9612 13.899Z"
					fill="white"></path>
				<path
					d="M29.4049 11.3596C28.1909 10.3261 26.624 8.06553 30.069 7.29135M29.4049 11.3596C33.3782 12.0082 42.052 13.4241 44.9612 13.899M29.4049 11.3596L20.4729 66.0777M29.4049 11.3596L44.9612 13.899M30.069 7.29135C32.3317 7.66071 41.3827 9.13816 45.6253 9.83071M30.069 7.29135L45.6253 9.83071M45.6253 9.83071C46.5026 10.8093 47.5981 12.9929 44.9612 13.899M44.9612 13.899L37.0535 61.4751M20.4729 66.0777L18.0158 81.1302C17.4494 83.7526 17.9462 89.1798 24.4643 89.9097C30.9824 90.6396 33.1173 86.0317 33.37 83.6366L37.0535 61.4751M20.4729 66.0777C25.2879 67.7686 35.3449 69.2154 37.0535 61.4751"
					stroke="#1F1F22" strokeWidth="0.9" strokeLinecap="round"></path>
			</g>

			<circle cx="74" cy="42" r="23.55" fill="#73DD44" stroke="#1F1F22" strokeWidth="0.9"></circle>

			<g className="svgAnime rotate(92deg) n2">
				<path
					d="M74.033 74.8519C79.6171 82.1436 78.0621 92.7414 70.5194 98.5177C62.9767 104.294 52.3399 103.033 46.7558 95.7413C41.1717 88.4496 42.7268 77.8518 50.2695 72.0755C57.8121 66.2992 68.4489 67.5602 74.033 74.8519ZM68.7558 96.2148C74.9548 91.4675 76.263 82.7266 71.6374 76.6866C67.0118 70.6465 58.2321 69.631 52.0331 74.3784C45.834 79.1257 44.5259 87.8666 49.1515 93.9067C53.7771 99.9467 62.5568 100.962 68.7558 96.2148Z"
					fill="#C6F0B3" stroke="#1F1F22" strokeWidth="0.9"></path>
				<path
					d="M70.3189 99.2382L80.3181 110.329L104.548 136.02C110.957 138.965 110.04 133.654 108.78 130.631L83.1433 103.477C79.495 99.51 74.5264 94.2503 74.2161 94.7278C73.8016 95.3655 71.7571 98.0038 70.3189 99.2382Z"
					fill="#C6F0B3" stroke="#1F1F22" strokeWidth="0.9"></path>
			</g>

			<path d="M65 40L62 44L72.5 53.5L86.5 32.5L82 30L71.5 45.5L65 40Z" fill="white" stroke="#1F1F22" strokeWidth="0.9"
			      strokeLinecap="round"></path>
		</svg>
	);
};

export default FoodIndustryTechnologist;