import { createContext, Dispatch } from 'react'

export type ButtonContextState = {
  disabled?: boolean
  link?: string
}

type NavContext = {
  setForwardState: Dispatch<React.SetStateAction<ButtonContextState | undefined>>
  setBackwardState: Dispatch<React.SetStateAction<ButtonContextState | undefined>>
}

export const NavContext = createContext<NavContext>({
  setForwardState: () => null,
  setBackwardState: () => null,
})
