import styled from 'styled-components'
import PercentProgressGrill from './PercentProgressGrill.svg'

const PercentProgress = styled.div<{ percent: number }>`
  @keyframes progress-bar-appear {
    from{
      transform: scaleX(0);
    }
    to {
      transform: scaleX(1);
    }
  }

  transition: all 0.3s;
  width: 33px;
  height: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #B7B7B8;
  border-radius: 0px;

  mask: url(${PercentProgressGrill});

  &:before {
    content: '';
    transform-origin: left center;
    position: absolute;
    top: 0px;
    left: 0px;
    width: ${({ percent }) => `${34 * percent}px`};
    height: 100%;
    transition: all 0.3s;
    background: #8533FF;
    z-index: 1;
    animation: progress-bar-appear 0.3s ease-in-out;

  }
`

export default PercentProgress
