import React from 'react'
import { IconType, RotateType } from '../../../std-lib/Images/icons/types'

const Chevron = (props: IconType & RotateType) => {
  const { rotate = 0, width = 6, height = 12, primaryColor = '#6E6D79' } = props

  const style: React.CSSProperties = {
    transition: 'rotate .3s',
    transform: `rotate(${rotate}deg)`,
    stroke: primaryColor,
    pointerEvents: 'none',
  }

  const viewBox =
    width > 6 && height > 12 ? () => `0 0 ${width} ${height}` : () => `0 0 6 12`
  return (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox={viewBox()}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 11L5 6L1 1"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default Chevron
