import React, { useContext, useEffect, useState } from 'react'
import CommonSlideLayout from '../../Components/CommonSlideLayout'
import ImgWithMaskAndCards from '../../../../Components/ImgWithMaskAndCards/ImgWithMaskAndCards'
import Input from '../../../../std-lib/UiKit/Input'
import Image from './assets/Pictures.jpg'
import Mask from './assets/mask.svg'
import { NavContext } from 'Context/NavContext'
import StepContentLayout from '../../Layouts/Step/components/StepContentLayout'
import styled, { css } from 'styled-components'
import { useUserData } from '../../UserDataStorage/UseUserData'

const ImageWrapper = css`
  flex-shrink: 1;
  min-width: 250px;

  @media screen and (max-width: 1000px) {
    width: 100%;
    margin: 0 auto;
  }
`

const StyledText = styled.span`
  font-family: 'Golos', sans-serif;
  font-size: 20px;
  font-weight: normal;
  line-height: normal;
  margin-bottom: 40px;

  @media screen and (max-width: 1000px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 16px;
    display: inline-block;
  }
`

const StyledNameInput = styled(Input)`
  width: 480px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  @media screen and (max-width: 1000px) {
    width: 100%;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    border-radius: 22px;
  }
`

export const InputLabel = styled.label`
  margin-top: 40px;
  font-size: 20px;
  line-height: 28px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media screen and (max-width: 1000px) {
    margin-top: 0;
    font-size: 16px;
    line-height: 24px;
  }
`

const NameQuestion = () => {
  const { updateUserDataWith, getUserData } = useUserData()
  const [name, setName] = useState<string | undefined>(getUserData().name)

  useEffect(() => {
    let newName = name
    if (newName && newName[0].toUpperCase() !== newName[0]) {
      newName = newName[0].toUpperCase() + newName.slice(1)
    }
    updateUserDataWith({ name: newName })
  }, [name])

  const { setForwardState } = useContext(NavContext)

  useEffect(() => {
    setForwardState({ disabled: !name })
  }, [name, setForwardState])

  return (
    <StepContentLayout title="Давай познакомимся!">
      <CommonSlideLayout gap={'48px'}>
        <div>
          <StyledText>
            ITMO TRACK&nbsp;— твой помощник в&nbsp;выборе образовательной программы.
            <br />
            <br />
            Мы&nbsp;зададим тебе несколько вопросов, чтобы лучше узнать тебя. Это поможет
            нам создать персонализированную траекторию, которая будет идеально подходить
            именно для&nbsp;тебя.
          </StyledText>

          <InputLabel>
            Как тебя зовут?
            <StyledNameInput
              placeholder={'Анастасия'}
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </InputLabel>
        </div>
        <ImgWithMaskAndCards
          initialSize={{ width: 500, height: 398 }}
          imageSrc={Image}
          maskSrc={Mask}
          styledFragment={ImageWrapper}
        />
      </CommonSlideLayout>
    </StepContentLayout>
  )
}

export default NameQuestion
