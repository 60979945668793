import styled, { css } from 'styled-components'

export const LoaderWrapper = styled.div<{ isAnimationFinish: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 40px;
  width: 670px;
  height: min-content;
  background: #FFFFFF;
  padding: 100px;
  overflow: hidden;
  border-radius: 40px;
  box-shadow: 4px 4px 76px 0 rgba(97, 120, 201, 0.20);

  @media screen and (max-width: 630px) {
    top: calc(50% - 30px);
    padding: 24px 16px;
    box-shadow: none;
    gap: 20px;
    width: 100%;
    height: min-content;
  }
`

export const TitleWrapper = styled.div<{ isAnimationFinish: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 1;
  transition: opacity 0.1s ease-out;
  transition-delay: 0.5s;

  ${({ isAnimationFinish }) =>
    isAnimationFinish &&
    css`
            opacity: 0;
          `};
`

export const Title = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  color: ${({ theme }) => theme.color.text.primary};
  max-width: 366px;

  @media screen and (max-width: 630px) {
    font-size: 20px;
    line-height: 28px;
  }
`

export const ProgressBarsWrapper = styled.div<{ isAnimationFinish: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 32px;

  opacity: 1;
  transition: opacity 0.1s ease-out;
  transition-delay: 0.5s;

  ${({ isAnimationFinish }) =>
    isAnimationFinish &&
    css`
            opacity: 0;
          `};
`

export const ProgressBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
`

export const ProgressBarText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

`

export const ProgressBarPhrase = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1F1F22;
`

export const ProgressBarPercent = styled.div`
  height: 20px;
  width: 100%;
  max-width: 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1F1F22;
`

export const CheckMarkWrapper = styled.div`
  width: 35px;
  height: 20px;
  text-align: end;
  animation: rollup 0.3s ease-in;

  @keyframes rollup {
    from {
      transform: rotateX(90deg) translateY(10px) scaleX(1.1);
    }
    50% {
      transform: rotateX(-10deg) translateY(-2px) scaleX(1);
    }
    to {
      transform: rotateX(0deg) translateY(0) scaleX(1);
    }
  }
`

export const ProgressBarLineWrapper = styled.div`
  position: relative;
`
export const ProgressBarLine = styled.div`
  width: 100%;
  height: 4px;
  background: rgba(120, 120, 128, 0.16);
  border-radius: 100px;
`
export const ProgressBarLineGradient = styled.div<{ width: number; isDisabled: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  width: ${p => p.width}%;
  border-radius: 100px;
  background: ${p =>
    p.isDisabled
      ? '#712FD3'
      : 'linear-gradient(90deg, #712FD3 0%, #B280FF 82.18%, #D8BEFF 194.79%);'};
  transition: all 0.4s ease-in-out;
`
