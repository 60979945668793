import React, { MouseEventHandler } from 'react'
import { ButtonCard, CardImg, Subtitle, TextWrapper, Title } from './styled'

type ImageButtonCardProps = {
  src?: string
  title: string
  subtitle?: string
  selected?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
}

const CategoryCard = ({
  src,
  title,
  subtitle,
  onClick,
  selected,
}: ImageButtonCardProps) => {
  return (
    <ButtonCard onClick={onClick} selected={selected}>
      <TextWrapper>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </TextWrapper>
      <CardImg src={src} alt={title} />
    </ButtonCard>
  )
}

export default CategoryCard
