import React from 'react'
import { IconType } from '../../../std-lib/Images/icons/types'

const IconDoubleArrow = (props: IconType) => {
  const { width = 24, height = 24, viewBox = '0 0 24 24' } = props
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Arrows">
        <path
          id="Path (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.21967 7.21967C6.51256 6.92678 6.98744 6.92678 7.28033 7.21967L11.2803 11.2197C11.5732 11.5126 11.5732 11.9874 11.2803 12.2803L7.28033 16.2803C6.98744 16.5732 6.51256 16.5732 6.21967 16.2803C5.92678 15.9874 5.92678 15.5126 6.21967 15.2197L9.68934 11.75L6.21967 8.28033C5.92678 7.98744 5.92678 7.51256 6.21967 7.21967Z"
          fill="#79797A"
        />
        <path
          id="Path (Stroke)_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.7197 7.21967C13.0126 6.92678 13.4874 6.92678 13.7803 7.21967L17.7803 11.2197C18.0732 11.5126 18.0732 11.9874 17.7803 12.2803L13.7803 16.2803C13.4874 16.5732 13.0126 16.5732 12.7197 16.2803C12.4268 15.9874 12.4268 15.5126 12.7197 15.2197L16.1893 11.75L12.7197 8.28033C12.4268 7.98744 12.4268 7.51256 12.7197 7.21967Z"
          fill="#79797A"
        />
      </g>
    </svg>
  )
}

export default IconDoubleArrow
