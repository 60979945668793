import React, { ReactNode, useState } from 'react'
import Rectangle from 'std-lib/FastUI/Components/Rectangle'
import { NotificationConfig } from './types'
import NotificationCard from './Components/notification'
import NotificationsContext from './context'
import { css } from 'styled-components'
import { useOutlet } from 'react-router'

type NotificationsWrapperProps = {
  children: ReactNode
}

const NotificationsWrapper = ({ children }: NotificationsWrapperProps) => {
  const [notificationConfig, setNotificationConfig] =
    useState<NotificationConfig | null>()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const outlet = useOutlet()

  const displayNotification = (notificationConfig: NotificationConfig) => {
    setNotificationConfig(notificationConfig)
    setIsOpen(true)
  }

  const closeNotification = () => {
    setIsOpen(false)
    setTimeout(() => {
      setNotificationConfig(null)
    }, 10000)
  }

  const removeNotification = () => {
    setNotificationConfig(null)
    setIsOpen(false)
  }

  return (
    <NotificationsContext.Provider
      value={{
        displayNotification,
        closeNotification,
        removeNotification,
      }}
    >
      {notificationConfig && (
        <Rectangle
          position={'absolute'}
          top={32}
          padding={16}
          right={0}
          width={600}
          desktop={{ padding: '16px 60px 16px 16px' }}
          background={'transparent'}
          height={'max-content'}
          overflow={'hidden'}
          fragment={css`
            z-index: 1;
            max-width: 500px;

          `}
          mobile={{
            width: '100vw',
          }}
        >
          <Rectangle
            borderRadius={16}
            width={'100%'}
            right={!isOpen ? 'calc(-100% - 64px)' : '0px'}
            fragment={css`
              transition: all 0.8s;
              display: flex;
              justify-content: flex-end;
            `}
            mobile={{
              right: !isOpen ? 'calc(-100% - 16px)' : '0px',
            }}
          >
            <NotificationCard {...notificationConfig} />
          </Rectangle>
        </Rectangle>
      )}
      {children ? children : outlet}
    </NotificationsContext.Provider>
  )
}

export default NotificationsWrapper
