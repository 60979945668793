import { css } from 'styled-components'

export const hoverOnboardingCard = css`
  background: ${({ theme }) => theme.color.background.noStroke.default};
  border-color: ${({ theme }) => theme.color.elements.stroke.hover};
  span {
    color: ${({ theme }) => theme.color.states.primary.default}};
}
`

export const selectedOnboardingCard = css`
  background: ${({ theme }) => theme.color.background.noStroke.default};
  border-color: ${({ theme }) => theme.color.elements.stroke.active};
  span {
    color: ${({ theme }) => theme.color.states.primary.active}};
}
`
