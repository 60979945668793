import React, { useRef, useState } from 'react'
import {
  autoUpdate,
  size,
  flip,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  FloatingFocusManager,
  FloatingPortal,
} from '@floating-ui/react'
import {
  AutocompleteInput,
  NotFoundWrapper,
  OptionsListWrapper,
  Option,
} from 'std-lib/UiKit/Autocomplete/styled'
import MagnifierIcon from 'Images/icons/magnifier/preview.svg'

interface AutocompleteProps<T> {
  options: T[]
  getOptionLabel: (value: T) => { title: string; subtitle?: string }
  onSelect: (value: T | undefined) => void
  value: T | undefined
}

export const AutoComplete = <T extends { id: number | string }>({
  options,
  getOptionLabel,
  onSelect,
  value,
}: AutocompleteProps<T>) => {
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [activeIndex, setActiveIndex] = useState<number | null>(null)

  const listRef = useRef<Array<HTMLElement | null>>([])

  const { refs, floatingStyles, context } = useFloating<HTMLInputElement>({
    whileElementsMounted: autoUpdate,
    open,
    onOpenChange: setOpen,
    middleware: [
      flip({ padding: 10 }),
      size({
        apply({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${availableHeight}px`,
          })
        },
        padding: 10,
      }),
    ],
  })

  const role = useRole(context, { role: 'listbox' })
  const dismiss = useDismiss(context)
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    virtual: true,
    loop: true,
  })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    role,
    dismiss,
    listNav,
  ])

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    onSelect(undefined)
    const value = event.target.value
    setInputValue(value)

    if (value) {
      setOpen(true)
      setActiveIndex(0)
    } else {
      setOpen(false)
    }
  }

  const items = options
    .filter(item =>
      getOptionLabel(item).title.toLowerCase().startsWith(inputValue.toLowerCase()),
    )
    .slice(0, 3)

  return (
    <>
      <AutocompleteInput
        withPopup={open}
        {...getReferenceProps({
          ref: refs.setReference,
          onChange,
          value: value ? getOptionLabel(value).title : inputValue,
          placeholder: 'Тюмень',
          'aria-autocomplete': 'list',
          onKeyDown(event) {
            if (event.key === 'Enter' && activeIndex != null && items[activeIndex]) {
              setInputValue(getOptionLabel(items[activeIndex]).title)
              setActiveIndex(null)
              setOpen(false)
              onSelect(items[activeIndex])
            }
          },
        })}
      />
      <FloatingPortal>
        <FloatingFocusManager context={context} initialFocus={-1} visuallyHiddenDismiss>
          <OptionsListWrapper
            {...getFloatingProps({
              ref: refs.setFloating,
              style: floatingStyles,
            })}
            isOpen={open}
          >
            {items.map((item, index) => (
              <Option
                {...getItemProps({
                  ref(node) {
                    listRef.current[index] = node
                  },
                  onClick() {
                    onSelect(item)
                    setInputValue(getOptionLabel(item).title)
                    setOpen(false)
                    refs.domReference.current?.focus()
                  },
                })}
                key={item.id}
                active={activeIndex === index}
              >
                <span>{getOptionLabel(item).title}</span>
                {getOptionLabel(item).subtitle && (
                  <span> {getOptionLabel(item).subtitle}</span>
                )}
              </Option>
            ))}

            {items.length === 0 && open && (
              <NotFoundWrapper>
                <img src={MagnifierIcon} alt="icon" />
                Извини, у нас в базе нет такого города..
                <br />
                Попробуй выбрать ближайший к тебе крупный город
              </NotFoundWrapper>
            )}
          </OptionsListWrapper>
        </FloatingFocusManager>
      </FloatingPortal>
    </>
  )
}
