import styled from 'styled-components'
import React from 'react'
import Logo from '../../Images/Static/logo'
import { useWindowDimensions } from '../../std-lib/Extensions/window'

const StyledErrorWrapper = styled.div<{ width: number; height: number }>`
  padding: 0 40px;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`
const StyledErrorHeader = styled.div<{ width: number }>`
  position: sticky;
  top: 0;
  width: 100%;
  height: 64px;
  padding: 12px 0 0;
  display: flex;
  justify-content: center;
`

const StyledLogoWrapper = styled.a`
  z-index: 100;
  transition: right .3s;
  position: absolute;
  right: 0;

  @media screen and (max-width: 500px) {
    right: calc(50% - 37px);
  }
`

type ErrorLayoutProps = {
  children: React.ReactNode
}

const ErrorLayout = ({ children }: ErrorLayoutProps) => {
  const { width, height } = useWindowDimensions()

  return (
    <StyledErrorWrapper width={width} height={height}>
      <StyledErrorHeader width={width}>
        <StyledLogoWrapper href={'/'}>
          <Logo />
        </StyledLogoWrapper>
      </StyledErrorHeader>
      {children}
    </StyledErrorWrapper>
  )
}

export default ErrorLayout
