import React from 'react'
import { ProgressesWrapper, StyledProgress } from './styled'
import {
  Section,
  Sections,
  SectionsType,
} from 'Widgets/Onboarding/Layouts/Step/onboradingRoutes/types'

type ProgressModuleProps = {
  config: SectionsType
  sectionName: Sections
  section: Section
  step: number
}

const ProgressModule = ({ sectionName, step, config }: ProgressModuleProps) => {
  const sectionIndex = (Object.values(Sections) as Sections[]).indexOf(sectionName)

  return (
    <ProgressesWrapper>
      {Object.values(config).map((section, index) => {
        const isSectionPrev = index < sectionIndex
        const isSectionActive = index === sectionIndex
        const max = section.steps.length

        if (isSectionActive) {
          return <StyledProgress key={index} max={max} value={step + 1} />
        }

        return <StyledProgress key={index} max={max} value={isSectionPrev ? max : 0} />
      })}
      <StyledProgress max={1} value={0} />
    </ProgressesWrapper>
  )
}

export default ProgressModule
