import React from 'react';

const SliderStar = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
      <path
        d="M13.0742 1.59038C13.4128 0.761029 14.5872 0.76103 14.9258 1.59038L17.9375 8.96687C18.1406 9.46447 18.5355 9.85936 19.0331 10.0625L26.4096 13.0742C27.239 13.4128 27.239 14.5872 26.4096 14.9258L19.0331 17.9375C18.5355 18.1406 18.1406 18.5355 17.9375 19.0331L14.9258 26.4096C14.5872 27.239 13.4128 27.239 13.0742 26.4096L10.0625 19.0331C9.85936 18.5355 9.46447 18.1406 8.96687 17.9375L1.59038 14.9258C0.761029 14.5872 0.761029 13.4128 1.59038 13.0742L8.96687 10.0625C9.46447 9.85936 9.85936 9.46447 10.0625 8.96687L13.0742 1.59038Z"
        fill="#F9CF6B" stroke="#323243" />
    </svg>
  );
};

export default SliderStar;
