import React, { Dispatch, KeyboardEventHandler, useEffect, useState } from 'react'
import styled from 'styled-components'
import CustomHobbieTag from 'Widgets/Onboarding/Slides/Hobbies/components/CustomHobbieTag'
import { useUserData } from 'Widgets/Onboarding/UserDataStorage/UseUserData'
import Input from 'std-lib/UiKit/Input'
import { sendTypedEvent } from '../../../../../Utils/events/sendEvent'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding-top: 24px;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.color.elements.stroke.default};

  @media screen and (max-width: 1000px) {
    padding-top: 16px;
  }
`

const CustomTagInputLabel = styled.label`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;

  padding: 0 1px;

  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  span {
    white-space: nowrap;
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    gap: 12px;
    align-items: start;

    span {
      white-space: normal;
    }
  }
`

const CustomTagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  gap: 16px;
  margin-top: 16px;

  @media screen and (max-width: 1000px) {
    gap: 8px;
    margin-top: 12px;
  }
`

const CustomInput = styled(Input)`
  height: 40px;
  box-sizing: border-box;
  border-radius: 0;
  border: none;
  width: 100%;
  padding: 8px 0 8px 20px;
  min-width: 150px;

  &:focus {
    border: none;
  }

  @media screen and (max-width: 1000px) {
    padding: 8px 0 8px 12px;
    font-size: 12px;
    line-height: 18px;
    height: 34px;
  }
`

const CustomInputWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
  box-sizing: border-box;
  transition: all .3s;
  border-radius: 20px;
  overflow: hidden;
  outline: 1px solid #DEDEDE;
  width: 100%;

  &:focus-within {
    outline: 1px solid ${({ theme }) => theme.color.states.primary.default};
    border-radius: 17px;
  }
`

const CustomInputButton = styled.button`
  height: 100%;
  padding: 8px 24px 8px 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  transition: 0.3s;
  color: ${({ theme }) => theme.color.text.white};
  background-color: ${({ theme }) => theme.color.states.primary.default};

  &:disabled {
    background-color: ${({ theme }) => theme.color.states.primary.disabled};
  }

  &:not([disabled]) {
    &:active {
      background-color: #5722A5;
    }

    &:hover {
      background-color: ${({ theme }) => theme.color.states.primary.active};
    }
  }

  @media screen and (max-width: 1000px) {
    font-size: 12px;
    line-height: 18px;
  }
`

export type CustomHobby = {
  id: number | string
  name: string
}

type CustomHobbiesProps = {
  tags: CustomHobby[]
  setTags: Dispatch<React.SetStateAction<CustomHobby[]>>
}

const CustomHobbies = ({ tags, setTags }: CustomHobbiesProps) => {
  const [inputValue, setInputValue] = useState('')

  const handleButtonClick = () => {
    if (!inputValue) return
    const newTag: CustomHobby = {
      id: Math.random(),
      name: inputValue,
    }
    sendTypedEvent('click', 'hobbies_quesstion.add_hoobie', 'ob', {
      hobbyAdded: inputValue,
    })
    setTags(prevState => [...prevState, newTag])
    setInputValue('')
  }

  const { updateUserDataWith } = useUserData()

  useEffect(() => {
    updateUserDataWith({
      customHobbies: tags,
    })
  }, [tags.length])

  const deleteTag = (id: CustomHobby['id']) => {
    const tag = tags.find(item => item.id === id)
    sendTypedEvent('click', 'hobbies_quesstion.del_hoobie', 'ob', {
      hobbyDeleted: tag ? tag.name : '',
    })
    setTags(prevTags => prevTags.filter(item => id !== item.id))
  }

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = ({ key }) => {
    if (key === 'Enter') {
      handleButtonClick()
    }
  }

  return (
    <Wrapper>
      <CustomTagInputLabel>
        <span>Не нашел подходящее занятие? Добавь свое!</span>
        <CustomInputWrapper>
          <CustomInput
            onChange={({ target: { value } }) => setInputValue(value)}
            value={inputValue}
            onKeyDown={onKeyDown}
          />
          <CustomInputButton onClick={handleButtonClick} disabled={!inputValue}>
            Добавить
          </CustomInputButton>
        </CustomInputWrapper>
      </CustomTagInputLabel>
      {tags && (
        <CustomTagWrapper>
          {tags.map(tag => (
            <CustomHobbieTag
              key={tag.id}
              title={tag.name}
              onDelete={() => deleteTag(tag.id)}
            />
          ))}
        </CustomTagWrapper>
      )}
    </Wrapper>
  )
}

export default CustomHobbies
