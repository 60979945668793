import ym from 'react-yandex-metrika'
import * as amplitude from '@amplitude/analytics-browser'

type EventType = 'visit' | 'click'
type ModuleID = 'ob' | 'trajectory' | undefined

export const createdEvent = (type: EventType, name: string, module: ModuleID) => {
  return `${module ? `${module}.` : ''}${name}.${type}`
}

type TrackerName = 'ym' | 'amplitude'
const allTrackerNames: TrackerName[] = ['ym', 'amplitude']

export const sendTypedEvent = (
  type: EventType,
  name: string,
  module?: ModuleID,
  params?: any,
  toTrackers: TrackerName[] | 'all' = 'all',
  setParamsAsUserProperty = true,
) => {
  const eventTypeAndName = createdEvent(type, name, module)
  let actualTrackers
  if (toTrackers === 'all') {
    actualTrackers = allTrackerNames
  } else {
    actualTrackers = toTrackers
  }

  actualTrackers.forEach(tracker => {
    switch (tracker) {
      case 'amplitude':
        if (params && setParamsAsUserProperty) {
          const identify = new amplitude.Identify()
          Object.keys(params).forEach(key => {
            identify.set(key, params[key])
          })
          amplitude.identify(identify)
        }
        amplitude.track(eventTypeAndName, params)
        break
      case 'ym':
        if (window.location.origin.includes('track.itmo.ru')) {
          ym('reachGoal', eventTypeAndName, params)
        } else {
          console.log(
            'Not a production env, skipping sending event',
            eventTypeAndName,
            params,
            'ym',
          )
        }
        break
      default:
        break
    }
  })
}

export const sendEvent = (
  toTrackers: TrackerName[] | 'all',
  name: string,
  params?: any,
) => {
  if (window.location.origin.includes('track.itmo.ru')) {
    let actualTrackers
    if (toTrackers === 'all') {
      actualTrackers = allTrackerNames
    } else {
      actualTrackers = toTrackers
    }
    actualTrackers.forEach(tracker => {
      switch (tracker) {
        case 'amplitude':
          amplitude.track(name, params)
          break
        case 'ym':
          ym('reachGoal', name, params)
          break
        default:
          break
      }
    })
  }
}
