import { City } from './types'

// самый длинный город Петропавловск-Камчатский
export const cities: City[] = [
  {
    'id': '1',
    'city': 'Москва',
    'region': 'Москва',
    'population': '13104',
  },
  {
    'id': '2',
    'city': 'Санкт-Петербург',
    'region': 'Санкт-Петербург',
    'population': '5600',
  },
  {
    'id': '6',
    'city': 'Нижний Новгород',
    'region': 'Нижегородская обл.',
    'population': '1213',
  },
  {
    'id': '3',
    'city': 'Новосибирск',
    'region': 'Новосибирская обл.',
    'population': '1635',
  },
  {
    'id': '4',
    'city': 'Екатеринбург',
    'region': 'Свердловская обл.',
    'population': '1539',
  },
  {
    'id': '5',
    'city': 'Казань',
    'region': 'Татарстан',
    'population': '1315',
  },
  {
    'id': '7',
    'city': 'Красноярск',
    'region': 'Красноярский край',
    'population': '1197',
  },
  {
    'id': '8',
    'city': 'Челябинск',
    'region': 'Челябинская обл.',
    'population': '1183',
  },
  {
    'id': '9',
    'city': 'Самара',
    'region': 'Самарская обл.',
    'population': '1164',
  },
  {
    'id': '10',
    'city': 'Уфа',
    'region': 'Башкортостан',
    'population': '1158',
  },
  {
    'id': '11',
    'city': 'Омск',
    'region': 'Омская обл.',
    'population': '1160',
  },
  {
    'id': '12',
    'city': 'Ростов-на-Дону',
    'region': 'Ростовская обл.',
    'population': '1130',
  },
  {
    'id': '13',
    'city': 'Ульяновск',
    'region': 'Ульяновская обл.',
    'population': '1040',
  },
  {
    'id': '14',
    'city': 'Пермь',
    'region': 'Пермский край',
    'population': '1030',
  },
  {
    'id': '15',
    'city': 'Воронеж',
    'region': 'Воронежская обл.',
    'population': '1020',
  },
  {
    'id': '16',
    'city': 'Волгоград',
    'region': 'Волгоградская обл.',
    'population': '1015',
  },
  {
    'id': '17',
    'city': 'Краснодар',
    'region': 'Краснодарский край',
    'population': '1005',
  },
  {
    'id': '18',
    'city': 'Саратов',
    'region': 'Саратовская обл.',
    'population': '1000',
  },
  {
    'id': '19',
    'city': 'Тюмень',
    'region': 'Тюменская обл.',
    'population': '995',
  },
  {
    'id': '20',
    'city': 'Тольятти',
    'region': 'Самарская обл.',
    'population': '990',
  },
  {
    'id': '21',
    'city': 'Ижевск',
    'region': 'Удмуртия',
    'population': '985',
  },
  {
    'id': '22',
    'city': 'Барнаул',
    'region': 'Алтайский край',
    'population': '980',
  },
  {
    'id': '23',
    'city': 'Иркутск',
    'region': 'Иркутская обл.',
    'population': '975',
  },
  {
    'id': '24',
    'city': 'Улан-Удэ',
    'region': 'Бурятия',
    'population': '970',
  },
  {
    'id': '25',
    'city': 'Ярославль',
    'region': 'Ярославская обл.',
    'population': '965',
  },
  {
    'id': '26',
    'city': 'Хабаровск',
    'region': 'Хабаровский край',
    'population': '960',
  },
  {
    'id': '27',
    'city': 'Махачкала',
    'region': 'Дагестан',
    'population': '955',
  },
  {
    'id': '28',
    'city': 'Оренбург',
    'region': 'Оренбургская обл.',
    'population': '950',
  },
  {
    'id': '29',
    'city': 'Новокузнецк',
    'region': 'Кемеровская обл.',
    'population': '945',
  },
  {
    'id': '30',
    'city': 'Кемерово',
    'region': 'Кемеровская обл.',
    'population': '940',
  },
  {
    'id': '31',
    'city': 'Рязань',
    'region': 'Рязанская обл.',
    'population': '935',
  },
  {
    'id': '32',
    'city': 'Томск',
    'region': 'Томская обл.',
    'population': '930',
  },
  {
    'id': '33',
    'city': 'Астрахань',
    'region': 'Астраханская обл.',
    'population': '925',
  },
  {
    'id': '34',
    'city': 'Пенза',
    'region': 'Пензенская обл.',
    'population': '920',
  },
  {
    'id': '35',
    'city': 'Липецк',
    'region': 'Липецкая обл.',
    'population': '915',
  },
  {
    'id': '36',
    'city': 'Киров',
    'region': 'Кировская обл.',
    'population': '910',
  },
  {
    'id': '37',
    'city': 'Чебоксары',
    'region': 'Чувашия',
    'population': '905',
  },
  {
    'id': '38',
    'city': 'Калининград',
    'region': 'Калининградская обл.',
    'population': '900',
  },
  {
    'id': '39',
    'city': 'Тверь',
    'region': 'Тверская обл.',
    'population': '895',
  },
  {
    'id': '40',
    'city': 'Ставрополь',
    'region': 'Ставропольский край',
    'population': '890',
  },
  {
    'id': '41',
    'city': 'Набережные Челны',
    'region': 'Татарстан',
    'population': '885',
  },
  {
    'id': '42',
    'city': 'Сургут',
    'region': 'Ханты-Мансийский АО',
    'population': '880',
  },
  {
    'id': '43',
    'city': 'Димитровград',
    'region': 'Ульяновская обл.',
    'population': '875',
  },
  {
    'id': '44',
    'city': 'Нижний Тагил',
    'region': 'Свердловская обл.',
    'population': '870',
  },
  {
    'id': '45',
    'city': 'Курск',
    'region': 'Курская обл.',
    'population': '865',
  },
  {
    'id': '46',
    'city': 'Магнитогорск',
    'region': 'Челябинская обл.',
    'population': '860',
  },
  {
    'id': '47',
    'city': 'Тула',
    'region': 'Тульская обл.',
    'population': '855',
  },
  {
    'id': '48',
    'city': 'Орёл',
    'region': 'Орловская обл.',
    'population': '850',
  },
  {
    'id': '49',
    'city': 'Брянск',
    'region': 'Брянская обл.',
    'population': '845',
  },
  {
    'id': '50',
    'city': 'Владивосток',
    'region': 'Приморский край',
    'population': '840',
  },
  {
    'id': '51',
    'city': 'Иваново',
    'region': 'Ивановская обл.',
    'population': '835',
  },
  {
    'id': '52',
    'city': 'Белгород',
    'region': 'Белгородская обл.',
    'population': '830',
  },
  {
    'id': '53',
    'city': 'Архангельск',
    'region': 'Архангельская обл.',
    'population': '825',
  },
  {
    'id': '54',
    'city': 'Смоленск',
    'region': 'Смоленская обл.',
    'population': '820',
  },
  {
    'id': '55',
    'city': 'Владимир',
    'region': 'Владимирская обл.',
    'population': '815',
  },
  {
    'id': '56',
    'city': 'Чита',
    'region': 'Забайкальский край',
    'population': '810',
  },
  {
    'id': '57',
    'city': 'Сочи',
    'region': 'Краснодарский край',
    'population': '805',
  },
  {
    'id': '58',
    'city': 'Курган',
    'region': 'Курганская обл.',
    'population': '800',
  },
  {
    'id': '59',
    'city': 'Севастополь',
    'region': 'Севастополь',
    'population': '795',
  },
  {
    'id': '60',
    'city': 'Кострома',
    'region': 'Костромская обл.',
    'population': '790',
  },
  {
    'id': '61',
    'city': 'Вологда',
    'region': 'Вологодская обл.',
    'population': '785',
  },
  {
    'id': '62',
    'city': 'Тамбов',
    'region': 'Тамбовская обл.',
    'population': '780',
  },
  {
    'id': '63',
    'city': 'Стерлитамак',
    'region': 'Башкортостан',
    'population': '775',
  },
  {
    'id': '64',
    'city': 'Грозный',
    'region': 'Чеченская Респ.',
    'population': '770',
  },
  {
    'id': '65',
    'city': 'Якутск',
    'region': 'Якутия',
    'population': '765',
  },
  {
    'id': '66',
    'city': 'Комсомольск-на-Амуре',
    'region': 'Хабаровский край',
    'population': '760',
  },
  {
    'id': '67',
    'city': 'Петрозаводск',
    'region': 'Карелия',
    'population': '755',
  },
  {
    'id': '68',
    'city': 'Таганрог',
    'region': 'Ростовская обл.',
    'population': '750',
  },
  {
    'id': '69',
    'city': 'Нижневартовск',
    'region': 'Ханты-Мансийский АО',
    'population': '745',
  },
  {
    'id': '70',
    'city': 'Йошкар-Ола',
    'region': 'Марий Эл',
    'population': '740',
  },
  {
    'id': '71',
    'city': 'Братск',
    'region': 'Иркутская обл.',
    'population': '735',
  },
  {
    'id': '72',
    'city': 'Новороссийск',
    'region': 'Краснодарский край',
    'population': '730',
  },
  {
    'id': '73',
    'city': 'Дзержинск',
    'region': 'Нижегородская обл.',
    'population': '725',
  },
  {
    'id': '74',
    'city': 'Шахты',
    'region': 'Ростовская обл.',
    'population': '720',
  },
  {
    'id': '75',
    'city': 'Орск',
    'region': 'Оренбургская обл.',
    'population': '715',
  },
  {
    'id': '76',
    'city': 'Химки',
    'region': 'Московская обл.',
    'population': '710',
  },
  {
    'id': '77',
    'city': 'Волжский',
    'region': 'Волгоградская обл.',
    'population': '705',
  },
  {
    'id': '78',
    'city': 'Ангарск',
    'region': 'Иркутская обл.',
    'population': '700',
  },
  {
    'id': '79',
    'city': 'Старый Оскол',
    'region': 'Белгородская обл.',
    'population': '695',
  },
  {
    'id': '80',
    'city': 'Мурманск',
    'region': 'Мурманская обл.',
    'population': '690',
  },
  {
    'id': '81',
    'city': 'Калуга',
    'region': 'Калужская обл.',
    'population': '685',
  },
  {
    'id': '82',
    'city': 'Сыктывкар',
    'region': 'Коми',
    'population': '680',
  },
  {
    'id': '83',
    'city': 'Прокопьевск',
    'region': 'Кемеровская обл.',
    'population': '675',
  },
  {
    'id': '84',
    'city': 'Подольск',
    'region': 'Московская обл.',
    'population': '670',
  },
  {
    'id': '85',
    'city': 'Южно-Сахалинск',
    'region': 'Сахалинская обл.',
    'population': '665',
  },
  {
    'id': '86',
    'city': 'Балаково',
    'region': 'Саратовская обл.',
    'population': '660',
  },
  {
    'id': '87',
    'city': 'Армавир',
    'region': 'Краснодарский край',
    'population': '655',
  },
  {
    'id': '88',
    'city': 'Королёв',
    'region': 'Московская обл.',
    'population': '650',
  },
  {
    'id': '89',
    'city': 'Норильск',
    'region': 'Красноярский край',
    'population': '645',
  },
  {
    'id': '90',
    'city': 'Сызрань',
    'region': 'Самарская обл.',
    'population': '640',
  },
  {
    'id': '91',
    'city': 'Новочеркасск',
    'region': 'Ростовская обл.',
    'population': '635',
  },
  {
    'id': '92',
    'city': 'Златоуст',
    'region': 'Челябинская обл.',
    'population': '630',
  },
  {
    'id': '93',
    'city': 'Мытищи',
    'region': 'Московская обл.',
    'population': '625',
  },
  {
    'id': '94',
    'city': 'Люберцы',
    'region': 'Московская обл.',
    'population': '620',
  },
  {
    'id': '95',
    'city': 'Великий Новгород',
    'region': 'Новгородская обл.',
    'population': '615',
  },
  {
    'id': '96',
    'city': 'Псков',
    'region': 'Псковская обл.',
    'population': '610',
  },
  {
    'id': '97',
    'city': 'Энгельс',
    'region': 'Саратовская обл.',
    'population': '605',
  },
  {
    'id': '98',
    'city': 'Бийск',
    'region': 'Алтайский край',
    'population': '600',
  },
  {
    'id': '99',
    'city': 'Сергиев Посад',
    'region': 'Московская обл.',
    'population': '595',
  },
  {
    'id': '100',
    'city': 'Нальчик',
    'region': 'Кабардино-Балкария',
    'population': '590',
  },
  {
    'id': '101',
    'city': 'Северодвинск',
    'region': 'Архангельская обл.',
    'population': '585',
  },
  {
    'id': '102',
    'city': 'Благовещенск',
    'region': 'Амурская обл.',
    'population': '580',
  },
  {
    'id': '103',
    'city': 'Череповец',
    'region': 'Вологодская обл.',
    'population': '575',
  },
  {
    'id': '104',
    'city': 'Туапсе',
    'region': 'Краснодарский край',
    'population': '570',
  },
  {
    'id': '105',
    'city': 'Каменск-Уральский',
    'region': 'Свердловская обл.',
    'population': '565',
  },
  {
    'id': '106',
    'city': 'Магадан',
    'region': 'Магаданская обл.',
    'population': '560',
  },
  {
    'id': '107',
    'city': 'Миасс',
    'region': 'Челябинская обл.',
    'population': '555',
  },
  {
    'id': '108',
    'city': 'Пятигорск',
    'region': 'Ставропольский край',
    'population': '550',
  },
  {
    'id': '109',
    'city': 'Рубцовск',
    'region': 'Алтайский край',
    'population': '545',
  },
  {
    'id': '110',
    'city': 'Ковров',
    'region': 'Владимирская обл.',
    'population': '540',
  },
  {
    'id': '111',
    'city': 'Коломна',
    'region': 'Московская обл.',
    'population': '535',
  },
  {
    'id': '112',
    'city': 'Уссурийск',
    'region': 'Приморский край',
    'population': '530',
  },
  {
    'id': '113',
    'city': 'Елец',
    'region': 'Липецкая обл.',
    'population': '525',
  },
  {
    'id': '114',
    'city': 'Ессентуки',
    'region': 'Ставропольский край',
    'population': '520',
  },
  {
    'id': '115',
    'city': 'Новомосковск',
    'region': 'Тульская обл.',
    'population': '515',
  },
  {
    'id': '116',
    'city': 'Октябрьский',
    'region': 'Башкортостан',
    'population': '510',
  },
  {
    'id': '117',
    'city': 'Домодедово',
    'region': 'Московская обл.',
    'population': '505',
  },
  {
    'id': '118',
    'city': 'Нефтекамск',
    'region': 'Башкортостан',
    'population': '500',
  },
  {
    'id': '119',
    'city': 'Новочебоксарск',
    'region': 'Чувашия',
    'population': '495',
  },
  {
    'id': '120',
    'city': 'Салават',
    'region': 'Башкортостан',
    'population': '490',
  },
  {
    'id': '121',
    'city': 'Хасавюрт',
    'region': 'Дагестан',
    'population': '485',
  },
  {
    'id': '122',
    'city': 'Обнинск',
    'region': 'Калужская обл.',
    'population': '480',
  },
  {
    'id': '123',
    'city': 'Назрань',
    'region': 'Ингушетия',
    'population': '475',
  },
  {
    'id': '124',
    'city': 'Кисловодск',
    'region': 'Ставропольский край',
    'population': '470',
  },
  {
    'id': '125',
    'city': 'Раменское',
    'region': 'Московская обл.',
    'population': '465',
  },
  {
    'id': '126',
    'city': 'Саранск',
    'region': 'Мордовия',
    'population': '460',
  },
  {
    'id': '127',
    'city': 'Дербент',
    'region': 'Дагестан',
    'population': '455',
  },
  {
    'id': '128',
    'city': 'Орехово-Зуево',
    'region': 'Московская обл.',
    'population': '450',
  },
  {
    'id': '129',
    'city': 'Камышин',
    'region': 'Волгоградская обл.',
    'population': '445',
  },
  {
    'id': '130',
    'city': 'Новошахтинск',
    'region': 'Ростовская обл.',
    'population': '440',
  },
  {
    'id': '131',
    'city': 'Новый Уренгой',
    'region': 'Ямало-Ненецкий АО',
    'population': '435',
  },
  {
    'id': '132',
    'city': 'Ноябрьск',
    'region': 'Ямало-Ненецкий АО',
    'population': '430',
  },
  {
    'id': '133',
    'city': 'Березники',
    'region': 'Пермский край',
    'population': '425',
  },
  {
    'id': '134',
    'city': 'Щёлково',
    'region': 'Московская обл.',
    'population': '420',
  },
  {
    'id': '135',
    'city': 'Ачинск',
    'region': 'Красноярский край',
    'population': '415',
  },
  {
    'id': '136',
    'city': 'Александров',
    'region': 'Владимирская обл.',
    'population': '410',
  },
  {
    'id': '137',
    'city': 'Элиста',
    'region': 'Калмыкия',
    'population': '405',
  },
  {
    'id': '138',
    'city': 'Арзамас',
    'region': 'Нижегородская обл.',
    'population': '400',
  },
  {
    'id': '139',
    'city': 'Батайск',
    'region': 'Ростовская обл.',
    'population': '395',
  },
  {
    'id': '140',
    'city': 'Жуковский',
    'region': 'Московская обл.',
    'population': '390',
  },
  {
    'id': '141',
    'city': 'Серпухов',
    'region': 'Московская обл.',
    'population': '385',
  },
  {
    'id': '142',
    'city': 'Воткинск',
    'region': 'Удмуртия',
    'population': '380',
  },
  {
    'id': '143',
    'city': 'Черкесск',
    'region': 'Карачаево-Черкессия',
    'population': '375',
  },
  {
    'id': '144',
    'city': 'Анапа',
    'region': 'Краснодарский край',
    'population': '370',
  },
  {
    'id': '145',
    'city': 'Северск',
    'region': 'Томская обл.',
    'population': '365',
  },
  {
    'id': '146',
    'city': 'Петропавловск-Камчатский',
    'region': 'Камчатский край',
    'population': '360',
  },
  {
    'id': '147',
    'city': 'Красногорск',
    'region': 'Московская обл.',
    'population': '355',
  },
  {
    'id': '148',
    'city': 'Всеволожск',
    'region': 'Ленинградская обл.',
    'population': '350',
  },
  {
    'id': '149',
    'city': 'Артём',
    'region': 'Приморский край',
    'population': '345',
  },
  {
    'id': '150',
    'city': 'Долгопрудный',
    'region': 'Московская обл.',
    'population': '340',
  },
]
