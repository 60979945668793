import styled from 'styled-components'

export const StyledHeader = styled.header`
  padding: 24px 64px 0 64px;

  @media screen and (max-width: 1000px) {
    padding: 16px 16px 12px;
    display: flex;
    justify-content: center;
  }
`
