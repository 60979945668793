import React, { ReactNode } from 'react'
import styled from 'styled-components'
import Text from 'std-lib/FastUI/Components/Text'

export const Label = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 0;
`

export const RadioBox = styled.div`
  height: 24px;
  width: 24px;
  border: 1px solid #DEDEDE;
  background-color: #EBEBFD;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 16px;
  transition: all 0.15s;

  &::after {
    content: "";
    width: 16px;
    height: 16px;
    display: block;
    background: #9480EF;
    border-radius: 50%;
    cursor: pointer;
    transform: scale(0);
    transition: all 0.15s;
  }

  &:hover {
    border: 1px solid #9480EF;
  }
`

export const Input = styled.input`
  display: none;


  &:checked + ${RadioBox} {
    border: 1px solid #9480EF;

    &::after {
      transform: scale(1);
    }

  }

  &:checked + ${RadioBox}:hover {
    border: 1px solid #712FD3;

    &::after {
      background: #712FD3;
    }
  }

`

interface CustomCheckboxProps<T> {
  children: ReactNode
  value: T
  onChange: (value: T) => void
  checked?: boolean
}

const CustomCheckbox = <T extends string | number>({
  value,
  children,
  onChange,
  checked,
}: CustomCheckboxProps<T>) => {
  return (
    <Label>
      <Input
        checked={checked}
        type="radio"
        name={String(value)}
        value={value}
        onChange={() => onChange(value)}
      />
      <RadioBox />
      <Text size={16}>{children}</Text>
    </Label>
  )
}

export default CustomCheckbox
