import React from 'react';

const Marketer = () => {
	return (
		<svg width="113" height="148" viewBox="0 0 113 148" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g className="svgAnime rotate(16deg) n0">
				<path
					d="M95.8609 111.98L92.4047 111.328C88.7537 114.242 85.8381 113.992 84.8367 113.503L71.2456 106.494L70.1583 105.934L79.0977 90.1548L79.9917 88.5769C82.4612 90.2431 88.7172 94.1839 93.9848 96.6177C99.2523 99.0515 97.318 104.25 95.6924 106.545L98.2447 108.827L95.8609 111.98Z"
					fill="#F7E1A0"></path>
				<path
					d="M84.8367 113.503L79.9599 120.388L77.8744 118.794L56.4909 130.972L53.1645 120.14C59.2792 118.291 70.9702 113.651 68.8173 109.883C70.3746 109.216 71.0851 107.346 71.2456 106.494L84.8367 113.503Z"
					fill="#F0C64C"></path>
				<path
					d="M46.8403 84.074C48.1824 79.4858 53.1277 78.8718 55.4326 79.1384C54.5568 83.2903 53.1361 87.5937 50.9527 91.501C49.0227 90.9371 45.4982 88.6622 46.8403 84.074Z"
					fill="#F0C64C"></path>
				<path
					d="M70.1583 105.934L71.2456 106.494M70.1583 105.934C63.7757 103.93 48.625 100.554 39.0826 103.077M70.1583 105.934L79.0977 90.1548M84.8367 113.503C85.8381 113.992 88.7537 114.242 92.4047 111.328L95.8609 111.98L98.2447 108.827L95.6924 106.545C97.318 104.25 99.2523 99.0515 93.9848 96.6177C88.7172 94.1839 82.4612 90.2431 79.9917 88.5769L79.0977 90.1548M84.8367 113.503L79.9599 120.388L77.8744 118.794L56.4909 130.972L53.1645 120.14C59.2792 118.291 70.9702 113.651 68.8173 109.883C70.3746 109.216 71.0851 107.346 71.2456 106.494M84.8367 113.503L71.2456 106.494M39.0826 103.077C31.7035 101.086 49.7218 62.4597 56.5248 64.3425M39.0826 103.077C44.453 100.486 48.276 96.2914 50.9527 91.501M56.5248 64.3425C59.6827 69.7404 75.2941 88.1826 79.0977 90.1548M56.5248 64.3425C56.8095 68.0539 56.6317 73.4542 55.4326 79.1384M90.836 103.828C87.9195 103.479 82.0089 101.924 81.6993 98.5041M88.2337 108.14C85.1615 107.561 79.0113 105.802 78.9877 103.396M55.4326 79.1384C53.1277 78.8718 48.1824 79.4858 46.8403 84.074C45.4982 88.6622 49.0227 90.9371 50.9527 91.501M55.4326 79.1384C54.5568 83.2903 53.1361 87.5937 50.9527 91.501"
					stroke="#1F1F22" strokeWidth="0.9"></path>
			</g>


			<g className="svgAnime translate(5px,-5px) n1">
				<path
					d="M63.312 51.2936L63.489 51.2817L63.6265 51.3939C66.046 53.3671 69.1342 54.55 72.5 54.55C80.2596 54.55 86.55 48.2596 86.55 40.5C86.55 32.7404 80.2596 26.45 72.5 26.45C64.7404 26.45 58.45 32.7404 58.45 40.5C58.45 43.532 59.4099 46.3385 61.0425 48.6339L61.1443 48.777L61.1222 48.9512L60.8045 51.4618L63.312 51.2936Z"
					fill="#F7E1A0" stroke="#1F1F22" strokeWidth="0.9"></path>
				<path fillRule="evenodd" clipRule="evenodd"
				      d="M72.5 37.8556C72.9568 37.3069 73.6361 36.9928 74.35 37.0001C75.935 37.0001 77 38.4901 77 39.8776C77 42.778 73.389 45 72.5 45C71.611 45 68 42.778 68 39.8776C68 38.4901 69.065 37.0001 70.65 37.0001C71.3639 36.9928 72.0432 37.3069 72.5 37.8556Z"
				      fill="white"></path>
			</g>

			<g className="svgAnime translate(-5px,-4px) n2">
				<path
					d="M35.767 74.732L35.5899 74.7201L35.4524 74.8323C32.6025 77.1565 28.9646 78.55 25 78.55C15.8597 78.55 8.45 71.1403 8.45 62C8.45 52.8597 15.8597 45.45 25 45.45C34.1403 45.45 41.55 52.8597 41.55 62C41.55 65.5714 40.4192 68.8775 38.4962 71.5813L38.3944 71.7244L38.4164 71.8986L38.8008 74.9355L35.767 74.732Z"
					fill="#F0C64C" stroke="#1F1F22" strokeWidth="0.9"></path>
				<path fillRule="evenodd" clipRule="evenodd"
				      d="M26.7711 56.5078L27.5707 59.9724C27.8623 59.8804 28.1845 59.8893 28.4828 60.0211L29.7372 60.5754C30.3998 60.8682 30.6937 61.6567 30.3935 62.3361L28.1694 67.3698C27.8692 68.0492 27.0883 68.3628 26.4257 68.07L25.1712 67.5157C24.7105 67.3122 24.4281 66.8689 24.3996 66.3894L24.261 66.451C23.7843 66.6631 23.2457 66.6649 22.7753 66.4571L18.5966 64.6107C17.8414 64.277 17.4043 63.4711 17.5242 62.6348L18.0427 59.019C18.1216 58.4658 18.437 57.9716 18.9022 57.669L19.2687 57.431C19.7958 57.0885 20.4519 57.0321 21.0176 57.2821L22.5763 57.9708L23.3353 56.2532C23.5125 55.852 23.8205 55.5213 24.2083 55.3209C25.254 54.779 26.5062 55.3582 26.7711 56.5078ZM25.5116 66.1954C25.4608 66.3104 25.5107 66.4442 25.6228 66.4937L26.8773 67.048C26.9894 67.0976 27.1219 67.0444 27.1727 66.9294L29.3969 61.8957C29.4477 61.7807 29.3978 61.647 29.2857 61.5974L28.0312 61.0431C27.919 60.9936 27.7866 61.0468 27.7357 61.1618L25.5116 66.1954ZM24.8577 64.9792L26.6576 60.9059L25.7056 56.7808C25.6009 56.326 25.1048 56.0959 24.69 56.3109C24.5324 56.3923 24.4052 56.5279 24.3319 56.6936L23.3472 58.9222C23.2225 59.2044 22.8983 59.3346 22.6231 59.213L20.566 58.304C20.3331 58.2012 20.063 58.2243 19.8458 58.3654L19.4795 58.6033C19.2874 58.7284 19.1577 58.9321 19.1255 59.1585L18.6069 62.7752C18.5575 63.1199 18.7376 63.4515 19.0481 63.5887L23.2268 65.4351C23.4208 65.5208 23.6427 65.5198 23.8381 65.4328L24.8577 64.9792Z"
				      fill="white"></path>
				<path fillRule="evenodd" clipRule="evenodd"
				      d="M27.2326 60.9525L25.2674 65.4002L24.0496 65.9419C23.7135 66.0915 23.3333 66.0928 23.001 65.9461L18.8224 64.0997C18.2895 63.8642 17.9809 63.2955 18.0655 62.705L18.5841 59.0888C18.6396 58.6988 18.862 58.35 19.1908 58.1362L19.5572 57.8982C19.9294 57.6563 20.3924 57.6166 20.7918 57.793L22.8489 58.702L23.8336 56.4734C23.9589 56.1899 24.1762 55.957 24.4489 55.8161C25.1792 55.4376 26.0536 55.8419 26.2383 56.6442L27.2326 60.9525ZM24.8577 64.9792L26.6576 60.9059L25.7056 56.7808C25.6009 56.326 25.1048 56.0959 24.69 56.3109C24.5324 56.3923 24.4052 56.5279 24.3319 56.6936L23.3472 58.9222C23.2225 59.2044 22.8983 59.3346 22.6231 59.213L20.566 58.304C20.3331 58.2012 20.063 58.2243 19.8458 58.3654L19.4795 58.6033C19.2874 58.7284 19.1577 58.9321 19.1255 59.1585L18.6069 62.7752C18.5575 63.1199 18.7376 63.4515 19.0481 63.5887L23.2268 65.4351C23.4208 65.5208 23.6427 65.5198 23.8381 65.4328L24.8577 64.9792Z"
				      fill="#F0C64C"></path>
				<path fillRule="evenodd" clipRule="evenodd"
				      d="M26.6515 67.559L25.397 67.0047C25.0097 66.8336 24.8378 66.3724 25.0133 65.9752L25.2674 65.4002L27.2326 60.9525L27.2374 60.9416C27.4129 60.5444 27.8696 60.3609 28.257 60.5321L29.5114 61.0864C29.8988 61.2576 30.0707 61.7187 29.8952 62.1159L27.671 67.1496C27.4955 67.5468 27.0389 67.7302 26.6515 67.559ZM25.5116 66.1954C25.4608 66.3104 25.5107 66.4442 25.6228 66.4937L26.8773 67.048C26.9894 67.0976 27.1219 67.0444 27.1727 66.9294L29.3969 61.8957C29.4477 61.7807 29.3978 61.647 29.2857 61.5974L28.0312 61.0431C27.919 60.9936 27.7866 61.0468 27.7357 61.1618L25.5116 66.1954Z"
				      fill="#F0C64C"></path>
				<path
					d="M25.6228 66.4937C25.5107 66.4442 25.4608 66.3104 25.5116 66.1954L27.7357 61.1618C27.7866 61.0468 27.919 60.9936 28.0312 61.0431L29.2857 61.5974C29.3978 61.647 29.4477 61.7807 29.3969 61.8957L27.1727 66.9294C27.1219 67.0444 26.9894 67.0976 26.8773 67.048L25.6228 66.4937Z"
					fill="white"></path>
				<path
					d="M26.6576 60.9059L24.8577 64.9792L23.8381 65.4328C23.6427 65.5198 23.4208 65.5208 23.2268 65.4351L19.0481 63.5887C18.7376 63.4515 18.5575 63.1199 18.6069 62.7752L19.1255 59.1585C19.1577 58.9321 19.2874 58.7284 19.4795 58.6033L19.8458 58.3654C20.063 58.2243 20.3331 58.2012 20.566 58.304L22.6231 59.213C22.8983 59.3346 23.2225 59.2044 23.3472 58.9222L24.3319 56.6936C24.4052 56.5279 24.5324 56.3923 24.69 56.3109C25.1048 56.0959 25.6009 56.326 25.7056 56.7808L26.6576 60.9059Z"
					fill="white"></path>
			</g>


			<g className="svgAnime translate(-1px,-6px) n3">
				<path
					d="M41.3257 31.8521L41.2102 31.7174L41.0339 31.6978C39.1021 31.4831 37.2303 30.6269 35.7626 29.1313C32.3793 25.6835 32.4315 20.1458 35.8792 16.7625C39.327 13.3791 44.8647 13.4313 48.248 16.8791C51.6314 20.3268 51.5792 25.8645 48.1314 29.2479C46.7841 30.5701 45.1188 31.3671 43.3871 31.6425L43.2137 31.6701L43.1048 31.8079L42.2334 32.9102L41.3257 31.8521Z"
					fill="#F0C64C" stroke="#1F1F22" strokeWidth="0.9"></path>
				<path
					d="M41.7622 19.2318C41.8371 19.0014 42.1629 19.0014 42.2378 19.2318L42.9542 21.4367C42.9877 21.5397 43.0836 21.6094 43.1919 21.6094H45.5103C45.7525 21.6094 45.8532 21.9193 45.6573 22.0617L43.7817 23.4244C43.694 23.4881 43.6574 23.6009 43.6908 23.7039L44.4073 25.9088C44.4821 26.1391 44.2185 26.3307 44.0226 26.1883L42.1469 24.8256C42.0593 24.7619 41.9407 24.7619 41.8531 24.8256L39.9774 26.1883C39.7815 26.3307 39.5179 26.1391 39.5927 25.9088L40.3092 23.7039C40.3426 23.6009 40.306 23.4881 40.2183 23.4244L38.3427 22.0617C38.1468 21.9193 38.2475 21.6094 38.4897 21.6094H40.8081C40.9164 21.6094 41.0123 21.5397 41.0458 21.4367L41.7622 19.2318Z"
					fill="white"></path>
			</g>

		</svg>
	);
};

export default Marketer;