import styled, { css } from 'styled-components'
import { ContentCard, NavBarWrapper } from 'Widgets/Onboarding/Layouts/Step/styled'

export const EducationStageCard = styled(ContentCard)`
  min-height: 560px;
  max-width: 1440px;
  margin-bottom: 134px;

  @media screen and (max-width: 1000px) {
    min-height: unset;
    margin-bottom: 92px;
  }
`
export const EducationStageContentLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  @media screen and (max-width: 1000px) {
    gap: 24px;
  }
`

export const StagesWrapper = styled.div`
  display: flex;
  gap: 40px;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    gap: 12px;
  }
`

const notSelectedStageButtonFragment = css`
  color: ${({ theme }) => theme.color.text.primary};

  @media (hover: hover) {
    &:hover {
      background-color: #D8D7FE
    }
  }

  &:active {
    background-color: #9480EF;
  }
`

const selectedStageButtonFragment = css`
  background-color: #9480EF;
  color: ${({ theme }) => theme.color.text.white};
`

export const StageButton = styled.button<{ active: boolean }>`
  height: 152px;
  border-radius: 20px;
  background-color: #EBEBFD;
  width: max-content;
  flex-grow: 3;
  transition: all .3s;

  font-family: "ALS Gorizont", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;

  ${({ active }) =>
    active ? selectedStageButtonFragment : notSelectedStageButtonFragment};

  @media screen and (max-width: 1000px) {
    width: 100%;
    max-width: unset;
    height: 72px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
`

export const EducationStageButtonStyle = css`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  gap: 12px;
  height: auto;

  @media screen and (max-width: 1000px) {
    font-size: 14px;
    line-height: 22px
  }

  @media screen and (max-width: 1000px) {
    padding: 8px 12px;
    width: 100%;
    height: 38px;
  }
`

export const EducationStageNavBar = styled(NavBarWrapper)`
  @media screen and (max-width: 1000px) {
    padding: 16px;
    height: 70px;
  }
`
