import React, { useContext, useEffect, useState } from 'react'
import Text from 'std-lib/FastUI/Components/Text'
import CommonSlideLayout from '../../Components/CommonSlideLayout'
import ImgWithMaskAndCards from '../../../../Components/ImgWithMaskAndCards/ImgWithMaskAndCards'
import Image from './assets/image.jpg'
import Icon from './assets/Icon.svg'
import Mask from './assets/mask.svg'
import { GenericCard } from 'std-lib/Сomponents/GenericCard'
import { cities } from './assets/cities'
import styled, { css } from 'styled-components'
import { City } from './assets/types'
import { NavContext } from 'Context/NavContext'
import StepContentLayout from '../../Layouts/Step/components/StepContentLayout'
import { useUserData } from '../../UserDataStorage/UseUserData'
import { InputLabel } from '../NameQuestion'
import Br from 'std-lib/FastUI/Components/Br'
import { AutoComplete } from 'std-lib/UiKit/Autocomplete/AutocompleteV2/AutocompleteV2'
import Flex from 'std-lib/FastUI/Components/Flex'

const ImageWrapper = css`
  min-width: 250px;
  flex-shrink: 1;
  @media screen and (max-width: 1000px) {
    margin: 0 auto;
  }
`

export const FrequentOption = styled.button<{ active: boolean }>`
  background-color: ${({ theme }) => theme.color.background.noStroke.default};

  padding: 8px 20px;
  border-radius: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  white-space: nowrap;
  color: #1F1F22;
  transition: all .3s;

  &:hover {
    background-color: ${({ theme }) => theme.color.background.noStroke.hover};
  }

  &:active {
    background-color: #9480EF;
    color: #FFFFFF;
  }

  ${({ active }) =>
    active &&
    css`
            color: #FFFFFF;
            background: #9480EF;

            &:active {
              background: #712FD3;
            }

            &:hover {
              background: #7D6AD6;
            }
          `};

  @media screen and (max-width: 1200px) {
    padding: 8px 12px;
    font-size: 14px;
    line-height: 22px;
  }
`

const StyledText = styled(Text)`
  display: inline-block;
  @media screen and (max-width: 1000px) {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 12px;
  }
`

const CityQuestionLayout = styled(CommonSlideLayout)`
  z-index: 3;
`

const CityQuestion = () => {
  const { updateUserDataWith, getUserData } = useUserData()

  const [city, setCity] = useState(getUserData().city)

  useEffect(() => {
    updateUserDataWith({ city: city })
  }, [city])

  const onCityChange = (newCity: City | undefined) => {
    setCity(newCity)
  }
  const { setForwardState } = useContext(NavContext)

  useEffect(() => {
    setForwardState({ disabled: !city })
  }, [city, setForwardState])

  const frequentOptions = cities.slice(0, 3)

  return (
    <StepContentLayout title="Про твой город">
      <CityQuestionLayout gap={'48px'} mediaWidth={900}>
        <div>
          <StyledText size={20} weight={'normal'}>
            <b>{getUserData().name}</b>, а&nbsp;тебе известно, что Университет ИТМО
            находится в самом центре Санкт-Петербурга?
            <Br height={20} />
            Если вдруг надоест сидеть в&nbsp;студенческом коворкинге, хватай кофе
            и&nbsp;отправляйся на&nbsp;прогулку по&nbsp;Петропавловской крепости.{' '}
          </StyledText>

          <InputLabel>
            Из какого ты города?
            <AutoComplete
              options={cities}
              onSelect={onCityChange}
              value={city}
              getOptionLabel={({ city, region }) => ({ title: city, subtitle: region })}
            />
          </InputLabel>

          <Flex gap={'12px'} wrap={'wrap'} mobile={{ gap: '8px' }}>
            {frequentOptions.map(option => {
              return (
                <FrequentOption
                  key={option.id}
                  onClick={() => onCityChange(option)}
                  active={option.id == city?.id}
                >
                  {option.city}
                </FrequentOption>
              )
            })}
          </Flex>
        </div>
        <ImgWithMaskAndCards
          imageSrc={Image}
          maskSrc={Mask}
          initialSize={{ width: 500, height: 398 }}
          styledFragment={ImageWrapper}
        >
          <GenericCard
            bg={'#323243'}
            height={265}
            width={118}
            absoluteConfig={{ bottom: 0, right: 10 }}
          >
            <img src={Icon} alt="icon" />
          </GenericCard>
        </ImgWithMaskAndCards>
      </CityQuestionLayout>
    </StepContentLayout>
  )
}

export default CityQuestion
