import { AxiosInstance, AxiosResponse } from 'axios';
import request from '../../../std-lib/API/request';
function personalDataModule(instance: AxiosInstance) {
  return {
    sendPersonalContacts(email: string): Promise<AxiosResponse<any>> {
      return instance.post(`contacts/`, {
        email
      })
    },
  }
}

export const personalDataRequest = Object.assign(request, personalDataModule(request))