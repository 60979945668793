import React, { useContext, useEffect, useState } from 'react'
import CommonSlideLayout from '../../Components/CommonSlideLayout'
import Tags from '../../../../std-lib/Сomponents/Tags'
import { NavContext } from 'Context/NavContext'
import { HobbiesTitle, TagsBlock, TagsWrapperFragment, Wrapper } from './styled'
import StepContentLayout from '../../Layouts/Step/components/StepContentLayout'
import { useUserData } from '../../UserDataStorage/UseUserData'
import CustomHobbies, {
  CustomHobby,
} from 'Widgets/Onboarding/Slides/Hobbies/components/CustomHobbies'
import { onboardingRequest } from 'Widgets/Onboarding/API/API'

export enum HobbyCategory {
  Casual = 'casual',
  Sport = 'sport',
}

export type Hobby = { id: number; name: string; category: HobbyCategory }

const Hobbies = () => {
  const { updateUserDataWith, getUserData } = useUserData()

  const [defaultTags, setDefaultTags] = useState<Hobby[]>([])
  const [customTags, setCustomTags] = useState<CustomHobby[]>(getUserData().customHobbies)

  // TODO псле фикса типов хука убрать '||[]'
  const [hobbiesSelectedTags, setHobbiesSelectedTags] = useState(
    getUserData().selectedHobbies || [],
  )

  useEffect(() => {
    updateUserDataWith({ selectedHobbies: hobbiesSelectedTags })
  }, [hobbiesSelectedTags])

  useEffect(() => {
    onboardingRequest.getInterestsTags().then(hobbies => setDefaultTags(hobbies))
  }, [])
  const onHobbiesChange = (tags: { id: number; name: string }[]) => {
    setHobbiesSelectedTags(tags.map(tag => ({ ...tag, category: HobbyCategory.Casual })))
  }

  const onSportChange = (tags: { id: number; name: string }[]) => {
    setHobbiesSelectedTags(tags.map(tag => ({ ...tag, category: HobbyCategory.Sport })))
  }

  const { setForwardState } = useContext(NavContext)

  useEffect(() => {
    setForwardState({
      disabled: !hobbiesSelectedTags?.length && !customTags.length,
    })
  }, [hobbiesSelectedTags?.length, setForwardState, customTags.length])

  return (
    <StepContentLayout title="Чем бы тебе было интересно заниматься помимо учебы?">
      <CommonSlideLayout>
        <Wrapper>
          <TagsBlock>
            <HobbiesTitle size={20} weight={600}>
              Повседневное
            </HobbiesTitle>
            <Tags
              tags={defaultTags.filter(tag => tag.category === 'casual')}
              selectedTags={hobbiesSelectedTags}
              onSelectedTagsChange={onHobbiesChange}
              styledFragment={TagsWrapperFragment}
            />
          </TagsBlock>
          <TagsBlock>
            <HobbiesTitle size={20} weight={600}>
              Спорт
            </HobbiesTitle>
            <Tags
              tags={defaultTags.filter(tag => tag.category === 'sport')}
              selectedTags={hobbiesSelectedTags}
              onSelectedTagsChange={onSportChange}
              styledFragment={TagsWrapperFragment}
            />
          </TagsBlock>
          <CustomHobbies tags={customTags} setTags={setCustomTags} />
        </Wrapper>
      </CommonSlideLayout>
    </StepContentLayout>
  )
}

export default Hobbies
