import React, { ReactNode } from 'react'
import styled from 'styled-components'
import WebIcon from '../../Images/icons/WebIcon'
import TelegramIcon from '../../Images/icons/TelegramIcon'
import VKIcon from '../../Images/icons/VKIcon'
import YouTubeIcon from '../../Images/icons/YouTubeIcon'

const medias = ['VK', 'Telegram', 'YouTube', 'Web'] as const

type MediaName = (typeof medias)[number]

type Media = {
  link: string
  icon: ReactNode
}

const mediaConfig: Record<MediaName, Media> = {
  Web: {
    link: 'https://itmo.ru/',
    icon: <WebIcon />,
  },
  Telegram: {
    link: 'https://t.me/itmoru',
    icon: <TelegramIcon />,
  },
  VK: {
    link: 'https://vk.com/itmoru',
    icon: <VKIcon />,
  },
  YouTube: {
    link: 'https://youtube.com/@IfmoRu',
    icon: <YouTubeIcon />,
  },
}

const StyledLink = styled.a`
  width: 32px;
  height: 32px;
  padding: 0;
  cursor: pointer;
  border-radius: 50%;
  background-color: #1F1F22;
  transition: all .3s;

  @media (hover: hover) {
    &:hover {
      background-color: #712FD3;
    }
  }

  &:active {
    background-color: #5722A5;
  }

  * {
    pointer-events: none;
  }
`

const List = styled.div`
  display: flex;
  gap: 12px;
`

type SocialLinkProps = {
  mediaName: MediaName
}

const SocialMediaLink = ({ mediaName }: SocialLinkProps) => {
  return (
    <StyledLink href={mediaConfig[mediaName].link} target="_blank">
      {mediaConfig[mediaName].icon}
    </StyledLink>
  )
}

export const SocialMediaList = () => {
  return (
    <List>
      {medias.map(media => (
        <SocialMediaLink key={media} mediaName={media} />
      ))}
    </List>
  )
}

export default SocialMediaLink
