import React, { ReactNode, useState } from 'react'
import { Content, Header, Title, Wrapper } from './styled'
import Plus from 'Images/icons/plus'
import styled from 'styled-components';
interface MobileCardContentProps {
  title: ReactNode
  className?: string
  children: ReactNode
}

const PlusWrapper = styled.div`
  width: 40px;
  height: 40px;
`

const Card = (props: MobileCardContentProps) => {
  const { title, children, className } = props
  const [isOpen, setIsOpen] = useState(false)

  const toggleCard = () => {
    setIsOpen(!isOpen)
  }

  return (
    <Wrapper className={className}>
      <Header onClick={toggleCard}>
        <Title>{title}</Title>
        <PlusWrapper>
          <Plus width={40} height={40} rotate={isOpen ? 45 : 0} />
        </PlusWrapper>
      </Header>
      <Content isOpen={isOpen}>{children}</Content>
    </Wrapper>
  )
}

export default Card
