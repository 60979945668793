import React from 'react';

export const InformationSecurity = () => {
	return (
		<svg width="114" height="148" viewBox="0 0 114 172" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="2.75" y="82.0459" width="51.7478" height="9.88228" rx="4.94114" fill="#C1D5F6" stroke="#1F1F22"
			      strokeWidth="0.5"></rect>
			<path
				d="M10.8263 88.5477L10.872 87.3016L9.80976 87.97L9.53564 87.4942L10.655 86.9164L9.53564 86.3387L9.80976 85.8629L10.872 86.5313L10.8263 85.2852H11.3745L11.3288 86.5313L12.391 85.8629L12.6652 86.3387L11.5458 86.9164L12.6652 87.4942L12.391 87.97L11.3288 87.3016L11.3745 88.5477H10.8263Z"
				fill="#1F1F22"></path>
			<path
				d="M15.8518 88.5477L15.8975 87.3016L14.8353 87.97L14.5612 87.4942L15.6805 86.9164L14.5612 86.3387L14.8353 85.8629L15.8975 86.5313L15.8518 85.2852H16.4L16.3543 86.5313L17.4166 85.8629L17.6907 86.3387L16.5714 86.9164L17.6907 87.4942L17.4166 87.97L16.3543 87.3016L16.4 88.5477H15.8518Z"
				fill="#1F1F22"></path>
			<path
				d="M20.8773 88.5477L20.923 87.3016L19.8608 87.97L19.5867 87.4942L20.706 86.9164L19.5867 86.3387L19.8608 85.8629L20.923 86.5313L20.8773 85.2852H21.4255L21.3799 86.5313L22.4421 85.8629L22.7162 86.3387L21.5969 86.9164L22.7162 87.4942L22.4421 87.97L21.3799 87.3016L21.4255 88.5477H20.8773Z"
				fill="#1F1F22"></path>
			<path
				d="M25.9028 88.5477L25.9485 87.3016L24.8863 87.97L24.6122 87.4942L25.7315 86.9164L24.6122 86.3387L24.8863 85.8629L25.9485 86.5313L25.9028 85.2852H26.4511L26.4054 86.5313L27.4676 85.8629L27.7417 86.3387L26.6224 86.9164L27.7417 87.4942L27.4676 87.97L26.4054 87.3016L26.4511 88.5477H25.9028Z"
				fill="#1F1F22"></path>
			<path
				d="M30.9283 88.5477L30.974 87.3016L29.9118 87.97L29.6377 87.4942L30.757 86.9164L29.6377 86.3387L29.9118 85.8629L30.974 86.5313L30.9283 85.2852H31.4766L31.4309 86.5313L32.4931 85.8629L32.7672 86.3387L31.6479 86.9164L32.7672 87.4942L32.4931 87.97L31.4309 87.3016L31.4766 88.5477H30.9283Z"
				fill="#1F1F22"></path>
			<rect x="2.75" y="67.0925" width="51.7478" height="9.88228" rx="4.94114" fill="#C1D5F6" stroke="#1F1F22"
			      strokeWidth="0.5"></rect>
			<path
				d="M10.8263 73.5944L10.872 72.3482L9.80976 73.0166L9.53564 72.5408L10.655 71.9631L9.53564 71.3853L9.80976 70.9095L10.872 71.5779L10.8263 70.3318H11.3745L11.3288 71.5779L12.391 70.9095L12.6652 71.3853L11.5458 71.9631L12.6652 72.5408L12.391 73.0166L11.3288 72.3482L11.3745 73.5944H10.8263Z"
				fill="#1F1F22"></path>
			<path
				d="M15.8518 73.5944L15.8975 72.3482L14.8353 73.0166L14.5612 72.5408L15.6805 71.9631L14.5612 71.3853L14.8353 70.9095L15.8975 71.5779L15.8518 70.3318H16.4L16.3543 71.5779L17.4166 70.9095L17.6907 71.3853L16.5714 71.9631L17.6907 72.5408L17.4166 73.0166L16.3543 72.3482L16.4 73.5944H15.8518Z"
				fill="#1F1F22"></path>
			<path
				d="M20.8773 73.5944L20.923 72.3482L19.8608 73.0166L19.5867 72.5408L20.706 71.9631L19.5867 71.3853L19.8608 70.9095L20.923 71.5779L20.8773 70.3318H21.4255L21.3799 71.5779L22.4421 70.9095L22.7162 71.3853L21.5969 71.9631L22.7162 72.5408L22.4421 73.0166L21.3799 72.3482L21.4255 73.5944H20.8773Z"
				fill="#1F1F22"></path>
			<path
				d="M25.9028 73.5944L25.9485 72.3482L24.8863 73.0166L24.6122 72.5408L25.7315 71.9631L24.6122 71.3853L24.8863 70.9095L25.9485 71.5779L25.9028 70.3318H26.4511L26.4054 71.5779L27.4676 70.9095L27.7417 71.3853L26.6224 71.9631L27.7417 72.5408L27.4676 73.0166L26.4054 72.3482L26.4511 73.5944H25.9028Z"
				fill="#1F1F22"></path>
			<path
				d="M7.84694 112.408L7.5 145.367H25.5411C26.1194 134.612 27.2759 112.824 27.2759 111.714C27.2759 110.603 25.8881 110.326 25.1942 110.326H9.58167C8.19389 110.326 7.84694 111.714 7.84694 112.408Z"
				fill="#677CE8" stroke="#1F1F22" strokeWidth="0.5"></path>
			<rect className="svgAnime translateY(-11px) n0"  x="13.8066" y="106.25" width="27.4696" height="34.2502"
			      fill="white" stroke="#1F1F22" strokeWidth="0.5"></rect>
			<rect className="svgAnime translateY(-8px) n1" x="18.0444" y="109.64" width="28.3172" height="34.2502"
			      fill="white" stroke="#1F1F22" strokeWidth="0.5"></rect>
			<path
				d="M15.8267 113.795C12.2185 113.795 12.3572 115.877 12.0103 116.918L7.5 145.367H50.8681C52.1402 137.156 54.8233 120.04 55.3784 117.265C55.9335 114.489 54.4532 113.795 53.6437 113.795H15.8267Z"
				fill="#C1D5F6" stroke="#1F1F22" strokeWidth="0.5"></path>
			<g className="svgAnime rotate(13deg) n2">
				<rect x="20.75" y="39.25" width="21.5" height="20.7667" rx="1.21667" fill="#677CE8" stroke="#1F1F22"
				      strokeWidth="0.5"></rect>
				<path fillRule="evenodd" clipRule="evenodd"
				      d="M36.5776 53.5386V54.2092C36.5776 54.5794 36.2912 54.8798 35.9383 54.8798H28.2667C27.9138 54.8798 27.6274 54.5794 27.6274 54.2092V53.5386V53.5279C27.6274 51.4986 29.8381 50.1802 32.1025 50.1802C34.3669 50.1802 36.5776 51.4993 36.5776 53.5279"
				      fill="#C1D5F6"></path>
				<path fillRule="evenodd" clipRule="evenodd"
				      d="M33.7778 44.6021C34.703 45.5197 34.703 47.0076 33.7778 47.9252C32.8526 48.8429 31.3525 48.8429 30.4273 47.9252C29.5021 47.0076 29.5021 45.5197 30.4273 44.6021C31.3525 43.6844 32.8526 43.6844 33.7778 44.6021"
				      fill="#C1D5F6"></path>
			</g>
			<path
				d="M79.9952 26C75.2631 33.0633 56.7291 40.9114 46.8705 43.6582C46.8705 46.405 46.0369 55.4547 46.8705 69.5569C48.0536 89.5695 51.6027 98.9872 78.8121 117.823C107.205 98.9872 110.754 88.3923 111.937 69.5569V43.6582C97.7405 41.3038 84.7273 33.0633 79.9952 26Z"
				fill="#677CE8" stroke="#1F1F22" strokeWidth="0.75"></path>
			<path className="svgAnime translateX(-7px) n3" fillRule="evenodd" clipRule="evenodd"
			      d="M92.9412 66.0371V85.6184C87.5868 88.882 72.4607 89.2735 65.5 85.6184V66.0371H92.9412ZM78.2167 82.3549V77.1332C77.5474 76.6981 76.2088 75.4362 76.2088 73.8697C76.2088 71.9115 78.2167 70.6061 79.5553 70.6061C80.8939 70.6061 82.9018 71.9115 82.9018 73.8697C82.9018 75.4362 81.5632 76.6981 80.8939 77.1332V82.3549C80.8939 82.79 80.6261 83.6603 79.5553 83.6603C78.4844 83.6603 78.2167 82.79 78.2167 82.3549Z"
			      fill="white"></path>
			<path className="svgAnime translateX(16px) n4"
			      d="M70.1286 64C70.1286 60.8421 71.4429 54.5263 78.6714 54.5263C80.9934 54.5263 82.773 55.1129 84.119 56.0347C86.9631 57.9827 87.8714 61.4278 87.8714 64H90.5C90.5 61.3808 89.6276 56.6496 85.7859 53.9771C84.0695 52.7831 81.7603 52 78.6714 52C68.8143 51.9999 67.5 60.2106 67.5 64H70.1286Z"
			      fill="white"></path>
		</svg>
	);
};

export default InformationSecurity;