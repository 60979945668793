import {
  css,
  DefaultTheme,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  ThemeProps,
} from 'styled-components'

const MOBILE_MAX_WIDTH = 1000

export const Fragments = {
  hidden: css`display: none`,
  centered: css`display: flex;
    align-items: center;
    justify-content: center`,
}

export type Prepared<ComponentPropType> = ComponentPropType & {
  make?: (keyof typeof Fragments)[] | keyof typeof Fragments
  fragment?: FlattenSimpleInterpolation | undefined
  mobileBreakpoint?: number

  mobile?: ComponentPropType & {
    make?: (keyof typeof Fragments)[] | keyof typeof Fragments
    fragment?: FlattenSimpleInterpolation | undefined
  }

  desktop?: ComponentPropType & {
    make?: (keyof typeof Fragments)[] | keyof typeof Fragments
    fragment?: FlattenSimpleInterpolation | undefined
  }
}

function parseMake(make?: (keyof typeof Fragments)[] | keyof typeof Fragments) {
  if (!make) {
    return css``
  }
  if (typeof make == 'string') {
    return Fragments[make]
  }
  return make.map(infusion => Fragments[infusion])?.join(' ')
}

function prepare<ComponentProps>(
  props: Prepared<ComponentProps>,
  processor: (props: ComponentProps) => FlattenInterpolation<ThemeProps<DefaultTheme>>,
) {
  return css`
    ${processor(props)};
    ${parseMake(props.make)};
    ${props.fragment};

    @media (max-width: ${props.mobileBreakpoint || MOBILE_MAX_WIDTH}px) {
      ${props.mobile && processor(Object.assign({ ...props }, props.mobile))};
      ${props.mobile && parseMake(props.mobile.make)};
      ${props.mobile && props.mobile.fragment};
    }

    @media (min-width: ${props.mobileBreakpoint || MOBILE_MAX_WIDTH}px) {
      ${props.desktop && processor(Object.assign({ ...props }, props.desktop))};
      ${props.desktop && parseMake(props.desktop.make)};
      ${props.desktop && props.desktop.fragment};
    }
  `
}

export default prepare
