import styled, { FlattenSimpleInterpolation } from 'styled-components'

export const DisciplineModalWrapper = styled.div<{ withStickyBlock?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  max-height: calc(100vh - 80px);
  padding: 0 16px 16px;
  transition: all .3s;

  @media screen and (max-width: 1000px) {
    padding: ${({ withStickyBlock }) => (withStickyBlock ? '72px' : '20px')} 16px 16px;
    gap: 20px;
    height: unset;
    overflow-y: auto;
  }
`

export const DisciplineTitle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 30px;
  justify-content: space-between;

  @media screen and (max-width: 1000px) {
    flex-direction: column-reverse;
    gap: 20px;
  }
`

export const DisciplineModalTitle = styled.span`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  color: #1F1F22;
  text-align: left;

  &::first-letter {
    text-transform: capitalize;
  }

  @media screen and (max-width: 1000px) {
    max-width: calc(100% - 30px);
  }
`

export const DropdownButtonWrapper = styled.div`
  position: relative;
  height: min-content;
  width: min-content;

  @media screen and (max-width: 1000px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px 16px 8px;
    background-color: white;
    box-shadow: 0 4px 10px 0 rgba(31, 31, 34, 0.06), 0 0 1px rgba(31, 31, 34, 0.10);
  }
`

export const DropdownButton = styled.button<{ isActive?: boolean }>`
  display: flex;
  height: 40px;
  padding: 4px 8px 4px 12px;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border-radius: 8px;
  background: ${({ isActive }) => (isActive ? '#EBEBFD' : '#F4F4FB')};
  flex-wrap: nowrap;
  white-space: nowrap;
  width: min-content;
  transition: background .3s;

  &:hover {
    background-color: #EBEBFD;
  }

  @media screen and (max-width: 1000px) {
    font-size: 14px;
    line-height: 20px;
    height: 32px;
    padding: 4px 4px 4px 10px;
  }
`

export const DropdownWrapper = styled.div`
  position: absolute;
  width: 352px;
  right: 0;
  top: calc(100% + 8px);
  max-height: 30vh;
  box-shadow: 0 4px 10px 0 rgba(31, 31, 34, 0.06), 0 0 1px 0 rgba(31, 31, 34, 0.10);
  overflow: auto;
  border-radius: 12px;
  z-index: 1;
`

export const DropDownModalWrapper = styled.div`
  padding: 20px 16px;
`

export const DropDownModalTitle = styled.div`
  color: #1F1F22;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 20px;
`

export const SecondaryTitle = styled.span<{ fragment?: FlattenSimpleInterpolation }>`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: #1F1F22 ${({ fragment }) => fragment};
`

export const DisciplineInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`

export const TagsWrapper = styled.div`
  display: flex;
  gap: 12px;
  order: 2
`
export const InfoTag = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  display: flex;
  padding: 6px 8px;
  align-items: center;
  border-radius: 8px;
  color: #1F1F22;
`

export const DisciplineDescription = styled.span`
  color: #1F1F22;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  order: 3;
`

export const KeywordsTitle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
`

export const KeywordsCoverageTitle = styled.span`
  color: #677CE8;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`

export const CoverageWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const PromptWrapper = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
`

export const DisciplineKeywordsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

export const HintText = styled.div`
  width: 311px;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

export const Tag = styled.div<{ bgColor: string }>`
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 8px;
  padding: 8px 12px;
  color: #1F1F22;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
`
