import React from 'react';
const Checkmark = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M10 1.875C5.51269 1.875 1.875 5.51269 1.875 10C1.875 14.4873 5.51269 18.125 10 18.125C14.4873 18.125 18.125 14.4873 18.125 10C18.125 5.51269 14.4873 1.875 10 1.875ZM10 3.125C6.20304 3.125 3.125 6.20304 3.125 10C3.125 13.797 6.20304 16.875 10 16.875C13.797 16.875 16.875 13.797 16.875 10C16.875 6.20304 13.797 3.125 10 3.125Z" fill="#8533FF"/>
			<path d="M13.3472 8.44443C13.5913 8.20036 13.5913 7.80463 13.3472 7.56055C13.1031 7.31647 12.7074 7.31647 12.4633 7.56055L8.83111 11.1928L7.47889 9.84055C7.23481 9.59647 6.83908 9.59647 6.595 9.84055C6.35093 10.0846 6.35093 10.4804 6.595 10.7244L8.40084 12.5303C8.64492 12.7743 9.04064 12.7743 9.28472 12.5303C9.31958 12.4954 9.34946 12.4575 9.37437 12.4173L13.3472 8.44443Z" fill="#8533FF"/>
		</svg>
	);
};

export default Checkmark;