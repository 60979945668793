import styled from 'styled-components';

export const TabsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 24px;
  border-right: 1px solid #DEDEDE;
`

export const Tab = styled.div<{isActive: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  width: 370px;
  padding: 16px 24px;
  background: ${(props) => props.isActive ? props.theme.color.background.tags.main : props.theme.color.background.withStroke};
  transition: all 0.3s;
  border-radius: 20px;
  cursor: pointer;
`