import React from 'react';
import PercentProgress from '../../../../std-lib/UiKit/PercentProgress';
import { Wrapper, NameAndCodeWrapper, CoverageWrapper, LinkWrapper, Link, NameText, CodeText } from './styled';
import Arrow from '../../../../Images/icons/arrow';
import Text from '../../../../std-lib/FastUI/Components/Text';
import { css } from 'styled-components';

interface TrajectoryProps {
  coverage: number;
  name: string;
  code: string;
  onClick: () => void;
}

const Trajectory = (props: TrajectoryProps) => {
  const { name, coverage, code, onClick } = props;
  return (
    <Wrapper onClick={onClick}>
      <CoverageWrapper>
        <PercentProgress percent={coverage} />
        <Text
          size={14}
          weight={400}
          fragment={css`
            @media screen and (max-width: 1000px) {
              font-size: 12px;
            }
          `}
        >
          Подходит на {Math.floor(coverage * 100)}%
        </Text>
      </CoverageWrapper>
      <NameAndCodeWrapper>
        <NameText
          size={20}
          weight={600}
        >
          {name}
        </NameText>
        <CodeText
          size={14}
          weight={400}
        >
          {code}
        </CodeText>
      </NameAndCodeWrapper>
      <LinkWrapper>
        <Link>Изучить траекторию</Link>
        <Arrow />
      </LinkWrapper>
    </Wrapper>
  );
};

export default Trajectory;
