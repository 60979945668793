import React, { useContext, useEffect } from 'react'
import { AxiosError } from 'axios'
import NotFound from './NotFound/NotFound'
import UnknownError from './UnknownError/UnknownError'
import { StyleContext } from 'std-lib/Сontext/StyleContext'
import { sendTypedEvent } from 'Utils/events/sendEvent'
import { primaryThemeFragment } from 'std-lib/Stylesheets/StyledVars/newVars'

// CONSTANTS
const customCodeTitles: Record<string, string> = {
  '400': 'С запросом что-то не так',
  '403': 'А доступов то нету',
  '404': 'Заблудились?',
  '405': 'Есть такое, но такого нет',
  '500': 'Мы упали, но мы обязательно встанем',
  '501': 'Такое сервер не умеет',
  '503': 'Мы перегрузились',
  // '503': 'Не затащили',
}

const generalCodeTitles: Record<string, string> = {
  '4': 'Что-то не так с сайтом',
  '5': 'Что-то не так с сервером',
}

type ErrorPageProps = {
  error?: any
}

const ErrorPage = ({ error }: ErrorPageProps) => {
  const { setThemeFragment } = useContext(StyleContext)

  useEffect(() => {
    setThemeFragment(primaryThemeFragment)
    sendTypedEvent('visit', 'error_page')
  })

  console.log(error)
  if (error?.message == 'Not Found Page') {
    return (
      <NotFound
        code={404}
        title={
          'Кажется, эта траектория завела вас не туда. <br/>Выбрать верный путь можно тут.'
        }
      />
    )
  }

  if (error instanceof AxiosError) {
    const code = error.request.status
    const codeType = String(code)[0]
    if ((code && customCodeTitles[code]) || generalCodeTitles[codeType]) {
      const title = customCodeTitles[code] ?? generalCodeTitles[codeType]
      return <NotFound code={code} title={title} />
    }
  }

  return <UnknownError />
}

export default ErrorPage
