import React from 'react'

const Urbanism = () => {
  return (
    <svg
      width="113"
      height="148"
      viewBox="0 0 113 148"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="15.45"
        y="136.55"
        width="46.1"
        height="81.1"
        transform="rotate(-90 15.45 136.55)"
        stroke="#1F1F22"
        strokeWidth="0.9"
      />
      <path
        d="M18.45 133.55L18.45 131.464L18.45 128.928L18.45 126.392L18.45 120.474L18.45 93.45L92.55 93.45L92.55 133.55L32.7472 133.55L18.45 133.55Z"
        stroke="#1F1F22"
        strokeWidth="0.9"
      />
      <path
        d="M51.95 106.849V115H61.2667V106.849M61.2667 106.849H50L56.5 103L63 106.849H61.2667Z"
        stroke="#1F1F22"
        strokeWidth="0.9"
      />
      <g className="svgAnime opacity(0) n0">
        <path
          d="M72.95 115V106.849H71L77.5 103L84 106.849H82.2667V115H72.95Z"
          fill="#F5A171"
          opacity="1"
        />
        <path
          d="M30.95 115V106.849H29L35.5 103L42 106.849H40.2667V115H30.95Z"
          fill="#F5A171"
        />
      </g>
      <path
        d="M72.95 106.849V115H82.2667V106.849M82.2667 106.849H71L77.5 103L84 106.849H82.2667Z"
        stroke="#1F1F22"
        strokeWidth="0.9"
      />
      <path
        d="M30.95 106.849V115H40.2667V106.849M40.2667 106.849H29L35.5 103L42 106.849H40.2667Z"
        stroke="#1F1F22"
        strokeWidth="0."
      />
      <path
        d="M15 24.1798V82H21.5V71.5H27.5H33V82H40V24.1798H37.2222H28.8889H15Z"
        fill="#F8CFB9"
      />
      <path
        d="M28.8889 24.1798H15V82H21.5M28.8889 24.1798V20H37.2222V24.1798M28.8889 24.1798H37.2222M37.2222 24.1798H40V82H33M21.5 82H27.5H33M21.5 82V71.5H27.5H33V82"
        stroke="#1F1F22"
        strokeWidth="0.75"
      />
      <rect
        x="17.375"
        y="29.375"
        width="9.25"
        height="5.25"
        fill="white"
        stroke="#1F1F22"
        strokeWidth="0.75"
      />
      <rect
        x="28.375"
        y="29.375"
        width="9.25"
        height="5.25"
        fill="white"
        stroke="#1F1F22"
        strokeWidth="0.75"
      />
      <rect
        x="17.375"
        y="37.375"
        width="9.25"
        height="5.25"
        fill="white"
        stroke="#1F1F22"
        strokeWidth="0.75"
      />
      <rect
        x="28.375"
        y="37.375"
        width="9.25"
        height="5.25"
        fill="white"
        stroke="#1F1F22"
        strokeWidth="0.75"
      />
      <rect
        x="17.375"
        y="45.375"
        width="9.25"
        height="5.25"
        fill="white"
        stroke="#1F1F22"
        strokeWidth="0.75"
      />
      <rect
        x="28.375"
        y="45.375"
        width="9.25"
        height="5.25"
        fill="white"
        stroke="#1F1F22"
        strokeWidth="0.75"
      />
      <rect
        x="17.375"
        y="53.375"
        width="9.25"
        height="5.25"
        fill="white"
        stroke="#1F1F22"
        strokeWidth="0.75"
      />
      <rect
        x="28.375"
        y="53.375"
        width="9.25"
        height="5.25"
        fill="white"
        stroke="#1F1F22"
        strokeWidth="0.75"
      />
      <rect
        x="17.375"
        y="61.375"
        width="9.25"
        height="5.25"
        fill="white"
        stroke="#1F1F22"
        strokeWidth="0.75"
      />
      <rect
        x="28.375"
        y="61.375"
        width="9.25"
        height="5.25"
        fill="white"
        stroke="#1F1F22"
        strokeWidth="0.75"
      />

      <rect
        x="50.375"
        y="57.375"
        width="15.25"
        height="5.25"
        fill="#F5A171"
        stroke="#1F1F22"
        strokeWidth="0.75"
      />
      <rect
        x="50.375"
        y="65.375"
        width="6.25"
        height="5.25"
        fill="#F5A171"
        stroke="#1F1F22"
        strokeWidth="0.75"
      />
      <rect
        x="59.375"
        y="65.375"
        width="6.25"
        height="5.25"
        fill="#F5A171"
        stroke="#1F1F22"
        strokeWidth="0.75"
      />
      <path
        d="M47 54V68V82H56M69 54V68V82H60M56 82V75H60V82M56 82H60"
        stroke="#1F1F22"
        strokeWidth="0.75"
      />

      <g className="svgAnime translateY(-23px) n1">
        <path
          d="M47 82V54L58 43L63 48M69 82V54L65.5 50.5M63 48V44H65.5V50.5M63 48L65.5 50.5"
          stroke="#1F1F22"
          strokeWidth="0.75"
        />
        <rect
          x="50.375"
          y="57.375"
          width="15.25"
          height="5.25"
          fill="#F5A171"
          stroke="#1F1F22"
          strokeWidth="0.75"
        />
        <rect
          x="50.375"
          y="65.375"
          width="6.25"
          height="5.25"
          fill="#F5A171"
          stroke="#1F1F22"
          strokeWidth="0.75"
        />
        <rect
          x="59.375"
          y="65.375"
          width="6.25"
          height="5.25"
          fill="#F5A171"
          stroke="#1F1F22"
          strokeWidth="0.75"
        />
      </g>

      <path
        className="svgAnime opacity(1) n2"
        d="M51.95 115V106.849H50L56.5 103L63 106.849H61.2667V115H51.95Z"
        fill="#F5A171"
        opacity="0"
      />

      <g className="svgAnime rotate(-36deg) n3">
        <path
          d="M86.9461 38.0324C87.2149 37.9805 88.9328 39.8537 89.491 40.3261L92.9941 36.8646L90.383 34.2356L90.5922 31.7583L92.8781 30.9688L91.1483 25.7327L88.5925 26.9225L86.9841 25.8405L87.1272 23.0279L82.0926 22.2593L81.2775 25.2018L79.5975 25.5262L77.5869 24.1737L74.5521 28.241L76.5627 29.5935L76.3535 32.0708L73.3956 32.9901L75.0593 37.8908L77.6812 37.0364L79.5595 37.7181L79.1465 40.931L84.2472 42.0349L84.6602 38.822C85.3102 38.5804 86.6773 38.0844 86.9461 38.0324Z"
          fill="#F8CFB9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72.9589 32.7678L76.0316 31.8128L76.2046 29.764L74.0631 28.3236L77.5067 23.7084L79.6706 25.164L81.0038 24.9066L81.8433 21.876L87.485 22.7372L87.336 25.6659L88.6224 26.5313L91.3525 25.2604L93.3088 31.182L90.9149 32.0088L90.7376 34.1081L93.4784 36.8678L89.5098 40.7893L89.27 40.5863C89.0705 40.4175 88.7308 40.0794 88.3705 39.7208C88.2044 39.5555 88.0339 39.3858 87.8708 39.2263C87.605 38.9665 87.3551 38.7299 87.1576 38.5627C87.0617 38.4816 86.9863 38.4237 86.9321 38.3884C86.8724 38.4054 86.7862 38.432 86.6746 38.4684C86.5019 38.5249 86.2883 38.5984 86.061 38.6783C85.6894 38.8091 85.2855 38.9559 84.9737 39.0708L84.5396 42.4475L78.7669 41.1982L79.185 37.9453L77.6763 37.3978L74.8441 38.3207L72.9589 32.7678ZM75.2746 37.461L77.6862 36.6751L79.9342 37.491L79.5263 40.664L83.9549 41.6224L84.3468 38.5736L84.5398 38.5019C84.866 38.3807 85.3723 38.1956 85.8313 38.034C86.0606 37.9533 86.2798 37.8779 86.4594 37.8192C86.6305 37.7632 86.7877 37.715 86.88 37.6972C86.9956 37.6748 87.0943 37.7095 87.1394 37.7275C87.195 37.7497 87.2479 37.78 87.2939 37.8094C87.3869 37.8689 87.4919 37.9512 87.5998 38.0424C87.8174 38.2266 88.0823 38.4781 88.3494 38.7391C88.5322 38.9179 88.7149 39.0998 88.8871 39.2712C89.11 39.4931 89.3153 39.6975 89.4806 39.8549L92.5099 36.8617L90.0286 34.3633L90.2697 31.508L92.4476 30.7558L90.9442 26.2052L88.5628 27.3139L86.6323 26.0153L86.7695 23.3187L82.342 22.6429L81.5514 25.4971L79.5245 25.8885L77.6673 24.6392L75.0412 28.1587L76.9209 29.4231L76.6755 32.3289L73.8323 33.2125L75.2746 37.461Z"
          fill="#1F1F22"
        />
        <path
          d="M82.9467 28.3608C85.1735 27.9309 87.3339 29.3841 87.7721 31.6067C88.2103 33.8293 86.7603 35.9796 84.5335 36.4096C82.3067 36.8396 80.1463 35.3864 79.7081 33.1637C79.2699 30.9411 80.7199 28.7908 82.9467 28.3608Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M87.1001 31.7364C86.7349 29.8843 84.9346 28.6732 83.0789 29.0316C81.2233 29.3899 80.015 31.1818 80.3801 33.034C80.7452 34.8862 82.5456 36.0972 84.4012 35.7389C86.2569 35.3805 87.4652 33.5886 87.1001 31.7364ZM87.7721 31.6067C87.3339 29.3841 85.1735 27.9309 82.9467 28.3608C80.7199 28.7908 79.2699 30.9411 79.7081 33.1637C80.1463 35.3864 82.3067 36.8396 84.5335 36.4096C86.7603 35.9796 88.2103 33.8293 87.7721 31.6067Z"
          fill="#1F1F22"
        />
      </g>
    </svg>
  )
}

export default Urbanism
