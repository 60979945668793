import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    border-radius: 20px;
    border: 1px solid #DEDEDE;
    gap: 32px;
    min-width: 307px;
    height: 312px;
    max-width: 350px;

    @media screen and (max-width: 1000px) {
        width: 255px;
        min-width: 312px;
    }
`

export const Tag = styled.div<{ background: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    padding: 8px 20px;
    border-radius: 20px;
    background: ${props => props.background};
`

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
`
