import React, { useContext } from 'react'
import Rectangle from 'std-lib/FastUI/Components/Rectangle'
import Flex from '../../../std-lib/FastUI/Components/Flex'
import Button from '../../../std-lib/FastUI/Components/Button'
import Img from '../../../std-lib/FastUI/Components/Img'
import Text from '../../../std-lib/FastUI/Components/Text'
import { NotificationConfig } from '../types'
import { css } from 'styled-components'
import NotificationsContext from '../context'
import Cross from '../assets/cross.svg'

const NotificationCard = ({
  title,
  description,
  buttonText,
  image,
  imageAlt,
  onClick,
}: NotificationConfig) => {
  const { closeNotification } = useContext(NotificationsContext)
  return (
    <Rectangle
      borderRadius={16}
      width={500}
      background={'white'}
      padding={'24px 16px 16px 16px'}
      mobile={{
        width: '100%',
        padding: '22px 12px 12px 12px',
      }}
      fragment={css`
        box-shadow: 4px 4px 16px 0px rgba(97, 120, 201, 0.12);
      `}
    >
      <Flex direction="column">
        <Flex direction="row" gap={16} alignItems={'flex-start'}>
          <Img src={image} alt={imageAlt} />
          <Flex direction="column" gap={8} alignItems={'flex-end'} mobile={{ gap: 8 }}>
            <Flex
              direction="row"
              gap={10}
              justifyContent={'stretch'}
              alignItems={'center'}
              fragment={css`align-self: stretch`}
            >
              <Text size={16} weight={600} fragment={css`flex-grow: 2`}>
                {title}
              </Text>
              <Button
                padding={0}
                width={24}
                height={24}
                size={15}
                onClick={closeNotification}
                make={'centered'}
              >
                <Img src={Cross} />
              </Button>
            </Flex>
            <Text size={14} weight={400}>
              {description}
            </Text>
            <Button
              size={14}
              weight={400}
              onClick={onClick}
              background={'#F4F4FB'}
              borderRadius={40}
              padding={'12px 40px'}
              make={'centered'}
              width={'max-content'}
            >
              {buttonText}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Rectangle>
  )
}

export default NotificationCard
