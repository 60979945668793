import React from 'react';

const BusinessAnalyst = () => {
	return (
		<svg width="113" height="148" viewBox="0 0 113 148" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g className="svgAnime scaleY(1.297) n0">
				<path d="M33 61H53L53 45H33V61Z" fill="#F0C64C" stroke="#1F1F22" strokeMiterlimit="10" strokeLinecap="round"
				      strokeLinejoin="round"></path>
				<path d="M60 61H80V40H60L60 61Z" fill="#F7E1A0" stroke="#1F1F22" strokeMiterlimit="10"></path>
				<path d="M7 61H27L27 50H7L7 61Z" fill="#F7E1A0" stroke="#1F1F22" strokeMiterlimit="10"></path>
				<path d="M87 61H106V34H87V61Z" fill="#F0C64C" stroke="#1F1F22" strokeMiterlimit="10" strokeLinecap="round"
				      strokeLinejoin="round"></path>
			</g>

			<path className="svgAnime translateY(-3px) n1"
			      d="M7 44L18.4968 32.5714L36.6497 38.7755L58.4331 21.1429L71.7452 28.3265L102 12" stroke="#1F1F22"
			      strokeLinecap="round"></path>

			<rect x="58.9648" y="76.5" width="44.0704" height="63" fill="#F7E1A0" stroke="#1F1F22"></rect>
			<rect x="63.4718" y="81.9084" width="35.0563" height="7.11268" fill="white" stroke="#1F1F22"></rect>
			<path className="svgAnime translateY(-6px) n2" d="M94 93.5H97V91M97 91V89.5H94V91H97Z" stroke="#1F1F22"
			      strokeLinecap="round"></path>
			<path className="svgAnime translateY(-6px) n3" d="M89 93.5H92V91M92 91V89.5H89V91H92Z" stroke="#1F1F22"
			      strokeLinecap="round"></path>
			<path className="svgAnime translateY(-6px) n4" d="M84 93.5H87V91M87 91V89.5H84V91H87Z" stroke="#1F1F22"
			      strokeLinecap="round"></path>
			<rect x="60" y="89.5" width="42" height="6" fill="#F7E1A0"></rect>
			<rect x="64.3732" y="94.5281" width="6.21127" height="7.11268" fill="white" stroke="#1F1F22"></rect>
			<rect x="64.3732" y="104.444" width="6.21127" height="7.11268" fill="white" stroke="#1F1F22"></rect>
			<rect x="64.3732" y="114.359" width="6.21127" height="7.11268" fill="white" stroke="#1F1F22"></rect>
			<rect x="64.3732" y="124.275" width="6.21127" height="7.11268" fill="#F0C64C" stroke="#1F1F22"></rect>
			<rect x="73.3873" y="94.5281" width="6.21127" height="7.11268" fill="white" stroke="#1F1F22"></rect>
			<rect x="73.3873" y="104.444" width="6.21127" height="7.11268" fill="white" stroke="#1F1F22"></rect>
			<rect x="73.3873" y="114.359" width="6.21127" height="7.11268" fill="white" stroke="#1F1F22"></rect>
			<rect x="73.3873" y="124.275" width="6.21127" height="7.11268" fill="#F0C64C" stroke="#1F1F22"></rect>
			<rect x="82.4014" y="94.5281" width="6.21127" height="7.11268" fill="white" stroke="#1F1F22"></rect>
			<rect x="91.4155" y="94.5281" width="6.21127" height="7.11268" fill="#F0C64C" stroke="#1F1F22"></rect>
			<rect x="91.4155" y="104.444" width="6.21127" height="7.11268" fill="#F0C64C" stroke="#1F1F22"></rect>
			<rect x="91.4155" y="114.359" width="6.21127" height="7.11268" fill="#F0C64C" stroke="#1F1F22"></rect>
			<rect x="91.4155" y="124.275" width="6.21127" height="7.11268" fill="#F0C64C" stroke="#1F1F22"></rect>
			<rect x="82.4014" y="104.444" width="6.21127" height="7.11268" fill="white" stroke="#1F1F22"></rect>
			<rect x="82.4014" y="114.359" width="6.21127" height="7.11268" fill="white" stroke="#1F1F22"></rect>
			<rect x="82.4014" y="124.275" width="6.21127" height="7.11268" fill="#F0C64C" stroke="#1F1F22"></rect>
			<rect x="9.5" y="131.5" width="29" height="8" fill="#F0C64C" stroke="#1F1F22"></rect>
			<rect x="15.5" y="123.5" width="29" height="8" fill="#F0C64C" stroke="#1F1F22"></rect>
			<rect x="15.5" y="115.5" width="29" height="8" fill="#F0C64C" stroke="#1F1F22"></rect>
			<rect x="9.5" y="107.5" width="29" height="8" fill="#F0C64C" stroke="#1F1F22"></rect>
			<rect x="9.5" y="99.5" width="29" height="8" fill="#F0C64C" stroke="#1F1F22"></rect>
			<rect x="11.5" y="91.5" width="29" height="8" fill="#F0C64C" stroke="#1F1F22"></rect>
		</svg>
	);
};

export default BusinessAnalyst;