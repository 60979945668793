import styled, { css } from 'styled-components'
import { isMobile } from 'react-device-detect'
import prepare, { Prepared } from '../../Models'
import { rectangleProcessor, RectangleProps } from '../Rectangle'
import { textCssProcessor, TextProps } from '../Text'

interface ButtonProps {
  primary?: boolean
  secondary?: boolean
  invisible?: boolean
  disabled?: boolean
  link?: boolean
}

const buttonFragment = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 40px;
  width: max-content;
  height: 40px;
  border-radius: 26px;
  cursor: pointer;
  outline: none !important;
  border: none;
  font-family: "Golos";
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  transition: all .3s;
`

const buttonProcessor = (props: ButtonProps) => {
  if (props.primary) {
    if (props.disabled) {
      return css`
        color: #FFFFFF;
        background-color: #D8BEFF;
        pointer-events: none;
       `
    }
    return css`
      background-color: ${({ theme }) => theme.color.states.primary.default};
      &:hover {
        ${({ theme }) =>
          isMobile ? css`background-color: ${theme.color.states.primary.active}` : ''};
      }
    `
  }

  if (props.secondary) {
    if (props.disabled) {
      return css`
        border: 1px solid ${({ theme }) => theme.color.states.primary.disabled};
        background-color: #FFFFFF;
        color: ${({ theme }) => theme.color.states.primary.disabled};
        pointer-events: none;
      `
    }
    return css`
      background-color: #FFFFFF;
      color: ${({ theme }) => theme.color.text.button};
      border: 1px solid ${({ theme }) => theme.color.text.button};

      &:hover {
        ${({ theme }) =>
          isMobile
            ? css`background-color: ${theme.color.states.primary.default}; color: #712FD3;`
            : ''};
      }
      &:active {
        background-color: ${({ theme }) => theme.color.background.noStroke.active};
        color: #5722A5;
        border: 1px solid #5722A5;
      }
    `
  }

  return css``
}

const FastButton = styled.button.attrs(props => ({
  // @ts-ignore
  as: props.link ? 'a' : 'button',
}))<Prepared<ButtonProps & RectangleProps & TextProps>>`
  ${props => prepare(props, rectangleProcessor)}
  ${props => prepare(props, textCssProcessor)}
`

export default FastButton
