import React, { useContext, useEffect, useState } from 'react'
import StepContentLayout from '../../Layouts/Step/components/StepContentLayout'
import Picture from './assets/Picture.png'
import Text from '../../../../std-lib/FastUI/Components/Text'
import { useUserData } from '../../UserDataStorage/UseUserData'
import styled from 'styled-components'
import CommonSlideLayout from '../../Components/CommonSlideLayout'
import { NavContext } from 'Context/NavContext'
import Tag from 'std-lib/UiKit/Tag'

const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`

const StyledPicture = styled.img`
  width: 393px;
  height: 262px;

  @media screen and (max-width: 1000px) {
    height: auto;
    width: 100%;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;

  @media screen and (max-width: 1000px) {
    gap: 12px;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 8px;
`

const tags: { id: number; name: string }[] = [
  { id: 1, name: 'Работаю' },
  { id: 2, name: 'Учусь в бакалавриате' },
  { id: 3, name: 'Учусь в магистратуре' },
  { id: 4, name: 'Отдыхаю' },
]

const UserOccupation = () => {
  const { updateUserDataWith, getUserData } = useUserData()
  const { setForwardState } = useContext(NavContext)
  const [selectedTags, setSelectedTags] = useState<{ id: number; name: string }[]>(
    getUserData().userOccupation ?? [],
  )

  const toggleTag = (tag: { id: number; name: string }, disabled: boolean) => {
    if (disabled) return
    const isSelected = selectedTags.some(selectedTag => selectedTag.id === tag.id)

    if (isSelected) {
      setSelectedTags(selectedTags.filter(selectedTag => selectedTag.id !== tag.id))
    } else {
      setSelectedTags([...selectedTags, tag])
    }
  }

  useEffect(() => {
    updateUserDataWith({ userOccupation: selectedTags })
  }, [selectedTags])

  useEffect(() => {
    setForwardState({ disabled: !selectedTags.length })
  }, [selectedTags.length, setForwardState])

  const selectedTagsIds = selectedTags.map(item => item.id)
  return (
    <StepContentLayout title="Чем сейчас занимаешься ?">
      <CommonSlideLayout gap={'60px'}>
        <TextWrapper>
          <Text
            size={20}
            weight={400}
            mobile={{
              size: 14,
            }}
          >
            <b>{getUserData().name}</b>, для того, чтобы наш сервис смог построить
            индивидуальную образовательную траекторию, нам важно узнать, в каком статусе
            ты находишься в данный момент и чем именно ты занимаешься.
          </Text>
          <QuestionWrapper>
            <Text
              size={20}
              weight={600}
              mobile={{
                size: 14,
              }}
            >
              Чем сейчас занимаешься? Выбери все, что подходит
            </Text>
            <Wrapper>
              {tags.map(item => {
                const isDisabled =
                  (item.id === 2 && selectedTagsIds.includes(3)) ||
                  (item.id === 3 && selectedTagsIds.includes(2))
                return (
                  <Tag
                    key={item.id}
                    tag={item.name}
                    selected={selectedTags.some(
                      selectedTag => selectedTag.id === item.id,
                    )}
                    disabled={isDisabled}
                    onClick={() => toggleTag(item, isDisabled)}
                  />
                )
              })}
            </Wrapper>
          </QuestionWrapper>
        </TextWrapper>
        <StyledPicture src={Picture} />
      </CommonSlideLayout>
    </StepContentLayout>
  )
}

export default UserOccupation
