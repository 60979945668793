import styled, { css } from 'styled-components'
import Text from 'std-lib/FastUI/Components/Text'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;

  @media screen and (max-width: 1000px) {
    gap: 16px;
  }
`
export const TagsBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  @media screen and (max-width: 1000px) {
    gap: 8px;
  }
`

export const HobbiesTitle = styled(Text)`
  @media screen and (max-width: 1000px) {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
`

export const TagsWrapperFragment = css`
  @media screen and (max-width: 1000px) {
    gap: 8px;
  }
`
