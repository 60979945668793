import React from 'react'
import { IconType, RotateType } from '../../../std-lib/Images/icons/types'
import Cross from '../../../std-lib/Images/icons/cross'

const Plus = (props: IconType & RotateType) => {
  const { primaryColor = '#1F1F22', width = 10, height = 10, rotate } = props
  return (
    <Cross primaryColor={primaryColor} rotate={rotate} width={width} height={height} />
  )
}

export default Plus
