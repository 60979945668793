import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import ImgWithMaskAndCards from 'Components/ImgWithMaskAndCards/ImgWithMaskAndCards'
import Mask from './mask.svg'
import { GenericCard } from 'std-lib/Сomponents/GenericCard'
import StepContentLayout from '../../Layouts/Step/components/StepContentLayout'
import { CategoryDetails, onboardingRequest } from 'Widgets/Onboarding/API/API'
import { useUserData } from 'Widgets/Onboarding/UserDataStorage/UseUserData'
import {
  categoryInfoByCategoryId,
  SamuraiCategory,
} from 'Widgets/Onboarding/Slides/ProfessionCategory/constants'
import SkeletonRow, { createSkeletonRows } from 'std-lib/Сomponents/SkeletonRow'
import {
  cardStyle,
  CategoryTitle,
  ImageWrapper,
  NumberBlock,
  NumberItem,
  NumberSubtitle,
  NumberTitle,
  ProfessionCategoryWrapper,
  TextContent,
} from 'Widgets/Onboarding/Slides/ProfessionCategory/styled'

import SphereIcons from './assets/sphereIcons'
import { Fragments } from 'std-lib/FastUI/Models'
import parse from 'html-react-parser'

const ProfessionCategory = () => {
  const theme = useTheme()
  const { getUserData } = useUserData()

  const [selectedCategory] = useState(getUserData().selectedSphere)
  const [category, setCategory] = useState<CategoryDetails | undefined>()

  const isMaster = getUserData().userType === 'master'

  const categoryInfo = selectedCategory
    ? categoryInfoByCategoryId[selectedCategory.title]
    : null

  useEffect(() => {
    if (selectedCategory && selectedCategory.id > 0) {
      onboardingRequest.getCategory(selectedCategory.id).then(({ data: category }) => {
        setCategory(category)
      })
    } else {
      setCategory(SamuraiCategory)
    }
  }, [])

  const getIconsForCategory = (category: string | undefined) => {
    switch (category) {
      case 'Физика и инженерия':
        return SphereIcons.Physics
      case 'IT и программирование':
        return SphereIcons.Coding
      case 'Дизайн':
        return SphereIcons.Design
      case 'Бизнес и управление':
        return SphereIcons.Business
      case 'Науки о жизни':
        return SphereIcons.Bio
      default:
        return SphereIcons.NotSelected
    }
  }
  const getBitText = (bigText: string, index: number) => {
    if (category && category.id === 1 && index === 2) {
      return isMaster ? 31 : 11
    }
    return parse(bigText)
  }

  return (
    <StepContentLayout title={categoryInfo ? categoryInfo.slideTitle : <SkeletonRow />}>
      <ProfessionCategoryWrapper>
        {categoryInfo?.img && (
          <ImgWithMaskAndCards
            initialSize={{ width: 400, height: 400 }}
            imageSrc={categoryInfo?.img}
            maskSrc={Mask}
            styledFragment={ImageWrapper}
          >
            <GenericCard
              style={cardStyle}
              absoluteConfig={{ top: 0, left: 0 }}
              bg={'#FCE8B9'}
              styledFragment={Fragments.centered}
            >
              <img src={getIconsForCategory(category?.title)['1']} alt={''} />
            </GenericCard>
            <GenericCard
              style={cardStyle}
              bg={'#323243'}
              color={theme.color.background.tags.management}
              absoluteConfig={{ bottom: 0, right: 0 }}
              styledFragment={Fragments.centered}
            >
              <img src={getIconsForCategory(category?.title)['2']} alt={''} />
            </GenericCard>
          </ImgWithMaskAndCards>
        )}
        <TextContent>
          <div>
            {category ? (
              category.title && <CategoryTitle>{parse(category.title)}</CategoryTitle>
            ) : (
              <SkeletonRow fontSize={18} />
            )}
            {category ? (
              <span>{parse(category.description)}</span>
            ) : (
              createSkeletonRows(8)
            )}
          </div>

          {categoryInfo?.numberContent && (
            <NumberBlock>
              {categoryInfo.numberContent?.map((content, index) => (
                <NumberItem key={content.bigText + content.smallText}>
                  <NumberTitle>{getBitText(content.bigText, index)}</NumberTitle>
                  <NumberSubtitle>{parse(content.smallText)}</NumberSubtitle>
                </NumberItem>
              ))}
            </NumberBlock>
          )}
        </TextContent>
      </ProfessionCategoryWrapper>
    </StepContentLayout>
  )
}

export default ProfessionCategory
