import React from 'react';

const Economist = () => {
	return (
		<svg width="113" height="148" viewBox="0 0 113 148" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g className="svgAnime translateX(-35.7171px)+rotate(169.08deg) n0">
				<circle cx="84" cy="118" r="24.55" fill="#F0C64C" stroke="#1F1F22" strokeWidth="0.9"></circle>
				<circle cx="84.5" cy="117.5" r="19.05" fill="#F7E1A0" stroke="#1F1F22" strokeWidth="0.9"></circle>
				<path
					d="M90 129L86.375 124.25M75.5 110C78.1667 107.5 84.5 103.5 88.5 107.5C92.5 111.5 86.3333 117.167 82.75 119.5M75.5 110L82.75 119.5M75.5 110L74 111.5M82.75 119.5L86.375 124.25M82.75 119.5L81 121M86.375 124.25L85 125.5M86.375 124.25L92 119.5"
					stroke="#1F1F22" strokeWidth="0.9"></path>
			</g>

			<rect x="19.45" y="38.45" width="84.1" height="51.1" rx="1.55" stroke="#1F1F22" strokeWidth="0.9"
			      strokeDasharray="3 3"></rect>
			<path
				d="M24 80.5V84H89.5V49.5C87 51.6667 80.5 57.6 74.5 64C67 72 55.5 70.5 51.5 66.5C47.5 62.5 40 64.5 37 72C34.6 78 27.3333 80.1667 24 80.5Z"
				fill="#F0C64C"></path>
			<path
				d="M24 80.5V48V44M24 80.5V84H89.5M24 80.5C27.3333 80.1667 34.6 78 37 72C40 64.5 47.5 62.5 51.5 66.5C55.5 70.5 67 72 74.5 64C80.5 57.6 87 51.6667 89.5 49.5V84M89.5 84H93H97.5"
				stroke="#1F1F22" strokeWidth="0.9"></path>
			<path d="M23.9993 71.5C23.8326 70.8333 33.0003 57.5 50.9993 74C67.432 89.0641 90.4993 58.1667 96.4993 48"
			      stroke="#1F1F22" strokeWidth="0.9" strokeDasharray="3 3"></path>
			<mask id="path-8-inside-1_555_37488" fill="white">
				<path
					d="M12.0963 23.8743C11.3013 27.0533 11.5691 30.4051 12.8588 33.4175C14.1486 36.4299 16.3893 38.9371 19.2385 40.5558C22.0876 42.1745 25.3885 42.8157 28.6364 42.3813C31.8844 41.9469 34.9008 40.4609 37.2245 38.1504C39.5482 35.8399 41.0513 32.832 41.5042 29.5866C41.957 26.3412 41.3347 23.0367 39.7322 20.1784C38.1298 17.32 35.6354 15.0651 32.6304 13.7582C29.6254 12.4513 26.2751 12.1644 23.0917 12.9414L26.6481 27.5137L12.0963 23.8743Z"></path>
			</mask>
			<path
				d="M12.0963 23.8743C11.3013 27.0533 11.5691 30.4051 12.8588 33.4175C14.1486 36.4299 16.3893 38.9371 19.2385 40.5558C22.0876 42.1745 25.3885 42.8157 28.6364 42.3813C31.8844 41.9469 34.9008 40.4609 37.2245 38.1504C39.5482 35.8399 41.0513 32.832 41.5042 29.5866C41.957 26.3412 41.3347 23.0367 39.7322 20.1784C38.1298 17.32 35.6354 15.0651 32.6304 13.7582C29.6254 12.4513 26.2751 12.1644 23.0917 12.9414L26.6481 27.5137L12.0963 23.8743Z"
				fill="#F7E1A0" stroke="#1F1F22" strokeWidth="1.8" mask="url(#path-8-inside-1_555_37488)"></path>
			<mask id="path-9-inside-2_555_37488" fill="white">
				<path
					d="M20.4134 10.0551C17.5575 10.6948 14.9512 12.1559 12.9155 14.2585C10.8798 16.3611 9.50364 19.0132 8.95654 21.8882L23.6921 24.6924L20.4134 10.0551Z"></path>
			</mask>
			<g className="svgAnime translate(-5px,-4px) n1">
				<path
					d="M20.4134 10.0551C17.5575 10.6948 14.9512 12.1559 12.9155 14.2585C10.8798 16.3611 9.50364 19.0132 8.95654 21.8882L23.6921 24.6924L20.4134 10.0551Z"
					fill="#F0C64C" stroke="#1F1F22" strokeWidth="1.8" mask="url(#path-9-inside-2_555_37488)"></path>
			</g>
			<path
				d="M64 35.5L64.2385 35.1184L63.7356 34.8041L63.5683 35.373L64 35.5ZM80 45.5L79.7615 45.8816L80.185 46.1463L80.4043 45.6976L80 45.5ZM91 23L86.6895 25.9016L91.3576 28.1838L91 23ZM59.4317 52.627L64.4317 35.627L63.5683 35.373L58.5683 52.373L59.4317 52.627ZM63.7615 35.8816L79.7615 45.8816L80.2385 45.1184L64.2385 35.1184L63.7615 35.8816ZM80.4043 45.6976L89.6255 26.8361L88.8169 26.4408L79.5957 45.3024L80.4043 45.6976Z"
				fill="#1F1F22"></path>
		</svg>
	);
};

export default Economist;