import React, { useRef, useState } from 'react'
import Flex from '../../../../std-lib/FastUI/Components/Flex'
import Rectangle from '../../../../std-lib/FastUI/Components/Rectangle'
import { TrajectoryType } from '../../types'
import Text from '../../../../std-lib/FastUI/Components/Text'
import { DisciplineType } from '../../../../types'
import { css } from 'styled-components'
import DisciplineCard from './DisciplineCard'
import Chevron from '../../../../Images/icons/chevron'

type SemesterViewProps = {
  trajectory: TrajectoryType
}

const SemesterView = ({ trajectory }: SemesterViewProps) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const [selectedDiscipline, setSelectedDiscipline] = useState<number>()

  return (
    <Rectangle
      borderRadius={24}
      width={'100%'}
      background={'white'}
      border={'1px solid #DEDEDE'}
      boxShadow={'4px 4px 50px 0px rgba(97, 120, 201, 0.12)'}
    >
      <Rectangle
        position={'absolute'}
        top={24}
        left={12}
        border={'1px solid #DEDEDE'}
        borderRadius={22}
        width={44}
        height={44}
        boxShadow={
          '0px 4px 10px 0px rgba(31, 31, 34, 0.06), 0px 0px 1px 0px rgba(31, 31, 34, 0.10)'
        }
        background={'white'}
        fragment={css`z-index: 1;
          display: flex;
          align-items: center;
          justify-content: center`}
        mobile={{ left: 8 }}
      >
        <button
          onClick={() => {
            if (scrollContainerRef.current) {
              scrollContainerRef.current.scrollBy(-200, 0)
            }
          }}
        >
          <Chevron rotate={180} />
        </button>
      </Rectangle>
      <Rectangle
        background={'white'}
        position={'absolute'}
        top={24}
        right={12}
        border={'1px solid #DEDEDE'}
        borderRadius={22}
        width={44}
        height={44}
        boxShadow={
          '0px 4px 10px 0px rgba(31, 31, 34, 0.06), 0px 0px 1px 0px rgba(31, 31, 34, 0.10)'
        }
        mobile={{ right: 8 }}
        fragment={css`z-index: 1;
          display: flex;
          align-items: center;
          justify-content: center`}
      >
        <button
          onClick={() => {
            if (scrollContainerRef.current) {
              scrollContainerRef.current.scrollBy(200, 0)
            }
          }}
        >
          <Chevron rotate={0} />
        </button>
      </Rectangle>
      <Flex
        ref={scrollContainerRef}
        fragment={css`overflow-x: auto;
          padding: 24px 32px;
          scroll-snap-type: x mandatory`}
      >
        {trajectory.courses.map(course => {
          let firstSemesterDisciplines: DisciplineType[] = []
          let secondSemesterDisciplines: DisciplineType[] = []

          course.classes.forEach(classItem => {
            firstSemesterDisciplines = firstSemesterDisciplines.concat(
              classItem.first_semesters_disciplines,
            )
            secondSemesterDisciplines = secondSemesterDisciplines.concat(
              classItem.second_semesters_disciplines,
            )
          })

          return (
            <>
              <Flex
                direction={'column'}
                gap={24}
                fragment={css`
                  scroll-snap-align: start;
                  padding-left: 16px;

                  &:first-child {
                    padding-left: 24px;
                  }
                `}
              >
                <Rectangle
                  width={264}
                  height={44}
                  borderRadius={12}
                  background={'#EBEBFD'}
                  fragment={css`display: flex;
                    align-items: center;
                    justify-content: center`}
                >
                  <Text weight={600} size={16}>
                    {(course.course - 1) * 2 + 1} Семестр
                  </Text>
                </Rectangle>
                <Flex direction={'column'} gap={16}>
                  {firstSemesterDisciplines.map(discipline => {
                    return (
                      <DisciplineCard
                        isSelected={selectedDiscipline === discipline.id}
                        onClick={() => setSelectedDiscipline(discipline.id)}
                        key={discipline.name}
                        discipline={discipline}
                      />
                    )
                  })}
                </Flex>
              </Flex>

              <Flex
                direction={'column'}
                gap={24}
                fragment={css`
                  scroll-snap-align: start;
                  padding-left: 16px;

                  &:last-child {
                    padding-right: 16px;
                    scroll-snap-align: end;
                  }
                `}
              >
                <Rectangle
                  width={264}
                  height={44}
                  borderRadius={12}
                  background={'#EBEBFD'}
                  fragment={css`display: flex;
                    align-items: center;
                    justify-content: center`}
                >
                  <Text weight={600} size={16}>
                    {(course.course - 1) * 2 + 2} Семестр
                  </Text>
                </Rectangle>
                <Flex direction={'column'} gap={16}>
                  {secondSemesterDisciplines.map(discipline => {
                    return (
                      <DisciplineCard
                        isSelected={selectedDiscipline === discipline.id}
                        onClick={() => setSelectedDiscipline(discipline.id)}
                        key={discipline.name}
                        discipline={discipline}
                      />
                    )
                  })}
                </Flex>
              </Flex>
            </>
          )
        })}
      </Flex>
      <Flex justifyContent={'space-between'} alignItems={'center'}></Flex>
    </Rectangle>
  )
}

export default SemesterView
