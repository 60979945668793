import React, { useContext, useEffect, useState } from 'react'
import CommonSlideLayout from '../../Components/CommonSlideLayout'
import Tags from '../../../../std-lib/Сomponents/Tags'
import { NavContext } from 'Context/NavContext'
import StepContentLayout from '../../Layouts/Step/components/StepContentLayout'
import { onboardingRequest, Preset } from '../../API/API'
import { useUserData } from '../../UserDataStorage/UseUserData'
import styled from 'styled-components'
import * as Scroll from 'react-scroll'
import Br from '../../../../std-lib/FastUI/Components/Br'
import Text from '../../../../std-lib/FastUI/Components/Text'

const UnderlinedSpan = styled.span<{ withUnderLine: boolean }>`
  transition: all .3s ease-in-out .31s;
  border-bottom: ${({ withUnderLine }) => (withUnderLine ? 4 : 0)}px solid #F9CF6B;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`

const SkillSets = () => {
  const { updateUserDataWith, getUserData } = useUserData()
  const professionId = getUserData().selectedProfession
  const [selectedPresets, setSelectedPresets] = useState<{ id: number; name: string }[]>(
    getUserData().selectedSkillSets || [],
  )
  const [presets, setPresets] = useState<Preset[] | undefined>(undefined)
  const animateScroll = Scroll.animateScroll

  useEffect(() => {
    updateUserDataWith({ selectedSkillSets: selectedPresets })
  }, [selectedPresets])

  const onSelectedTagsChange = (tags: { id: number; name: string }[]) => {
    setSelectedPresets(tags)
  }

  const { setForwardState } = useContext(NavContext)

  useEffect(() => {
    setForwardState({ disabled: !selectedPresets.length, link: '/step/profile' })
    if (selectedPresets.length === 5) {
      animateScroll.scrollToTop({ duration: 300 })
    }
  }, [selectedPresets.length, setForwardState])

  useEffect(() => {
    if (!professionId || presets?.length) return
    onboardingRequest.getPresets(professionId.id).then(({ data }) => setPresets(data))
  }, [professionId])

  return (
    <StepContentLayout
      title={
        <>
          <span>Чему еще ты бы хотел научиться? Выбери </span>
          <UnderlinedSpan withUnderLine={selectedPresets.length >= 5}>
            до 5 навыков
          </UnderlinedSpan>
        </>
      }
    >
      <CommonSlideLayout>
        <ContentWrapper>
          <Text size={20} weight={400}>
            Мы уже учли необходимые навыки для освоения профессии{' '}
            <strong>{getUserData().selectedProfession?.name}</strong>. <br />
            Выбери дополнительные навыки из других областей.
          </Text>
          {presets && (
            <Tags
              tags={presets?.map(preset => {
                return { id: preset.id, name: preset.title }
              })}
              withSkeleton
              selectedTags={selectedPresets}
              onSelectedTagsChange={onSelectedTagsChange}
              limit={5}
            />
          )}
        </ContentWrapper>
      </CommonSlideLayout>
    </StepContentLayout>
  )
}

export default SkillSets
