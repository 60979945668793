import React from 'react';

const MobileDeveloper = () => {
	return (
		<svg width="113" height="148" viewBox="0 0 113 148" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M77.5 52L77.5 82L35.5 82L35.5 52L77.5 52Z" stroke="#1F1F22" strokeWidth="0.5"
			      strokeDasharray="2.14 2.14"></path>
			<path fillRule="evenodd" clipRule="evenodd"
			      d="M33.8121 21.0202H78.1908C80.1513 20.8417 84.0723 21.7701 84.0723 26.9119V120.108C84.0723 121.894 83.0029 125.464 78.7254 125.464C74.448 125.464 48.0703 125.821 35.4162 126C33.2775 126 29 124.607 29 119.037V26.3763C29 24.7694 29.1069 21.0202 33.8121 21.0202ZM35.4162 23.6983C31.9943 23.6983 31.6734 26.3763 31.6735 27.9831V117.43C31.6735 121.715 35.9509 122.429 38.0896 122.251H74.9827C80.3295 122.251 81.3988 119.037 81.3988 117.43V28.5188C81.3988 24.6624 78.5472 23.6983 77.1214 23.6983H71.2399C70.1705 23.6983 68.0318 23.6983 68.0318 25.3051C68.0318 26.5906 66.606 26.9119 65.8931 26.9119H48.2486C47.1792 26.9119 45.0405 27.1262 45.0405 25.8407C45.0405 24.5552 43.9711 23.8768 43.4364 23.6983H35.4162Z"
			      fill="#1F1F22"></path>
			<path
				d="M35.4162 23.6983C31.9943 23.6983 31.6734 26.3763 31.6735 27.9831V117.43C31.6735 121.715 35.9509 122.429 38.0896 122.251H74.9827C80.3295 122.251 81.3988 119.037 81.3988 117.43M35.4162 23.6983C38.8382 23.6983 42.1888 23.6983 43.4364 23.6983M35.4162 23.6983H43.4364M43.4364 23.6983C43.9711 23.8768 45.0405 24.5552 45.0405 25.8407C45.0405 27.1262 47.1792 26.9119 48.2486 26.9119H65.8931C66.606 26.9119 68.0318 26.5906 68.0318 25.3051C68.0318 23.6983 70.1705 23.6983 71.2399 23.6983M71.2399 23.6983C72.0954 23.6983 75.5173 23.6983 77.1214 23.6983M71.2399 23.6983H77.1214M77.1214 23.6983C78.5472 23.6983 81.3988 24.6624 81.3988 28.5188M81.3988 28.5188C81.3988 32.3752 81.3988 89.3999 81.3988 117.43M81.3988 28.5188V117.43M84.0723 26.9119C84.0723 21.7701 80.1513 20.8417 78.1908 21.0202H33.8121C29.1069 21.0202 29 24.7694 29 26.3763M84.0723 26.9119C84.0723 32.0538 84.0723 91.1853 84.0723 120.108M84.0723 26.9119V120.108M84.0723 120.108C84.0723 121.894 83.0029 125.464 78.7254 125.464C74.448 125.464 48.0703 125.821 35.4162 126C33.2775 126 29 124.607 29 119.037M29 119.037C29 113.467 29 54.9422 29 26.3763M29 119.037V26.3763"
				stroke="#1F1F22" strokeWidth="0.802023"></path>
			<g className="svgAnime translateX(33.5px)+translateY(3px) n0">
				<path d="M2 79.1007V49.0486H44V79.1007H2Z" fill="#C1D5F6" stroke="#1F1F22" strokeWidth="0.5"></path>
				<circle cx="23.4162" cy="64.4648" r="6.41618" fill="#677CE8"></circle>
				<path d="M26.2678 64.4647L21.9904 62.3259V66.6034L26.2678 64.4647Z" fill="white"></path>
			</g>
			<path d="M77.5 32L77.5 47L35.5 47L35.5 32L77.5 32Z" stroke="#1F1F22" strokeWidth="0.5"
			      strokeDasharray="2.14 2.14"></path>
			<path d="M77.5 86.1131L77.5 101.139L35.5 101.139L35.5 86.1131L77.5 86.1131Z" stroke="#1F1F22"
			      strokeWidth="0.427746" strokeDasharray="2.14 2.14"></path>
			<g className="svgAnime translateX(-24px)+translateY(-2.2px) n1">
				<path d="M60 104.144V88.1165H102V104.144H60Z" fill="#677CE8" stroke="#1F1F22" strokeWidth="0.5"></path>
				<path fillRule="evenodd" clipRule="evenodd"
				      d="M64.6663 92.8178C64.1332 92.8178 63.7012 93.2498 63.7012 93.7829V98.4908C63.7012 98.5745 63.7118 98.6558 63.7319 98.7332L65.4159 97.0492C65.6518 96.8128 66.0344 96.813 66.2704 97.049L66.6279 97.4065L68.1622 95.8723C68.3981 95.6358 68.7807 95.636 69.0167 95.8721L70.3393 97.1947V93.7829C70.3393 93.2498 69.9073 92.8178 69.3742 92.8178H64.6663ZM70.3393 97.7939L68.7171 96.1717C68.6463 96.1009 68.5323 96.1012 68.4622 96.1715L66.9275 97.7061L68.6773 99.4559H69.3742C69.9073 99.4559 70.3393 99.0238 70.3393 98.4908V97.7939ZM68.0781 99.4559L65.9708 97.3486C65.9001 97.2779 65.786 97.2782 65.7159 97.3485L63.939 99.1253C64.116 99.3279 64.3762 99.4559 64.6663 99.4559H68.0781ZM63.2775 93.7829C63.2775 93.0158 63.8992 92.394 64.6663 92.394H69.3742C70.1413 92.394 70.763 93.0158 70.763 93.7829V98.4908C70.763 99.2578 70.1413 99.8796 69.3742 99.8796H64.6663C63.8992 99.8796 63.2775 99.2578 63.2775 98.4908V93.7829Z"
				      fill="white"></path>
				<path
					d="M65.7159 97.3485C65.786 97.2782 65.9001 97.2779 65.9708 97.3486L68.0781 99.4559H64.6663C64.3762 99.4559 64.116 99.3279 63.939 99.1253L65.7159 97.3485Z"
					fill="white"></path>
				<path
					d="M68.7171 96.1717L70.3393 97.7939V98.4908C70.3393 99.0238 69.9073 99.4559 69.3742 99.4559H68.6773L66.9275 97.7061L68.4622 96.1715C68.5323 96.1012 68.6463 96.1009 68.7171 96.1717Z"
					fill="white"></path>
				<path fillRule="evenodd" clipRule="evenodd"
				      d="M78.568 92.8178C78.035 92.8178 77.6029 93.2498 77.6029 93.7829V98.4908C77.6029 98.5745 77.6136 98.6558 77.6336 98.7332L79.3176 97.0492C79.5535 96.8128 79.9362 96.813 80.1722 97.049L80.5296 97.4065L82.0639 95.8723C82.2998 95.6358 82.6824 95.636 82.9184 95.8721L84.241 97.1947V93.7829C84.241 93.2498 83.809 92.8178 83.2759 92.8178H78.568ZM84.241 97.7939L82.6188 96.1717C82.5481 96.1009 82.434 96.1012 82.3639 96.1715L80.8293 97.7061L82.579 99.4559H83.2759C83.809 99.4559 84.241 99.0238 84.241 98.4908V97.7939ZM81.9798 99.4559L79.8726 97.3486C79.8018 97.2779 79.6877 97.2782 79.6176 97.3485L77.8408 99.1253C78.0177 99.3279 78.2779 99.4559 78.568 99.4559H81.9798ZM77.1792 93.7829C77.1792 93.0158 77.8009 92.394 78.568 92.394H83.2759C84.043 92.394 84.6647 93.0158 84.6647 93.7829V98.4908C84.6647 99.2578 84.043 99.8796 83.2759 99.8796H78.568C77.8009 99.8796 77.1792 99.2578 77.1792 98.4908V93.7829Z"
				      fill="white"></path>
				<path
					d="M79.6176 97.3485C79.6877 97.2782 79.8018 97.2779 79.8726 97.3486L81.9798 99.4559H78.568C78.2779 99.4559 78.0177 99.3279 77.8408 99.1253L79.6176 97.3485Z"
					fill="white"></path>
				<path
					d="M82.6188 96.1717L84.241 97.7939V98.4908C84.241 99.0238 83.809 99.4559 83.2759 99.4559H82.579L80.8293 97.7061L82.3639 96.1715C82.434 96.1012 82.5481 96.1009 82.6188 96.1717Z"
					fill="white"></path>
				<path fillRule="evenodd" clipRule="evenodd"
				      d="M92.4698 92.8178C91.9367 92.8178 91.5046 93.2498 91.5046 93.7829V98.4908C91.5046 98.5745 91.5153 98.6558 91.5353 98.7332L93.2194 97.0492C93.4553 96.8128 93.8379 96.813 94.0739 97.049L94.4314 97.4065L95.9656 95.8723C96.2015 95.6358 96.5842 95.636 96.8202 95.8721L98.1428 97.1947V93.7829C98.1428 93.2498 97.7107 92.8178 97.1777 92.8178H92.4698ZM98.1428 97.7939L96.5206 96.1717C96.4498 96.1009 96.3358 96.1012 96.2656 96.1715L94.731 97.7061L96.4808 99.4559H97.1777C97.7107 99.4559 98.1428 99.0238 98.1428 98.4908V97.7939ZM95.8815 99.4559L93.7743 97.3486C93.7035 97.2779 93.5895 97.2782 93.5194 97.3485L91.7425 99.1253C91.9194 99.3279 92.1796 99.4559 92.4698 99.4559H95.8815ZM91.0809 93.7829C91.0809 93.0158 91.7027 92.394 92.4698 92.394H97.1777C97.9447 92.394 98.5665 93.0158 98.5665 93.7829V98.4908C98.5665 99.2578 97.9447 99.8796 97.1777 99.8796H92.4698C91.7027 99.8796 91.0809 99.2578 91.0809 98.4908V93.7829Z"
				      fill="white"></path>
				<path
					d="M93.5194 97.3485C93.5895 97.2782 93.7035 97.2779 93.7743 97.3486L95.8815 99.4559H92.4698C92.1796 99.4559 91.9194 99.3279 91.7425 99.1253L93.5194 97.3485Z"
					fill="white"></path>
				<path
					d="M96.5206 96.1717L98.1428 97.7939V98.4908C98.1428 99.0238 97.7107 99.4559 97.1777 99.4559H96.4808L94.731 97.7061L96.2656 96.1715C96.3358 96.1012 96.4498 96.1009 96.5206 96.1717Z"
					fill="white"></path>
			</g>
			<g className="svgAnime translateX(23px) n2">
				<rect x="12.25" y="108.401" width="41.5" height="7.9146" rx="3.95" fill="#C1D5F6" stroke="#1F1F22"
				      strokeWidth="0.5"></rect>
				<g clipPath="url(#clip0_4724_90030)">
					<path
						d="M17.1777 110.733C16.9151 110.733 16.6584 110.811 16.44 110.957C16.2217 111.103 16.0515 111.311 15.951 111.554C15.8506 111.797 15.8243 112.064 15.8755 112.322C15.9267 112.58 16.0532 112.817 16.2388 113.003C16.4245 113.189 16.6611 113.316 16.9186 113.367C17.1762 113.419 17.4431 113.392 17.6857 113.292C17.9283 113.191 18.1357 113.021 18.2816 112.802C18.4275 112.583 18.5053 112.326 18.5053 112.063C18.5053 111.71 18.3654 111.372 18.1164 111.122C17.8675 110.873 17.5298 110.733 17.1777 110.733V110.733Z"
						stroke="white" strokeWidth="0.393388" strokeMiterlimit="10"></path>
					<path d="M18.1682 113.055L19.0954 113.984" stroke="white" strokeWidth="0.393388" strokeMiterlimit="10"
					      strokeLinecap="round"></path>
				</g>
			</g>
			<g className="svgAnime translateX(-33.5px)+translateY(2px) n3">
				<path d="M69 45.0417V29.0139H111V45.0417H69Z" fill="#677CE8" stroke="#1F1F22" strokeWidth="0.5"></path>
				<path fillRule="evenodd" clipRule="evenodd"
				      d="M87.8024 37.8856C87.498 37.4896 87.3158 37.0157 87.3158 36.5023C87.3158 35.1279 88.5881 34.0139 90.1579 34.0139C91.7277 34.0139 93 35.1279 93 36.5023C93 37.8767 91.7277 38.9907 90.1579 38.9907C89.676 38.9907 89.2232 38.8837 88.8253 38.6984C88.4002 38.897 87.918 39.0139 87.4029 39.0139C87.2659 39.0139 87.1323 39.0031 87 38.9879C87.3704 38.6929 87.6489 38.3141 87.8024 37.8856Z"
				      fill="white"></path>
			</g>
			<defs>
				<clipPath id="clip0_4724_90030">
					<rect width="4.32727" height="4.3348" fill="white" transform="translate(15.3091 110.191)"></rect>
				</clipPath>
			</defs>
		</svg>
	);
};

export default MobileDeveloper;