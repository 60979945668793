import styled, { css } from 'styled-components'
import { CssFrom, MarginPaddingValue, UnitValue, WidthHeightValue } from '../../TypedCss'
import prepare, { Prepared } from '../../Models'
import { generateTransformFragment, TransformConfig } from '../../Models/transform'

export const centered = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

export function Absolute(
  left?: UnitValue,
  right?: UnitValue,
  top?: UnitValue,
  bottom?: UnitValue,
) {
  return css`
    position: absolute;
    left: ${left};
    right: ${right};
    top: ${top};
    bottom: ${bottom};
  `
}

export interface RectangleProps {
  width?: WidthHeightValue
  height?: WidthHeightValue
  borderRadius?: UnitValue
  padding?: MarginPaddingValue
  overflow?: 'visible' | 'hidden' | 'scroll'
  overflowX?: 'visible' | 'hidden' | 'scroll'
  overflowY?: 'visible' | 'hidden' | 'scroll'
  margin?: MarginPaddingValue
  position?: 'static' | 'relative' | 'absolute' | 'fixed' | 'sticky'
  transformConfig?: TransformConfig
  border?: string
  background?: string
  boxShadow?: string
  left?: UnitValue
  right?: UnitValue
  top?: UnitValue
  bottom?: UnitValue
}

export interface PositionProps {
  position?: 'static' | 'relative' | 'absolute' | 'fixed' | 'sticky'
  left?: UnitValue
  right?: UnitValue
  top?: UnitValue
  bottom?: UnitValue
}

export const positionProcessor = (props: RectangleProps) => {
  return css`
    position: ${props.position || 'relative'};
    left: ${CssFrom(props.left)};
    right: ${CssFrom(props.right)};
    top: ${CssFrom(props.top)};
    bottom: ${CssFrom(props.bottom)};
  `
}

export const rectangleProcessor = (props: RectangleProps) => {
  return css`
    width: ${CssFrom(props.width)};
    height: ${CssFrom(props.height)};
    border-radius: ${CssFrom(props.borderRadius)};
    padding: ${CssFrom(props.padding)};
    margin: ${CssFrom(props.margin)};
    position: ${props.position || 'relative'};
    left: ${CssFrom(props.left)};
    right: ${CssFrom(props.right)};
    top: ${CssFrom(props.top)};
    bottom: ${CssFrom(props.bottom)};
    border: ${props.border || 'none'};
    ${props.boxShadow ? `box-shadow: ${props.boxShadow};` : ''}
    background: ${props.background || 'none'};
    ${props.overflow ? `overflow: ${props.overflow};` : ''};
    ${props.overflowX ? `overflow-x: ${props.overflowX};` : ''};
    ${props.overflowY ? `overflow-y: ${props.overflowY};` : ''};
    transform: ${
      props.transformConfig ? generateTransformFragment(props.transformConfig) : 'none'
    };
  `
}

const Rectangle = styled.div<Prepared<RectangleProps>>`
  ${props => prepare(props, rectangleProcessor)}

`

export default Rectangle
