import React, { useState } from 'react'
import {
  CareerMobileTextWrapper,
  CareerSliderWrapper,
  CareerWrapper,
  CompaniesLogoWrapper,
  CompanyLogo,
  TextWrapper,
} from './styled'
import Text from '../../../../std-lib/FastUI/Components/Text'
import Slider from './Slider'
import Tabs from '../Tabs'
import { Profession } from '../../../../types'
import { isMobile } from 'react-device-detect'
import { MobileCardsWrapper } from '../../styled'
import MobileContentCard from '../MobileContentCard'
import { useTheme } from 'styled-components'
import { TrajectoryType } from '../../types'
import { makeEmptyArray } from '../../../../std-lib/Extensions/array'

interface CareerProps {
  trajectory: TrajectoryType
}

export const CareerContentBlock = (props: {
  salaries: number[]
  name: string
  slug: string
}) => {
  const IsMagister = false
  const { salaries, name, slug } = props
  const slugForLink = slug ? slug.replace('/', '') : ''
  return (
    <CareerSliderWrapper>
      <TextWrapper>
        <Text size={16} weight={400} lineHeight={24}>
          После обучения на программе ты сможешь работать по профессии {name.toLowerCase()}.
        </Text>
        <Text size={16} weight={400} lineHeight={24}>
          Мы собрали реалистичные зарплаты специалистов в компаниях-партнерах направления,
          у которых ты сможешь пройти стажировку и работать.
        </Text>
        <Text size={14} weight={400} color={'#B7B7B8'}>
          *по данным Хабр. Карьера
        </Text>
      </TextWrapper>
      <Slider salaries={salaries} />
      <CompaniesLogoWrapper>
        {makeEmptyArray(4).map((_, i) => (
          <CompanyLogo
            key={i + 1}
            id={`${slugForLink}-trac-compny-logo-no-${i + 1}`}
            onError={() => {
              const self = document.getElementById(
                `${slugForLink}-trac-compny-logo-no-${i + 1}`,
              )
              console.log(self)
              if (self) {
                self.parentElement?.removeChild(self)
              }
            }}
            src={`/OP_logos/${
              IsMagister ? 'Master' : 'Bachelor'
            }_OP_Logos/${slugForLink}/${i + 1}.svg`}
          />
        ))}
      </CompaniesLogoWrapper>
    </CareerSliderWrapper>
  )
}

const Career = (props: CareerProps) => {
  const { trajectory } = props
  const theme = useTheme()

  const [activeProfession, setActiveProfession] = useState<Profession>(
    trajectory.professions[0],
  )
  const handleActiveProfession = (id: number) => {
    const activeProfession = trajectory?.professions.find(
      profession => profession.id === id,
    )
    if (activeProfession) {
      setActiveProfession(activeProfession)
    }
  }

  return isMobile ? (
    <MobileCardsWrapper>
      {trajectory.professions.map(profession => (
        <MobileContentCard
          key={profession.id}
          title={
            <CareerMobileTextWrapper>
              <Text size={16} weight={600}>
                {profession.name}
              </Text>
              <Text size={14} weight={400} color={theme.color.text.secondary}>
                {profession.description}
              </Text>
            </CareerMobileTextWrapper>
          }
        >
          <CareerContentBlock
            salaries={profession.salaries}
            name={profession.name}
            slug={trajectory.abit_link}
          />
        </MobileContentCard>
      ))}
    </MobileCardsWrapper>
  ) : (
    <CareerWrapper>
      <Tabs tabs={trajectory.professions} onTabClick={handleActiveProfession} />
      {activeProfession && (
        <CareerContentBlock
          salaries={activeProfession.salaries}
          name={activeProfession.name}
          slug={trajectory.abit_link}
        />
      )}
    </CareerWrapper>
  )
}

export default Career
