import styled from 'styled-components';
export const CareerSliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 60px;
  padding: 24px;
  
  @media screen and (max-width: 1000px) {
    padding: 16px;
  }
`
export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`
export const CompaniesLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const CareerMobileTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  max-width: 250px;
  
  @media screen and (max-width: 1000px) {
    max-width: none;
  }
`
export const CareerWrapper = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  border-radius: 20px;
  border: 1px solid #DEDEDE;
  box-shadow: 4px 4px 50px 0 rgba(97, 120, 201, 0.12);
`

export const CompanyLogo = styled.img`
  width: max-content;
  height: max-content;
  
  @media screen and (max-width: 1000px) {
    width: 60px;
    height: 24px;
    object-fit: contain;
  }
  
`