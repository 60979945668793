import styled, { css } from 'styled-components'
import { CssFrom, UnitValue } from '../../TypedCss'
import prepare, { Prepared } from '../../Models'
import { rectangleProcessor, RectangleProps } from '../Rectangle'

interface FlexProps {
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse'
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch'
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch'
  alignContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'stretch'
  gap?: UnitValue
}

const flexProcessor = (props: FlexProps) => {
  return css`
    position: relative;
    display: flex;
    flex-direction: ${props.direction || 'row'};
    flex-wrap: ${props.wrap || 'nowrap'};
    justify-content: ${props.justifyContent || 'flex-start'};
    align-items: ${props.alignItems || 'stretch'};
    align-content: ${props.alignContent || 'stretch'};
    gap: ${CssFrom(props.gap)};
  `
}

const Flex = styled.div<Prepared<FlexProps & { rectangleProps?: RectangleProps }>>`
  ${props => prepare(props, flexProcessor)}
  ${props => prepare(props.rectangleProps || {}, rectangleProcessor)}
`

export default Flex
