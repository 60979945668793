import styled from 'styled-components'

export const SideModalOverlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 11000;
`
export const CloseModalButton = styled.button`
  margin: 16px 16px 12px;
  padding: 0;
  height: 24px;
  width: 24px;
`

export const SideModalWrapper = styled.div<{ isModalOpen: boolean }>`
  width: 640px;
  position: fixed;
  right: ${({ isModalOpen }) => (isModalOpen ? '0' : '-640px')};
  top: 0;
  bottom: 0;
  z-index: 11000;
  transition: right .3s;
  box-shadow: 1px 4px 20px 0 rgba(31, 31, 34, 0.10), 0px -1px 12px 0px rgba(31, 31, 34, 0.06);
  background-color: #FFFFFF;
  border-top-left-radius: 16px;

  animation-name: sideSlide;
  animation-duration: .3s;
  animation-iteration-count: 1;

  @keyframes sideSlide {
    from {
      right: -640px;
    }

    to {
      right: 0;
    }
  }
`
