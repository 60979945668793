import React from 'react';

const Designer = () => {
	return (
		<svg width="113" height="148" viewBox="0 0 113 148" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd"
			      d="M0 5H89V143H0V5ZM0.735537 5.73016V13.2751H88.2645V5.73016H0.735537ZM88.2645 14.0053H0.735537V142.27H88.2645V14.0053ZM4.41322 17.6561H45.3581V22.7672H4.41322V17.6561ZM5.14876 18.3862V22.037H44.6226V18.3862H5.14876ZM47.5647 17.6561H70.8568V22.7672H47.5647V17.6561ZM48.3003 18.3862V22.037H70.1212V18.3862H48.3003ZM4.41322 24.9577H84.0964V48.0794H4.41322V24.9577ZM5.14876 25.6878V47.3492H83.3609V25.6878H5.14876ZM4.41322 56.1111H41.4353V61.709H4.41322V56.1111ZM5.14876 56.8413V60.9788H40.6997V56.8413H22.9242H5.14876Z"
			      fill="#1F1F22"></path>
			<path d="M0.735537 13.2751V5.73016H88.2645V13.2751H0.735537Z" fill="#C083C9"></path>
			<path d="M48.3003 22.037V18.3862H70.1212V22.037H48.3003Z" fill="#C083C9"></path>
			<path d="M5.14876 22.037V18.3862H44.6226V22.037H5.14876Z" fill="#C083C9"></path>
			<path d="M5.14876 60.9788V56.8413H22.9242H40.6997V60.9788H5.14876Z" fill="#C083C9"></path>
			<path d="M5.14876 47.3492V25.6878H83.3609V47.3492H5.14876Z" fill="#F2D5F7"></path>
			<path fillRule="evenodd" clipRule="evenodd"
			      d="M4.41322 95.9562H41.4353V101.554H4.41322V95.9562ZM5.14876 96.6863V100.824H40.6997V96.6863H22.9242H5.14876Z"
			      fill="#1F1F22"></path>
			<path d="M5.14876 100.824V96.6863H22.9242H40.6997V100.824H5.14876Z" fill="#C083C9"></path>
			<path fillRule="evenodd" clipRule="evenodd"
			      d="M4.5 66H42.75V69.75H4.5V66ZM5.25 66.75V69H42V66.75H5.25ZM4.5 71H25.75V74.75H4.5V71ZM5.25 71.75V74H25V71.75H5.25ZM26.5 71H41.75V74.75H26.5V71ZM27.25 71.75V74H41V71.75H27.25Z"
			      fill="#1F1F22"></path>
			<path fillRule="evenodd" clipRule="evenodd"
			      d="M4.5 76H18.75V79.75H4.5V76ZM5.25 76.75V79H18V76.75H5.25ZM20 76H39.75V79.75H20V76ZM20.75 76.75V79H39V76.75H20.75ZM4.5 81H25.75V84.75H4.5V81ZM5.25 81.75V84H25V81.75H5.25ZM26.5 81H41.75V84.75H26.5V81ZM27.25 81.75V84H41V81.75H27.25Z"
			      fill="#1F1F22"></path>
			<path d="M5.25 69V66.75H42V69H5.25Z" fill="#F2D5F6"></path>
			<path d="M27.25 74V71.75H41V74H27.25Z" fill="#F2D5F6"></path>
			<path d="M5.25 74V71.75H25V74H5.25Z" fill="#F2D5F6"></path>
			<path d="M20.75 79V76.75H39V79H20.75Z" fill="#F2D5F6"></path>
			<path d="M27.25 84V81.75H41V84H27.25Z" fill="#F2D5F6"></path>
			<path d="M5.25 84V81.75H25V84H5.25Z" fill="#F2D5F6"></path>
			<path d="M5.25 79V76.75H18V79H5.25Z" fill="#F2D5F6"></path>
			<path fillRule="evenodd" clipRule="evenodd"
			      d="M84.5 124.25C84.9142 124.25 85.25 123.914 85.25 123.5C85.25 123.086 84.9142 122.75 84.5 122.75C84.0858 122.75 83.75 123.086 83.75 123.5C83.75 123.914 84.0858 124.25 84.5 124.25ZM84.5 125C85.3284 125 86 124.328 86 123.5C86 122.672 85.3284 122 84.5 122C83.6716 122 83 122.672 83 123.5C83 124.328 83.6716 125 84.5 125Z"
			      fill="#1F1F22"></path>
			<path fillRule="evenodd" clipRule="evenodd" d="M25.25 108.75H5.75V138.25H25.25V108.75ZM5 108V139H26V108H5Z"
			      fill="#1F1F22"></path>
			<path d="M5.75 108.75H25.25V138.25H5.75V108.75Z" fill="#F2D5F6"></path>
			<path fillRule="evenodd" clipRule="evenodd" d="M52.25 108.75H32.75V138.25H52.25V108.75ZM32 108V139H53V108H32Z"
			      fill="#1F1F22"></path>
			<path d="M32.75 108.75H52.25V138.25H32.75V108.75Z" fill="#F2D5F6"></path>
			<path fillRule="evenodd" clipRule="evenodd" d="M79.25 108.75H59.75V138.25H79.25V108.75ZM59 108V139H80V108H59Z"
			      fill="#1F1F22"></path>
			<path d="M59.75 108.75H79.25V138.25H59.75V108.75Z" fill="#F2D5F6"></path>
			<g className="svgAnime translateX(-20px) n0">
				<rect x="66.5" y="56.5" width="38" height="35" fill="#C083C9" stroke="#1F1F22" strokeDasharray="5 2"></rect>
				<g clipPath="url(#clip0_4741_90044)">
					<path
						d="M92.3248 81.4632C91.1265 81.4632 90.1517 80.4769 90.1517 79.265C90.1517 78.1371 91.1252 77.0492 91.6484 76.4651C91.7119 76.394 91.7669 76.3326 91.8073 76.2852C91.8708 76.2096 91.9502 76.1489 92.0398 76.1072C92.1294 76.0655 92.2271 76.0439 92.3259 76.0439C92.4248 76.0439 92.5225 76.0655 92.6121 76.1072C92.7017 76.1489 92.781 76.2096 92.8446 76.2852C92.8829 76.3296 92.9339 76.3868 92.9926 76.4522C93.5189 77.0384 94.4978 78.1297 94.4978 79.266C94.4978 80.4769 93.523 81.4632 92.3248 81.4632ZM91.7214 74.5732L83.8666 66.8003C83.5349 66.4701 83.0856 66.2847 82.6171 66.2847C82.1486 66.2847 81.6992 66.4701 81.3676 66.8003L81.2144 66.9531C80.8742 67.287 80.6803 67.7417 80.6749 68.2179C80.6736 68.4504 80.7187 68.6808 80.8076 68.8956C80.8965 69.1105 81.0274 69.3056 81.1927 69.4694L82.6021 70.8755L79.728 73.7384C79.5857 73.8807 79.4734 74.0498 79.3976 74.236C79.3218 74.4222 79.284 74.6216 79.2866 74.8226C79.2882 75.0186 79.3297 75.2123 79.4085 75.3919C79.4873 75.5714 79.6017 75.7332 79.7449 75.8674L84.201 80.1364C84.4802 80.4035 84.8524 80.5521 85.2392 80.5509C85.626 80.5498 85.9972 80.399 86.2749 80.1303L90.4889 76.0551C90.5651 75.9795 90.6625 75.9288 90.7683 75.9098C90.9022 75.8884 91.0378 75.8787 91.1734 75.8807H91.1836C91.3355 75.8809 91.4841 75.836 91.6104 75.7517C91.7366 75.6674 91.8349 75.5474 91.8926 75.4072C91.9503 75.2669 91.9648 75.1127 91.9343 74.9642C91.9038 74.8156 91.8297 74.6795 91.7214 74.5732ZM83.3708 70.1081L81.9607 68.7014C81.8974 68.6384 81.8471 68.5636 81.8128 68.4812C81.7786 68.3988 81.7609 68.3105 81.7609 68.2213C81.7609 68.1321 81.7786 68.0437 81.8128 67.9613C81.8471 67.879 81.8974 67.8041 81.9607 67.7412L82.1359 67.5667C82.1992 67.5035 82.2744 67.4534 82.3571 67.4192C82.4398 67.385 82.5284 67.3674 82.6179 67.3674C82.7074 67.3674 82.7961 67.385 82.8788 67.4192C82.9615 67.4534 83.0366 67.5035 83.0999 67.5667L84.5154 68.9683L83.3708 70.1081Z"
						fill="#1F1F22"></path>
				</g>
				<path
					d="M98.5448 104.068L101.954 100.653L107.635 107.483L110.476 105.206C108.772 103.119 105.363 98.3757 105.363 98.3757C105.363 98.3757 108.393 97.0475 109.908 96.668L94.5677 85.853L98.5448 104.068Z"
					fill="#F2D5F6"></path>
				<path fillRule="evenodd" clipRule="evenodd"
				      d="M94 85L110.78 96.83L109.999 97.0255C109.27 97.2082 108.156 97.6258 107.207 98.0059C106.737 98.1945 106.313 98.3714 106.007 98.5011C105.982 98.5118 105.957 98.5222 105.934 98.5322C106.015 98.6447 106.113 98.7802 106.225 98.9346C106.571 99.4114 107.05 100.069 107.582 100.791C108.647 102.239 109.921 103.939 110.766 104.975L111 105.261L107.582 108L101.929 101.203L98.3258 104.812L94 85ZM105.363 98.3757L105.212 98.0386L105.221 98.0346L105.247 98.0232L105.347 97.98C105.433 97.9427 105.558 97.889 105.713 97.8234C106.022 97.6922 106.451 97.5134 106.927 97.3225C107.604 97.0513 108.389 96.7508 109.069 96.5297L95.1354 86.7061L98.7637 103.324L101.979 100.102L107.688 106.966L109.954 105.15C109.106 104.091 107.951 102.546 106.979 101.225C106.445 100.501 105.966 99.8422 105.619 99.3644C105.446 99.1254 105.306 98.9317 105.209 98.7978L105.059 98.5893L105.363 98.3757ZM105.363 98.3757L105.059 98.5893L104.794 98.2215L105.212 98.0386L105.363 98.3757Z"
				      fill="#1F1F22"></path>
			</g>
			<defs>
				<clipPath id="clip0_4741_90044">
					<rect width="16" height="16" fill="white" transform="translate(79 66)"></rect>
				</clipPath>
			</defs>
		</svg>
	);
};

export default Designer;