import React from 'react'
import styled from 'styled-components'
import SkeletonDiv from '../../UiKit/SkeletonDiv/SkeletonDiv'
import { makeEmptyArray } from '../../Extensions/array'
import { randomNumberBetween } from '../../Extensions/number'

const WrapText = styled.div`
  gap: 4px;
  display: flex;
  flex-wrap: wrap;
`

type SkeletonTextPropType = {
  wordCount: number
  height?: number
}
const SkeletonText = (props: SkeletonTextPropType) => {
  const { wordCount, height } = props
  return (
    <WrapText>
      {makeEmptyArray(wordCount).map((_, index) => {
        return (
          <SkeletonDiv
            key={index}
            width={randomNumberBetween(30, 100, true)}
            height={height ?? 10}
          />
        )
      })}
    </WrapText>
  )
}
export default SkeletonText
