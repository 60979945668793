import React, { useEffect, useState } from 'react'
import { ActionButtonWrapper, Container, Footer, LoaderWrapper } from './styled'
import Admission from './Components/Admission'
import Trajectory from './Components/Trajectory'
import Button from '../../std-lib/UiKit/Button'
import { css } from 'styled-components'
import Text from '../../std-lib/FastUI/Components/Text'
import { Club, finalRequest } from './API/finalRequest'
import { SimpleTrajectoryType, TrajectoryType } from './types'
import TrajectoryInfoBlock from './Components/TrajectoryInfoBlock'
import Flex from '../../std-lib/FastUI/Components/Flex'
import ImageStack from './Components/ImageStack'
import HeroImage from 'Images/Static/FinalHero.png'
import FinalHeader from './Components/FinalHeader'
import CoursesView from './Components/CoursesView'
import InfoBlock from './Components/InfoBlock'
import Career from './Components/Career'
import ContactForm from './Components/ContactForm'
import { default as ClubCard } from './Components/Club'
import SemesterView from './Components/SemesterView'
import Rectangle from '../../std-lib/FastUI/Components/Rectangle'
import Chevron from '../../Images/icons/chevron'
import Br from '../../std-lib/FastUI/Components/Br'
import { useUserData } from '../Onboarding/UserDataStorage/UseUserData'
import TrajectoriesLoader from '../../Components/TrajectroriesLoader'
import * as Scroll from 'react-scroll'
import { makeAbitLink } from 'Utils/makeAbitLink'
import { sendTypedEvent } from 'Utils/events/sendEvent'
import * as amplitude from '@amplitude/analytics-browser'
import Slider from './Components/Slider'
import { useLocation } from 'react-router-dom'
const Final = () => {
  const { getUserData } = useUserData()

  const search = useLocation().search
  const searchParams = new URLSearchParams(search)
  const id = searchParams.get('id')

  const [isLearningPlanOpen, setIsLearningPlanOpen] = useState(false)
  const [trajectories, setTrajectories] = useState<SimpleTrajectoryType[]>([])
  const [trajectory, setTrajectory] = useState<TrajectoryType | undefined>(undefined)
  const [isLoadingFinished, setIsLoadingFinished] = useState<boolean>(!!id)
  const [maxCoverage, setMaxCoverage] = useState<number | null>(null)
  const [clubs, setClubs] = useState<Club[]>([])

  const isMaster = getUserData().userType === 'master'
  const getFinalData = async (id: number) => {
    finalRequest.getFinalData(id).then(({ data }) => {
      setTrajectory(data)
    })
  }
  const getTrajectories = () => {
    const userData = getUserData();
    if (!userData.selectedProfession) return;
    const presetsId = userData.selectedSkillSets.map(preset => preset.id);

    finalRequest
      .getTrajectories(presetsId, userData.selectedProfession?.id, isMaster ? 2 : 1)
      .then(async ({ data }) => {
        let maxCoverage = 0;
        let suitableTrajectory = data[0];

        for (const trajectory of data) {
          if (trajectory.is_required && trajectory.coverage > maxCoverage) {
            maxCoverage = trajectory.coverage;
            suitableTrajectory = trajectory;
          }
        }

        if (suitableTrajectory && !id) {
          await getFinalData(suitableTrajectory.id);
        }

        setTrajectories(data)
        setMaxCoverage(maxCoverage)
      })
  };
  const getClubs = async () => {
    const interestsId = getUserData().selectedHobbies.map(({ id }) => id)
    const clubs = (await finalRequest.getClubs(interestsId)).data

    const clubsObject: Record<Club['category'], Array<Club>> = {}

    clubs.forEach(item => {
      if (clubsObject[item.category]) {
        clubsObject[item.category].push(item)
      } else {
        clubsObject[item.category] = [item]
      }
    })

    for (const category in clubsObject) {
      const filteredClubs = clubsObject[category]
        .sort((a, b) => b.tags.length - a.tags.length)
        .slice(0, 4)
      setClubs(prev => [...filteredClubs, ...prev])
    }
  }
  const onLoadingFinish = (isLoading: boolean) => {
    setIsLoadingFinished(isLoading)
  }
  const handleTrajectoryClick = (id: number) => {
    sendTypedEvent('click', 'change_trajectory', 'trajectory')
    Scroll.animateScroll.scrollToTop()
    window.location.href = `${window.location.pathname}?id=${id}`
  }

  useEffect(() => {
    if (id) {
      getFinalData(+id)
    }
    getTrajectories()
    getClubs()
  }, [])

  useEffect(() => {
    if (!isLoadingFinished) {
      sendTypedEvent('visit', 'loader', 'trajectory')
    } else {
      sendTypedEvent('visit', 'page', 'trajectory')
    }
  }, [isLoadingFinished])

  return (
    <Flex direction={'column'} alignItems={'center'} justifyContent={'center'}>
      {!isLoadingFinished && (
        <LoaderWrapper>
          <TrajectoriesLoader
            onLoadingFinish={onLoadingFinish}
            canFinishAnimation={Boolean(trajectories.length)}
            minAnimationTime={8}
          />
        </LoaderWrapper>
      )}
      {isLoadingFinished && (
        <>
          <FinalHeader />
          {trajectory && (
            <Container>
              <InfoBlock title={''}>
                <Flex
                  justifyContent={'space-between'}
                  mobile={{
                    direction: 'column-reverse',
                    alignItems: 'center',
                  }}
                  fragment={css`width: 100%`}
                >
                  <TrajectoryInfoBlock
                    coverage={trajectory.coverage}
                    code={trajectory.code}
                    OPName={trajectory.educational_plan}
                    id={trajectory.id}
                    abitLink={trajectory.abit_link}
                    isTaughtInEnglish={trajectory.is_taught_in_english}
                    isTaughtInRussian={trajectory.is_taught_in_russian}
                  />
                  <ImageStack imgSrc={HeroImage} />
                </Flex>
              </InfoBlock>
              {trajectories && trajectory && maxCoverage && (
                <InfoBlock title={'Другие подходящие траектории'}>
                  <Slider dataLength={trajectories.length}>
                    {trajectories.filter(
                      simpleTrajectory => simpleTrajectory.id !== trajectory.id,
                    )
                      .sort((a, b) => b.coverage - a.coverage)
                      .map(simpleTrajectory => (
                        <Trajectory
                          key={simpleTrajectory.id}
                          name={simpleTrajectory.educational_plan}
                          coverage={Math.min(simpleTrajectory.coverage, maxCoverage)}
                          code={simpleTrajectory.code}
                          onClick={() => handleTrajectoryClick(simpleTrajectory.id)}
                        />
                      ))}
                  </Slider>
                </InfoBlock>
              )}
              <InfoBlock
                title={'Тебе предстоит изучить'}
              >
                <CoursesView trajectory={trajectory} />
              </InfoBlock>
              <Rectangle margin={'auto'}>
                <Button
                  buttonStyle={'secondary'}
                  onClick={() => {
                    sendTypedEvent('click', 'full_edu_plan', 'trajectory')
                    setIsLearningPlanOpen(!isLearningPlanOpen)
                  }}
                  styledFragment={css`
                    max-width: 100%`}
                >
                  {`${isLearningPlanOpen ? 'Скрыть' : 'Показать'} учебный план`}
                  <Br width={24} />
                  <Chevron
                    rotate={isLearningPlanOpen ? -90 : 90}
                    primaryColor={'#8533FF'}
                  />
                </Button>
              </Rectangle>
              {isLearningPlanOpen && (
                <InfoBlock
                  title={'Учебный план'}
                  subtitle={'Твой персональный учебный план готов! Возможны небольшие отличия в деталях.'}
                >
                  <SemesterView trajectory={trajectory} />
                </InfoBlock>
              )}
              <InfoBlock title={'Ты сможешь стажироваться и работать'}>
                <Career trajectory={trajectory} />
              </InfoBlock>
              <InfoBlock title={'В ИТМО тебя ждут'}>
                <Slider dataLength={clubs.length}>
                  {clubs.map(club => (
                    <ClubCard key={club.id} {...club} />
                  ))}
                </Slider>
              </InfoBlock>
              {/* <InfoBlock title={'Как поступить'}> */}
              {/*  <Admission /> */}
              {/* </InfoBlock> */}
              <ActionButtonWrapper>
                <Button
                  onClick={() => {
                    sendTypedEvent('click', 'go_to_abit', 'trajectory')
                    const identify = new amplitude.Identify().add(
                      'viewd_trajectory_count',
                      1,
                    )
                    amplitude.identify(identify)
                    window.open(
                      makeAbitLink(trajectory?.abit_link, trajectory?.id),
                      '_blank',
                    )
                  }}
                  styledFragment={css`
                    width: 330px;
                    height: 60px;
                    padding: 16px 40px;
                    font-size: 20px;
                    font-weight: 600;
                    border-radius: 40px;

                    @media screen and (max-width: 1000px) {
                      padding: 8px 20px;
                      font-size: 14px;
                      height: 40px;
                    }
                  `}
                >
                  Присоединиться к ИТМО
                </Button>
              </ActionButtonWrapper>
            </Container>
          )}
          <ContactForm />
          <Footer>
            <Text size={14} weight={400}>
              Сделано студентами для студентов 💜
            </Text>
          </Footer>
        </>
      )}
    </Flex>
  )
}

export default Final
