export const theme = {
  color: {
    brand: {
      primary: '#8533FF',
      secondary: '#F9CF6B',
    },
    subject: {
      necessary: '#FF824D',
      elective: '#37CE86',
      faculty: '#4DBFFF',
    },
    text: {
      primary: '#1F1F22',
      secondary: '#79797A',
      tertiary: '#B7B7B8',
      button: '#8533FF',
      white: '#FFFFFF',
    },
    link: {
      default: '#79797A',
      hover: '#8533FF',
      active: '#712FD3',
    },
    background: {
      withStroke: {
        primary: '#FFFFFF',
      },
      noStroke: {
        default: '#F4F4FB',
        hover: '#EBEBFD',
        active: '#E8E5F6',
      },
      tags: {
        main: '#EBEBFD',
        preset: '#D8D7FE',
        biotech: '#C6F0B3',
        management: '#FCE8B9',
        it: '#C1D5F6',
        optics: '#C5E9F0',
        art: '#F2D5F6',
        engineering: '#F8CFB9',
      },
      trackCard: {
        math: '#7C81F1',
        electronics: '#6290D0',
        humanitarian: '#9480EF',
        programming: '#677CE8',
        naturalScience: '#75C2DC',
        practice: '#989CF8',
        economics: '#F18AA7',
        sport: '#E185CD',
        intro: '#CF7DDC',
        softSkills: '#F49987',
        physics: '#B386E7',
        other: '#996FDB',
        additional1: '#789DE2',
        additional2: '#9592F3',
        additional3: '#C083C9',
        additional4: '#CE81B5',
        additional5: '#F57B7B',
      },
    },
    elements: {
      icons: {
        primary: '#1F1F22',
        secondary: '#79797A',
        tertiary: '#B7B7B8',
        quaternary: '#FFFFFF',
        button: '#8533FF',
      },
      stroke: {
        default: '#EDEDED',
        hover: '#8533FF',
        active: '#712FD3',
        warning: '#FF824D',
      },
      bar: {
        default: '#D8BEFF',
        active: '#C49DFF',
        disabled: '#EDEDED',
      },
    },
    states: {
      primary: {
        default: '#8533FF',
        hover: '#C49DFF',
        active: '#712FD3',
        disabled: '#D8BEFF',
      },
    },
  },

  border: {
    background: '20px',
    tag: '8px',
    button: '12px',
  },
}

export type ThemeFragment = {
  bgColorPrimary: string
  bgColorSecondary: string
}

export const primaryThemeFragment: ThemeFragment = {
  bgColorPrimary: '#FFFFFF',
  bgColorSecondary: '#EBEBFD',
}

export const secondaryThemeFragment: ThemeFragment = {
  bgColorPrimary: '#EBEBFD',
  bgColorSecondary: '#FFFFFF',
}

export type ThemeType = typeof theme

export const flatObject = (obj: object) => {
  function flat(o: object, prev: string): [string, string][] {
    return Object.entries(o).flatMap(([key, val]) => {
      if (typeof val === 'object') return flat(val, prev + `${!prev ? '' : '-'}` + key)
      return [[prev + '-' + key, val]]
    })
  }

  return Object.fromEntries(flat(obj, ''))
}
