import React, { useState } from 'react'
import Text from '../../../../std-lib/FastUI/Components/Text'
import { css, useTheme } from 'styled-components'
import { Tab, TabsWrapper } from './styled'

interface TabsProps {
  tabs: any[]
  onTabClick: (newValue: any) => void
}

const Tabs = (props: TabsProps) => {
  const { tabs, onTabClick } = props
  const theme = useTheme()

  const [activeTab, setActiveTab] = useState<number>(0)

  const handleTabClick = (index: number, id: number | string) => {
    setActiveTab(index)
    onTabClick(id)
  }

  return (
    <TabsWrapper>
      {tabs.map((tab, index) => (
        <Tab
          key={tab.id}
          onClick={() => handleTabClick(index, tab.id)}
          isActive={activeTab === index}
        >
          <Text
            size={20}
            weight={600}
            lineHeight={28}
            fragment={css`
              color: ${
                activeTab === index ? theme.color.text.button : theme.color.text.primary
              };
            `}
          >
            {tab.name}
          </Text>
          {tab.description && (
            <Text
              size={14}
              weight={400}
              lineHeight={22}
              fragment={css`
                color: ${theme.color.text.secondary};
              `}
            >
              {tab.description}
            </Text>
          )}
        </Tab>
      ))}
    </TabsWrapper>
  )
}

export default Tabs
